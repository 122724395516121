import { call, put, takeLatest } from "redux-saga/effects";
import {
  ADD_ENROLLMENT_VERIFICATION,
  ADD_LEAVE_ABSENCE,
  GET_WITHDRAWAL_REASONS,
  ADD_ADMISSION_WITHDRAWAL,
  ADD_TRANSCRIPT,
  ADD_CERTIFICATE,
  ADD_ENROLLMENT,
  GET_HOLIDAYS,
  ADD_GRIEVANCE,
} from "../ActionTypes";
import {
  addEnrollmentVerificationAPI,
  addLeaveAbsenceAPI,
  getWithdrawalReasonsAPI,
  addAdmissionWithdrawalAPI,
  addTranscriptAPI,
  addCertificateAPI,
  addEnrollmentAPI,
  getHolidaysAPI,
  addGrievanceAPI,
} from "../Api";
import {
  addEnrollmentVerificationFailure,
  addEnrollmentVerificationSuccess,
  addLeaveAbsenceSuccess,
  addLeaveAbsenceFailure,
  getWithdrawalReasonsSuccess,
  getWithdrawalReasonsFailure,
  addAdmissionWithdrawalSuccess,
  addAdmissionWithdrawalFailure,
  addTranscriptFailure,
  addTranscriptSuccess,
  addCertificateSuccess,
  addCertificateFailure,
  addEnrollmentSuccess,
  addEnrollmentFailure,
  getHolidaysSuccess,
  getHolidaysFailure,
  addGrievanceSuccess,
  addGrievanceFailure,
} from "../actions/StudentServices.action";

function* addEnrollmentVerificationSaga(action) {
  try {
    const response = yield call(addEnrollmentVerificationAPI, action.payload);
    if (response) {
      yield put(addEnrollmentVerificationSuccess(response));
    }
  } catch (error) {
    yield put(addEnrollmentVerificationFailure(error));
    console.log("error", error);
  }
}

function* addLeaveAbsenceSaga(action) {
  try {
    const response = yield call(addLeaveAbsenceAPI, action.payload);
    if (response) {
      yield put(addLeaveAbsenceSuccess(response));
    }
  } catch (error) {
    yield put(addLeaveAbsenceFailure(error));
    console.log("error", error);
  }
}

function* getWithdrawalReasonsSaga(action) {
  try {
    const response = yield call(getWithdrawalReasonsAPI, action.payload);
    if (response) {
      yield put(getWithdrawalReasonsSuccess(response.data));
    }
  } catch (error) {
    yield put(getWithdrawalReasonsFailure(error));
    console.log("error", error);
  }
}

function* addAdmissionWithdrawalSaga(action) {
  try {
    const response = yield call(addAdmissionWithdrawalAPI, action.payload);
    if (response) {
      yield put(addAdmissionWithdrawalSuccess(response));
    }
  } catch (error) {
    yield put(addAdmissionWithdrawalFailure(error));
    console.log("error", error);
  }
}

function* addTranscriptSaga(action) {
  try {
    const response = yield call(addTranscriptAPI, action.payload);
    if (response) {
      yield put(addTranscriptSuccess(response));
    }
  } catch (error) {
    yield put(addTranscriptFailure(error));
    console.log("error", error);
  }
}

function* addCertificateSaga(action) {
  try {
    const response = yield call(addCertificateAPI, action.payload);
    if (response) {
      yield put(addCertificateSuccess(response));
    }
  } catch (error) {
    yield put(addCertificateFailure(error));
    console.log("error", error);
  }
}

function* addEnrollmentSaga(action) {
  try {
    const response = yield call(addEnrollmentAPI, action.payload);
    if (response) {
      yield put(addEnrollmentSuccess(response));
    }
  } catch (error) {
    yield put(addEnrollmentFailure(error));
    console.log("error", error);
  }
}

function* getHolidaysSaga(action) {
  try {
    const response = yield call(getHolidaysAPI, action.payload);
    if (response) {
      const disabledDatesArray = response.data.data.map((item) => {
        // Convert ISO date string to Date object
        const date = new Date(item.date);
        // Return formatted date in 'YYYY-MM-DD' format
        return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
          2,
          "0"
        )}-${String(date.getDate()).padStart(2, "0")}`;
      });
      yield put(getHolidaysSuccess(disabledDatesArray));
    }
  } catch (error) {
    yield put(getHolidaysFailure(error));
    console.log("error", error);
  }
}

function* addGrievanceSaga(action) {
  try {
    const response = yield call(addGrievanceAPI, action.payload);
    if (response) {
      yield put(addGrievanceSuccess(response));
    }
  } catch (error) {
    yield put(addGrievanceFailure(error));
    console.log("error", error);
  }
}

export function* studentServicesSaga() {
  yield takeLatest(ADD_ENROLLMENT_VERIFICATION, addEnrollmentVerificationSaga);
  yield takeLatest(ADD_LEAVE_ABSENCE, addLeaveAbsenceSaga);
  yield takeLatest(GET_WITHDRAWAL_REASONS, getWithdrawalReasonsSaga);
  yield takeLatest(ADD_ADMISSION_WITHDRAWAL, addAdmissionWithdrawalSaga);
  yield takeLatest(ADD_TRANSCRIPT, addTranscriptSaga);
  yield takeLatest(ADD_CERTIFICATE, addCertificateSaga);
  yield takeLatest(ADD_ENROLLMENT, addEnrollmentSaga);
  yield takeLatest(GET_HOLIDAYS, getHolidaysSaga);
  yield takeLatest(ADD_GRIEVANCE, addGrievanceSaga);
}
