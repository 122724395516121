import React from "react";
import projectManagementCourse from "../../../../assets/images/projectManagementCourse.svg";
import coursePlayIcon from "../../../../assets/images/coursePlayIcon.svg";

const Overview = () => {
  return (
    <>
      <div>
        <div className="text-content md:ml-[20px] md:mb-[20px] xl:ml-[99px] xl:mb-[40px] xl:mt-[85px]  w-1/2 float-right hidden md:flex justify-center items-center relative">
          <img
            src={projectManagementCourse}
            alt="Project Management Professional Course"
          />
          <img
            className="absolute"
            src={coursePlayIcon}
            alt="course play icon"
          />
        </div>
        <p className="font-[500] text-[20px] md:text-[24px] xl:text-[32px] leading-[36px] xl:leading-[48px] text-[#003C9D]">
          Project Management Professional Program
        </p>
        <div className="flex md:hidden justify-center items-center mt-10">
          <img
            src={projectManagementCourse}
            alt="Project Management Professional Course"
          />
          <img
            className="absolute"
            src={coursePlayIcon}
            alt="course play icon"
          />
        </div>
        <p className="mt-10 text-[18px] md:text-[20px] leading-[45px]">
          Project Management is the discipline of planning, organizing,
          motivating, and effectively controlling resources to achieve specific
          goals. A project is a focused temporary endeavor to implement and
          achieve specific results within in a fixed schedule. The project goals
          and objectives are typically to bring about a beneficial change or
          achieve added value. The completion date is frequently dictated by the
          needs of the client or business group and may require aggressive fast
          track scheduling techniques to achieve the deadlines. The temporary
          nature of projects stands in contrast with business as usual (or
          operations), which are repetitive, permanent, or semi-permanent
          functional activities to produce products or services. In practice,
          the management of these two systems is often quite different, and as
          such requires the development of distinct technical skills and
          management strategies to achieve quality and meet the deadline within
          the budget.
        </p>
      </div>
      <div className="mt-10 md:mt-[60px]">
        <p className="font-[500] text-[20px] md:text-[24px] xl:text-[32px] leading-[36px] xl:leading-[48px] text-[#003C9D]">
          Program Outline
        </p>
        <div className="p-6 border-[1px] rounded-[5px] border-[#D9D9D9] mt-5 md:mt-10 shadow-lg">
          <ul className="list-disc font-[400] text-[18px] md:text-[20px] text-[#1B2336] leading-[35px] flex flex-col gap-[10px] ml-6">
            <li>
              Prerequisite:{" "}
              <span className="font-[500] text-[#003C9D]">
                Proof of High School Diploma or GED required.{" "}
              </span>
            </li>
            <li>
              Program Length:{" "}
              <span className="font-[500] text-[#003C9D]">6 Weeks</span>
            </li>
            <li>
              Academic Credential Awarded:{" "}
              <span className="font-[500] text-[#003C9D]">Certificate</span>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Overview;
