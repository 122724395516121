import { all } from "redux-saga/effects";
import { commonSaga } from "./common.saga";
import { blogCommonSaga } from "./blog.saga";
import { appointmentBookingSaga } from "./appointmentBook.saga";
import { educationLevelSaga } from "./educationLevel.saga";
import { studentServicesSaga } from "./studentServices.saga";

export default function* rootSaga() {
  yield all([
    commonSaga(),
    blogCommonSaga(),
    appointmentBookingSaga(),
    educationLevelSaga(),
    studentServicesSaga(),
  ]);
}
