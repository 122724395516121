import React from "react";

const CourseOutcomes = () => {
  return (
    <>
      <div>
        <p className="font-[500] text-[20px] md:text-[24px] xl:text-[32px] leading-[36px] xl:leading-[48px] text-[#003C9D]">Certified Nurse Assistant Program Outcome</p>

        <div className="text-content text-[18px] md:text-[20px] leading-[35px] flex flex-col gap-5 md:gap-10 mt-5 md:mt-10 text-[#5C616E]">
          <p className="">Upon the completion of this CNA Program the student will be able to:</p>
          <p>
            The CNA Program is designed to ensure that students are well prepared to enter the profession as a Nursing
            Assistant and have the ability to continue their learning throughout their professional careers. At the
            completion of the CNA program, graduates will be able to function as Nursing  assistants who are competent
            in the following:
          </p>

          <ul className="list-disc ml-6 flex leading-[45px] flex-col gap-4">
            <li>Define the role of a nursing assistant.</li>
            <li>Describe the responsibilities of a nursing assistant.</li>
            <li>Perform specified skills required to function as a member of the health care team as a nursing assistant.</li>
            <li>Demonstrate safe, competent care within the practice guidelines of a nursing assistant.</li>
            <li>Provide care in accordance with the rights of every patient.</li>
            <li>Perform basic nursing skills for clients in a variety of settings.</li>
            <li>Safely and effectively perform personal care skills.</li>
            <li>
              Recognize the psychological, emotional, physical and spiritual needs of clients.
            </li>
            <li>Demonstrate appropriate attitudes in giving nursing care.</li>
            <li>
              Exhibit proper ethical and legal behavior in giving nursing care.
            </li>
            <li>Demonstrate effective communication and interpersonal skills.</li>
            <li>Demonstrate behavior that maintains the rights of the client.</li>
            <li>Perform nursing care with regard to the principles of infection control.</li>
            <li>Acquire the proper knowledge and skills to respond to emergencies.</li>

          </ul>
        </div>
      </div>
    
    
    </>
  );
};

export default CourseOutcomes;
