import React from "react";
import coursePlayIcon from "../../../../assets/images/coursePlayIcon.svg";
import massageTherapyCourse from "../../../../assets/images/massageTherapyCourse.svg";

const Overview = () => {
  return (
    <>
      <div className="inline-block">
        <div className="text-content md:ml-[20px] md:mb-[20px] xl:ml-[99px] xl:mb-[40px] xl:mt-[85px]  w-1/2 float-right hidden md:flex justify-center items-center relative">
          <img
            src={massageTherapyCourse}
            alt="Yoga Teacher Training Certificate Program (200-Hours)"
          />
          <img
            className="absolute"
            src={coursePlayIcon}
            alt="course play icon"
          />
        </div>
        <p className="font-[500] text-[20px] md:text-[24px] xl:text-[32px] leading-[36px] xl:leading-[48px] text-[#003C9D]">
          Massage Therapy Program
        </p>
        <div className="flex md:hidden justify-center items-center mt-10">
          <img
            src={massageTherapyCourse}
            alt="Yoga Teacher Training Certificate Program (200-Hours)"
          />
          <img
            className="absolute"
            src={coursePlayIcon}
            alt="course play icon"
          />
        </div>
        <p className="text-[18px] md:text-[20px] leading-[45px] text-[#5C616E] mt-10">
          The Massage Therapy Program caters to students aspiring to enter the
          world of massage therapy. Participants receive comprehensive education
          on their responsibilities and the role of a massage therapist. This
          program enhances professional skills and deepens knowledge within the
          field. With a curriculum encompassing 650 hours of instructional and
          clinical training, students gain a profound understanding of various
          massage styles, techniques, and client care fundamentals. They emerge
          equipped with the expertise to address diverse client needs
          effectively.
        </p>
        <p className="text-[18px] md:text-[20px] leading-[45px] text-[#5C616E] mt-4">
          This program prepares you for massage therapy state licensing exams.
          once you complete the massage therapy program you are eligible to for
          the state licensing MBLEX exams.
        </p>
      </div>
      <div className="mt-10 md:mt-[60px]">
        <p className="font-[500] text-[20px] md:text-[24px] xl:text-[32px] leading-[36px] xl:leading-[48px] text-[#003C9D]">
          What are the Key Reasons for Pursuing a Massage Therapy Program?
        </p>
        <p className="text-[18px] md:text-[20px] leading-[40px] mt-5 md:mt-10 text-[#5C616E]">
          There are several key reasons why someone might pursue a Massage
          Therapy Program:
        </p>
        <ul className="mt-6 text-[#F37021] font-[500] text-[20px] md:text-[24px] leading-[30px] flex flex-col gap-6">
          <li className="flex flex-col relative before:content-[''] before:absolute before:left-0 before:w-[30px] pl-[54px] before:h-[30px] before:bg-checkmark-bg before:bg-no-repeat">
            Passion for Helping Others
            <span className="mt-4 text-[#5C616E] text-[18px] md:text-[20px] leading-10 font-[400]">
              Many individuals are drawn to massage therapy because they have a
              genuine desire to help people improve their physical and mental
              well-being through therapeutic touch.
            </span>
          </li>
          <li className="flex flex-col relative before:content-[''] before:absolute before:left-0 before:w-[30px] pl-[54px] before:h-[30px] before:bg-checkmark-bg before:bg-no-repeat">
            Career Opportunities
            <span className="mt-4 text-[#5C616E] text-[18px] md:text-[20px] leading-10 font-[400]">
              Massage therapy offers a variety of career paths, including
              working in spas, wellness centers, chiropractic offices,
              hospitals, and even starting their own private practice. The
              demand for qualified massage therapists continues to grow,
              providing stable employment prospects.
            </span>
          </li>
          <li className="flex flex-col relative before:content-[''] before:absolute before:left-0 before:w-[30px] pl-[54px] before:h-[30px] before:bg-checkmark-bg before:bg-no-repeat">
            Flexible Work Schedule
            <span className="mt-4 text-[#5C616E] text-[18px] md:text-[20px] leading-10 font-[400]">
              Massage therapists often have the flexibility to set their own
              schedules, allowing for a better work-life balance. They can
              choose to work full-time, part-time, or even freelance, depending
              on their preferences and lifestyle.
            </span>
          </li>
          <li className="flex flex-col relative before:content-[''] before:absolute before:left-0 before:w-[30px] pl-[54px] before:h-[30px] before:bg-checkmark-bg before:bg-no-repeat">
            Personal Growth and Development
            <span className="mt-4 text-[#5C616E] text-[18px] md:text-[20px] leading-10 font-[400]">
              Engaging in a massage therapy program not only provides technical
              skills but also fosters personal growth and development. Students
              learn about anatomy, physiology, and psychology, which can deepen
              their understanding of the human body and mind.
            </span>
          </li>
          <li className="flex flex-col relative before:content-[''] before:absolute before:left-0 before:w-[30px] pl-[54px] before:h-[30px] before:bg-checkmark-bg before:bg-no-repeat">
            Holistic Approach to Health
            <span className="mt-4 text-[#5C616E] text-[18px] md:text-[20px] leading-10 font-[400]">
              Massage therapy is often viewed as a complementary and alternative
              medicine practice that takes a holistic approach to health.
              Students in massage therapy programs learn how to address not only
              physical ailments but also emotional stress and tension, promoting
              overall wellness.
            </span>
          </li>

          <li className="flex flex-col relative before:content-[''] before:absolute before:left-0 before:w-[30px] pl-[54px] before:h-[30px] before:bg-checkmark-bg before:bg-no-repeat">
            Continual Learning
            <span className="mt-4 text-[#5C616E] text-[18px] md:text-[20px] leading-10 font-[400] flex flex-col gap-[10px]">
              <p>
                The field of massage therapy is constantly evolving, with new
                techniques and modalities emerging. Pursuing a massage therapy
                program allows individuals to stay updated on the latest trends
                and advancements in the field, ensuring they provide the best
                possible care to their clients.
              </p>
              <p>
                Pursuing a Massage Therapy program can be a fulfilling and
                rewarding journey for those passionate about helping others,
                seeking career opportunities with flexibility, and committed to
                ongoing personal and professional growth.
              </p>
            </span>
          </li>
        </ul>
      </div>
      <div className="mt-10 md:mt-[60px]">
        <p className="font-[500] text-[20px] md:text-[24px] xl:text-[32px] leading-[36px] xl:leading-[48px] text-[#003C9D]">
          Massage Therapy Program Outline
        </p>
        <div className="p-6 border-[1px] rounded-[5px] border-[#D9D9D9] mt-5 md:mt-10 shadow-lg">
          <ul className="list-disc font-[400] text-[18px] md:text-[20px] text-[#1B2336] leading-[35px] flex flex-col gap-[10px] ml-6">
            <li>
              Program Length:{" "}
              <span className="font-[500] text-[#003C9D]">
                36 Weeks, 09 months
              </span>
            </li>
            <li>
              Total Hours of Program:{" "}
              <span className="font-[500] text-[#003C9D]">650 hours</span>
            </li>
            <li>
              Academy Credential Award:{" "}
              <span className="font-[500] text-[#003C9D]">Certificate</span>
            </li>
          </ul>
        </div>
        <div className="p-6 border-[1px] rounded-[5px] border-[#D9D9D9] mt-6 shadow-lg">
          <ul className="font-[400] text-[18px] md:text-[20px] text-[#1B2336] leading-[30px] flex flex-col gap-[10px]">
            <li>Module 1: Introduction to Massage Therapy</li>
            <li>Module 2: Medical Terminology</li>
            <li>Module 3: Anatomy & Physiology I</li>
            <li>Module 4: Musculoskeletal Anatomy & Kinesiology I</li>
            <li>Module 5: Pathology I</li>
            <li>Module 6: Musculoskeletal Anatomy & Kinesiology II</li>
            <li>Module 7: Massage Technique I</li>
            <li>Module 8: Massage Technique II</li>
            <li>
              Module 9: Therapeutic Application I (Effect and Contraindication)
            </li>
            <li>Module 10: Oriental Medicine Theory</li>
            <li>Module 11: Shiatsu Massage / 16 movements</li>
            <li>Module 12: Swedish Massage</li>
            <li>Module 13: Business and Professional Standard</li>
            <li>Module 14: Ethics and Legal Practice</li>
            <li>Externship</li>
          </ul>
        </div>
        <div className="mt-10 md:mt-[60px]">
          <div className="">
            <p className="font-[500] text-[20px] md:text-[32px] leading-[36px] text-[#003C9D]">
              What you get from us?
            </p>
            <ul className="list-disc font-[400] text-[18px] md:text-[20px] text-[#5C616E] leading-[30px] mt-10 flex flex-col gap-4 ml-6">
              <li>Lectures and material access</li>
              <li>Live instructor for your queries</li>
              <li>Administrative support</li>
              <li>Onsite clinical training</li>
              <li>Career pathways opportunities and guidelines</li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Overview;
