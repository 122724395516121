import React from "react";

function FAQ() {
  const data1 = [
    {
      title: "How long does it take to complete this course?",
      content:
        "An LPN program provides one of the quickest pathways to a nursing career. This Programs is of 1554 hours of training and most students complete their training in 12  to 15months.",
    },
    {
      title: "What kind of support will I receive?",
      content:
        "The course instructor will be available by email to answer any questions and provide feedback on your performance. Occasionally, your course may be supported by a team of industry experts. You will also receive support from the student advising team.",
    },
    {
      title:
        "What if I don't have enough time to complete my course within the time frame provided?",
      content:
        "The time allotted for course completion has been calculated based on the number of course hours. However, if you are unable to complete the course, contact the student advising team to see what options you may have available to work out a suitable completion date. Please note that an extension fee may be charged.",
    },
    {
      title: "What happens when I complete the course?",
      content:
        "Upon successful completion of the course, you will be awarded a Certificate of Completion and you are eligible for NCLEX-PN.",
    },
    {
      title: "Am I guaranteed a job?",
      content:
        "This course will provide you with the skills you need to obtain an entry-level position in most cases. We will assist you with your resume preparation and finding a job. Also, Potential students should always do research on the job market in their area before registering.",
    },
    {
      title: "Can I get financial assistance?",
      content:
        "This course is non-credit, so it does not qualify for federal aid, FAFSA and Pell Grant. In some states, vocational rehab or workforce development boards will pay for qualified students to take our courses.  ",
    },
    {
      title: "How much does Program school cost?",
      content:
        "Tuition Fees at Param Institute of Education varies for different program offered, but we assure you that our tuition is very competitive among the other vocational schools in New Jersey. Remember that attending program at Param institute of Education is an investment in your future as a healthcare professional. Plus, there are ways to fund your education through scholarships offered at school making it easier than ever to pay for your education.",
    },
    {
      title: "How can I get more information about this course?",
      content:
        'If you have questions that are not answered on our website, representatives are available via EMAIL (info@paramedu.com). You can also call us at +1 848 256 2737  during regular business hours to have your questions promptly answered. If you are visiting us during non-business hours, please send us a question using the "Contact Us" form.',
    },
  ];
  return (
    <div className=" w-full font-poppins flex flex-col gap-5 md:gap-10 items-center">
      <p className="w-full font-[500] text-[20px] md:text-[24px] xl:text-[32px] text-[#003C9D] ">
        LPN  Program FAQs
      </p>
      <div className="flex flex-col gap-6">
        <div className="w-auto md:w-[98%] ml-7 flex flex-col gap-3">
          <ul className=" list-disc font-[500] text-[18px] md:text-[20px] text-[#1B2336]">
            <li>What does a LPN do?</li>
          </ul>
          <div className="flex flex-col gap-2">
            <p className="font-[400] text-[18px] md:text-[20px] text-[#5C616E] leading-[35px]">
              Licensed practical and licensed vocational nurses typically do the
              following:
            </p>
            <ul className="list-disc font-[400] text-[18px] md:text-[20px] text-[#5C616E] ml-8 flex flex-col gap-3">
              <li>
                Monitor patients’ health by checking their blood pressure, body
                temperature, and other vital signs
              </li>
              <li>
                Provide basic patient care and comfort, including changing
                bandages and helping with bathing or dressing
              </li>
              <li>Discuss care with patients and listen to their concerns</li>
              <li>
                Report patients’ status and concerns to registered nurses, advanced practice nurses, physicians.
                
              </li>
              <li>Document patient care and maintain health records</li>
            </ul>
          </div>
        </div>
        <div className="w-auto md:w-[98%] ml-7 flex flex-col gap-3">
          <ul className=" list-disc font-[500] text-[18px] md:text-[20px] text-[#1B2336]">
            <li>What is a Licensed Practical Nurse - LPN?</li>
          </ul>
          <p className="font-[400] text-[18px] md:text-[20px] text-[#5C616E] leading-[35px]">
            LPN/LVNs are nurses who provide standard emergency and preventative
            healthcare to patients who are sick, injured, or just need routine
            health checkups. LPNs are supervised by registered nurses and
            doctors and perform their work alongside a team of healthcare
            providers.
          </p>
          <p className="font-[400] text-[18px] md:text-[20px] text-[#5C616E] leading-[35px]">
            To obtain state certification, LPNs must complete LPN programs,
            which generally take around one year to complete from the Board of
            Nursing approved school in state of new jersey .
          </p>
          <p className="font-[400] text-[18px] md:text-[20px] text-[#5C616E] leading-[35px]">
            Becoming an LPN requires successful completion of a year-long,
            state-approved diploma or certificate program followed by a passing
            score on the National Council Licensure Examination (NCLEX-PN).
            Passing the NCLEX-PN is a requirement for licensing in all states.
          </p>
        </div>
        <div className="w-auto md:w-[98%] ml-7 flex flex-col gap-3">
          <ul className=" list-disc font-[500] text-[18px] md:text-[20px] text-[#1B2336]">
            <li>What's the Difference Between an LPN and LVN?</li>
          </ul>
          <p className="font-[400] text-[18px] md:text-[20px] text-[#5C616E] leading-[35px]">
            The simple answer to this commonly asked question is, well, simply
            the name.
          </p>
          <p className="font-[400] text-[18px] md:text-[20px] text-[#5C616E] leading-[35px]">
            For example, the licensed practical nursing (LPN) in Arizona who
            wishes to relocate to Texas or California, obtains his or her
            license in either state and is now called a licensed vocational
            nurse (LVN). There is no difference in job duties, education, or
            scope of practice, as mandated by each state, and Texas and
            California are the only states using the title LVN.
          </p>
        </div>
        <div className="w-auto md:w-[98%] ml-7 flex flex-col gap-3">
          <ul className=" list-disc font-[500] text-[18px] md:text-[20px] text-[#1B2336] flex flex-col gap-3">
            <li>What is NCLEX Exam ?</li>
            <p className="font-[400] text-[18px] md:text-[20px] text-[#5C616E] leading-[35px]">
              Practical nursing graduates are required to take the NCLEX-PN
              examination in order to gain official licensure. This exam covers
              various practical nursing topics to test the student’s
              comprehension. For more information on the NCLEX-PN, check out our
              guide here. We also offer an NCLEX-PN practice test for students
              to take in preparation for the real exam. If you don't pass the
              NCLEX-PN exam, don't worry, you'll be able to retake the test
              after 45 days.
            </p>
            <p className="font-[400] text-[18px] md:text-[20px] text-[#5C616E] leading-[35px]">
              For example, the licensed practical nursing (LPN) in Arizona who
              wishes to relocate to Texas or California, obtains his or her
              license in either state and is now called a licensed vocational
              nurse (LVN). There is no difference in job duties, education, or
              scope of practice, as mandated by each state, and Texas and
              California are the only states using the title LVN.
            </p>
            <ul className=" list-disc font-[500] text-[18px] md:text-[20px] text-[#1B2336] flex flex-col gap-3 ml-8">
              <li>Do LPNs need to pass the NCLEX?</li>
              <p className="font-[400] text-[18px] md:text-[20px] text-[#5C616E] leading-[35px]">
                All licensed practical nurses and licensed vocational nurses
                must graduate from an approved LPN program and pass the NCLEX-PN
                exam to earn their state license and practice legally in the
                U.S. The NCLEX-PN determines if LPNs and LVNs have the necessary
                knowledge and skills to enter the workplace and practice safely.
              </p>
              <li>How hard is it to become an LPN?</li>
              <p className="font-[400] text-[18px] md:text-[20px] text-[#5C616E] leading-[35px]">
                Many students find the pace of the typical LPN program
                challenging but manageable with the right attitude, time
                management skills, and study habits. Our schools efforts,
                qualified Nurse instructor, program content, clinical skills and
                lab skills will make easier pathway to success in this program.
              </p>
              <li>Is it smart to become an LPN before RN?</li>
              <p className="font-[400] text-[18px] md:text-[20px] text-[#5C616E] leading-[35px]">
                Many nurses choose to enter the workforce right after earning
                their LPN to determine if a nursing career is a good fit before
                becoming an RN. Working as an LPN can build your nursing
                experience and help you earn money to pay for the college degree
                required for RN roles.
              </p>
            </ul>
          </ul>
        </div>
        <div className="w-auto md:w-[98%] ml-7 flex flex-col gap-3">
          <ul className=" list-disc font-[500] text-[18px] md:text-[20px] text-[#1B2336]">
            <li>Do I Need to Be Certified for additional courses?</li>
          </ul>
          <p className="font-[400] text-[18px] md:text-[20px] text-[#5C616E] leading-[35px]">
            An LPN education covers a wide variety of topics, so you can leave
            your program with knowledge and skills in many areas. Earning a
            specialty certification isn’t required, but you might consider it if
            you find yourself drawn to a particular area of nursing. A
            certification also has the potential to increase your earnings.
          </p>
          <p className="font-[400] text-[18px] md:text-[20px] text-[#5C616E] leading-[35px]">
            You can specialize in areas such as:
            <ul className=" list-disc text-[18px] md:text-[20px] text-[#5C616E] ml-12">
              <li>IV therapy</li>
              <li>Long-term care</li>
              <li>Pharmacology</li>
              <li>Correctional health</li>
              <li>Developmental disabilities</li>
            </ul>
            <p className="font-[400] text-[18px] md:text-[20px] text-[#5C616E] leading-[35px]">
              State agencies, as well{" "}
              <a
                className="text-[#0755b9] underline"
                href="https://napnes.org/amember/"
                target="_blank"
              >
                as the National Association of Practical Nurse Education and
                Services (NAPNES),
              </a>{" "}
              offer specialty certification programs for LPNs.
            </p>
          </p>
        </div>
        <div className="w-auto md:w-[98%] ml-7 flex flex-col gap-3">
          <ul className=" list-disc font-[500] text-[18px] md:text-[20px] text-[#1B2336]">
            <li>Are LPN in high demand?</li>
          </ul>
          <p className="font-[400] text-[18px] md:text-[20px] text-[#5C616E] leading-[35px]">
            LPN earnings and job prospects vary by specialty area, workplace
            setting, and location. The median salary for LPN is $48,000,
            according to the{" "}
            <a
              className="text-[#0755b9] underline"
              href="https://www.bls.gov/ooh/healthcare/licensed-practical-and-licensed-vocational-nurses.htm"
              target="_blank"
            >
              Bureau of Labor Statistics
            </a>
            (BLS), which is slightly above the median annual wage for all
            occupations. The BLS projects that LPN jobs will grow 6% from 2021
            to 2031, which is as fast as average. LPNs with in-demand
            specialties in areas like gerontology or intravenous therapy can
            earn higher than average salaries. LPNs working in government
            positions tend to earn the most.
          </p>
        </div>
        <div className="w-auto md:w-[98%] ml-7 flex flex-col gap-3">
          <ul className=" list-disc font-[500] text-[18px] md:text-[20px] text-[#1B2336]">
            <li>How much does a LPN make?</li>
          </ul>
          <p className="font-[400] text-[18px] md:text-[20px] text-[#5C616E] leading-[35px]">
            The median annual wage for licensed practical and licensed
            vocational nurses was $59,730 in May 2023. Employment of licensed
            practical and licensed vocational nurses is projected to grow 5
            percent from 2022 to 2032, faster than the average for all
            occupations.
          </p>
          <p className="font-[400] text-[18px] md:text-[20px] text-[#5C616E] leading-[35px]">
            About 54,400 openings for licensed practical and licensed vocational
            nurses are projected each year, on average, over the decade. Many of
            those openings are expected to result from the need to replace
            workers who transfer to different occupations or exit the labor
            force, such as to retire.
          </p>
        </div>
        {data1.map((data) => (
          <div className="w-auto md:w-[98%] ml-7 flex flex-col gap-3">
            <ul className=" list-disc font-[500] text-[18px] md:text-[20px] text-[#1B2336]">
              <li>{data.title}</li>
            </ul>
            <p className="font-[400] text-[18px] md:text-[20px] text-[#5C616E] leading-[35px]">
              {data.content}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default FAQ;
