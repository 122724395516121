import React from "react";

const Faqs = () => {
  return (
    <>
      <div>
        <p className="font-[500] text-[20px] md:text-[24px] xl:text-[32px] leading-[36px] xl:leading-[48px] text-[#003C9D]">
          Ayurveda Wellness Counselor (AWC) Program FAQ:
        </p>
        <div className="mt-5 md:mt-10 leading-[30px] text-[18px] md:text-[20px]">
          <ul className="list-disc flex flex-col gap-6 ml-5 font-[500]">
            <li>
              What is Hybrid Programs?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  All hybrid programs will allow you to choose in-person and/or
                   online instruction. Lectures may be offered at the  School
                  location or remotely via a Web Conferencing Platform.
                  Laboratory/ clinical classes and practical training will be
                  held on at School site or at a clinical site. Synchronous
                  (Online synchronous classes) instruction is real-time
                  instruction that requires students to attend classes at
                  scheduled times.
                </p>
              </div>
            </li>
            <li className="">
              What does an Ayurveda Wellness Counselor (AWC) do?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  An Ayurvedic Wellness Counselor is trained to provide guidance
                  and support to individuals seeking to improve their health and
                  well-being using principles and practices rooted in Ayurveda,
                  an ancient holistic healing system. Some common tasks and
                  responsibilities of an Ayurvedic Wellness Counselor include:
                </p>

                <ul className="list-disc marker:text-[#003C9D]  leading-[35px] ml-8 flex flex-col gap-[10px]">
                  <li>
                    <span className="text-[#003C9D]">
                      Conducting Assessments:{" "}
                    </span>{" "}
                    Assessing clients' health concerns, medical history,
                    lifestyle habits, and constitution (dosha) to identify
                    imbalances and determine personalized wellness plans.
                  </li>
                  <li>
                    <span className="text-[#003C9D]">
                      Providing Dietary Guidance:{" "}
                    </span>{" "}
                    Offering dietary recommendations tailored to each client's
                    unique constitution, imbalances, and health goals. This may
                    include suggesting specific foods, herbs, spices, and
                    dietary practices to promote balance and support healing.
                  </li>

                  <li>
                    <span className="text-[#003C9D]">
                      Recommending Lifestyle Modifications:{" "}
                    </span>{" "}
                    Advising clients on lifestyle modifications, daily routines,
                    and self-care practices aligned with Ayurvedic principles to
                    optimize health and prevent illness. This may include
                    suggestions for sleep hygiene, stress management techniques,
                    exercise, and mindful living practices.
                  </li>

                  <li>
                    <span className="text-[#003C9D]">Herbal Remedies: </span>{" "}
                    Recommending Ayurvedic herbs, supplements, and remedies to
                    address specific health concerns or imbalances and support
                    the body's natural healing processes.
                  </li>

                  <li>
                    <span className="text-[#003C9D]">Stress Management:</span>{" "}
                    Teaching stress reduction techniques such as meditation,
                    yoga, pranayama (breathwork), and mindfulness practices to
                    promote mental and emotional well-being.
                  </li>

                  <li>
                    <span className="text-[#003C9D]">Client Education:</span>{" "}
                    Educating clients about Ayurvedic principles, the mind-body
                    connection, and the importance of holistic health to empower
                    them to take an active role in their wellness journey.
                  </li>

                  <li>
                    <span className="text-[#003C9D]">Monitoring Progress:</span>{" "}
                    Monitoring clients' progress over time, adjusting wellness
                    plans as needed, and providing ongoing support and
                    encouragement to help clients achieve their health goals.
                    <br />
                    Overall, an Ayurvedic Wellness Counselor serves as a
                    knowledgeable guide and ally on the journey to holistic
                    health, helping clients to restore balance, prevent disease,
                    and cultivate vitality using the wisdom of Ayurveda.
                  </li>
                </ul>
              </div>
            </li>

            <li>
              What is the Ayurveda Counselor Program (AWC)?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  The AWC is a comprehensive training program that provides
                  individuals with the knowledge, skills, and tools to become
                  proficient in Ayurvedic principles and practices, enabling
                  them to offer holistic wellness counseling and support.
                </p>
              </div>
            </li>

            <li>
              For whom is the AWC program suitable?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  The AWC program is ideal for individuals interested in
                  holistic health, wellness coaching, and alternative medicine.
                  It is suitable for aspiring wellness counselors, healthcare
                  professionals, yoga instructors, nutritionists, and anyone
                  enthusiastic about promoting well-being.
                </p>
              </div>
            </li>

            <li>
              What are the prerequisites for enrolling in the AWC program?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  Typically, applicants are required to have a high school
                  diploma or equivalent and a foundation understanding of
                  anatomy and physiology.
                </p>
              </div>
            </li>

            <li>
              What topics are covered in the AWC curriculum?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  The curriculum typically covers a wide range of topics,
                  including Ayurvedic philosophy and principles, anatomy, and
                  physiology from an Ayurvedic perspective, Ayurvedic nutrition
                  and cooking, lifestyle practices, herbology, Ayurvedic
                  psychology, Ayurvedic disease prevention science, Disease
                  pathology and management, Yoga philosophy and counseling
                  skills.
                </p>
              </div>
            </li>

            <li>
              What learning formats are available for the AWC program?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  AWC programs may be offered in various formats, including
                  in-person classes, hybrid formats combining both online and
                  in-person learning, and self-paced study options.
                </p>
              </div>
            </li>

            <li>
              What are the career opportunities for graduates of the AWC
              program?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  Graduates of the AWC program may pursue various career paths,
                  including Ayurvedic wellness counseling, holistic health
                  coaching, Ayurvedic cooking and nutrition consulting, yoga
                  instruction, spa, and wellness center management.
                </p>
              </div>
            </li>

            <li>
              Is certification available upon completion of the AWC program?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  Yes, AWC programs offer certification from the Param Institute
                  of Education upon successful completion of the coursework and
                  any required assessments. Certification demonstrates
                  proficiency in Ayurvedic principles and may enhance career
                  opportunities.
                </p>
              </div>
            </li>

            <li>
              How long does it take to complete the AWC program?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>The duration of the program may be completed in 52 weeks.</p>
              </div>
            </li>

            <li>
              Can I continue my education after completing the AWC program?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  Yes, graduates of the AWC program may choose to further their
                  education by pursuing advanced studies in Ayurveda, such as
                  becoming an Ayurvedic Practitioner.
                </p>
              </div>
            </li>

            <li>
              When can I start the course?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  This course is open enrollment, so you can register and start
                  the course as soon as you are ready.
                </p>
              </div>
            </li>

            <li>
              What kind of support will I receive?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  During the course, you can reach out to your instructor via
                  email for any questions or feedback on your performance.
                  Occasionally, industry experts may also contribute to your
                  learning experience. You will have the support of a student
                  advising team available to assist you throughout your journey.
                </p>
              </div>
            </li>

            <li>
              What if I do not have enough time to complete my course within the
              time provided?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  The duration for completing the course has been determined
                  according to the total number of course hours. If you find
                  yourself unable to finish within the allotted time, you can
                  reach out to the student advising team to explore potential
                  options for extending your completion date. Please be aware
                  that there may be an extension fee associated with this.
                </p>
              </div>
            </li>

            <li>
              Can I be assured of employment after completing the program?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  This course equips you with the necessary skills for securing
                  a job position in many cases. We offer support with resume
                  preparation and job search strategies to aid you in finding
                  employment. Additionally, prospective students are advised to
                  research the job market in their area before enrolling.
                </p>
              </div>
            </li>

            <li>
              Can I get financial assistance?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  This course is non-credit, so it does not qualify for federal
                  aid, FAFSA and/or Pell Grant.
                </p>
              </div>
            </li>

            <li>
              How can I get more information about this course?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  If you have questions that are not answered on our website,
                  representatives are available via EMAIL (info@paramedu.com).
                  You can also call us at +1 848 256 2737 during regular
                  business hours to have your questions promptly answered. If
                  you are visiting us during non-business hours, please send us
                  a question using the "Contact Us" form.
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Faqs;
