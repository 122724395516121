import React from "react";

const Faqs = () => {
  return (
    <>
      <div>
        <p className="font-[500] text-[20px] md:text-[24px] xl:text-[32px] leading-[36px] xl:leading-[48px] text-[#003C9D]">
          Yoga Teacher Training Certification (200-Hours) Program FAQ
        </p>
        <div className="mt-5 md:mt-10 leading-[30px] text-[18px] md:text-[20px]">
          <ul className="list-disc flex flex-col gap-6 ml-5 font-[500]">
            <li>
              What is Hybrid Programs?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  All hybrid programs will allow you to choose in-person and/or
                   online instruction. Lectures may be offered at the  School
                  location or remotely via a Web Conferencing Platform.
                  Laboratory/ clinical classes and practical training will be
                  held on at School site or at a clinical site. Synchronous
                  (Online synchronous classes) instruction is real-time
                  instruction that requires students to attend classes at
                  scheduled times.
                </p>
              </div>
            </li>
            <li className="">
              What does a Yoga Teacher do?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  A Yoga Teacher plays a multifaceted role in guiding and
                  helping yoga practice for individuals or groups. Here is a
                  breakdown of what a yoga teacher typically does:
                </p>

                <ul className="list-disc marker:text-[#003C9D]  leading-[35px] ml-8 flex flex-col gap-[10px]">
                  <li>
                    <span className="text-[#003C9D]">
                      Instructing Yoga Classes:{" "}
                    </span>{" "}
                    The primary responsibility of a yoga teacher is to lead yoga
                    classes. This involves guiding students through a series of
                    yoga poses (asanas), incorporating breathwork (pranayama),
                    and meditation techniques. Teachers create sequences that
                    are safe, effective, and tailored to the needs and abilities
                    of their students.
                  </li>
                  <li>
                    <span className="text-[#003C9D]">
                      Demonstrating Poses:{" "}
                    </span>{" "}
                    Teachers demonstrate yoga poses to illustrate proper
                    alignment, technique, and transitions between poses. This
                    helps students understand how to perform the poses correctly
                    and safely.
                  </li>

                  <li>
                    <span className="text-[#003C9D]">
                      Providing Guidance and Corrections:{" "}
                    </span>{" "}
                    Throughout the class, yoga teachers offer verbal cues and
                    hands-on adjustments to help students refine their
                    alignment, deepen their practice, and avoid injury. They may
                    also offer modifications or variations of poses to
                    accommodate various levels of experience and physical
                    abilities.
                  </li>

                  <li>
                    <span className="text-[#003C9D]">
                      Creating a Supportive Environment:{" "}
                    </span>{" "}
                    Yoga teachers cultivate a welcoming and inclusive atmosphere
                    in their classes, encouraging students to explore their
                    practice without judgment or competition. They may offer
                    inspirational messages, themes, or intentions to enhance the
                    spiritual and mental aspects of the practice.
                  </li>

                  <li>
                    <span className="text-[#003C9D]">
                      Offering Personalized Attention:{" "}
                    </span>{" "}
                    In smaller classes or one-on-one sessions, yoga teachers may
                    provide individualized attention to students, addressing
                    their specific needs, goals, or concerns. This could involve
                    adapting poses, offering therapeutic adjustments, or
                    offering personalized guidance for enhancing their practice.
                  </li>

                  <li>
                    <span className="text-[#003C9D]">Educating Students: </span>{" "}
                    Yoga teachers share their knowledge of yoga philosophy,
                    anatomy, and physiology to deepen students' understanding of
                    the practice. They may incorporate teachings from ancient
                    texts, explain the benefits of different poses, or discuss
                    the principles of mindfulness and self-awareness.
                  </li>

                  <li>
                    <span className="text-[#003C9D]">Fostering Community:</span>{" "}
                    Many yoga teachers aim to create a sense of community among
                    their students by organizing events, workshops, or social
                    gatherings outside of regular classes. This helps students
                    connect with each other and build supportive relationships
                    within the yoga community.
                  </li>

                  <li>
                    <span className="text-[#003C9D]">
                      Continuing Professional Development:
                    </span>{" "}
                    Yoga teachers engage in ongoing learning and professional
                    development to refine their skills, expand their knowledge,
                    and stay current with developments in the field of yoga.
                    This may involve attending workshops, training programs, or
                    conferences, as well as self-study and personal practice.
                  </li>
                </ul>
              </div>
            </li>

            <li>
              What is the Yoga Teacher Training Program (200 Hrs.)?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  A Yoga Teacher Training Certification Program is a
                  comprehensive training program designed to provide individuals
                  with the knowledge, skills, and credentials needed to become a
                  certified yoga teacher. Here is an overview of what such a
                  program typically entails:
                </p>
                <ul className="list-disc marker:text-[#003C9D]  leading-[35px] ml-8 flex flex-col gap-[10px]">
                  <li>
                    <span className="text-[#003C9D]">Duration: </span> The
                    program is structured to cover a minimum of 200 hours of
                    instruction, which can span several weeks or months
                    depending on the format.
                  </li>
                  <li>
                    <span className="text-[#003C9D]">Curriculum: </span> The
                    curriculum of a 200-Hour YTT program covers a wide range of
                    topics related to yoga practice, philosophy, teaching
                    method, anatomy, and professional ethics. It typically
                    includes:
                    <ul className="flex flex-col gap-[10px] mt-[10px]">
                      <li>
                        <span className="text-[#F37021]">Yoga Asana: </span>
                        Study and practice of various yoga poses (asanas),
                        including alignment principles, modifications, and
                        sequencing.
                      </li>
                      <li>
                        <span className="text-[#F37021]">
                          Pranayama and Meditation:
                        </span>{" "}
                        Instruction in breathing techniques (pranayama) and
                        meditation practices to cultivate awareness and focus.
                      </li>
                      <li>
                        <span className="text-[#F37021]">Yoga Philosophy:</span>{" "}
                        Exploration of ancient yogic texts, such as the Yoga
                        Sutras of Patanjali and the Bhagavad Gita, as well as
                        philosophical concepts like the Eight Limbs of Yoga and
                        the concept of self-realization.
                      </li>
                      <li>
                        <span className="text-[#F37021]">
                          Anatomy and Physiology:
                        </span>{" "}
                        Understanding of the human body's structure and
                        function, with a focus on how it relates to yoga
                        practice and teaching.
                      </li>
                      <li>
                        <span className="text-[#F37021]">
                          Teaching Methodology:{" "}
                        </span>{" "}
                        Instruction on effective teaching techniques, including
                        class sequencing, cueing, hands-on adjustments, and
                        student engagement.
                      </li>
                      <li>
                        <span className="text-[#F37021]">
                          Ethics and Professionalism:{" "}
                        </span>{" "}
                        Discussion of ethical guidelines for yoga teachers,
                        including boundaries, communication skills, and
                        professional conduct.
                      </li>
                      <li>
                        <span className="text-[#F37021]">Practicum: </span> A
                        significant portion of the program is dedicated to
                        practical teaching experience. Students typically have
                        opportunities to practice teaching under the supervision
                        of experienced instructors, receive feedback, and refine
                        their teaching skills.
                      </li>
                      <li>
                        <span className="text-[#F37021]">
                          {" "}
                          Assessment and Certification:{" "}
                        </span>{" "}
                        To receive certification, students must usually complete
                        all program requirements, including attendance,
                        participation, assignments, and assessments.
                        Certification is awarded upon successfully demonstrating
                        ability in teaching, understanding of yoga principles,
                        and adherence to ethical standards.
                      </li>
                    </ul>
                  </li>
                </ul>
                <p>
                  A 200-Hour Yoga Teacher Training Certification Program
                  provides a comprehensive foundation for individuals aspiring
                  to become yoga teachers, equipping them with the knowledge,
                  skills, and confidence to guide others in the practice of yoga
                  safely and effectively.
                </p>
              </div>
            </li>

            <li>
              For whom is the 200 Hrs. Yoga Teacher Training program suitable?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  The 200-Hour Yoga Teacher Training (YTT) program is suitable
                  for a diverse range of individuals who have a passion for yoga
                  and wish to deepen their practice, understanding, and teaching
                  abilities. Here's a breakdown of who the program is suitable
                  for Yoga Practitioners, Aspiring Yoga Teachers, Career
                  Changers, Fitness Professionals: Personal trainers, group
                  fitness instructors, or other fitness professionals who want
                  to expand their expertise and offerings, Mindfulness
                  Practitioners, those Seeking Personal Growth, People seeking
                  personal growth, self-discovery, and a deeper connection to
                  themselves and others, Yoga Enthusiasts.
                </p>
              </div>
            </li>

            <li>
              What are the prerequisites for enrolling in the Yoga Teacher
              Training (200 Hrs.) program?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  To enroll in the 200 Hour Yoga Teacher Training Certification
                  program, candidates must possess a minimum of six months'
                  experience practicing yoga across any level or style.
                </p>
              </div>
            </li>

            <li>
              What topics are covered in the Yoga Teacher Training (200 Hrs.)
              curriculum?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  The curriculum typically covers a wide range of topics,
                  including seven characteristics of a beginner body
                  methodology, anatomical landmarks in asana, introduction to
                  therapeutic application of asana, 36 foundation asana, special
                  restorative yoga postures,  experience the inner practice of
                  yoga, develop a meditation home practice, understand your
                  ayurvedic dosha, teaching covered in this course exceed all
                  philosophy, lifestyle and ethics requirements set forth by the
                  National Yoga Alliance, focus on the art of teaching, learning
                  principles of correction, instruction, voice & language, and
                  demonstration while gaining the practical experience of
                  teaching in small groups, learn the art of sequencing and
                  formatting group yoga classes in the styles of FORM, FLOW &
                  POWER.
                </p>
              </div>
            </li>

            <li>
              What learning formats are available for the Yoga Teacher Training
              (200 Hrs.)  program?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  Yoga Teacher Training (200 Hrs.) program may be offered in
                  various formats, including in-person classes, hybrid formats
                  combining both online and in-person learning, and self-paced
                  study options.
                </p>
              </div>
            </li>

            <li>
              What are the career opportunities for graduates of the Yoga
              Teacher Training (200 Hrs.) program?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  Graduates of a Yoga Teacher Training (200-Hour) program have
                  various career opportunities within the field of yoga and
                  wellness. Here are some potential career paths for graduates:
                </p>

                <ul className="list-disc marker:text-[#003C9D]  leading-[35px] ml-8 flex flex-col gap-[10px]">
                  <li>
                    <span className="text-[#003C9D]">Yoga Instructor: </span>The
                    most common career path for Yoga Teacher Training (200 Hrs.)
                    program graduates is to become a yoga instructor. They can
                    teach classes at yoga studios, fitness centers, community
                    centers, corporate settings, schools, or offer private
                    sessions.
                  </li>
                  <li>
                    <span className="text-[#003C9D]">Studio Owner: </span> Some
                    graduates may choose to open their own yoga studio or
                    wellness center. Owning a studio allows for creative freedom
                    in designing class schedules, curating workshops, and
                    building a community of practitioners.
                  </li>
                  <li>
                    <span className="text-[#003C9D]">Corporate Wellness: </span>{" "}
                    Many companies offer corporate wellness programs that
                    include yoga classes as part of their employee benefits.
                    Yoga Teacher Training (200 Hrs.) program graduates can work
                    as corporate wellness instructors, leading onsite or virtual
                    yoga sessions for employees to promote physical health and
                    stress reduction.
                  </li>
                  <li>
                    <span className="text-[#003C9D]">
                      Specialized Populations:{" "}
                    </span>{" "}
                    Yoga Teacher Training (200 Hrs.) program graduates may
                    specialize in teaching yoga to specific populations, such as
                    children, seniors, pregnant women, individuals with
                    disabilities, or individuals recovering from injuries.
                    Specialized training may be needed for certain populations.
                  </li>
                  <li>
                    <span className="text-[#003C9D]">Therapeutic Yoga: </span>{" "}
                    Some graduates pursue additional training in therapeutic
                    yoga to collaborate with clients dealing with specific
                    health concerns, injuries, or chronic conditions. They may
                    collaborate with healthcare professionals to integrate yoga
                    into treatment plans.
                  </li>
                  <li>
                    <span className="text-[#003C9D]">
                      Yoga Retreat Leader:{" "}
                    </span>{" "}
                    Graduates may lead yoga retreats domestically or
                    internationally, offering immersive experiences that combine
                    yoga practice, meditation, healthy cuisine, and cultural
                    exploration.
                  </li>
                  <li>
                    <span className="text-[#003C9D]">Teacher Trainer: </span>{" "}
                    Experienced yoga instructors with additional training and
                    ability may become teacher trainers themselves, leading Yoga
                    Teacher Training (200 Hrs.) program programs, workshops, and
                    continuing education courses for aspiring yoga teachers.
                  </li>
                  <li>
                    <span className="text-[#003C9D]">Yoga Therapist: </span>{" "}
                    With advanced training in yoga therapy, graduates can work
                    as yoga therapists, using yoga practices to address
                    physical, mental, and emotional health issues. They may work
                    in clinical settings, rehabilitation centers, or private
                    practice.
                  </li>
                  <li>
                    <span className="text-[#003C9D]">Online Instructor: </span>{" "}
                    In the digital age, there is a growing demand for online
                    yoga classes. Graduates can create and teach yoga classes
                    through online platforms, social media channels, or their
                    own websites.
                  </li>
                  <li>
                    <span className="text-[#003C9D]">
                      Yoga Blogger/Influencer:{" "}
                    </span>{" "}
                    Some graduates may build a career as yoga bloggers,
                    influencers, or content creators, sharing their knowledge,
                    experiences, and inspiration with a wider audience through
                    blogs, videos, podcasts, and social media. The career
                    opportunities for graduates of a Yoga Teacher Training
                    program are diverse, allowing individuals to pursue paths
                    aligned with their interests, skills, and goals within the
                    thriving field of yoga and wellness.
                  </li>
                </ul>
              </div>
            </li>

            <li>
              Is certification available upon completion of the Yoga Teacher
              Training (200 Hrs.) program?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  Yes, Yoga Teacher Training (200 Hrs.) program offers
                  certification from the Param Institute of Education upon
                  successful completion of the coursework and any required
                  assessments. Certification demonstrates proficiency in Yoga
                  principles and may enhance career opportunities.
                </p>
              </div>
            </li>

            <li>
              How long does it take to complete the Yoga Teacher Training (200
              Hrs.) program?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>The duration of the program may be completed in 10 weeks.</p>
              </div>
            </li>

            <li>
              Can I continue my education after completing the Yoga Teacher
              Training (200 Hrs.) program?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  Yes, graduates of the Yoga Teacher Training (200 Hrs.) program
                  may choose to further their education by pursuing advanced
                  studies in Yoga, such as Yoga Teacher Training (400 Hrs.)
                  program.
                </p>
              </div>
            </li>

            <li>
              When can I start the course?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  This course is open enrollment, so you can register and start
                  the course as soon as you are ready.
                </p>
              </div>
            </li>

            <li>
              What kind of support will I receive?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  During the course, you can reach out to your instructor via
                  email for any questions or feedback on your performance.
                  Occasionally, industry experts may also contribute to your
                  learning experience. You will have the support of a student
                  advising team available to assist you throughout your journey.
                </p>
              </div>
            </li>

            <li>
              What if I do not have enough time to complete my course within the
              time provided?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  The duration for completing the course has been determined
                  according to the total number of course hours. If you find
                  yourself unable to finish within the allotted time, you can
                  reach out to the student advising team to explore potential
                  options for extending your completion date. Please be aware
                  that there may be an extension fee associated with this.
                </p>
              </div>
            </li>

            <li>
              Can I be assured of employment after completing the program?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  This course equips you with the necessary skills for securing
                  a job position in many cases. We offer support with resume
                  preparation and job search strategies to aid you in finding
                  employment. Additionally, prospective students are advised to
                  research the job market in their area before enrolling.
                </p>
              </div>
            </li>

            <li>
              Can I get financial assistance?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  This course is non-credit, so it does not qualify for federal
                  aid, FAFSA and/or Pell Grant.
                </p>
              </div>
            </li>

            <li>
              How can I get more information about this course?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  If you have questions that are not answered on our website,
                  representatives are available via EMAIL (info@paramedu.com).
                  You can also call us at +1 848 256 2737 during regular
                  business hours to have your questions promptly answered. If
                  you are visiting us during non-business hours, please send us
                  a question using the "Contact Us" form.
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Faqs;
