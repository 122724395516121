import React from "react";

const Faqs = () => {
  return (
    <>
      <div>
        <p className="font-[500] text-[20px] md:text-[24px] xl:text-[32px] leading-[36px] xl:leading-[48px] text-[#003C9D]">Pharmacy technician Program  FAQs</p>
        <div className="mt-5 md:mt-10 leading-[30px] text-[18px] md:text-[20px]">
          <ul className="list-disc flex flex-col gap-6 ml-5 font-[500]">
            
            <li className="">
              Are Pharmacy Technician  in high demand?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E] leading-[35px]">
                <p>
                  According to the Bureau of Labor Statistics (BLS), demand for pharmacy technicians will grow 12%
                  through 2028 – that equates to over 30,000 new jobs!
                </p>
                <p>
                  The BLS notes that on average, pharmacy technicians earn an annual salary of $32,700. Top earners can
                  make up to $47,000 per year.
                </p>
              </div>
            </li>

            <li>
              What does a pharmacy technician do?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E] leading-[35px]">
                <p>
                  Pharmacy technicians order and stock medications, prepare medications for distribution and provide information to patients. Pharmacy technicians may perform many of the same duties as a pharmacist; however, their work must be approved by a pharmacist before medication can be dispensed.
                </p>
              </div>
            </li>

            <li>
              Do pharmacy technicians need a certification?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E] leading-[35px]">
                <p>
                  Requirements for pharmacy technicians vary by state, but most require certification, registration or licensure. Earning your certification from the Pharmacy Technician Certification Board (PTCB) provides a valuable, industry-recognized credential that meets most states’ requirements.
                </p>
              </div>
            </li>

            <li>
              How long does it take to complete a pharmacy technician course?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E] leading-[35px]">
                <p>
                  This pharmacy technician course can be completed in one year or less. This does not include the time you spend in an externship program or preparing and sitting for state licensure.
                </p>
              </div>
            </li>

            <li>
              Is a pharmacy technician a good career?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E] leading-[35px]">
                <p>
                  As the population ages and the prevalence of chronic diseases rises, more pharmacy technicians will be needed in pharmacies and may even see themselves taking on greater responsibilities in their role.
                </p>
              </div>
            </li>

            <li>
              How much does a Pharmacy technician assistant make?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E] leading-[35px]">
                <p>
                  Wages in Seattle-Bellevue metro area range from $15.48/hour (entry-level) to $26.21/hour (additional experience), and the median is $20.51/hour.
                </p>
              </div>
            </li>

            <li>
              When can I start the course?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E] leading-[35px]">
                <p>
                  This course is open enrollment, so you can register and start the course as soon as you are ready. Other best beast way to get started toward your new career in healthcare is to contact an Admissions Advisor. Our advisors will guide you through every step of the school enrollment process.
                </p>
              </div>
            </li>

            <li>
              How long does it take to complete this course?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E] leading-[35px]">
                <p>
                  This course is online and open enrollment, so you can start as soon as you can. The program will take 70 hours of training only for certification.
                </p>
              </div>
            </li>

            <li>
              What kind of support will I receive?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E] leading-[35px]">
                <p>
                  The course instructor will be available by email to answer any questions and provide feedback on your performance. Occasionally, your course may be supported by a team of industry experts. You will also receive support from the student advising team.
                </p>
              </div>
            </li>

            <li>
              What happens when I complete the course?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E] leading-[35px]">
                <p>Upon successful completion of the course, you will be awarded a Certificate of Completion.</p>
              </div>
            </li>
            <li>
              Am I guaranteed a job?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E] leading-[35px]">
                <p>
                  This course will provide you with the skills you need to obtain an entry-level position in most cases.
                  We will assist you with your resume preparation and finding a job. Also, Potential students should
                  always do research on the job market in their area before registering.
                </p>
              </div>
            </li>
            <li>
              Can I get financial assistance?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E] leading-[35px]">
                <p>This course is non-credit, so it does not qualify for federal aid, FAFSA and Pell Grant. In some states, vocational rehab or workforce development boards will pay for qualified students to take our courses.  </p>
              </div>
            </li>
            <li>
              How can I get more information about this course?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E] leading-[35px]">
                <p>
                  If you have questions that are not answered on our website, representatives are available via EMAIL (info@paramedu.com). You can also call us at +1 848 256 2737  during regular business hours to have your questions promptly answered. If you are visiting us during non-business hours, please send us a question using the "Contact Us" form.
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Faqs;
