import React from "react";
import PhlebotomyFAQ from "../../../../assets/images/PhlebotomyFAQ.svg";
function FAQ() {
  const data1 = [
    {
      title: "What does a medical biller and coder do?",
      content:
        "Medical Billers and Coders are responsible for processing patient data including medical records and related insurance. In this position, you will code a patient’s diagnosis and then request payment from the patient’s insurance company. You will play an important role in ensuring that healthcare providers are quickly and accurately paid for the treatment they give patients.",
    },
    {
      title:
        "Is there a difference between a medical billing and a medical coding?",
      content:
        "Yes. Medical coders translate patient care into current procedural terminology (CPT) codes. Their primary responsibility is to ensure that the medical services provided are accurately coded. Medical billers are responsible for creating a claim based on the codes a medical coder provides. Many professionals in this area do both medical billing and medical coding.",
    },
    {
      title:
        "What are the requirements for a medical billing and coding career?",
      content:
        "Entry-level positions typically require completion of a certificate and passing one of the certification exams or an associate degree program in medical billing and coding. Additionally, medical billing and coding professionals must understand the Health Insurance Portability and Accountability Act (HIPAA).",
    },
    {
      title: "How long does it take to become a medical biller and coder?",
      content:
        "In most cases, it takes between one and three years to become a medical biller and coder. Earning a medical billing and coding certification can take up to one year, while earning an associate degree can take up to three years.",
    },
    {
      title:
        "What is the difference between certification exams (cpc, cca, and cbcs)?",
      content:
        "Obtaining a CPC, CCA, or CBCS certification implies that an individual has met competencies in the field of medical billing and coding. Certification is invaluable to the student’s career goals. Students have an opportunity to make confident, informed decisions about the national certification they prefer.",
    },
  ];
  const data2 = [
    {
      title: "How long does it take to complete this course?",
      content:
        "This course has open enrollment, so you can start as soon as you can. The program will take 300 hours of training only for certification.",
    },
    {
      title: "What kind of support will I receive?",
      content:
        "The course instructor will be available by email to answer any questions and provide feedback on your performance. Occasionally, your course may be supported by a team of industry experts. You will also receive support from the student advising team.",
    },
    {
      title:
        "What if I don't have enough time to complete my course within the time frame provided?",
      content:
        "The time allotted for course completion has been calculated based on the number of course hours. However, if you are unable to complete the course, contact the student advising team to see what options you may have available to work out a suitable completion date. Please note that an extension fee may be charged.",
    },
    {
      title: "What happens when I complete the course?",
      content:
        "Upon successful completion of the course, you will be awarded a Certificate of Completion.",
    },
    {
      title: "Am I guaranteed a job?",
      content:
        "This course will provide you with the skills you need to obtain an entry-level position in most cases. We will assist you with your resume preparation and finding a job. Also, Potential students should always do research on the job market in their area before registering.",
    },
    {
      title: "Can I get financial assistance?",
      content:
        "This course is non-credit, so it does not qualify for federal aid, FAFSA and Pell Grant. In some states, vocational rehab or workforce development boards will pay for qualified students to take our courses.",
    },
    {
      title: "How much does Program school cost?",
      content:
        "Tuition Fees at Param Institute of Education varies for different program offered, but we assure you that our tuition is very competitive among the other vocational schools in New Jersey. Remember that attending program at Param institute of Education is an investment in your future as a healthcare professional. Plus, there are ways to fund your education through scholarships offered at school making it easier than ever to pay for your education.",
    },
  ];
  return (
    <div className=" w-full font-poppins flex flex-col gap-5 md:gap-10">
      <p className=" font-[500] text-[20px] md:text-[24px] xl:text-[32px] leading-[36px] xl:leading-[48px] text-[#003C9D] ">
        Certified Phlebotomy Technician Program (Hybrid) FAQs
      </p>
      <div className="flex flex-col gap-6">
        <div className="w-auto md:w-[98%] ml-7 flex flex-col gap-3" id="hybrid-faq">
          <ul className=" list-disc font-[500] text-[18px] md:text-[20px] text-[#1B2336]">
            <li>What is Hybrid Programs?</li>
          </ul>
          <p className="font-[400] text-[18px] md:text-[20px] text-[#5C616E] leading-[35px]">
            All hybrid programs will allow you to choose in-person and/or
             online instruction. Lectures may be offered at the  School location
            or remotely via a Web Conferencing Platform. Laboratory/ clinical
            classes and practical training will be held on at School site or at
            a clinical site. Synchronous (Online synchronous classes)
            instruction is real-time instruction that requires students to
            attend classes at scheduled times.
          </p>
        </div>
        <div className="w-[98%] ml-7 flex flex-col gap-3">
          <ul className=" list-disc font-[500] text-[20px] text-[#1B2336]">
            <li>What is NHA and AMCA Certification?</li>
          </ul>
          <div className="flex flex-col gap-2">
            <p className="font-[400] text-[20px] text-[#5C616E] leading-[35px]">
              The{" "}
              <a
                className="underline text-[#0755b9]"
                href="https://www.nhanow.com/"
              >
                National Healthcareer Association (NHA)
              </a>{" "}
              is the largest allied health certification agency in the United
              States, with over 1 million certifications awarded since 1989.
            </p>
            <p className="font-[400] text-[20px] text-[#5C616E] leading-[35px]">
              The{" "}
              <a
                className="underline text-[#0755b9]"
                href="https://www.amcaexams.com/amca-certification-board/"
              >
                AMCA
              </a>{" "}
              Certification Board was established in 2013 to be an autonomous
              and independent board of the AMCA, LLC. The AMCA Certification
              Board complies with national accreditation standards set by the
              National Commission for Certifying Agencies (NCCA).
            </p>
          </div>
        </div>
        <div className="w-auto md:w-[98%] ml-7 flex flex-col gap-3">
          <ul className=" list-disc font-[500] text-[18px] md:text-[20px] text-[#1B2336]">
            <li>What does a certified Phlebotomy Technician do?</li>
          </ul>
          <p className="font-[400] text-[18px] md:text-[20px] text-[#5C616E] leading-[35px]">
            Phlebotomists typically do the following:
          </p>
          <ul className=" list-disc font-[400] text-[18px] md:text-[20px] text-[#5C616E] leading-[35px] ml-8 flex flex-col gap-[10px]">
            <li>Draw blood from patients or blood donors</li>
            <li>
              Explain their work to help relax patients or donors who
              feel nervous about having blood drawn
            </li>
            <li>Verify a patient’s or donor’s identity</li>
            <li>Label the collected blood for testing or processing</li>
            <li>
              Label sterile containers for other samples, such as urine, and
              instruct patients on proper collection procedures.
            </li>
            <li>
              Assemble, maintain, and dispose of medical instruments such as
              needles, test tubes, and blood vials
            </li>
            <li>Keep work areas and equipment clean and sanitary</li>
          </ul>
        </div>
        <div className="w-auto md:w-[98%] ml-7 flex flex-col gap-3">
          <ul className=" list-disc font-[500] text-[18px] md:text-[20px] text-[#1B2336]">
            <li>Is certification necessary for Phlebotomy Program?</li>
          </ul>
          <p className="font-[400] text-[18px] md:text-[20px] text-[#5C616E] leading-[35px]">
            Majority of those hiring Phlebotomy‘s position require
            certification. With our CCMA training Program, you will be fully
            prepared to sit for one of two national certifications after
            completing our course. You will have the option of choosing between
            AMCA provisional certification exam OR the National Healthcareer
            Association’s (NHA) Certification.
          </p>
        </div>
        <div className="w-auto md:w-[98%] ml-7 flex flex-col gap-3">
          <ul className=" list-disc font-[500] text-[18px] md:text-[20px] text-[#1B2336]">
            <li>Are Certified Phlebotomy Technician in high demand?</li>
          </ul>
          <p className="font-[400] text-[18px] md:text-[20px] text-[#5C616E] leading-[35px]">
            The growing population, with its rising share of older people, will
            continue to increase demand for medical services, including blood
            testing. Blood analysis remains an essential part of medical care,
            as it is used to check for a wide range of issues. Therefore, demand
            for phlebotomists will remain high as doctors and other healthcare
            professionals require bloodwork for analysis and diagnosis.
            Employment of phlebotomists is projected to grow 10 percent from
            2021 to 2031, faster than the average for all occupations.
          </p>
        </div>
        <div className="flex justify-center">
          <img src={PhlebotomyFAQ} />
        </div>
        <div className="w-auto md:w-[98%] ml-7 flex flex-col gap-3">
          <ul className=" list-disc font-[500] text-[18px] md:text-[20px] text-[#1B2336]">
            <li>How much does a certified Phlebotomy Technician make?</li>
          </ul>
          <p className="font-[400] text-[18px] md:text-[20px] text-[#5C616E] leading-[35px]">
            According to the U.S. Bureau of Labor Statistics, the median annual
            wage for phlebotomists was $37,380 in 2021, which is about $17.97
            per hour. This wage rate has been growing through 2020, and is only
            expected to increase into the future. Current healthcare industry
            experts predict a 15% increase in phlebotomy jobs by 2030.
          </p>
        </div>
        <div className="w-auto md:w-[98%] ml-7 flex flex-col gap-3">
          <ul className=" list-disc font-[500] text-[18px] md:text-[20px] text-[#1B2336]">
            <li>When can I start the course?</li>
          </ul>
          <p className="font-[400] text-[18px] md:text-[20px] text-[#5C616E] leading-[35px]">
            This course is open enrollment, so you can register and start the
            course as soon as you are ready. Other best way to get started
            toward your new career in healthcare is to contact an Admissions
            Advisor. Our advisors will guide you through every step of the
            school enrollment process.
          </p>
        </div>
        <div className="w-auto md:w-[98%] ml-7 flex flex-col gap-3">
          <ul className=" list-disc font-[500] text-[18px] md:text-[20px] text-[#1B2336]">
            <li>How long does it take to complete this course?</li>
          </ul>
          <p className="font-[400] text-[18px] md:text-[20px] text-[#5C616E] leading-[35px]">
            This course is online and open enrollment, so you can start as soon
            as you can. The program will take 70 hours of training only for
            certification.
          </p>
        </div>
        <div className="w-auto md:w-[98%] ml-7 flex flex-col gap-3">
          <ul className=" list-disc font-[500] text-[18px] md:text-[20px] text-[#1B2336]">
            <li>What kind of support will I receive?</li>
          </ul>
          <p className="font-[400] text-[18px] md:text-[20px] text-[#5C616E] leading-[35px]">
            The course instructor will be available by email to answer any
            questions and provide feedback on your performance. Occasionally,
            your course may be supported by a team of industry experts. You will
            also receive support from the student advising team.
          </p>
        </div>
        <div className="w-auto md:w-[98%] ml-7 flex flex-col gap-3">
          <ul className=" list-disc font-[500] text-[18px] md:text-[20px] text-[#1B2336]">
            <li>
              What if I don't have enough time to complete my course within the
              time frame provided?
            </li>
          </ul>
          <p className="font-[400] text-[18px] md:text-[20px] text-[#5C616E] leading-[35px]">
            The time allotted for course completion has been calculated based on
            the number of course hours. However, if you are unable to complete
            the course, contact the student advising team to see what options
            you may have available to work out a suitable completion date.
            Please note that an extension fee may be charged.
          </p>
        </div>
        <div className="w-auto md:w-[98%] ml-7 flex flex-col gap-3">
          <ul className=" list-disc font-[500] text-[18px] md:text-[20px] text-[#1B2336]">
            <li>What happens when I complete the course?</li>
          </ul>
          <p className="font-[400] text-[18px] md:text-[20px] text-[#5C616E] leading-[35px]">
            Upon successful completion of the course, you will be awarded a
            Certificate of Completion.
          </p>
        </div>
        <div className="w-auto md:w-[98%] ml-7 flex flex-col gap-3">
          <ul className=" list-disc font-[500] text-[18px] md:text-[20px] text-[#1B2336]">
            <li>Am I guaranteed a job?</li>
          </ul>
          <p className="font-[400] text-[18px] md:text-[20px] text-[#5C616E] leading-[35px]">
            This course will provide you with the skills you need to obtain an
            entry-level position in most cases. We will assist you for your
            resume preparation and finding a job. Also, Potential students
            should always do research on the job market in their area before
            registering.
          </p>
        </div>
        <div className="w-auto md:w-[98%] ml-7 flex flex-col gap-3">
          <ul className=" list-disc font-[500] text-[18px] md:text-[20px] text-[#1B2336]">
            <li>Can I get financial assistance?</li>
          </ul>
          <p className="font-[400] text-[18px] md:text-[20px] text-[#5C616E] leading-[35px]">
            This course is non-credit, so it does not qualify for federal aid,
            FAFSA and Pell Grant. In some states, vocational rehab or workforce
            development boards will pay for qualified students to take our
            courses
          </p>
        </div>
        <div className="w-auto md:w-[98%] ml-7 flex flex-col gap-3">
          <ul className=" list-disc font-[500] text-[18px] md:text-[20px] text-[#1B2336]">
            <li>How much does Program school cost?</li>
          </ul>
          <p className="font-[400] text-[18px] md:text-[20px] text-[#5C616E] leading-[35px]">
            Tuition Fees at Param Institute of Education varies for different
            program offered, but we assure you that our tuition is very
            competitive among the other vocational schools in New Jersey.
            Remember that attending program at Param institute of Education is
            an investment in your future as a healthcare professional. Plus,
            there are ways to fund your education through scholarships offered
            at school making it easier than ever to pay for your education.
          </p>
        </div>
        <div className="w-auto md:w-[98%] ml-7 flex flex-col gap-3">
          <ul className=" list-disc font-[500] text-[18px] md:text-[20px] text-[#1B2336]">
            <li>How can I get more information about this course?</li>
          </ul>
          <p className="font-[400] text-[18px] md:text-[20px] text-[#5C616E] leading-[35px]">
            If you have questions that are not answered on our website,
            representatives are available via EMAIL (info@paramedu.com). You can
            also call us at +1 848 256 2737 during regular business hours to
            have your questions promptly answered. If you are visiting us during
            non-business hours, please send us a question using the "Contact Us"
            form.
          </p>
        </div>
      </div>
    </div>
  );
}

export default FAQ;
