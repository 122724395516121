import React from "react";
import agileScrumCourse from "../../../../assets/images/agileScrumCourse.svg";
import coursePlayIcon from "../../../../assets/images/coursePlayIcon.svg";

const Overview = () => {
  return (
    <>
      <div className="inline-block">
        <div className="text-content md:ml-[20px] md:mb-[20px] xl:ml-[99px] xl:mb-[40px] 2xl:mb-0 xl:mt-[85px]  w-1/2 float-right hidden md:flex justify-center items-center relative">
          <img src={agileScrumCourse} alt="Agile/Scrum Master Course" />
          <img
            className="absolute"
            src={coursePlayIcon}
            alt="course play icon"
          />
        </div>
        <p className="font-[500] text-[20px] md:text-[24px] xl:text-[32px] leading-[36px] xl:leading-[48px] text-[#003C9D]">
          Agile/ Scrum Master Program
        </p>
        <div className="flex md:hidden justify-center items-center mt-10">
          <img src={agileScrumCourse} alt="Agile/Scrum Master Course" />
          <img
            className="absolute"
            src={coursePlayIcon}
            alt="course play icon"
          />
        </div>
        <p className="mt-10 text-[18px] md:text-[20px] leading-[45px] text-[#5C616E]">
          Agile software development is a group of software development methods
          based on iterative and incremental development, where requirements and
          solutions evolve through collaboration between self-organizing,
          cross-functional teams. It promotes adaptive planning, evolutionary
          development and delivery, a time-boxed iterative approach, and
          encourages rapid and flexible response to change. It is a conceptual
          framework that promotes foreseen interactions throughout the
          development cycle. Scrum is an iterative and incremental agile
          software development framework for managing software projects and
          product or application development. Its focus is on “a flexible,
          product development strategy where a development team works as a unit
          to reach a common goal” as opposed to a “traditional, sequential
          approach”.
        </p>
      </div>
      <div className="mt-10 md:mt-[60px]">
        <p className="font-[500] text-[20px] md:text-[24px] xl:text-[32px] leading-[36px] xl:leading-[48px] text-[#003C9D]">
          Program Outline
        </p>
        <div className="p-6 border-[1px] rounded-[5px] border-[#D9D9D9] mt-5 md:mt-10 shadow-lg">
          <ul className="list-disc font-[400] text-[18px] md:text-[20px] text-[#1B2336] leading-[35px] flex flex-col gap-[10px] ml-6">
            <li>
              Prerequisite:{" "}
              <span className="font-[500] text-[#003C9D]">
                Bachelors in Computer Science
              </span>
            </li>
            <li>
              Program Length:{" "}
              <span className="font-[500] text-[#003C9D]">3 Weeks</span>
            </li>
            <li>
              Academic Credential Awarded:{" "}
              <span className="font-[500] text-[#003C9D]">Certificate</span>
            </li>
          </ul>
        </div>

        <div className="p-6 border-[1px] rounded-[5px] border-[#D9D9D9] mt-6 shadow-lg">
          <ul className="list-disc font-[400] text-[18px] md:text-[20px] text-[#1B2336] leading-[35px] flex flex-col gap-[10px] ml-6">
            <li>
              To explain how an iterative, incremental development process leads
              to faster delivery of more useful software 
            </li>
            <li>To discuss the essence of agile development methods</li>
            <li>
              To explain the principles and practices of extreme programming
            </li>
            <li>To explain the roles of prototyping in the software process</li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Overview;
