import React from "react";

const CourseOutcomes = () => {
  return (
    <>
      <div>
        <p className="font-[500] text-[20px] md:text-[24px] xl:text-[32px] leading-[36px] xl:leading-[48px] text-[#003C9D]">
          Upon the completion of the Ayurveda Wellness Counselor (AWC) Program
          the student will be able to
        </p>

        <div className="text-content text-[18px] md:text-[20px] leading-[45px] flex flex-col gap-4 mt-5 md:mt-10 text-[#5C616E]">
          <p className="">
            The Ayurveda Wellness Counselor Program offers a comprehensive
            journey into the ancient healing tradition of Ayurveda, providing
            participants with a rich tapestry of knowledge and practical skills
            to enhance health, prevent illness, and foster holistic well-being.
          </p>
          <p>
            At its core, the program seeks to empower individuals with a diverse
            toolkit aimed at restoring and maintaining optimal health across all
            dimensions of being – physical, mental, emotional, and spiritual.
            Through a blend of theoretical teachings, experiential learning, and
            hands-on practice, participants delve into the timeless wisdom of
            Ayurveda, honing their understanding of its fundamental principles
            and methodologies.
          </p>
          <p>
            One cornerstone of the program is the exploration of nutritional
            education (Ahara). Participants embark on a journey to understand
            food not only as sustenance but also as medicine, learning how
            different foods affect the body and mind, and how to create
            balanced, nourishing meals tailored to individual constitutions and
            health needs. From the energetics of food to the art of mindful
            eating, students gain a profound appreciation for the role of
            nutrition in supporting optimal health and vitality.
          </p>
          <p>
            Another key aspect of the program is the cultivation of daily habits
            and rituals (Vihar) to optimize health. Drawing from Ayurvedic
            principles, participants learn how to design personalized daily
            routines that align with the body's natural rhythms and promote
            balance and well-being. From the practice of self-care rituals like
            oil massage (Abhyanga) to the incorporation of yoga, meditation, and
            breathwork into daily life, students discover how simple, yet
            profound lifestyle changes can profoundly impact their health and
            vitality.
          </p>
          <p>
            Moreover, the program delves deep into the realm of the mind and its
            influence on well-being (Vichara). Through the study of Ayurvedic
            psychology, participants gain insight into the interconnectedness of
            thoughts, emotions, and health, exploring techniques to cultivate
            mental clarity, emotional resilience, and spiritual harmony. From
            mindfulness practices to meditation and introspection, students
            learn how to harness the power of the mind to promote healing and
            holistic well-being.
          </p>
          <p>
            Overall, the Ayurveda Wellness Counselor Program offers a
            transformative journey of self-discovery and empowerment, equipping
            individuals with the knowledge, skills, and tools to navigate their
            own health journey with confidence and to support others on their
            path to wellness. By integrating the timeless wisdom of Ayurveda
            into modern life, participants emerge as compassionate and
            knowledgeable advocates for holistic health, committed to fostering
            wellness and vitality in themselves and their communities.
          </p>
        </div>
      </div>
    </>
  );
};

export default CourseOutcomes;
