import React from "react";
import coursePlayIcon from "../../../../assets/images/coursePlayIcon.svg";
import cnaCourse from "../../../../assets/images/cnaCourse.svg";

const Overview = ({ setTab }) => {
  return (
    <>
      <div>
        <div>
          <div className="text-content md:ml-[20px] md:mb-[20px] xl:ml-[99px] xl:mb-[40px]  w-1/2 float-right hidden md:flex justify-center items-center relative">
            <img
              src={cnaCourse}
              alt="Certified Clinical Medical Assistant Program"
            />
            <img
              className="absolute"
              src={coursePlayIcon}
              alt="course play icon"
            />
          </div>
          <p className="font-[500] text-[20px] md:text-[24px] xl:text-[32px] leading-[36px] xl:leading-[48px] text-[#003C9D]">
            Certified Nursing Assistant (CNA) Program Overview
          </p>
          <div className="flex md:hidden justify-center items-center mt-10">
            <img
              src={cnaCourse}
              alt="Certified Clinical Medical Assistant Program"
            />
            <img
              className="absolute"
              src={coursePlayIcon}
              alt="course play icon"
            />
          </div>
          <p className="text-[18px] md:text-[20px] leading-[45px] text-[#5C616E] mt-6">
            The Certified Nursing Assistant Training is a New Jersey Department
            of Health and Senior Services approved training course which
            consists of 90 hours of training (50 hours of classroom instruction
            and 40 hours of clinical instruction in a New Jersey licensed
            long-term care facility).
          </p>
          <p className="text-[18px] md:text-[20px] leading-[45px] text-[#5C616E] mt-4">
            This program teaches skills such as lab training and practical
            Nursing homes instruction as well as classroom teaching. Most skills
            will be taught through lectures, role playing, training videos; as
            well as real demonstrations.
          </p>
          <p className="text-[18px] md:text-[20px] leading-[45px] text-[#5C616E] mt-4">
            The comprehensive Certified Nursing Assistant Training and
            Development program meets current learning needs identified by
            employers and students. Along with the entire mandated CNA program
            content, lab work, and clinical experience, this full program offers
            a more thorough experience with training and professional
            development, networking, and support for up to four months beyond
            the completion of the basic class. These enhancements promote
            greater opportunity for students to build skills and enrich job
            engagement along their career pathway with the goal to also improve
            retention of quality employees for participating employers.
          </p>
        </div>
      </div>
      <div className="mt-10 md:mt-[60px]">
        <p className="font-[500] text-[20px] md:text-[24px] xl:text-[32px] leading-[36px] xl:leading-[48px] text-[#003C9D]">
          Why Choose  Certified Nursing  Assistant?
        </p>
        <div className="flex flex-col gap-6 text-[18px] md:text-[20px] leading-[40px] mt-10 text-[#5C616E]">
          <p>
            A Certified Nursing Assistant is a nurse assistant who has gone
            further in their career by qualifying for certification and state
            licensure. CNA  fulfill both clinical and administrative tasks at
            hospitals, Long term nursing care and other medical settings.
          </p>

          <p>
            No Additional training needed (postemployment) to attain competency
            in the skills needed in this occupation.
          </p>
          <p>
            Majority Jobs are NOT require Work experience that is commonly
            considered necessary by employers, or is a commonly accepted
            substitute for more formal types of training or education.
          </p>
          <p>
            Overall employment of nursing assistants and orderlies is projected
            to grow 4 percent from 2022 to 2032, about as fast as the average
            for all occupations.
          </p>
          <p>
            About 209,400 openings for nursing assistants and orderlies are
            projected each year, on average, over the decade. Many of those
            openings are expected to result from the need to replace workers who
            transfer to different occupations or exit the labor force, such as
            to retire.
          </p>
          <p>
            As the baby-boom population ages, nursing assistants and orderlies
            will be needed to help care for an increasing number of older people
            who have chronic or progressive diseases, such as heart disease and
            diabetes.
          </p>
          <p>
            Demand for nursing assistants may be constrained by financial
            pressures on nursing homes, which might lead some facilities to
            close or reduce staff. However, increased opportunities are expected
            in home- and community-based settings as patient preferences and
            shifts in federal and state funding generate demand for care in
            these settings.
          </p>
        </div>
      </div>
      <div className="flex flex-col gap-10 md:gap-10 mt-10 md:mt-[60px]">
        <p className="font-[500] text-[20px] md:text-[24px] xl:text-[32px] leading-[36px] xl:leading-[48px] text-[#003C9D]">
          Job Outlook for Certified Nursing Assistant
        </p>
        <ul className=" list-disc font-[400] text-[18px] md:text-[20px] text-[#5C616E] leading-[45px] ml-8 flex flex-col gap-4">
          <li>
            Overall employment of nursing assistants and orderlies is projected
            to grow 4 percent from 2022 to 2032, about as fast as the average
            for all occupations.
          </li>
          <li>
            About 209,400 openings for nursing assistants and orderlies are
            projected each year, on average, over the decade. Many of those
            openings are expected to result from the need to replace workers who
            transfer to different occupations or exit the labor force, such as
            to retire.
          </li>
          <li>
            As the baby-boom population ages, nursing assistants and orderlies
            will be needed to help care for an increasing number of older people
            who have chronic or progressive diseases, such as heart disease and
            diabetes.
          </li>
          <li>
            Demand for nursing assistants may be constrained by financial
            pressures on nursing homes, which might lead some facilities to
            close or reduce staff. However, increased opportunities are expected
            in home- and community-based settings as patient preferences and
            shifts in federal and state funding generate demand for care in
            these settings.
          </li>
          <li>
            <span className="text-[#003C9D] font-[500]">NOTE: </span> All
            occupations includes all occupations in the U.S. Economy.
          </li>
          <li>
            <span className="text-[#003C9D] font-[500]">SOURCE: </span>  U.S.
            Bureau of Labor Statistics, Employment Projections program.
          </li>
        </ul>
      </div>
      <div className="mt-10 md:mt-[60px] xl:mt-[72px]">
        <p className="font-[500] text-[20px] md:text-[24px] xl:text-[32px] leading-[36px] xl:leading-[48px] text-[#003C9D]">
          CNA Program Outline
        </p>
        <div className="p-6 border-[1px] rounded-[5px] border-[#D9D9D9] mt-5 md:mt-10 shadow-lg">
          <ul className="list-disc font-[400] text-[18px] md:text-[20px] text-[#1B2336] leading-[35px] flex flex-col gap-[10px] ml-6">
            <li>
              Total Hours: <span className="font-[500] text-[#003C9D]">90</span>
            </li>
            <li>
              In person theory study:{" "}
              <span className="font-[500] text-[#003C9D]">50 hours</span>
            </li>
            <li>
              Clinical Externship at healthcare facility:{" "}
              <span className="font-[500] text-[#003C9D]">40 Hours</span>
            </li>
          </ul>
        </div>
        <div className="p-6 border-[1px] rounded-[5px] border-[#D9D9D9] mt-6 shadow-lg">
          <ul className="font-[400] text-[18px] md:text-[20px] text-[#1B2336] leading-[30px] flex flex-col gap-[10px]">
            <li>
              Module 1: CORE CURRICULUM FOR NURSE PERSONNEL IN LONG-TERM CARE
              FACILITIES
            </li>
            <li>Module 2: PSYCHO-SOCIAL NEEDS OF THE RESIDENT</li>
            <li>Module 3: PHYSICAL NEEDS OF THE RESIDENT</li>
            <li>
              Module 4: SPIRITUAL, RECREATIONAL AND ACTIVITY NEEDS OF THE
              RESIDENT
            </li>
            <li>Externship 5: CLINICAL EXTERNSHIP</li>
          </ul>
        </div>

        <div className="w-full flex gap-10 flex-col">
          <div className="w-full  mt-[20px]">
            <p className="font-[500] text-[20px] md:text-[24px] xl:text-[32px] text-[#003C9D] pb-[40px] pt-10">
              What you get from us?
            </p>
            <div className="flex gap-8 xl:flex-row flex-col">
              <ul className=" w-full list-disc font-[400] text-[18px] md:text-[20px] text-[#5C616E] flex  list-inside gap-4 flex-col">
               
                <li>Administrative support</li>
                <li>Onsite clinical training</li>
                <li>Field/Internship/Externship training</li>
                <li>CNA certificate from school</li>

                <li>Preparing and applying for your CNA state exam</li>
                <li>
                Assistance for state licensure application
                </li>
              
                <li>Placement assistant</li>
                <li>Job opportunities</li>
                <li>Career pathways opportunities and guidelines</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Overview;
