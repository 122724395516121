import React, { useState } from "react";
import course1 from "../../assets/images/ItCourse1.webp";
import course2 from "../../assets/images/ItCourse2.webp";
import course3 from "../../assets/images/ItCourse3.webp";
import course4 from "../../assets/images/ItCourse4.webp";

import { useNavigate } from "react-router-dom";

function ITPrograms() {
  const navigation = useNavigate();
  const data = [
    {
      img: course1,
      title: "Project Management Professional Program",
      link: "../projectManagement",
      content:
        "Project Management is the discipline of planning, organizing, motivating, and effectively controlling resources to achieve specific goals. A project is a focused temporary endeavor to implement and achieve specific results within in a fixed schedule",
    },
    {
      img: course2,
      title: "iOS Developer Program",
      link: "../iOSDevelopment",
      content:
        "iOS (previously iPhone OS) is a mobile operating system developed and distributed by Apple Inc. Originally released in 2007 for the iPhone and iPod Touch platforms, it has been extended to support other Apple devices such as the iPAD and Apple TV.",
    },
    {
      img: course3,
      title: "Agile / Scrum Master Program",
      link: "../agileScrumMaster",
      content:
        "Agile software development is a group of software development methods based on iterative and incremental development, where requirements and solutions evolve through collaboration between self-organizing, cross-functional teams.",
    },

    {
      img: course4,
      title: "Core Java Developer Program",
      link: "../coreJavaDeveloperProgram",
      content:
        "A high-level programming language developed by Sun Microsystems. Java was originally called OAK, and was designed for handheld devices and set-top boxes. Java is an object-oriented language similar to C++ ",
    },
    {
      img: course4,
      title: "Advance Java Developer Program",
      link: "../advJavaDeveloperProgram",
      content:
        "A high-level programming language developed by Sun Microsystems. Java was originally called OAK, and was designed for handheld devices and set-top boxes. Java is an object-oriented language similar to C++ ",
    },
  ];
  return (
    <div className=" w-full flex flex-col items-center font-poppins mb-28">
      <div className="bg-itProgram-bg bg-cover w-full h-[400px] md:h-[600px] justify-center items-center flex flex-col gap-8 md:gap-12 ">
        <p className="font-[800] text-[32px] md:text-[50px] xl:text-[60px]  w-[calc(100%_-_32px)] md:w-[calc(100%_-_64px)] text-white text-center">
          IT Training Programs
        </p>
        <div className=" flex justify-center gap-4 md:gap-8 flex-col md:flex-row">
          <button
            className="orangeButton"
            onClick={() => navigation("../scheduleAppointment")}
          >
            Schedule a Visit
          </button>
          <button
            className="orangeButton"
            onClick={() => navigation("../requestInfo")}
          >
            Inquire Now
          </button>
          <button
            className="orangeButton"
            onClick={() => navigation("../enrollment")}
          >
            Enroll Now
          </button>
        </div>
      </div>
      <div className="w-[calc(100%_-_32px)] md:w-[calc(100%_-_64px)] xl:w-[90%] flex gap-10 flex-col mt-[60px]">
        <div className=" gap-5 grid grid-cols-1 576:grid-cols-2 991:grid-cols-3 2xl:grid-cols-4">
          {data.map((data) => (
            <div id="Card" className=" bg-white shadow-lg flex flex-col">
              <div className="max-h-[250px] overflow-hidden">
                <img src={data.img} className="w-full object-cover bg-center" />
              </div>

              <div
                className="flex flex-col w-[98%] items-center mt-3 group "
                onClick={() => navigation(data.link)}
              >
                <p className="font-[600] text-[18px] text-primary group-hover:text-[#F37021] text-center cursor-pointer">
                  {data.title}
                </p>
                <p className="font-[400] text-[14px] text-[#5C616E] text-center mt-4 w-[95%] group-hover:text-[#F37021] cursor-pointer px-3 pb-4">
                  {data.content}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
export default ITPrograms;
