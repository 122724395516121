import React from "react";
import phoneIcon from "../assets/images/phoneIcon.svg";

const CallButton = () => {
  return (
    <div class="fixed z-[9999] bottom-[100px] right-6 w-[60px] h-[60px] rounded-[50px] overflow-hidden transition-width duration-[0.5s] hover:w-[378px] bg-[#F37021] flex">
      <img
        src={phoneIcon}
        alt="Image"
        class="bg-[#F37021] rounded-[100px] border-[#F37021]"
      />
      <div class=" bg-[#F37021]  text-white text-[30px] px-7 pr-8 py-2">
        <a
          href="tel:+1 732-283-5656 "
          class="border-[#F37021] text-white rounded "
        >
          +1 732-283-5656
        </a>
      </div>
    </div>
    // <div class="relative w-48 overflow-hidden transition-width duration-500 hover:w-96">
    //   <img
    //     src="image.jpg"
    //     alt="Image"
    //     class="w-full h-full object-cover transition-transform duration-500 hover:-translate-x-48"
    //   />
    //   <div class="absolute top-0 right-0 w-48 bg-white p-4 opacity-0 transition-opacity duration-500 hover:opacity-100">
    //     <p>This is some text that will be displayed on hover</p>
    //   </div>
    // </div>
  );
};

export default CallButton;