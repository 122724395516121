import React from "react";

const AdmissionProcedure = () => {
  return (
    <>
      <div>
        <ul className="font-[600] text-[20px] md:text-[24px] xl:text-[30px] leading-[36px] xl:leading-[48px] text-[#003C9D] list-disc underline flex flex-col gap-10 list-outside ml-8">
          <li>
            <a href="./requirementAndEligibility">
            Please Click here to Check Enrollment Requirements and Eligibility
            </a>
          </li>
          <li>
            <a href="./admissionProcess">
              Please Click here for Admission/ Enrollment Procedure
            </a>
          </li>
        </ul>
      </div>
    </>
  );
};

export default AdmissionProcedure;
