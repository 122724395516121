import React, { useState, useEffect } from "react";
import Overview from "../../../components/course/ItTraining/IOSDevProgram/Overview";
import AdmissionProcedure from "../../../components/course/ItTraining/IOSDevProgram/AdmissionProcedure";
import { useNavigate,useLocation } from "react-router-dom";

const IOSDevelopmentProgram = () => {
  const [tab, setTab] = useState("overview");
  const navigation = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])
  return (
    <div className=" w-full flex flex-col items-center font-poppins mb-28">
      <div className="bg-iOSDevelopment-bg w-full h-[400px] md:h-[600px] justify-center items-center flex flex-col gap-8 md:gap-12 ">
        <p className="font-[800] text-[32px] md:text-[50px] xl:text-[60px]  w-[calc(100%_-_32px)] md:w-[calc(100%_-_64px)] text-white text-center">
          iOS Developer Program
        </p>
        <div className=" flex justify-center gap-4 md:gap-8 flex-col md:flex-row">
          <button
            className="orangeButton"
            onClick={() => navigation("../scheduleAppointment")}
          >
            Schedule a Visit
          </button>
          <button
            className="orangeButton"
            onClick={() => navigation("../requestInfo")}
          >
            Inquire Now
          </button>
          <button
            className="orangeButton"
            onClick={() => navigation("../enrollment")}
          >
            Enroll Now
          </button>
        </div>
      </div>
      <div className="w-[calc(100%_-_32px)] md:w-[calc(100%_-_64px)] xl:w-[90%] mt-[50px] md:mt-[60px] xl:mt-[63px] gap-4 flex-col lg:flex-row grid 415:grid-cols-2">
        <div
          onClick={() => setTab("overview")}
          className={`w-full  text-center shadow-lg pt-3 pb-3 px-[6px] cursor-pointer font-[500] text-[16px] md:text-[20px] xl:text-[20px]  flex justify-center items-center ${
            tab == "overview"
              ? "relative after:content-[''] after:absolute after:h-[5px] after:w-full after:left-0 after:bottom-[-5px] after:bg-[#003C9D]"
              : ""
          }`}
        >
          Overview
        </div>
        <div
          onClick={() => setTab("admissionProcedure")}
          className={`w-full  text-center shadow-lg pt-3 pb-3 px-[6px] cursor-pointer font-[500] text-[16px] md:text-[20px] xl:text-[20px]  flex justify-center items-center ${
            tab == "admissionProcedure"
              ? "relative after:content-[''] after:absolute after:h-[5px] after:w-full after:left-0 after:bottom-[-5px] after:bg-[#003C9D]"
              : ""
          }`}
        >
          Admission Requirement & Eligibility
        </div>
      </div>
      <div className="w-[calc(100%_-_32px)] md:w-[calc(100%_-_64px)] xl:w-[90%] mt-[60px] md:mt-20">
        {tab == "overview" && <Overview />}
        {tab === "admissionProcedure" && (
          <AdmissionProcedure
            title={
              "Eligibility Requirements for enrollment in Medical Billing & Coding Program"
            }
          />
        )}
      </div>
    </div>
  );
};

export default IOSDevelopmentProgram;
