import {
  GET_EDUCATION_LEVEL,
  GET_EDUCATION_LEVEL_SUCCESS,
  GET_EDUCATION_LEVEL_FAILURE,
} from "../ActionTypes";

export const getEducationLevel = (payload) => {
  return {
    type: GET_EDUCATION_LEVEL,
    payload: payload,
  };
};
export const getEducationLevelSuccess = (payload) => {
  return {
    type: GET_EDUCATION_LEVEL_SUCCESS,
    payload: payload,
  };
};
export const getEducationLevelFailure = (payload) => {
  return {
    type: GET_EDUCATION_LEVEL_FAILURE,
    payload: payload,
  };
};
