import React from "react";
import { useNavigate } from "react-router-dom";

function SchoolCatalog() {
  const navigation = useNavigate();

  return (
    <div className=" w-full flex flex-col items-center font-poppins mb-28 gap-12 md:gap-20 ">
      <div className="bg-schoolCatalog-bg w-full h-[400px] md:h-[600px] justify-center items-center flex flex-col gap-8 md:gap-12 ">
        <p className="font-[800] text-[32px] md:text-[50px] xl:text-[60px] w-[calc(100%_-_32px)] md:w-[calc(100%_-_64px)] text-white text-center">
          School Catalog
        </p>
        <div className=" flex justify-center gap-4 md:gap-8 flex-col md:flex-row">
          <button
            className="orangeButton"
            onClick={() => navigation("../scheduleAppointment")}
          >
            Schedule a Visit
          </button>
          <button
            className="orangeButton"
            onClick={() => navigation("../requestInfo")}
          >
            Inquire Now
          </button>
          <button
            className="orangeButton"
            onClick={() => navigation("../enrollment")}
          >
            Enroll Now
          </button>
        </div>
      </div>
      <div className="w-[calc(100%_-_32px)] md:w-[calc(100%_-_64px)] xl:w-[90%]">
        <ul className="flex flex-col gap-10 font-[600] text-[20px] md:text-[24px] xl:text-[30px] leading-[30px] md:leading-[36px] xl:leading-[45px] text-[#003C9D] underline xl:w-[90%] list-disc  list-outside ml-10">
          <li
            className="cursor-pointer"
            onClick={() => {
              navigation("/requestInfo", {
                state: {
                  course: "all",
                  title:
                    "To access school catalog please provide your information.",
                },
              });
            }}
          >
            School Catalog for Certification Program
          </li>
          <li
            className="cursor-pointer"
            onClick={() => {
              navigation("/requestInfo", {
                state: {
                  title:
                    "To access school catalog please provide your information.",
                  course: "lpn",
                },
              });
            }}
          >
            License Practical Nursing Program Catalog
          </li>
        </ul>
      </div>
    </div>
  );
}

export default SchoolCatalog;
