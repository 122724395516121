import React from 'react'
import tick from "../../../../assets/images/tick.svg"

function CourseOutcomes() {

    const data = [
        {
            title: "Telemetry technician",
            l1: "Perform EKGs",
            l2: "Monitor a patient's heart waveforms",
            l3: "Notify doctors and nurses when a patient's heartbeat"
        },
        {
            title: "Dialysis technician",
            l1: "Stop and start dialysis for patients with kidney",
            l2: "Keep dialysis equipment sterile",
            l3: "Provide support for dialysis patients"
        },
        {
            title: "Hospital patient care technician",
            l1: "Work regular hospital shifts, usually assigned to certain patients",
            l2: "Monitor vital signs",
            l3: "Help with wound care, catheters, gastric tubes, and IVs"
        },
        {
            title: "Emergency room technician",
            l1: "Work in the fast-paced ER",
            l2: "Take medical histories, vital signs, and important information from patients",
            l3: "Assist doctors and nurses with exams"
        },
        {
            title: "Rehabilitation technician",
            l1: "Provide support for patients in mental hospitals or those with substance use disorders",
            l2: "Help stabilize behavior",
            l3: "Assist with basic daily living needs"
        },
        {
            title: "Phlebotomist",
            l1: "Draw blood for testing",
            l2: "Assist with transfusions",
        }
    ]
    return (
        <div className='font-poppins flex flex-col gap-5 md:gap-10'>
            <p className="font-[500] text-[20px] md:text-[24px] xl:text-[32px] leading-[36px] xl:leading-[48px] text-[#003C9D]">Certified Patient Care Technician (Hybrid) Outcome</p>
            <p className="font-[400] text-[18px] md:text-[20px] text-[#5C616E]  font-inter">Upon the completion of this course the student will be able to:</p>
            <div className='flex flex-col gap-6'>
                {data.map((data) => (
                    <div className='flex flex-col gap-4'>
                        <div className='flex gap-6 items-center'>
                            <img src={tick} alt="" className="h-[22px] w-[22px] md:h-[30px] md:w-[30px]" />
                            <p className='font-[500] text-[20px] md:text-[24px]  text-[#F37021] font-inter'>{data.title}</p>
                        </div>
                        <ul className=" list-disc font-[400] text-[18px] md:text-[20px] text-[#5C616E]  font-inter flex flex-col gap-4 ml-12">
                            <li>{data.l1}</li>
                            <li>{data.l2}</li>
                            {data.l3 && <li>{data.l3}</li>}
                        </ul>
                    </div>
                ))}
                <div className='flex flex-col gap-4'>
                    <div className='flex gap-6 items-center'>
                        <img src={tick} alt="" className="h-[30px] w-[30px]" />
                        <p className='font-[500] text-[20px] md:text-[24px]  text-[#F37021] font-inter'>Strong Organization Skills</p>
                    </div>
                    <p className=" font-[400] text-[18px] md:text-[20px] text-[#5C616E]  font-inter flex flex-col gap-4 ml-12 leading-[]">
                        Many medical billers and coders work from home. This setting requires strong organization skills. Professionals should create a dedicated workspace and create a digital filing system to keep documents organized.
                    </p>
                </div>
            </div>

           
        </div>
    )
}

export default CourseOutcomes