import React from "react";
import { useNavigate } from "react-router-dom";

function History() {
  const navigation = useNavigate();
  return (
    <div className=" w-full flex flex-col items-center font-poppins mb-28">
      <div className="bg-history-bg w-full h-[400px] md:h-[600px] justify-center items-center flex flex-col gap-8 md:gap-12">
        <p className="font-[800] text-[32px] md:text-[50px] xl:text-[60px] text-white text-center">
          Param Institute of Education History
        </p>
        <div className=" flex justify-center gap-4 md:gap-8 flex-col md:flex-row">
          <button
            className="orangeButton"
            onClick={() => navigation("../scheduleAppointment")}
          >
            Schedule a Visit
          </button>
          <button
            className="orangeButton"
            onClick={() => navigation("../requestInfo")}
          >
            Inquire Now
          </button>
          <button
            className="orangeButton"
            onClick={() => navigation("../enrollment")}
          >
            Enroll Now
          </button>
        </div>
      </div>
      <div className="w-[calc(100%_-_32px)] md:w-[calc(100%_-_64px)] xl:w-[90%] mt-12 md:mt-24">
        <p className="font-[500] text-[24px] md:text-[32px] text-[#003C9D]">
          Param Institute of Education History
        </p>
        <div className="font-[400] text-[18px] md:text-[20px] text-[#5C616E] mt-10 leading-[45px] gap-6 md:gap-8 flex flex-col">
          <p>
            The Param Institute of Education (PIE) is a Private Vocational
            healthcare training school established in New Jersey since 2009 and
            committed to providing the highest quality of education to students
            pursuing careers in the allied health sciences and Information
            technology certification program.
          </p>
          <p>
            In 2010, Healthcare program was introduced with Certified Home
            Health Aide and Certified Nurse Aide program and trained 45 students
            in the first year. Gradually, PIE started introducing new programs
            in Medical Billing & Coding and Certified Clinical Medical Assistant
            in 2011. In addition to this PIE obtained the approval for Massage
            therapy and Wellness programs after 2016. In further development our
            school introduces the Hybrid Programs due to high demand after Covid
            Pandemic.
          </p>
          <p>
            In 2023, Param Institute of education reached a significant
            milestone when it became accepted as a Candidate for Accreditation
            by the Middle States Association Commissions on Elementary and
            Secondary Schools (MSA-CESS) and our school is in a process to
            achieving a accreditation. In 2024, A nursing program granted
            provisional accreditation by the NJ Board of nursing and NJ
            Department of Education.
          </p>
          <p>
            Today, Param Institute of education graduates continue to thrive in
            all corners of the healthcare & IT services industry. Param
            Institute of Education is one of the top healthcare & IT
            certification Private vocational training school in New Jersey. We
            also assist in career development, skill development and personal
            growth of individual who become a part of Param Institute of
            Education. PIE provides the in-depth, valuable education for
            students seeking to enhance their education and skillset.
          </p>
        </div>
      </div>
    </div>
  );
}

export default History;
