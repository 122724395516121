import React from "react";
import mbcsOverview from "../../../../assets/images/mbcsOverview.svg";
import coursePlayIcon from "../../../../assets/images/coursePlayIcon.svg";

const Overview = ({ setTab }) => {
  return (
    <>
      <div className="font-poppins">
        <div className="flex  gap-4">
          <div className="text-content  text-[18px] md:text-[20px] leading-[45px] text-[#5C616E]">
            <div className="text-content md:ml-[20px] md:mb-[20px] xl:ml-[99px] xl:mb-[40px] xl:mt-[85px]  w-1/2 float-right hidden md:flex justify-center items-center relative">
              <img src={mbcsOverview} alt="Agile/Scrum Master Course" />
              <img
                className="absolute"
                src={coursePlayIcon}
                alt="course play icon"
              />
            </div>
            <p className="font-[500] text-[20px] md:text-[24px] leading-[36px] xl:[48px] xl:text-[32px] text-[#003C9D] ">
              Medical Billing and coding Specialist (Hybrid) Certification
              Program
            </p>
            <div className="flex md:hidden justify-center items-center mt-10">
              <img src={mbcsOverview} alt="Agile/Scrum Master Course" />
              <img
                className="absolute"
                src={coursePlayIcon}
                alt="course play icon"
              />
            </div>
            <p className="mt-10">
              Medical billing and coding specialist{" "}
              <a
                className="underline text-[#0755b9]"
                href="#"
                onClick={(event) => {
                  event.preventDefault();
                  setTab("faq");
                }}
              >
                (Hybrid)
              </a>{" "}
              is a rising star in the healthcare field today. This course will
              prepare you to start a career in this high-demand occupation and
              earn the medical billing and coding specialist
              <a
                className="underline text-[#0755b9]"
                href="#"
                onClick={(event) => {
                  event.preventDefault();
                  setTab("faq");
                }}
              >
                (Hybrid)
              </a>{" "}
              professional certification that best aligns with your interest and
              career goals. You’ll learn how to use the Healthcare Common
              procedure Coding System (HCPCS) and the CPT Category II and ICD-10
              codes. Additionally, you’ll gain hands-on practice using medical
              billing and coding software.
            </p>
            <p className="mt-4">
              Our Certified Medical Billing and Coding Specialist{" "}
              <a
                className="underline text-[#0755b9]"
                href="#"
                onClick={(event) => {
                  event.preventDefault();
                  setTab("faq");
                }}
              >
                (Hybrid)
              </a>
              Certificate program can help you prepare for certification exams
              that further affirm your knowledge and skills in the Medical
              Billing and Coding Specialist field. Upon graduating from Param
              Institute of Education’s Medical Billing and Coding Specialist
              <a
                className="underline text-[#0755b9]"
                href="#"
                onClick={(event) => {
                  event.preventDefault();
                  setTab("faq");
                }}
              >
                (Hybrid)
              </a>{" "}
              program you will receive your Param Institute of Education
              Certificate of Completion attesting to your accomplishment.
              Further, Param Institute of Education's Medical Billing and Coding
              Specialist{" "}
              <a
                className="underline text-[#0755b9]"
                href="#"
                onClick={(event) => {
                  event.preventDefault();
                  setTab("faq");
                }}
              >
                (Hybrid)
              </a>{" "}
              program will prepare you to take a Certification exam of{" "}
              <a
                className="underline text-[#0755b9]"
                href="https://www.nhanow.com/"
              >
                National Healthcareer Association (NHA).
              </a>
            </p>

            <p className="mt-4">
              Certification exams are administered by independent third parties
              and Param institute of education is approved test center by
              National Healthcareer Association. You are not required to take a
              certification exam, however, taking this additional step will help
              you market yourself to potential employers and validate your
              Medical Coding and Billing knowledge.
            </p>
            <p className="mt-4">
              Certification exams you are eligible to take and we recommend upon
              graduating from our certificate program include the Certified
              Professional Coder Apprentice (CPC-A) exam offered by the
              Certified Billing & Coding Specialist (CBCS) exam offered by the{" "}
              <a
                className="underline text-[#0755b9]"
                href="https://www.nhanow.com/certification/nha-certifications/medical-billing-and-coding-specialist-(cbcs)"
              >
                NHA (National Healthcareer Association).
              </a>
            </p>
          </div>
        </div>

        <div className="flex flex-col gap-5 md:gap-10">
          <p className="font-[500] text-[20px] md:text-[24px] leading-[36px] xl:[48px] xl:text-[32px] text-[#003C9D] mt-10 md:mt-[60px]">
            Why Choose Medical Billing and Coding?
          </p>
          <div className="text-content w-full  text-[18px] md:text-[20px] leading-[45px] flex flex-col gap-4 text-[#5C616E]">
            <p>
              Healthcare is growing at a higher rate than most industries — and
              the increased number of patients and hospital admissions means
              more work for Medical Coding and Billing Specialists. Plus just
              think of the satisfaction you'll get working with doctors and
              other professionals in the medical field. As a Medical Billing and
              Coding Specialist, you will be a vital part of the team!
            </p>
            <p>
              Medical Coding Specialists are in such high demand because nearly
              every visit to the doctor requires properly coded documentation
              for the doctor to receive payment. Every insurer — from private
              companies to Medicare — has its own forms and unique coding
              requirements.
            </p>
            <p>
              Medical Billing Specialists apply all of these special codes to
              the forms that insurance companies, Medicare and state medical aid
              plans require before they pay a claim. Multiply this across every
              doctor's office, hospital and health insurance claim in the
              country and you'll see why this online Medical Coding and Billing
              course is in demand!
            </p>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-10 md:gap-10 mt-10 md:mt-[60px]">
        <p className="font-[500] text-[20px] md:text-[24px] xl:text-[32px] leading-[36px] xl:leading-[48px] text-[#003C9D]">
          Job Outlook for Medical Billing and Coding Specialists
        </p>
        <ul className=" list-disc font-[400] text-[18px] md:text-[20px] text-[#5C616E] leading-[45px] ml-8 flex flex-col gap-4">
          <li>
            According to the Bureau of Labor Statistics (BLS), medical billing
            and coding specialists earn an average annual salary of $40,350 and
            work in one of the fastest growing professions.
          </li>
          <li>
            The BLS also estimates that at least 27,000 new jobs will be needed
            in this profession by 2026.
          </li>
          <li>
            The projected percent change in employment from 2021 to 2031. The
            average growth rate for all occupations is 5 percent.
          </li>
        </ul>
      </div>
      <div className="mt-10 md:mt-[60px]">
        <p className="font-[500] text-[20px] md:text-[24px] leading-[36px] xl:[48px] xl:text-[32px] text-[#003C9D]">
          Program Outline
        </p>
        <div className="p-6 border-[1px] rounded-[5px] border-[#D9D9D9] mt-5 md:mt-10 shadow-lg">
          <ul className="list-disc font-[400] text-[18px] md:text-[20px] text-[#1B2336] leading-[35px] flex flex-col gap-[10px] ml-6">
            <li>
              Total Hours:{" "}
              <span className="font-[500] text-[#003C9D]">300</span>
            </li>
            <li>Study 200 hours in 10 weeks</li>
            <li>
              Medical billing and coding Software Class Training:{" "}
              <span className="font-[500] text-[#003C9D]">40 Hours</span>
            </li>
            <li>
              Online or in person field Externship:{" "}
              <span className="font-[500] text-[#003C9D]">60 Hours</span>
            </li>
          </ul>
        </div>

        <div className="p-6 border-[1px] rounded-[5px] border-[#D9D9D9] mt-6 shadow-lg">
          <ul className="list-disc font-[400] text-[18px] md:text-[20px] text-[#1B2336] leading-[35px] flex flex-col gap-[10px] ml-6">
            <li> WEEK 1: INTRODUCTION TO MBCS PROGRAM </li>
            <li>WEEK 2:  ROLE OF AN INSURANCE BILLING SPECIALIST</li>
            <li>WEEK 3:  FUNDAMENTAL OF HEALTH INSURANCE COVERAGE</li>
            <li>WEEK 4:  SOURCE DOCUMENTS & CLAIM CYCLE OF HEALTH INSURANCE</li>
            <li>WEEK 5:   DOCUMENTATION & MEDICAL RECORDS</li>
            <li>WEEK 6:  FEES- PRIVATE INSURANCE & MANAGED CARE</li>
            <li>WEEK 6:  MEDICAID</li>
            <li>WEEK 7: THE MEDICARE PROGRAM</li>
            <li>WEEK 8: TRICARE & CHAMPVA PROGRAM</li>
            <li>WEEK 9: WORKER COMPENSATION</li>
            <li>WEEK 9: COLLECTION PROCEDURE</li>
            <li>WEEK 10: CPT & ICD CODING</li>
            <li>WEEK 11: MBCS SOFTWARE TRAINING</li>
            <li>WEEK 12: MBCS SOFTWARE TRAINING</li>
            <li>WEEK 13 TO 15: EXTERNSHIP AT FIELD OR ONLINE</li>
          </ul>
        </div>
      </div>
      <div className="w-full flex gap-10 flex-col">
        <div className="w-full  mt-[20px]">
          <p className="font-[500] text-[20px] md:text-[24px] xl:text-[32px] text-[#003C9D] pb-[40px] pt-10 md:pt-[60px] 2xl:pt-[96px]">
            What you get from us?
          </p>
          <div className="flex gap-8 xl:flex-row flex-col">
            <ul className=" w-full list-disc font-[400] text-[18px] md:text-[20px] text-[#5C616E] flex  list-inside gap-4 flex-col">
              <li>Online/live lectures and material access</li>
              <li>Live instructor for your queries</li>
              <li>Administrative support</li>
              <li>Onsite clinical training</li>
              <li>Field/Internship/Externship training</li>
              <li>MBCS Certificate from school</li>

              <li>
                NHA or AMCA Medical billing & coding specialist (MBCS) exam
                preparation material
              </li>
              <li>
                NHA or AMCA certification once you appear and pass the NHA exams
              </li>
              <li>Placement Assistant</li>
              <li>Job opportunities</li>
              <li>Career pathways opportunities and Guidelines</li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Overview;
