//----------------------------------------------------------------- All Imports Starts------------------------------------------------------------------------//
import React, { useEffect, useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "react-date-picker/dist/DatePicker.css";
import { useDispatch, useSelector } from "react-redux";
import calendarIcon from "../../assets/images/calendarIcon.svg";
import globeIcon from "../../assets/images/globeIcon.svg";
import headerLogo from "../../assets/images/headerLogo.svg";
import locationIcon from "../../assets/images/locationIcon.svg";
import timeIcon from "../../assets/images/timeIcon.svg";
import { useNavigate } from "react-router-dom";
import { getBookingSlots } from "../../redux/actions/appointmentBooking.action.js";
import moment from "moment";
import { getHolidays } from "../../redux/actions/StudentServices.action.js";

//----------------------------------------------------------------- All Imports Ends ------------------------------------------------------------------------//

const AppointmentTime = ({ setFormStep }) => {
  //-------------------------------------------------------------- Variables, States Initialization Starts --------------------------------------------------//
  const dispatch = useDispatch();
  let date = useSelector((state) => state.appointmentReducer.selected_date);
  let holidaysData = useSelector(
    (state) => state.studentServicesReducer.getHolidayData
  );
  let bookingSlots = useSelector(
    (state) => state.appointmentReducer.bookingSlots
  );
  const navigation = useNavigate();
  let timeFormat = useSelector(
    (state) => state.appointmentReducer.selected_format
  );
  let selectedTime = useSelector(
    (state) => state.appointmentReducer.selected_time
  );
  const [timeValueState, setTimeValueState] = useState(null);

  //-------------------------------------------------------------- Variables, States Initialization Ends --------------------------------------------------//

  //-------------------------------------------------------------- Functions to Get Formated Time Starts --------------------------------------------------//
  const formattedDateWithYear = moment(date, "YYYY-MM-DD").format(
    "dddd, MMMM D, YYYY"
  );
  const formattedDateWithoutYear = moment(date, "YYYY-MM-DD").format(
    "dddd, MMMM D"
  );
  //-------------------------------------------------------------- Functions to Get Formated Time Ends --------------------------------------------------//

  //---------------------------------- UseEffect to Display Time Slots According to AM/PM or 24Hours Format Starts --------------------------------//

  useEffect(() => {
    if (timeFormat == "AM/PM") {
      function convertTimeTo12HourFormat(time) {
        // Use moment to parse the time in 24-hour format
        let parsedTime = moment(time, "HH:mm");
        // Format the time in 12-hour format with AM/PM
        return parsedTime.format("hh:mma");
      }
      // Iterate through the array and convert each time
      let convertedTimeArray = bookingSlots?.data.map((obj) => {
        return {
          id: obj.id,
          time: convertTimeTo12HourFormat(obj.time),
        };
      });
      setTimeValueState(convertedTimeArray);
    } else if (timeFormat == "24hrs") {
      function convertTimeTo24HourFormat(time) {
        // Use moment to parse the time in 24-hour format
        let parsedTime = moment(time, "hh:mma");
        // Format the time in 12-hour format with AM/PM
        return parsedTime.format("HH:mm");
      }

      // Iterate through the array and convert each time
      let convertedTimeArray = bookingSlots?.data.map((obj) => {
        return {
          id: obj.id,
          time: convertTimeTo24HourFormat(obj.time),
        };
      });
      setTimeValueState(convertedTimeArray);
    }

    // ------------------------------------- Setting default slot id starts -------------------------------------------------------------//
    dispatch({
      type: "SELECTED_TIME_ID",
      payload: bookingSlots?.data[0]?.id,
    });
    dispatch({
      type: "SELECTED_TIME",
      payload: bookingSlots?.data[0]?.time,
    });
    // ------------------------------------- Setting default slot id ends -------------------------------------------------------------//
  }, [bookingSlots, timeFormat]);
  //---------------------------------- UseEffect to Display Time Slots According to AM/PM or 24Hours Format Ends --------------------------------//

  // ---------------------------------------- useEffect to fetch available slots for specific day starts -------------------------------------------//
  useEffect(() => {
    const formattedDate = moment(date).format("YYYY-MM-DD");
    dispatch(getBookingSlots({ formattedDate }));
    dispatch(getHolidays());
  }, [date]);
  // ---------------------------------------- useEffect to fetch available slots for specific day ends -------------------------------------------//

  const matchTime = (selectedTime, arrayTime) => {
    if (
      moment(selectedTime, "hh:mm").format("hh:mm") ==
      moment(arrayTime, "hh:mm").format("hh:mm")
    ) {
      return true;
    }
    return false;
  };

  const dateIsDisabled = (date) => {
    // Format current date to match 'YYYY-MM-DD' format
    const formattedDate = `${date.getFullYear()}-${String(
      date.getMonth() + 1
    ).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`;
    // Check if formatted date exists in disabledDates array
    return holidaysData?.includes(formattedDate);
  };
  return (
    <>
      <div className="shadow-xl rounded-[10px] border border-[#D9D9D9] flex flex-col md:w-[calc(100%_-_64px)] xl:w-[89%] justify-center">
        <div className="flex flex-col 1600:flex-row">
          <div className="py-10 pb-[10px] px-8 block 1600:hidden">
            <img src={headerLogo} className="h-[59px] md:h-[83px]" />
          </div>
          <div className="1600:w-[32%] flex flex-col 991:flex-row 1600:flex-col gap-12 border-r-[0] 1600:border-r border-[#D9D9D9] py-10 px-8 991:pr-0 1600:pr-[64px]">
            <div className="hidden 1600:block">
              <img src={headerLogo} />
            </div>
            <div className="991:w-[55%] 1600:w-auto">
              <div className="text-[18px] leading-[27px]">
                <p className="text-[24px] font-[600]">
                  Schedule An Appointment
                </p>
                <div className="mt-4 flex flex-col gap-8 text-[#989898]">
                  <span className="flex items-center gap-4 leading-[27px]">
                    <img src={timeIcon} />
                    <p>15 min</p>
                  </span>

                  <span className="flex items-center gap-4 leading-[27px]">
                    <img src={locationIcon} />
                    <p>
                      200 Middlesex Essex Turnpike, Suite #105, Iselin, NJ,
                      08830.
                    </p>
                  </span>

                  <span className="flex items-center gap-4 leading-[27px]">
                    <img src={calendarIcon} />
                    <p>{`${
                      timeFormat == "24hrs"
                        ? `${moment(selectedTime, "hh:mma").format(
                            "HH:mm"
                          )} to ${moment(selectedTime, "hh:mma")
                            .add(15, "minutes")
                            .format("HH:mm")}`
                        : `${moment(selectedTime, "hh:mma").format(
                            "hh:mma"
                          )} to ${moment(selectedTime, "HH:mma")
                            .add(15, "minutes")
                            .format("hh:mma")}`
                    }, ${formattedDateWithYear}`}</p>
                  </span>

                  <span className="flex items-center gap-4 leading-[27px]">
                    <img src={globeIcon} />
                    <p>Eastern Time - US & Canada</p>
                  </span>
                </div>
              </div>
            </div>

            <hr className="text-[#D9D9D9] bg-[#D9D9D9] h-auto w-auto 991:w-[1px] 1600:w-auto" />
            <div className="flex flex-col gap-8 text-[18px] leading-[27px] 991:w-[45%] 1600:w-auto">
              <div>
                <p className="font-[500] text-[20px]">Time Zone</p>
                <span className="flex gap-4 mt-4 text-[#989898]">
                  <img src={globeIcon} />
                  <p>Eastern Time - US & Canada ({selectedTime})</p>
                </span>
              </div>
              <div>
                <p className="font-[500] text-[20px]">Time Format</p>
                <span className="flex gap-4 mt-4">
                  <div className="custom-radio-container">
                    <input
                      type="radio"
                      id="am-pm"
                      name="timeFormat"
                      value="AM/PM"
                      className="h-[24px] w-[24px]"
                      checked={timeFormat === "AM/PM"}
                      onChange={(e) => {
                        dispatch({
                          type: "SELECTED_FORMAT",
                          payload: e.target.value,
                        });
                      }}
                    />
                    <span class="checkmark"></span>
                    <label for="am-pm">AM/PM</label>
                  </div>

                  <div className="custom-radio-container">
                    <input
                      type="radio"
                      id="24hrs"
                      name="timeFormat"
                      value="24hrs"
                      className="h-[24px] w-[24px]"
                      checked={timeFormat === "24hrs"}
                      onChange={(e) => {
                        dispatch({
                          type: "SELECTED_FORMAT",
                          payload: e.target.value,
                        });
                      }}
                    />
                    <span class="checkmark"></span>
                    <label for="24hrs">24 Hrs</label>
                  </div>
                </span>
              </div>
            </div>
          </div>

          <div className="flex-col 1600:w-[68%] pt-10 pb-[54px] px-[64px] hidden 1600:flex">
            <div className="flex gap-10 ">
              <div className="flex flex-col gap-10">
                <p className="text-[20px] leading-[30px] font-[500]">
                  Select a Date & Time
                </p>
                <Calendar
                  onChange={(e) =>
                    dispatch({ type: "SELECTED_DATE", payload: e })
                  }
                  value={date}
                  calendarType={"gregory"}
                  minDate={new Date()}
                  tileDisabled={({ date }) => dateIsDisabled(date)} // Disable specific dates
                />
              </div>

              <div className="flex flex-col gap-10">
                <p className="text-[20px] leading-[30px] font-[500]">
                  {formattedDateWithoutYear}
                </p>
                <div className="flex flex-wrap gap-6">
                  {timeValueState?.map((item) => (
                    <span
                      className={`text-[16px] text-[#1B2336] py-[10px] px-[15px] border border-[#989898] rounded-[5px] w-[95px] hover:bg-[#003C9D] hover:text-[#FFFFFF] cursor-pointer flex justify-center ${
                        matchTime(selectedTime, item.time)
                          ? "bg-[#003C9D] text-[#FFFFFF]"
                          : ""
                      }`}
                      onClick={() => {
                        dispatch({ type: "SELECTED_TIME", payload: item.time });
                        dispatch({
                          type: "SELECTED_TIME_ID",
                          payload: item.id,
                        });
                      }}
                    >
                      {item.time}
                    </span>
                  ))}
                </div>
              </div>
            </div>
            <div className="flex justify-end gap-5 mt-[90px]">
              <button className="orangeButton" onClick={() => setFormStep(2)}>
                Next
              </button>
              <button
                className="orangeOutlineButton"
                onClick={() => navigation("./")}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="flex-col pt-[50px] 991:pt-[70px] pb-[54px] xl:px-[25px] flex 1600:hidden md:w-[calc(100%_-_64px)] xl:w-[90%]">
        <div className="flex flex-col 991:flex-row gap-[50px] 991:gap-10 justify-center items-center 991:items-start">
          <div className="flex flex-col gap-10">
            <p className="text-[20px] leading-[30px] font-[500] text-center 991:text-left">
              Select a Date & Time
            </p>
            <Calendar
              onChange={(e) => dispatch({ type: "SELECTED_DATE", payload: e })}
              value={date}
              calendarType={"gregory"}
              minDate={new Date()}
              tileDisabled={({ date }) => dateIsDisabled(date)} // Disable specific dates
            />
          </div>
          <div className="flex flex-col gap-10">
            <p className="text-[20px] leading-[30px] font-[500] text-center 991:text-left">
              {formattedDateWithoutYear}
            </p>
            <div className="grid grid-cols-2 415:grid-cols-3 576:grid-cols-4 991:grid-cols-4 2xl:grid-cols-6 gap-6">
              {timeValueState?.map((item) => (
                <span
                  className={`text-[16px] text-[#1B2336] py-[10px] px-[15px] border border-[#989898] rounded-[5px] w-[95px] hover:bg-[#003C9D] hover:text-[#FFFFFF] cursor-pointer flex justify-center ${
                    matchTime(selectedTime, item.time)
                      ? "bg-[#003C9D] text-[#FFFFFF]"
                      : ""
                  }`}
                  onClick={() => {
                    dispatch({ type: "SELECTED_TIME", payload: item.time });
                    dispatch({
                      type: "SELECTED_TIME_ID",
                      payload: item.id,
                    });
                  }}
                >
                  {item.time}
                </span>
              ))}
            </div>
          </div>
        </div>
        <div className="flex justify-center 991:justify-end gap-5 mt-[60px] 991:mt-[90px]">
          <button className="orangeButton px-4" onClick={() => setFormStep(2)}>
            Next
          </button>
          <button
            className="orangeOutlineButton"
            onClick={() => navigation("../")}
          >
            Cancel
          </button>
        </div>
      </div>
    </>
  );
};

export default AppointmentTime;
