import { React, useEffect, useRef, useState } from "react";
import { Formik, Field, Form, ErrorMessage, FormikProvider } from "formik";
import * as Yup from "yup";
import { imageGenerator } from "../../../utils/imageGenerator";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useLoader from "../../../hooks/useLoader";
import { getCourses } from "../../../redux/actions/common.action";
import {
  addGrievance,
  addGrievanceSuccess,
} from "../../../redux/actions/StudentServices.action";

const GrievanceForm = () => {
  const [file, setFile] = useState();
  const [image, setImage] = useState(null);
  const [fileName, setFileName] = useState(" no Selected file");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formRef = useRef(null);
  const { isLoading, setLoader, Loader } = useLoader();
  const { getcourses_data } = useSelector((state) => state.CommonReducer);
  const [imageError,setImageError]=useState(null)
  const { addGrievanceData } = useSelector(
    (state) => state.studentServicesReducer
  );

  //----------------------------------------------------------USE EFFECT FOR GET COURSES-----------------------------------------------------------------//
  useEffect(() => {
    dispatch(getCourses());
  }, [dispatch]);

  function handleFile(event) {
    setFile(event.target.files[0]);
  }

  function handleUpload() {
    const formData = new FormData();
    formData.append("file", file);
    fetch("url", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((result) => {
        console.log("success", result);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  const validationSchema = Yup.object().shape({
    fullName: Yup.string().required("Fullname is required."),
    email: Yup.string()
      .email("Please enter valid email")
      .required("Email is required."),
    phoneNumber: Yup.string().required("Phone Number is required."),
    programDepartment: Yup.string().required(
      "Please select your program/department."
    ),
    dateIncident: Yup.string().required("Please enter date of incident."),
    incindent: Yup.string().required("Location of incident is required"),
    state: Yup.string().required("Description of grievance is required."),
    resolution: Yup.string().required("Desired resolution is required."),
    // file: Yup.mixed().required("File is required"),

    SignName: Yup.string().required("Sign name is required."),
    todaydate: Yup.string().required("Today's Date is required."),
  });

  useEffect(() => {
    if (
      addGrievanceData&&addGrievanceData.status==200
    ) {  
      setLoader(false);
      const message = addGrievanceData.data.message;
      dispatch(addGrievanceSuccess(null));
      navigate("../thankYou", {
        state: { message: message },
      });
    }
  }, [addGrievanceData]);

  //------------------------------------------------------- Function to Handle Form Submission Starts ---------------------------------------------------//
  const handleSubmit = async (values, { resetForm }) => {
    if(!image){
      return setImageError("Supporting documentation is required.")
    }
    const formData = new FormData();

    // Append form values to FormData
    const pdf = await imageGenerator(formRef.current);

    formData.append("fullname", values.fullName);
    formData.append("email", values.email);
    formData.append("phonenumber", values.phoneNumber);
    formData.append("course_id", values.programDepartment);
    formData.append("dateOfIncident", values.dateIncident);
    formData.append("locationOfIncident", values.incindent);
    formData.append("descriptionOfGrievance", values.state);
    formData.append("desiredResolution", values.resolution);
    formData.append("name", values.SignName);
    formData.append("submittedDate", values.todaydate);
    formData.append("supportingDocumentation", image);
    formData.append("pdfFile", JSON.stringify(pdf));

    // formData.forEach((value, key) => {
    //   console.log(key, value);
    // });

    try {
      dispatch(addGrievance(formData));
      resetForm();
      setLoader(true);
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally{
      setImageError(null)
    }
  };
  return (
    <div
      className="bg-[#F5F5F5] font-poppins px-4 md:px-8 xl:px-10 py-12 flex flex-col -mb-10"
      ref={formRef}
    >
      <>
        {isLoading ? (
          <Loader type={"component"} />
        ) : (
          <>
            <p className="font-[600] text-[32px] md:text-[40px] xl:text-[42px] text-[#F37021] text-center mb-10">
              Student Grievance Form
            </p>
            <p className="font-poppins font-[400] text-[18px] md:text-[20px] text-[#5C616E] leading-[45px] text-left">
              A complaint is defined as a difference or dispute between a
              student and a faculty or staff member related to matters such as
              grades, assignments, attendance, classroom procedures, program
              requirements, or other services or activities. Complaints rise to
              the level of formal grievances:
            </p>
            <p className="font-poppins font-[400] text-[18px] md:text-[20px] text-[#5C616E] leading-[45px] text-left">
              1) after the student has attempted and been unsuccessful in
              resolving the concern informally in accordance with the Student
              Grievance procedure.
            </p>
            <p className="font-poppins font-[400] text-[18px] md:text-[20px] text-[#5C616E] leading-[45px] text-left">
              2) by completing and appropriately submitting this form.
            </p>
            <p className="font-poppins font-[400] text-[18px] md:text-[20px] text-[#5C616E] leading-[45px] text-left mb-10">
              Please supply all information requested below. Any omission will
              delay the processing of your grievance.
            </p>
            <p className="font-poppins text-[22px] md:text-[24px] font-[500] leading-[24px] text-[#003C9D] mb-8">
              Student Information
            </p>
            <Formik
              initialValues={{
                fullName: "",
                email: "",
                phoneNumber: "",
                programDepartment: "",
                dateIncident: "",
                incindent: "",
                state: "",
                resolution: "",

                SignName: "",
                todaydate: "",
              }}
              onSubmit={handleSubmit}
              validationSchema={validationSchema}
            >
              {({ errors, touched }) => (
                <Form className="w-full flex flex-col gap-8">
                  <div className="w-full xl:w-[100%] flex gap-8 md:gap-4 xl:gap-5 flex-col md:flex-row">
                    <div className=" w-full flex flex-col gap-2">
                      <label className="font-[400] text-[16px] md:text-[18px] text-[#1B2336]">
                        Full Name <span className="text-[#BB2727]">*</span>
                      </label>

                      <Field
                        type="text"
                        name="fullName"
                        className={`h-[50px] border border-[#989898] rounded-[5px] p-3 ${
                          errors.fullName && touched.fullName
                            ? "border-red-500"
                            : ""
                        }`}
                      />
                      <ErrorMessage
                        name="fullName"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                    <div className=" w-full flex flex-col gap-2">
                      <label className="font-[400] text-[16px] md:text-[18px] text-[#1B2336]">
                        Email Address <span className="text-[#BB2727]">*</span>
                      </label>

                      <Field
                        type="text"
                        name="email"
                        className={`h-[50px] border border-[#989898] rounded-[5px] p-3 ${
                          errors.email && touched.email ? "border-red-500" : ""
                        }`}
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                  </div>
                  <div className="w-full xl:w-[100%] flex gap-8 md:gap-5 flex-col md:flex-row">
                    <div className=" w-full flex flex-col gap-2">
                      <label className="font-[400] text-[16px] md:text-[18px] text-[#1B2336]">
                        Phone Number <span className="text-[#BB2727]">*</span>
                      </label>

                      <Field
                        type="text"
                        name="phoneNumber"
                        className={`h-[50px] border border-[#989898] rounded-[5px] p-3 ${
                          errors.phoneNumber && touched.phoneNumber
                            ? "border-red-500"
                            : ""
                        }`}
                      />
                      <ErrorMessage
                        name="phoneNumber"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                    <div className=" w-full flex flex-col gap-2 max-w-[100%] md:max-w-[49.4%]">
                      <label className="font-[400] text-[16px] md:text-[18px] text-[#1B2336]">
                        Program/Department{" "}
                        <span className="text-[#BB2727]">*</span>
                      </label>

                      <Field
                        as="select"
                        name="programDepartment"
                        type="text"
                        className={`h-[50px] border border-[#989898] rounded-[5px] p-3 ${
                          errors.programDepartment && touched.programDepartment
                            ? "border-red-500"
                            : ""
                        }`}
                      >
                        <option value="" disabled hidden>
                          Select Program
                        </option>
                        {getcourses_data?.data?.map((course) => (
                          <option value={course.id}>{course.coursename}</option>
                        ))}
                      </Field>

                      <ErrorMessage
                        name="programDepartment"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                  </div>

                  <p className="font-poppins text-[22px] md:text-[24px] font-[500] leading-[24px] text-[#003C9D]">
                    Grievance pertains to
                  </p>

                  <div className="w-full xl:w-[100%] flex gap-8 md:gap-5 flex-col 991:flex-row">
                    <div className=" w-full flex flex-col gap-2">
                      <label className="font-[400] text-[16px] md:text-[18px] text-[#1B2336]">
                        Name(s) of Individuals Involved (if applicable)
                      </label>

                      <Field
                        type="text"
                        name="individual"
                        className={`h-[50px] border border-[#989898] rounded-[5px] p-3 ${
                          errors.individual && touched.individual
                            ? "border-red-500"
                            : ""
                        }`}
                      />
                    </div>
                    <div className=" w-full flex flex-col gap-2">
                      <label className="font-[400] text-[16px] md:text-[18px] text-[#1B2336] 991:mb-[28px] 1379:mb-0">
                        Date(s) of Incident{" "}
                        <span className="text-[#BB2727]">*</span>
                      </label>

                      <Field
                        type="date"
                        name="dateIncident"
                        className={`h-[50px] border border-[#989898] rounded-[5px] p-3 ${
                          errors.dateIncident && touched.dateIncident
                            ? "border-red-500"
                            : ""
                        }`}
                      />
                      <ErrorMessage
                        name="dateIncident"
                        component="div"
                        className="text-red-500"
                      />
                    </div>

                    <div className=" w-full flex flex-col gap-2">
                      <label className="font-[400] text-[16px] md:text-[18px] text-[#1B2336] 991:mb-[28px] 1379:mb-0">
                        Location of Incident{" "}
                        <span className="text-[#BB2727]">*</span>
                      </label>

                      <Field
                        type="text"
                        name="incindent"
                        className={`h-[50px] border border-[#989898] rounded-[5px] p-3 ${
                          errors.incindent && touched.incindent
                            ? "border-red-500"
                            : ""
                        }`}
                      />
                      <ErrorMessage
                        name="incindent"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                  </div>

                  <p className="font-poppins text-[22px] md:text-[24px] font-[500] leading-[24px] text-[#003C9D]">
                    Description of Grievance
                  </p>

                  <div className="w-full xl:w-[100%] flex gap-8 md:gap-5 flex-col md:flex-row">
                    <div className=" w-full flex flex-col gap-2">
                      <label className="font-[400] text-[16px] md:text-[18px] text-[#1B2336]">
                        Please provide a detailed description of the issue or
                        incident. Include all relevant information and any
                        actions you have already taken to resolve the issue{" "}
                        <span className="text-[#BB2727]">*</span>
                      </label>

                      <Field
                        type="textarea"
                        rows="5"
                        as="textarea"
                        name="state"
                        className={` border border-[#989898] rounded-[5px] p-3 h-36 ${
                          errors.state && touched.state ? "border-red-500" : ""
                        }`}
                      />
                      <ErrorMessage
                        name="state"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                  </div>

                  <p className="font-poppins text-[22px] md:text-[24px] font-[500] leading-[24px] text-[#003C9D]">
                    Desired Resolution
                  </p>

                  <div className="w-full xl:w-[100%] flex gap-8 md:gap-5 flex-col md:flex-row">
                    <div className=" w-full flex flex-col gap-2">
                      <label className="font-[400] text-[16px] md:text-[18px] text-[#1B2336]">
                        Describe what resolution or outcome you are seeking{" "}
                        <span className="text-[#BB2727]">*</span>
                      </label>

                      <Field
                        type="textarea"
                        rows="5"
                        as="textarea"
                        name="resolution"
                        className={` border border-[#989898] rounded-[5px] p-3 h-36 ${
                          errors.state && touched.state ? "border-red-500" : ""
                        }`}
                      />
                      <ErrorMessage
                        name="resolution"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                  </div>

                  <p className="font-poppins text-[22px] md:text-[24px] font-[500] leading-[24px] text-[#003C9D]">
                    Supporting Documentation
                  </p>
                  <label className="font-[400] text-[16px] md:text-[18px] text-[#1B2336]">
                    Attach any documents or evidence that support your grievance
                    (e.g., emails, screenshots, written statements){" "}
                    <span className="text-[#BB2727]">*</span>
                  </label>
                  <div
                    className="w-full xl:w-[100%] flex justify-center flex-col md:flex-row border-dashed border-2 h-36 border-[#989898] rounded-[5px] cursor-pointer bg-white"
                    onClick={() =>
                      document.querySelector(".input-fieldCls").click()
                    }
                  >
                    <div className="w-full flex justify-center items-center flex-col">
                      <Field
                        type="file"
                        accept="doc/*"
                        name="file"
                        className="input-fieldCls"
                        hidden
                        onChange={({ target: { files } }) => {
                          files[0] && setFileName(files[0].name);
                          if (files) {
                            setImage(files[0]);
                            setImageError(null)
                          }
                        }}
                      />
                      {image ? (
                        <p>{fileName}</p>
                      ) : (
                        <>
                          <p>Upload Document</p>
                        </>
                      )}
                    </div>
                  </div>
              
                  <p className="text-red-500 mt-[-20px]">{imageError?imageError:""}</p>

                  <div
                    className="flex items-center gap-[10px]"
                    onClick={() => {
                      setFileName(null);
                      setImage(null);
                    }}
                  ></div>

                  <p className="font-poppins text-[22px] md:text-[24px] font-[500] leading-[24px] text-[#003C9D]">
                    Previous Actions Taken (if any)
                  </p>

                  <div className="w-full xl:w-[100%] flex gap-8 md:gap-5 flex-col md:flex-row">
                    <div className=" w-full flex flex-col gap-2">
                      <label className="font-[400] text-[16px] md:text-[18px] text-[#1B2336]">
                        List any steps you have previously taken to address this
                        grievance (e.g., speaking to a faculty member,
                        submitting an informal complaint)
                      </label>

                      <Field
                        type="textarea"
                        rows="5"
                        as="textarea"
                        name="documentUpload"
                        className={` border border-[#989898] rounded-[5px] p-3 h-36`}
                      />
                    </div>
                  </div>

                  <p className="font-poppins text-[22px] md:text-[24px] font-[500] leading-[24px] text-[#003C9D]">
                    Signature
                  </p>

                  <p className="font-poppins text-[16px] md:text-[18px] font-[400] leading-[24px] text-[#1B2336]">
                    By signing below, you confirm that the information provided
                    is accurate to the best of your knowledge and understand
                    that this grievance will be reviewed in accordance with the
                    institution's policies and procedures
                  </p>

                  <div className="w-full xl:w-[100%] flex gap-8 md:gap-5 flex-col md:flex-row">
                    <div className=" w-full flex flex-col gap-2">
                      <label className="font-inter font-[600] text-[20px] md:text-[24px] text-[#5C616E]">
                        E Signature
                      </label>
                    </div>
                  </div>

                  <div className="w-full xl:w-[100%] flex gap-8 md:gap-5 flex-col md:flex-row">
                    <div className=" w-full flex flex-col gap-2">
                      <label className="font-[400] text-[16px] md:text-[18px] text-[#1B2336]">
                        Name <span className="text-[#BB2727]">*</span>
                      </label>

                      <Field
                        type="text"
                        name="SignName"
                        className={`h-[50px] border border-[#989898] rounded-[5px] p-3 ${
                          errors.SignName && touched.SignName
                            ? "border-red-500"
                            : ""
                        }`}
                      />
                      <ErrorMessage
                        name="SignName"
                        component="div"
                        className="text-red-500"
                      />
                    </div>

                    <div className=" w-full flex flex-col gap-2">
                      <label className="font-[400] text-[16px] md:text-[18px] text-[#1B2336]">
                        Today's Date <span className="text-[#BB2727]">*</span>
                      </label>

                      <Field
                        type="date"
                        name="todaydate"
                        className={`h-[50px] border border-[#989898] rounded-[5px] p-3 ${
                          errors.todaydate && touched.todaydate
                            ? "border-red-500"
                            : ""
                        }`}
                      />
                      <ErrorMessage
                        name="todaydate"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                  </div>

                  <div className="flex gap-3 w-full xl:w-[100%] justify-center mt-3">
                    <button type="submit" className="orangeHoverButton">
                      Submit
                    </button>
                    <button
                      type="button"
                      className="orangeOutlineButton"
                      onClick={() => navigate(-1)}
                    >
                      Cancel
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </>
        )}
      </>
    </div>
  );
};

export default GrievanceForm;
