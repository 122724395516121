import React from "react";

const CourseOutcomes = () => {
  return (
    <>
      <div>
        <p className="font-[500] text-[20px] md:text-[24px] xl:text-[32px] leading-[36px] xl:leading-[48px] text-[#003C9D]">
          Upon the Completion of the Massage Therapy Program the Student will be
          Able to
        </p>
        <p className="text-[18px] md:text-[20px] leading-[40px] mt-5 md:mt-10 text-[#5C616E]">
          Upon completing the Massage Therapy Program, students will emerge
          equipped with a comprehensive set of skills and knowledge to excel in
          the field. Here's a detailed breakdown of what they can expect to
          achieve:
        </p>
        <ul className="mt-6 text-[#F37021] font-[500] text-[20px] md:text-[24px] leading-[30px] flex flex-col gap-6">
          <li className="flex flex-col relative before:content-[''] before:absolute before:left-0 before:w-[30px] pl-[54px] before:h-[30px] before:bg-checkmark-bg before:bg-no-repeat">
            Massage Technical Proficiency
            <span className="mt-4 text-[#5C616E] text-[18px] md:text-[20px] leading-10 font-[400]">
              Students will have mastered a wide range of massage techniques,
              including Swedish massage, deep tissue massage, sports massage,
              and others. They will demonstrate proficiency in applying these
              techniques effectively to address various client needs, such as
              muscle tension relief, pain management, and relaxation.
            </span>
          </li>
          <li className="flex flex-col relative before:content-[''] before:absolute before:left-0 before:w-[30px] pl-[54px] before:h-[30px] before:bg-checkmark-bg before:bg-no-repeat">
            Anatomy and Physiology Knowledge
            <span className="mt-4 text-[#5C616E] text-[18px] md:text-[20px] leading-10 font-[400]">
              A solid understanding of human anatomy and physiology is essential
              for massage therapists. Students will have gained in-depth
              knowledge of the musculoskeletal system, nervous system,
              circulatory system, and other relevant body systems. This
              understanding enables them to tailor massage treatments to
              specific anatomical structures and physiological conditions.
            </span>
          </li>
          <li className="flex flex-col relative before:content-[''] before:absolute before:left-0 before:w-[30px] pl-[54px] before:h-[30px] before:bg-checkmark-bg before:bg-no-repeat">
            Client Assessment Skills
            <span className="mt-4 text-[#5C616E] text-[18px] md:text-[20px] leading-10 font-[400]">
              Students will have developed the ability to conduct thorough
              client assessments to identify areas of tension, muscle
              imbalances, postural issues, and other factors affecting their
              clients' well-being. They will know how to communicate effectively
              with clients to gather relevant information and establish
              treatment goals.
            </span>
          </li>
          <li className="flex flex-col relative before:content-[''] before:absolute before:left-0 before:w-[30px] pl-[54px] before:h-[30px] before:bg-checkmark-bg before:bg-no-repeat">
            Ethical and Professional Standards
            <span className="mt-4 text-[#5C616E] text-[18px] md:text-[20px] leading-10 font-[400]">
              Massage therapists are held to high ethical and professional
              standards. Students will have learned about ethical guidelines,
              boundaries, confidentiality, and the importance of always
              maintaining a professional attitude They will understand the legal
              and ethical considerations involved in client-therapist
              relationships.
            </span>
          </li>
          <li className="flex flex-col relative before:content-[''] before:absolute before:left-0 before:w-[30px] pl-[54px] before:h-[30px] before:bg-checkmark-bg before:bg-no-repeat">
            Communication and Interpersonal Skills
            <span className="mt-4 text-[#5C616E] text-[18px] md:text-[20px] leading-10 font-[400]">
              Effective communication is key to building rapport with clients
              and ensuring their comfort and satisfaction. Students will have
              honed their communication and interpersonal skills, including
              active listening, empathy, and clear verbal and nonverbal
              communication. They will know how to establish trust and create a
              safe and supportive environment for clients.
            </span>
          </li>

          <li className="flex flex-col relative before:content-[''] before:absolute before:left-0 before:w-[30px] pl-[54px] before:h-[30px] before:bg-checkmark-bg before:bg-no-repeat">
            Business and Marketing Acumen
            <span className="mt-4 text-[#5C616E] text-[18px] md:text-[20px] leading-10 font-[400]">
              For those aspiring to start their own massage therapy practice or
              work as independent contractors, understanding business and
              marketing principles is crucial. Students will have learned about
              business planning, marketing strategies, client retention
              techniques, and other aspects of running a successful massage
              therapy business.
            </span>
          </li>
          <li className="flex flex-col relative before:content-[''] before:absolute before:left-0 before:w-[30px] pl-[54px] before:h-[30px] before:bg-checkmark-bg before:bg-no-repeat">
            Continued Professional Development
            <span className="mt-4 text-[#5C616E] text-[18px] md:text-[20px] leading-10 font-[400] flex flex-col gap-[10px]">
              <p>
                The learning journey doesn't end with graduation. Students will
                be prepared to pursue continued professional development
                opportunities, such as advanced training workshops, specialized
                certifications, and participation in professional associations.
                They will understand the importance of staying updated on
                industry trends and best practices to enhance their skills and
                maintain relevance in the field.
              </p>
              <p>
                Overall, upon completing the Massage Therapy Program, students
                will be well-prepared to embark on fulfilling careers as
                competent, compassionate, and professional massage therapists,
                capable of making a positive impact on the health and well-being
                of their clients.
              </p>
            </span>
          </li>
        </ul>
      </div>
     
    </>
  );
};

export default CourseOutcomes;
