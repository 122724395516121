import {
  SELECTED_DATE,
  SELECTED_TIME,
  SELECTED_TIME_ID,
  SELECTED_FORMAT,
  GET_BOOKING_SLOTS,
  GET_BOOKING_SLOTS_FAILURE,
  GET_BOOKING_SLOTS_SUCCESS,
  ADD_APPOINTMENT,
  ADD_APPOINTMENT_SUCCESS,
  ADD_APPOINTMENT_FAILURE,
} from "../ActionTypes";
const initialState = {
  selected_date: new Date(),
  selected_format: "AM/PM",
  selected_time: "09:15am",
  selected_time_id: null,
  bookingSlots: null,
  bookingSlotsLoader: null,
  bookingSlotsError: null,
  appointmentData: null,
  appointmentError: null,
  appointmentLoader: null,
};
const educationLevelReducer = (state = initialState, action) => {
  switch (action.type) {
    case SELECTED_DATE:
      return { ...state, selected_date: action.payload };
    case SELECTED_TIME:
      return { ...state, selected_time: action.payload };
    case SELECTED_TIME_ID:
      return { ...state, selected_time_id: action.payload };
    case SELECTED_FORMAT:
      return { ...state, selected_format: action.payload };
    case GET_BOOKING_SLOTS:
      return { ...state, bookingSlotsLoader: true };
    case GET_BOOKING_SLOTS_SUCCESS:
      return {
        ...state,
        bookingSlotsLoader: false,
        bookingSlots: action?.payload,
      };
    case GET_BOOKING_SLOTS_FAILURE:
      return {
        ...state,
        bookingSlotsLoader: false,
        bookingSlotsError: action?.payload,
      };
    case ADD_APPOINTMENT:
      return {
        ...state,
        appointmentLoader: true,
      };
    case ADD_APPOINTMENT_SUCCESS:
      return {
        ...state,
        appointmentLoader: false,
        appointmentData: action?.payload,
      };
    case ADD_APPOINTMENT_FAILURE:
      return {
        ...state,
        appointmentLoader: false,
        appointmentData: action?.payload,
      };

    default:
      return state;
  }
};

export default educationLevelReducer;
