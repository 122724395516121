import React,  { useState, useEffect } from 'react';
import TestimonialReview from "../../components/testimonial/TestimonialReview";
import TestimonialVideos from "../../components/testimonial/TestimonialVideos";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';

function Testimonial() {
    const [tab, setTab] = useState("review");
    const navigation = useNavigate();
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
  return (
    <div className='w-full flex flex-col items-center font-poppins mb-28 gap-12 md:gap-20'>
        <div className="bg-Testimonial-bg w-full h-[400px] md:h-[600px] justify-center items-center flex flex-col gap-12 ">
        <p className="font-[800] text-[32px] md:text-[50px] xl:text-[60px] text-white text-center w-[calc(100%_-_32px)] md:w-[calc(100%_-_64px)]">
            What Our Students Says
        </p>
        <div className='flex justify-center gap-4 md:gap-8 flex-col md:flex-row'>
        <button
            className="orangeButton"
            onClick={() => navigation("../scheduleAppointment")}
          >
            Schedule a Visit
          </button>
          <button
            className="orangeButton"
            onClick={() => navigation("../requestInfo")}
          >
            Inquire Now
          </button>
          <button
            className="orangeButton"
            onClick={() => navigation("../enrollment")}
          >
            Enroll Now
          </button>
        </div>
      </div>

      <div className="w-[calc(100%_-_32px)] md:w-[calc(100%_-_64px)] xl:w-[90%]  gap-4 flex-col lg:flex-row grid 415:grid-cols-2">
        <div
          onClick={() => setTab("review")}
          className={`w-full  text-center shadow-lg pt-3 pb-3 px-[6px] cursor-pointer font-[500] text-[16px] md:text-[20px] xl:text-[20px]  flex justify-center items-center ${
            tab == "review"
              ? "relative after:content-[''] after:absolute after:h-[5px] after:w-full after:left-0 after:bottom-[-5px] after:bg-[#003C9D]"
              : ""
          }`}
        >
          Students Review
        </div>
        <div
          onClick={() => setTab("videos")}
          className={`w-full  text-center shadow-lg pt-3 pb-3 px-[6px] cursor-pointer font-[500] text-[16px] md:text-[20px] xl:text-[20px]  flex justify-center items-center ${
            tab == "videos"
              ? "relative after:content-[''] after:absolute after:h-[5px] after:w-full after:left-0 after:bottom-[-5px] after:bg-[#003C9D]"
              : ""
          }`}
        >
          Videos
        </div>
      </div>
      <div className="w-[calc(100%_-_32px)] md:w-[calc(100%_-_64px)] xl:w-[90%] ">
        {tab == "review" && <TestimonialReview />}
        {tab === "videos" && (
          <TestimonialVideos
            title={
              "Eligibility Requirements for enrollment in Medical Billing & Coding Program"
            }
          />
        )}
      </div>
    </div>
  )
}

export default Testimonial