import React from "react";

const CourseOutcomes = () => {
  return (
    <>
      <div>
        <p className="font-[500] text-[20px] md:text-[24px] xl:text-[32px] leading-[36px] xl:leading-[48px] text-[#003C9D]">
          Upon the Completion of the Ayurveda Wellness Practitioner (AWP)
          Program the Student will be Able to
        </p>

        <p className="text-[18px] md:text-[20px] leading-[40px] mt-5 md:mt-10 text-[#5C616E]">
          Upon completion of the Ayurvedic Wellness Practitioner (AWP) Program,
          students will acquire a diverse skill set and knowledge base enabling
          them to:
        </p>
        <ul className="mt-6 text-[#F37021] font-[500] text-[20px] md:text-[24px] leading-[30px] flex flex-col gap-6">
          <li className="flex flex-col relative before:content-[''] before:absolute before:left-0 before:w-[30px] pl-[54px] before:h-[30px] before:bg-checkmark-bg before:bg-no-repeat">
            Assess Health Conditions
            <span className="mt-4 text-[#5C616E] text-[18px] md:text-[20px] leading-10 font-[400]">
              Evaluate the health status of individuals using Ayurvedic
              diagnostic methods, including pulse diagnosis (Nadi Pariksha),
              tongue examination (Jivha Pariksha), and observation of physical
              characteristics (Darshanam).
            </span>
          </li>
          <li className="flex flex-col relative before:content-[''] before:absolute before:left-0 before:w-[30px] pl-[54px] before:h-[30px] before:bg-checkmark-bg before:bg-no-repeat">
            Create Personalized Wellness Plans
            <span className="mt-4 text-[#5C616E] text-[18px] md:text-[20px] leading-10 font-[400]">
              Develop customized treatment plans tailored to the unique
              constitution (Prakriti) and imbalances (Vikriti) of individual
              integrating Ayurvedic principles such as diet, lifestyle
              modifications, herbs, and therapies.
            </span>
          </li>
          <li className="flex flex-col relative before:content-[''] before:absolute before:left-0 before:w-[30px] pl-[54px] before:h-[30px] before:bg-checkmark-bg before:bg-no-repeat">
            Provide Lifestyle Recommendations
            <span className="mt-4 text-[#5C616E] text-[18px] md:text-[20px] leading-10 font-[400]">
              Offer guidance on diet, exercise, sleep, stress management, and
              other lifestyle factors to promote balance and prevent disease
              based on Ayurvedic principles.
            </span>
          </li>
          <li className="flex flex-col relative before:content-[''] before:absolute before:left-0 before:w-[30px] pl-[54px] before:h-[30px] before:bg-checkmark-bg before:bg-no-repeat">
            Administer Therapeutic Treatments
            <span className="mt-4 text-[#5C616E] text-[18px] md:text-[20px] leading-10 font-[400]">
              Administer various Ayurvedic therapies and treatments, including
              Panchakarma (detoxification), Abhyanga (massage), Shirodhara (oil
              pouring therapy), and herbal formulations, to restore health and
              vitality.
            </span>
          </li>
          <li className="flex flex-col relative before:content-[''] before:absolute before:left-0 before:w-[30px] pl-[54px] before:h-[30px] before:bg-checkmark-bg before:bg-no-repeat">
            Facilitate Mind-Body Healing
            <span className="mt-4 text-[#5C616E] text-[18px] md:text-[20px] leading-10 font-[400]">
              Employ techniques to address the interconnectedness of mind and
              body, including meditation, yoga, pranayama (breathing exercises),
              and mindfulness practices, to support holistic healing.
            </span>
          </li>

          <li className="flex flex-col relative before:content-[''] before:absolute before:left-0 before:w-[30px] pl-[54px] before:h-[30px] before:bg-checkmark-bg before:bg-no-repeat">
            Conduct Health Education
            <span className="mt-4 text-[#5C616E] text-[18px] md:text-[20px] leading-10 font-[400]">
              Educate individuals and communities about Ayurvedic principles,
              preventive healthcare measures, and self-care practices to empower
              them in managing their health and well-being.
            </span>
          </li>
          <li className="flex flex-col relative before:content-[''] before:absolute before:left-0 before:w-[30px] pl-[54px] before:h-[30px] before:bg-checkmark-bg before:bg-no-repeat">
            Collaborate with Healthcare Professionals
            <span className="mt-4 text-[#5C616E] text-[18px] md:text-[20px] leading-10 font-[400]">
              Work collaboratively with other healthcare providers to integrate
              Ayurvedic approaches into mainstream healthcare settings, ensuring
              comprehensive and coordinated patient care.
            </span>
          </li>
          <li className="flex flex-col relative before:content-[''] before:absolute before:left-0 before:w-[30px] pl-[54px] before:h-[30px] before:bg-checkmark-bg before:bg-no-repeat">
            Ethical Practice and Professionalism
            <span className="mt-4 text-[#5C616E] text-[18px] md:text-[20px] leading-10 font-[400]">
              Adhere to ethical standards and professionalism in all aspects of
              practice, including maintaining patient confidentiality, upholding
              integrity in interactions, and practicing within legal and
              regulatory frameworks.
            </span>
          </li>
          <li className="flex flex-col relative before:content-[''] before:absolute before:left-0 before:w-[30px] pl-[54px] before:h-[30px] before:bg-checkmark-bg before:bg-no-repeat">
            Continual Learning and Development
            <span className="mt-4 text-[#5C616E] text-[18px] md:text-[20px] leading-10 font-[400] flex flex-col gap-[10px]">
              <p>
                Commit to lifelong learning and professional development by
                staying updated with advances in Ayurvedic research, attending
                workshops, seminars, and conferences, and engaging in
                self-reflection and mentorship.
              </p>
              <p>
                By mastering these competencies, graduates of the Ayurvedic
                Wellness Practitioner (AWP) Program are equipped to make
                meaningful contributions to the field of Ayurveda, promoting
                health, wellness, and vitality in individuals and communities.
              </p>
            </span>
          </li>
        </ul>
      </div>
    </>
  );
};

export default CourseOutcomes;
