import { React, useEffect, useRef } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { getCourses } from "../../../redux/actions/common.action";
import { useDispatch, useSelector } from "react-redux";
import {
  addCertificate,
  addCertificateSuccess,
} from "../../../redux/actions/StudentServices.action";
import { imageGenerator } from "../../../utils/imageGenerator";
import useLoader from "../../../hooks/useLoader";

function CertificateRequest() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formRef = useRef(null);
  const { getcourses_data } = useSelector((state) => state.CommonReducer);
  const { addCertificateData } = useSelector(
    (state) => state.studentServicesReducer
  );
  const { isLoading, setLoader, Loader } = useLoader();
  useEffect(() => {
    if (addCertificateData && addCertificateData.status == 201) {
      setLoader(false);
      const message = addCertificateData.data.message;
      dispatch(addCertificateSuccess(null));
      navigate("../thankYou", {
        state: { message: message },
      });
    }
  }, [addCertificateData]);

  //----------------------------------------------------------USE EFFECT FOR GET COURSES-----------------------------------------------------------------//
  useEffect(() => {
    dispatch(getCourses());
  }, [dispatch]);
  //----------------------------------------------------------USE EFFECT FOR GET COURSES-----------------------------------------------------------------//
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    email: Yup.string()
      .email("Please enter valid email")
      .required("Email is required"),
    phone: Yup.string().required("Phone Number is required"),
    interest: Yup.string().required("Please select your program of interest."),
    admissiondate: Yup.string().required("Admission Date is required"),
    address: Yup.string().required("Address is required"),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State is required"),
    zip: Yup.string().required("Zip is required"),
    dateofbirth: Yup.string().required("Date of Birth is required"),
    deliveryOption: Yup.array().required("Please select one option."),
    mailAddress: Yup.string().required("Verification address is required."),
    certificateName: Yup.string().required("Certificate Name is required."),
    name: Yup.string().required("Name is required"),
    todaydate: Yup.string().required("Today Date is required"),
  });

  //------------------------------------------------------- Function to Handle Form Submission Starts ---------------------------------------------------//
  const handleSubmit = async (values, { resetForm }) => {
    const pdf = await imageGenerator(formRef.current);
    const payloadData = {
      firstname: values.firstName,
      lastname: values.lastName,
      email: values.email,
      phonenumber: values.phone,
      course_id: values.interest,
      dob: values.dateofbirth,
      admissionYearMonth: values.admissiondate,
      address: values.address,
      city: values.city,
      state: values.state,
      zip: values.zip,
      deliveryOption: values.deliveryOption[0],
      mailAddress: values.mailAddress,
      issuedName: values.issuedname,
      issuedEmail: values.issuedEmail,
      name: values.name,
      submittedDate: values.todaydate,
      nameOnCertificate: values.certificateName,
      pdfFile: pdf,
    };

    try {
      await dispatch(addCertificate(payloadData));
      setLoader(true);
      resetForm();
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  //------------------------------------------------------- Function to Handle Form Submission Ends ---------------------------------------------------//
  return (
    <div
      className="bg-[#F5F5F5] font-poppins px-4 md:px-8 xl:px-10 py-12 flex flex-col items-center gap-12 -mb-10"
      ref={formRef}
    >
      {isLoading ? (
        <Loader type={"component"} />
      ) : (
        <>
          <p className="font-[600] text-[32px] md:text-[40px] xl:text-[42px] text-[#F37021]">
            Request for Certificate
          </p>
          <Formik
            initialValues={{
              // toggle: false,
              firstName: "",
              lastName: "",
              email: "",
              phone: "",
              interest: "",
              admissiondate: "",
              address: "",
              city: "",
              state: "",
              zip: "",
              dateofbirth: "",
              deliveryOption: "",
              mailAddress: "",
              certificateName: "",
              name: "",
              todaydate: "",
            }}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            {({ errors, touched }) => (
              <Form className="w-full flex flex-col items-center gap-8">
                <div className="w-full xl:w-[90%] flex gap-8 md:gap-4 xl:gap-5 flex-col md:flex-row">
                  <div className=" w-full flex flex-col gap-2">
                    <label className="font-[400] text-[16px] md:text-[18px] text-[#1B2336]">
                      First Name <span className="text-[#BB2727]">*</span>
                    </label>
                    <Field
                      type="text"
                      name="firstName"
                      className={`h-[50px] border border-[#989898] rounded-[5px] p-3 ${
                        errors.firstName && touched.firstName
                          ? "border-red-500"
                          : ""
                      }`}
                    />
                    <ErrorMessage
                      name="firstName"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                  <div className=" w-full flex flex-col gap-2">
                    <label className="font-[400] text-[16px] md:text-[18px] text-[#1B2336]">
                      Last Name <span className="text-[#BB2727]">*</span>
                    </label>
                    <Field
                      type="text"
                      name="lastName"
                      className={`h-[50px] border border-[#989898] rounded-[5px] p-3 ${
                        errors.lastName && touched.lastName
                          ? "border-red-500"
                          : ""
                      }`}
                    />
                    <ErrorMessage
                      name="lastName"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                </div>
                <div className="w-full xl:w-[90%] flex gap-8 md:gap-5 flex-col md:flex-row">
                  <div className=" w-full flex flex-col gap-2">
                    <label className="font-[400] text-[16px] md:text-[18px] text-[#1B2336]">
                      Email Address <span className="text-[#BB2727]">*</span>
                    </label>
                    <Field
                      type="text"
                      name="email"
                      className={`h-[50px] border border-[#989898] rounded-[5px] p-3 ${
                        errors.email && touched.email ? "border-red-500" : ""
                      }`}
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                  <div className=" w-full flex flex-col gap-2">
                    <label className="font-[400] text-[16px] md:text-[18px] text-[#1B2336]">
                      Phone <span className="text-[#BB2727]">*</span>
                    </label>
                    <Field
                      type="text"
                      name="phone"
                      className={`h-[50px] border border-[#989898] rounded-[5px] p-3 ${
                        errors.phone && touched.phone ? "border-red-500" : ""
                      }`}
                    />
                    <ErrorMessage
                      name="phone"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                </div>
                <div className="w-full xl:w-[90%] flex gap-8 md:gap-5 flex-col md:flex-row">
                  <div className=" md:w-1/2 flex flex-col gap-2">
                    <label className="font-[400] text-[16px] md:text-[18px] text-[#1B2336]">
                      Program Of Interest{" "}
                      <span className="text-[#BB2727]">*</span>
                    </label>
                    <Field
                      as="select"
                      name="interest"
                      type="text"
                      className={`h-[50px] border border-[#989898] rounded-[5px] p-3 ${
                        errors.interest && touched.interest
                          ? "border-red-500"
                          : ""
                      }`}
                    >
                      <option value="" disabled hidden>
                        Select Program
                      </option>
                      {getcourses_data?.data?.map((course) => (
                        <option value={course.id}>{course.coursename}</option>
                      ))}
                    </Field>
                    <ErrorMessage
                      name="interest"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                  <div className="  md:w-1/2 flex flex-col gap-2">
                    <label className="font-[400] text-[16px] md:text-[18px] text-[#1B2336]">
                      Admission Year & Month{" "}
                      <span className="text-[#BB2727]">*</span>
                    </label>
                    <Field
                      type="date"
                      name="admissiondate"
                      className={`h-[50px] border border-[#989898] rounded-[5px] p-3 ${
                        errors.admissiondate && touched.admissiondate
                          ? "border-red-500"
                          : ""
                      }`}
                    />
                    <ErrorMessage
                      name="admissiondate"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                </div>
                <div className="w-full xl:w-[90%] flex gap-8 md:gap-5 flex-col md:flex-row">
                  <div className=" w-full flex flex-col gap-2">
                    <label className="font-[400] text-[16px] md:text-[18px] text-[#1B2336]">
                      Address <span className="text-[#BB2727]">*</span>
                    </label>
                    <Field
                      type="text"
                      name="address"
                      className={`h-[50px] border border-[#989898] rounded-[5px] p-3 ${
                        errors.address && touched.address
                          ? "border-red-500"
                          : ""
                      }`}
                    />
                    <ErrorMessage
                      name="address"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                  <div className=" w-full flex flex-col gap-2">
                    <label className="font-[400] text-[16px] md:text-[18px] text-[#1B2336]">
                      City <span className="text-[#BB2727]">*</span>
                    </label>
                    <Field
                      type="text"
                      name="city"
                      className={`h-[50px] border border-[#989898] rounded-[5px] p-3 ${
                        errors.city && touched.city ? "border-red-500" : ""
                      }`}
                    />
                    <ErrorMessage
                      name="city"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                </div>
                <div className="w-full xl:w-[90%] flex gap-8 md:gap-5 flex-col md:flex-row">
                  <div className=" w-full flex flex-col gap-2">
                    <label className="font-[400] text-[16px] md:text-[18px] text-[#1B2336]">
                      State <span className="text-[#BB2727]">*</span>
                    </label>
                    <Field
                      type="text"
                      name="state"
                      className={`h-[50px] border border-[#989898] rounded-[5px] p-3 ${
                        errors.state && touched.state ? "border-red-500" : ""
                      }`}
                    />
                    <ErrorMessage
                      name="state"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                  <div className=" w-full flex flex-col gap-2">
                    <label className="font-[400] text-[16px] md:text-[18px] text-[#1B2336]">
                      ZIP <span className="text-[#BB2727]">*</span>
                    </label>
                    <Field
                      type="text"
                      name="zip"
                      className={`h-[50px] border border-[#989898] rounded-[5px] p-3 ${
                        errors.zip && touched.zip ? "border-red-500" : ""
                      }`}
                    />
                    <ErrorMessage
                      name="zip"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                </div>
                <div className="w-full xl:w-[90%] flex gap-8 md:gap-5 flex-col 991:flex-row">
                  <div className=" lg:w-[35%] xl:w-full flex flex-col gap-2">
                    <label className="font-[400] text-[16px] md:text-[18px] text-[#1B2336]">
                      Your Name Appears On Certificate{" "}
                      <span className="text-[#BB2727]">*</span>
                    </label>
                    <Field
                      type="text"
                      name="certificateName"
                      className={`h-[50px] border border-[#989898] rounded-[5px] p-3 ${
                        errors.certificateName && touched.certificateName
                          ? "border-red-500"
                          : ""
                      }`}
                    />
                    <ErrorMessage
                      name="certificateName"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                  <div className="lg:w-[35%] xl:w-full flex flex-col gap-2">
                    <label className="font-[400] text-[16px] md:text-[18px] text-[#1B2336]">
                      Date of Birth <span className="text-[#BB2727]">*</span>
                    </label>
                    <Field
                      type="date"
                      name="dateofbirth"
                      className={`h-[50px] border border-[#989898] rounded-[5px] p-3 ${
                        errors.dateofbirth && touched.dateofbirth
                          ? "border-red-500"
                          : ""
                      }`}
                    />
                    <ErrorMessage
                      name="dateofbirth"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                  <div className=" lg:w-[30%] xl:w-full flex flex-col gap-2">
                    <label className="font-[400] text-[16px] md:text-[18px] text-[#1B2336]">
                      Delivery Option <span className="text-[#BB2727]">*</span>
                    </label>
                    <div id="my-checkbox-group"></div>
                    <div
                      role="group"
                      aria-labelledby="my-checkbox-group"
                      className="flex flex-col gap-6"
                    >
                      <div className="flex gap-[32px]">
                        <label className="font-[400] text-[14px] md:text-[16px] text-primary flex gap-[8px] ">
                          <Field
                            type="checkbox"
                            name="deliveryOption"
                            value="Electronic"
                            className={`h-[21px] w-[21px] ${
                              errors.deliveryOption && touched.deliveryOption
                                ? "border-red-500"
                                : ""
                            }`}
                          />
                          Electronic
                        </label>
                        <label className="font-[400] text-[14px] md:text-[16px] text-primary flex gap-[8px] ">
                          <Field
                            type="checkbox"
                            name="deliveryOption"
                            value="In-person"
                            className={`h-[21px] w-[21px] ${
                              errors.deliveryOption && touched.deliveryOption
                                ? "border-red-500"
                                : ""
                            }`}
                          />
                          In-person
                        </label>
                      </div>

                      <ErrorMessage
                        name="deliveryOption"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                  </div>
                </div>
                <div className="w-full xl:w-[90%] flex gap-8 md:gap-5 flex-col md:flex-row">
                  <div className=" w-full flex flex-col gap-2">
                    <label className="font-[400] text-[16px] md:text-[18px] text-[#1B2336]">
                      Please mail my enrollment verification letter  to this
                      address: <span className="text-[#BB2727]">*</span>
                    </label>
                    <Field
                      type="textarea"
                      rows="5"
                      as="textarea"
                      name="mailAddress"
                      placeholder="Example :&#10;Anywhere Collage&#10;Attn: Admissions Department &#10;123 Main Street &#10;Anytown, NJ 12345"
                      className={` border border-[#989898] rounded-[5px] p-3 ${
                        errors.mailAddress && touched.mailAddress
                          ? "border-red-500"
                          : ""
                      }`}
                    />
                    <ErrorMessage
                      name="mailAddress"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                </div>
                <div className="w-full xl:w-[90%] flex gap-8 md:gap-5 flex-col md:flex-row">
                  <div className=" w-full flex flex-col gap-2">
                    <label className="font-inter font-[600] text-[20px] md:text-[24px] text-[#5C616E]">
                      E Signature
                    </label>
                  </div>
                </div>

                <div className="w-full xl:w-[90%] flex gap-8 md:gap-5 flex-col md:flex-row">
                  <div className=" w-full flex flex-col gap-2">
                    <label className="font-[400] text-[16px] md:text-[18px] text-[#1B2336]">
                      Name <span className="text-[#BB2727]">*</span>
                    </label>
                    <Field
                      type="text"
                      name="name"
                      className={`h-[50px] border border-[#989898] rounded-[5px] p-3 ${
                        errors.name && touched.name ? "border-red-500" : ""
                      }`}
                    />
                    <ErrorMessage
                      name="name"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                  <div className=" w-full flex flex-col gap-2">
                    <label className="font-[400] text-[16px] md:text-[18px] text-[#1B2336]">
                      Today’s Date <span className="text-[#BB2727]">*</span>
                    </label>
                    <Field
                      type="date"
                      name="todaydate"
                      className={`h-[50px] border border-[#989898] rounded-[5px] p-3 ${
                        errors.todaydate && touched.todaydate
                          ? "border-red-500"
                          : ""
                      }`}
                    />
                    <ErrorMessage
                      name="todaydate"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                </div>

                <div className="flex gap-3 w-full xl:w-[90%] justify-center mt-3">
                  <button type="submit" className="orangeHoverButton">
                    Submit
                  </button>
                  <button
                    type="button"
                    className="orangeOutlineButton"
                    onClick={() => navigate(-1)}
                  >
                    Cancel
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </>
      )}
    </div>
  );
}

export default CertificateRequest;
