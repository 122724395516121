import React, { useEffect, useState, useRef } from "react";
import HamBurgerIcon from "../assets/images/hamburger-icon.svg";
import headerLogo from "../assets/images/headerLogo.svg";
import closeIcon from "../assets/images/close.svg";
import AccordionItem from "./AccordionItem";

const HamburgerMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);

  const closeMenu = () => {
    setIsOpen(false);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      document.body.classList.remove("no-scroll");
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
  }, [isOpen]);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [menuRef]);
  return (
    <>
      <div
        className="py-[7px] px-[5px] cursor-pointer"
        onClick={(e) => setIsOpen(true)}
      >
        <img src={HamBurgerIcon} alt="hamburger-icon" />
      </div>
      <main
        ref={menuRef}
        className={`flex flex-col justify-center bg-[#FFF] ${
          isOpen
            ? "translate-x-0 w-[100vw] md:w-[70vw] z-40"
            : "translate-x-[-100%]"
        }  text-center absolute top-0 left-0 transition duration-300 ease-in-out  `}
      >
        <section className="py-6 px-9 h-screen overflow-auto">
          <div className="top flex justify-end md:justify-between  items-center">
            <div className="logo hidden md:flex items-start">
              <img src={headerLogo} alt="" />
            </div>
            <div className="close-btn" onClick={(e) => setIsOpen(false)}>
              <img src={closeIcon} alt="" />
            </div>
          </div>
          <div className="menu mt-[55px] md:mt-[92px] flex flex-col gap-9 items-start font-[600] text-[20px] leading-[24px] h-full text-[#1B2336] w-full overflow-auto">
            <AccordionItem closeMenu={closeMenu} />
          </div>
        </section>
      </main>
    </>
  );
};

export default HamburgerMenu;
