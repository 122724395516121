import React from "react";

function FAQ() {
  const data1 = [
    {
      title: "What does a medical biller and coder do?",
      content:
        "Medical Billers and Coders are responsible for processing patient data including medical records and related insurance. In this position, you will code a patient’s diagnosis and then request payment from the patient’s insurance company. You will play an important role in ensuring that healthcare providers are quickly and accurately paid for the treatment they give patients.",
    },
    {
      title:
        "Is there a difference between a medical billing and a medical coding?",
      content:
        "Yes. Medical coders translate patient care into current procedural terminology (CPT) codes. Their primary responsibility is to ensure that the medical services provided are accurately coded. Medical billers are responsible for creating a claim based on the codes a medical coder provides. Many professionals in this area do both medical billing and medical coding.",
    },
    {
      title:
        "What are the requirements for a medical billing and coding career?",
      content:
        "Entry-level positions typically require completion of a certificate and passing one of the certification exams or an associate degree program in medical billing and coding. Additionally, medical billing and coding professionals must understand the Health Insurance Portability and Accountability Act (HIPAA).",
    },
    {
      title: "How long does it take to become a medical biller and coder?",
      content:
        "In most cases, it takes between one and three years to become a medical biller and coder. Earning a medical billing and coding certification can take up to one year, while earning an associate degree can take up to three years.",
    },
  ];
  const data2 = [
    {
      title: "How long does it take to complete this course?",
      content:
        "This course has open enrollment, so you can start as soon as you can. The program will take 300 hours of training only for certification.",
    },
    {
      title: "What kind of support will I receive?",
      content:
        "The course instructor will be available by email to answer any questions and provide feedback on your performance. Occasionally, your course may be supported by a team of industry experts. You will also receive support from the student advising team.",
    },
    {
      title:
        "What if I don't have enough time to complete my course within the time frame provided?",
      content:
        "The time allotted for course completion has been calculated based on the number of course hours. However, if you are unable to complete the course, contact the student advising team to see what options you may have available to work out a suitable completion date. Please note that an extension fee may be charged.",
    },
    {
      title: "What happens when I complete the course?",
      content:
        "Upon successful completion of the course, you will be awarded a Certificate of Completion.",
    },
    {
      title: "Am I guaranteed a job?",
      content:
        "This course will provide you with the skills you need to obtain an entry-level position in most cases. We will assist you with your resume preparation and finding a job. Also, Potential students should always do research on the job market in their area before registering.",
    },
    {
      title: "Can I get financial assistance?",
      content:
        "This course is non-credit, so it does not qualify for federal aid, FAFSA and Pell Grant. In some states, vocational rehab or workforce development boards will pay for qualified students to take our courses.",
    },
    {
      title: "How much does Program school cost?",
      content:
        "Tuition Fees at Param Institute of Education varies for different program offered, but we assure you that our tuition is very competitive among the other vocational schools in New Jersey. Remember that attending program at Param institute of Education is an investment in your future as a healthcare professional. Plus, there are ways to fund your education through scholarships offered at school making it easier than ever to pay for your education.",
    },
  ];
  return (
    <div className=" w-full font-poppins flex flex-col gap-5 md:gap-10 items-center">
      <p className="w-full font-[500] text-[20px] md:text-[24px] xl:text-[32px] leading-[36px] xl:leading-[48px] text-[#003C9D] ">
        Medical Billing and Coding Specialist (Hybrid) FAQs
      </p>
      <div className="flex flex-col gap-6">
        <div className="w-[98%] ml-7 flex flex-col gap-3" id="hybrid-faq">
          <ul className=" list-disc font-[500] text-[20px] text-[#1B2336]">
            <li>What is Hybrid Programs?</li>
          </ul>
          <div className="flex flex-col gap-2">
            <p className="font-[400] text-[20px] text-[#5C616E] leading-[35px]">
              All hybrid programs will allow you to choose in-person and/or
               online instruction. Lectures may be offered at the  School
              location or remotely via a Web Conferencing Platform. Laboratory/
              clinical classes and practical training will be held on at School
              site or at a clinical site. Synchronous (Online synchronous
              classes) instruction is real-time instruction that requires
              students to attend classes at scheduled times.
            </p>
          </div>
        </div>
        <div className="w-[98%] ml-7 flex flex-col gap-3">
          <ul className=" list-disc font-[500] text-[20px] text-[#1B2336]">
            <li>What is NHA and AMCA Certification?</li>
          </ul>
          <div className="flex flex-col gap-2">
            <p className="font-[400] text-[20px] text-[#5C616E] leading-[35px]">
              The{" "}
              <a
                className="underline text-[#0755b9]"
                href="https://www.nhanow.com/"
              >
                National Healthcareer Association (NHA)
              </a>{" "}
              is the largest allied health certification agency in the United
              States, with over 1 million certifications awarded since 1989.
            </p>
            <p className="font-[400] text-[20px] text-[#5C616E] leading-[35px]">
              The{" "}
              <a
                className="underline text-[#0755b9]"
                href="https://www.amcaexams.com/amca-certification-board/"
              >
                AMCA
              </a>{" "}
              Certification Board was established in 2013 to be an autonomous
              and independent board of the AMCA, LLC. The AMCA Certification
              Board complies with national accreditation standards set by the
              National Commission for Certifying Agencies (NCCA).
            </p>
          </div>
        </div>
        {data1.map((data) => (
          <div className="w-[98%] ml-7 flex flex-col gap-3">
            <ul className=" list-disc font-[500] text-[18px] md:text-[20px] text-[#1B2336]">
              <li>{data.title}</li>
            </ul>
            <p className="font-[400] text-[18px] md:text-[20px] text-[#5C616E] leading-[35px]">
              {data.content}
            </p>
          </div>
        ))}
        <div className="w-[98%] ml-7 flex flex-col gap-3">
          <ul className=" list-disc font-[500] text-[20px] text-[#1B2336]">
            <li>
              What is the difference between certification exams (cpc, cca, and
              cbcs)?
            </li>
          </ul>
          <div className="flex flex-col gap-2">
            <p className="font-[400] text-[20px] text-[#5C616E] leading-[35px]">
              Obtaining a CPC, CCA, or CBCS certification implies that an
              individual has met competencies in the field of medical billing
              and coding. Certification is invaluable to the student’s career
              goals. Students have an opportunity to make confident, informed
              decisions about the national certification they prefer.
            </p>
            <p className="font-[400] text-[20px] text-[#5C616E] leading-[35px]">
              The Certified Professional Coder (CPC) exam is offered by the
              American Academy of Professional Coders (AAPC). It is the gold
              standard entry-level coding certification for physician, or
              professional fee, coders.
            </p>
            <p className="font-[400] text-[20px] text-[#5C616E] leading-[35px]">
              The Certified Coding Associate (CCA) is offered by the American
              Health Information Management Association (AHIMA). It is an
              entry-level medical coding certification across all
              settings–physician practices and inpatient hospital.
            </p>
            <p className="font-[400] text-[20px] text-[#5C616E] leading-[35px]">
              The Certified Billing and Coding Specialist (CBCS) is offered by
              the National Healthcareer Association (NHA) and is currently an
              entry-level medical billing certification for physician
              practices. In the summer of 2021, the exam will transition to an
              entry-level billing and coding certification, with the inclusion
              of ICD-10-CM, CPT, and HCPCS Level II testing.
            </p>
          </div>
        </div>
        <div className="w-[98%] ml-7 flex flex-col gap-3">
          <ul className=" list-disc font-[500] text-[20px] text-[#1B2336]">
            <li>Is medical billing and coding a good career?</li>
          </ul>
          <p className="font-[400] text-[20px] text-[#5C616E] leading-[35px]">
            U.S. News & World Report ranked medical records technician
            (professionals that perform medical billing, medical coding or both)
            as #9 on its list of “25 Best Jobs that Don’t Require a College
            Degree,” #12 in “Best Health Care Support Jobs” and on the “The 100
            Best Jobs” list.
          </p>
        </div>
        <div className="w-[98%] ml-7 flex flex-col gap-3">
          <ul className=" list-disc font-[500] text-[20px] text-[#1B2336]">
            <li>What Do Medical Billers and Coders Do?</li>
          </ul>
          <p className="font-[400] text-[20px] text-[#5C616E] leading-[35px]">
            These professionals analyze patient records, organize databases, and
            apply correct classification codes. They also verify test results
            and medical histories. Some workers choose a specialization, such as
            cancer registrar. Medical billers and coders ensure pathology
            reports contain all required information.
          </p>
        </div>
        <div className="w-[98%] ml-7 flex flex-col gap-3">
          <ul className=" list-disc font-[500] text-[20px] text-[#1B2336]">
            <li>How Much Do Medical Billing and Coding Professionals Earn?</li>
          </ul>
          <div className="flex flex-col gap-2">
            <p className="font-[400] text-[20px] text-[#5C616E] leading-[35px]">
              Certified medical billing and coding specialists earn an average
              annual salary of $44,160, according to Jan. 2022 Payscale data.
              This surpasses the annual median salary of $40,350 for all
              occupations, according to the BLS.
            </p>
            <p className="font-[400] text-[20px] text-[#5C616E] leading-[35px]">
              Medical billing and coding professionals working for the federal
              government, professional services companies, and hospitals earn
              the highest salaries.{" "}
              <a
                className="underline"
                href="https://www.bls.gov/oes/tables.htm#st"
              >
                Geographic location
              </a>{" "}
              also impacts pay. Professionals in Maryland, Washington, D.C., and
              New York earn the highest salaries.
            </p>
          </div>
        </div>

        {data2.map((data) => (
          <div className="w-[98%] ml-7 flex flex-col gap-3">
            <ul className=" list-disc font-[500] text-[20px] text-[#1B2336]">
              <li>{data.title}</li>
            </ul>
            <p className="font-[400] text-[20px] text-[#5C616E] leading-[35px]">
              {data.content}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default FAQ;
