import React, { useEffect } from "react";
import Blog1 from "../../assets/images/Blog1.svg";
import Blog2 from "../../assets/images/Blog2.svg";
import Blog3 from "../../assets/images/Blog3.svg";
import Blog4 from "../../assets/images/Blog4.svg";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";
import { getRecentBlogs } from "../../redux/actions/blog.action";
import { useDispatch, useSelector } from "react-redux";
import defaultBlog from "../../assets/images/defaultBlog.webp";

function Section5() {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const recentBlogsLimit = "";
  const latestPosts = useSelector(
    (state) => state.BlogReducer.recent_blogs_data
  );

  const settings = {
    dots: false,
    arrows: false,
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    speed: 10,
    cssEase: "ease-in",
    appendDots: (dots) => <ul>{dots}</ul>,
    customPaging: (i) => (
      <div className="ft-slick__dots--custom">
        <div className="loading" />
      </div>
    ),
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 2.1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
          autoplay: true,
        },
      },
    ],
  };

  const blogdata = [
    {
      img: Blog1,
      date: "Mar 21,2024",
      publishedBy: "By Param Institute Of Education",
      title: "Should You Consider Medical Assisting as Your Next Career Move?",
      content:
        "Thinking about a career in healthcare? Medical assisting is a great option to consider! It offers a unique blend of challenge, reward, and opportunity. But is it the right fit for you?  Let’s explore some .........",
    },
    {
      img: Blog2,
      date: "Mar 21,2024",
      publishedBy: "By Param Institute Of Education",
      title: "Importance of Phlebotomy and Blood bank in patient care",
      content:
        "Phlebotomy and blood banks play indispensable roles in patient care, serving as vital components of modern healthcare systems. Phlebotomy, the practice of drawing blood for diagnostic testing........",
    },
    {
      img: Blog3,
      date: "Mar 21,2024",
      publishedBy: "By Param Institute Of Education",
      title: "The Licensed Massage Therapist: A Holistic Wellness",
      content:
        "The Massage Therapy License Program in New Jersey stands as a cornerstone for individuals aspiring to embark on a fulfilling career in holistic wellness. With its comprehensive curriculum and stringent........",
    },
    {
      img: Blog4,
      date: "Mar 21,2024",
      publishedBy: "By Param Institute Of Education",
      title:
        "Navigating the Path: Exploring Medical Billing and Coding Careers",
      content:
        "In the realm of healthcare administration, Medical Billing and Coding Specialist (MBCS) professionals serve as the essential, ensuring the seamless flow of financial transactions and accurate........",
    },
  ];
  useEffect(() => {
    dispatch(getRecentBlogs(recentBlogsLimit));
  }, [dispatch]);

  function removeHtmlTags(html) {
    // Remove HTML tags using a regular expression
    return html.replace(/<[^>]*>?/gm, "");
  }
  return (
    <div className="w-full font-poppins flex items-center flex-col gap-10 mt-12 md:mt-0">
      <p className="font-[600] text-[24px] md:text-[35px] xl:text-[48px] text-primary">
        Blog
      </p>
      <div className="w-[calc(100%_-_32px)] md:w-[calc(100%_-_64px)] xl:w-[90%] flex gap-5">
        <Slider {...settings} className="w-full shadow-xl md:shadow-none">
          {latestPosts?.data?.map((blog, index) => (
            <div
              id="Card"
              className="w-full  md:w-[415px]   bg-white items-center flex flex-col shadow-lg"
            >
              <div className="relative w-full h-0 pb-[56%]">
                <div className="absolute inset-0">
                  <img
                    src={
                      blog.blog_img.includes("undefined")
                        ? defaultBlog
                        : blog.blog_img
                    }
                    className="absolute inset-0 w-full h-full object-cover"
                  />
                </div>
              </div>
              <div className="w-[100%] flex flex-col gap-2 px-3">
                <p className="font-[400] text-[14px] text-[#5C616E] mt-4">
                  {blog.published_date} | {blog.blog_published_by}
                </p>
                <p className="font-[600] text-[20px] text-primary overflow-hidden text-ellipsis line-clamp-1">
                  {blog.blog_title}
                </p>
                <p className="font-[400] text-[14px] text-[#5C616E] overflow-hidden text-ellipsis line-clamp-4">
                  {removeHtmlTags(blog.blog_content)}
                </p>
              </div>
              <div className="w-[93%] py-4 px-3">
                <button
                  className="orangeOutlineButton"
                  onClick={() => {
                    navigation(`./blogDetail/${blog.id}`);
                  }}
                >
                  Read More
                </button>
              </div>
            </div>
          ))}
        </Slider>
        {/* <div id="Card" className='h-[527px ] w-[415px] drop-shadow-md bg-white items-center flex flex-col' >
                    <div className='h-[40%]'>
                        <img src={Blog1} />
                    </div>
                    <div className='w-[93%] flex flex-col gap-2'>
                        <p className='font-[400] text-[14px] text-[#5C616E] mt-4'>Mar 21,2024 | By Param Institute Of Education</p>
                        <p className='font-[600] text-[20px] text-primary '>Should You Consider Medical Assisting as Your Next Career Move?</p>
                        <p className='font-[400] text-[14px] text-[#5C616E] '>Thinking about a career in healthcare? Medical assisting is a great option to consider! It offers a unique blend of challenge, reward, and opportunity. But is it the right fit for you?  Let’s explore some .........</p>
                    </div>
                    <div className='w-[93%] py-4'>
                        <button className='orangeOutlineButton'>
                            Read More
                        </button>
                    </div>
                </div> */}
      </div>
      <div>
        <button
          className="orangeHoverButton mt-12 md:mt-[-50px] md:mb-[50px]"
          onClick={() => {
            navigation("blog");
          }}
        >
          View More
        </button>
      </div>
    </div>
  );
}

export default Section5;
