import React, { useState } from "react";
import course1 from "../../assets/images/course1.svg";
import course2 from "../../assets/images/course2.svg";
import course3 from "../../assets/images/course3.svg";
import course4 from "../../assets/images/course4.svg";
import course5 from "../../assets/images/course5.svg";
import course6 from "../../assets/images/course6.svg";
import course7 from "../../assets/images/course7.svg";
import course8 from "../../assets/images/course8.svg";
import course9 from "../../assets/images/course9.svg";
import course10 from "../../assets/images/course10.svg";
import course11 from "../../assets/images/course11.svg";
import course12 from "../../assets/images/course12.svg";
import LPN from "../../assets/images/LPN-course.svg";

import { useNavigate } from "react-router-dom";

function HealthcarePrograms() {

  const navigation = useNavigate();
  const data = [
    {
      img: LPN,
      title: "Licensed Practical Nursing Program",
      link: "../LPN",
      content:
        "PARAM INSTITUTE OF EDUCATION offers a Licensed Practical Nursing (LPN) program in the state of New Jersey. The program consisting of 1,554 hours of theory and clinical practicum.",
    },
    {
      img: course9,
      title: "Certified Clinical Medical Assistant (Hybrid)",
      link: "../CCMA",
      content:
        "Certified Clinical Medical Assistant (CCMA) Program trains students to assist physicians by performing functions related to the clinical responsibilities of a medical office.",
    },
    {
      img: course10,
      title: "Medical Billing And Coding Specialist (Hybrid)",
      link: "../mbcs",
      content:
        "Medical billing and coding is a rising star in the healthcare field today. This course will prepare you to start a career in this high-demand occupation and earn the professional certification.",
    },
    {
      img: course5,
      title: "Home-Maker Home Health Aide (CHHA) (Hybrid)",
      link: "../CHHA",
      content:
        "The population of the elderly in our country is steadily growing and many people now prefer to receive health care at home in a familiar setting for convenience and to reduce cost.",
    },
    {
      img: course6,
      title: "Certified Phlebotomy Technician Program (Hybrid)",
      link: "../phlebotomy",
      content:
        "Phlebotomists are health care professionals who work in hospitals, blood banks and other diagnostic health care facilities, where they draw blood from patients.",
    },

    {
      img: course4,
      title: "Certified  Patient Care Technician (Hybrid)",
      link: "../patientCare",
      content:
        "The Patient Care Technician Program at Param Institute of Education (PIE) is 300 hours (20 Weeks) comprehensive program includes classroom instructions and clinical externship training.",
    },
    {
      img: course2,
      title: "Certified Electrocardiogram Technician (Hybrid)",
      link: "../ecg",
      content:
        "This technique will help them to diagnose primary heart conditions. The students will be able to learn basic anatomy and physiology of the heart and common pathology of heart conditions.",
    },
    {
      img: course5,
      title: "CNA to Certified Home-Maker Home Health Aide Program (Hybrid)",
      link: "../CNAToCHHA",
      content:
        "This program is for Certified Nurse Assistants who are planning to do Home Care patients along with Nursing home patients. They will get education regarding their duties and role in transition from facilities to homes. ",
    },
    {
      img: course3,
      title: "Certified Nurse Assistant",
      link: "../CNA",
      content:
        "This program teaches skills such as lab training and practical Nursing homes instruction as well as classroom teaching. Most skills will be taught through lectures, role playing, training videos",
    },

    {
      img: course1,
      title: "Pharmacy Technician Program",
      link: "../PTP",
      content:
        "The curriculum is designed in accordance with Pharmacy Technician Certification Board (PTCB) guidelines and prepares individual under the supervision of pharmacist",
    },
  ];
  return (
    <div className=" w-full flex flex-col items-center font-poppins mb-28">
      <div className="bg-healthcarePrograms-bg bg-cover w-full h-[400px] md:h-[600px] justify-center items-center flex flex-col gap-8 md:gap-12 ">
        <p className="font-[800] text-[32px] md:text-[50px] xl:text-[60px]  w-[calc(100%_-_32px)] md:w-[calc(100%_-_64px)] text-white text-center">
          Healthcare Programs
        </p>
        <div className=" flex justify-center gap-4 md:gap-8 flex-col md:flex-row">
          <button
            className="orangeButton"
            onClick={() => navigation("../scheduleAppointment")}
          >
            Schedule a Visit
          </button>
          <button
            className="orangeButton"
            onClick={() => navigation("../requestInfo")}
          >
            Inquire Now
          </button>
          <button
            className="orangeButton"
            onClick={() => navigation("../enrollment")}
          >
            Enroll Now
          </button>
        </div>
      </div>
      <div className="w-[calc(100%_-_32px)] md:w-[calc(100%_-_64px)] xl:w-[90%] flex gap-10 flex-col mt-[60px]">
        <div className=" gap-5 grid grid-cols-1 576:grid-cols-2 991:grid-cols-3 2xl:grid-cols-4">
          {data.map((data) => (
            <div id="Card" className=" bg-white shadow-lg flex flex-col">
              <div className="max-h-[250px] overflow-hidden">
                <img src={data.img} className="w-full object-cover bg-center" />
              </div>

              <div
                className="flex flex-col w-[98%] items-center mt-3 group"
                onClick={() => navigation(data.link)}
              >
                <p className="font-[600] text-[18px] text-primary group-hover:text-[#F37021] text-center cursor-pointer">
                  {data.title}
                </p>
                <p className="font-[400] text-[14px] text-[#5C616E] text-center mt-4 w-[95%] group-hover:text-[#F37021] cursor-pointer px-3 pb-4">
                  {data.content}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
export default HealthcarePrograms;
