import React from "react";
import { useNavigate } from "react-router-dom";

function AcademicCalendar() {
  const navigation = useNavigate();
  const holidayList = [
    {
      id: 1,
      title: "New Year Day",
      date: "January 2nd, 2024, Monday",
    },
    {
      id: 2,
      title: "Martin Luther King B- Day",
      date: "January 15th, 2024, Monday",
    },
    {
      id: 3,
      title: "President’s Day",
      date: "February 19th, 2024, Monday",
    },
    {
      id: 4,
      title: "Good Friday",
      date: "March 29th, 2024, Friday",
    },
    {
      id: 5,
      title: "Memorial Day",
      date: "May 27th, 2024, Monday",
    },
    {
      id: 6,
      title: "Juneteenth",
      date: "June 19th, 2024, Wednesday",
    },
    {
      id: 7,
      title: "Independence Day",
      date: "July 4th, 2024, Thursday",
    },
    {
      id: 8,
      title: "Labor Day",
      date: "September 2nd, 2024, Monday",
    },
    {
      id: 9,
      title: "Veterans Day",
      date: "November 11th, 2024, Monday",
    },
    {
      id: 10,
      title: "Thanksgiving Day",
      date: "November 28th, 2024, Thursday",
    },
    {
      id: 11,
      title: "Black Friday",
      date: "November 29th, 2024, Friday",
    },
    {
      id: 12,
      title: "Christmas Day",
      date: "December 25th, 2024, Wednesday",
    },
  ];
  const classScedule = [
    {
      title: "Morning Class for All Certificate Courses ",
      day: "Monday - Friday",
      time: "9:00 a.m. to 1:00 p.m.",
      breakTime: "Break - 10:15 a.m. to 10:25 a.m.",
      lunchTime: "Lunch - 12:15 p.m. to 12:30 p.m",
    },
    {
      title: "Afternoon Class for All Certificate Courses ",
      day: "Monday - Friday",
      time: "1:30 p.m. to 5:30 p.m.",
      breakTime: "Tea Break - 2:15 a.m. to 2:30 a.m.",
    },
    {
      title: "Evening Class for All Certificate Courses ",
      day: "Monday - Friday",
      time: "6:00 p.m. to 9:30 p.m.",
      breakTime: "Break  - 7:15 p.m. to 7:30 p.m.",
    },
    {
      title: "Weekend Class for All Certificate Courses ",
      day: "Saturday - Sunday",
      time: "9:30 a.m. to 4:30 p.m.",
      breakTime: "Break/Lunch - 11:30 a.m. to 12:00 p.m.",
      lunchTime: "Tea Break - 3:00 p.m. to 3:10 p.m.",
    },
    {
      title: "Morning In-Person Class for LPN Program",
      day: "Monday - Friday",
      time: "8:00 a.m. to 1:00 p.m.",
      breakTime: "Break - 10:15 a.m. to 10:25 a.m.",
      lunchTime: "Lunch - 12:15 p.m. to 12:30 p.m.",
    },
    {
      title: "Evening In-Person Class for LPN Program",
      day: "Monday - Saturday",
      time: "5:00 p.m. to 9:15 p.m.",
      breakTime: "Break - 7:15 p.m. to 7:30 p.m.",
    },
  ];

  return (
    <div className=" w-full flex flex-col items-center font-poppins mb-28 gap-12 md:gap-20 ">
      <div className="bg-academicCalendar-bg w-full h-[400px] md:h-[600px] justify-center items-center flex flex-col gap-8 md:gap-12 ">
        <p className="font-[800] text-[32px] md:text-[50px] xl:text-[60px] w-[calc(100%_-_32px)] md:w-[calc(100%_-_64px)] text-white text-center">
          Academic Calendar
        </p>
        <div className=" flex justify-center gap-4 md:gap-8 flex-col md:flex-row">
          <button
            className="orangeButton"
            onClick={() => navigation("../scheduleAppointment")}
          >
            Schedule a Visit
          </button>
          <button
            className="orangeButton"
            onClick={() => navigation("../requestInfo")}
          >
            Inquire Now
          </button>
          <button
            className="orangeButton"
            onClick={() => navigation("../enrollment")}
          >
            Enroll Now
          </button>
        </div>
      </div>
      <div className="w-[90%] flex flex-col gap-12">
        <p className="font-[500] text-[20px] md:text-[24px] xl:text-[32px] leading-[30px] md:leading-[36px] xl:leading-[48px] text-[#003C9D]">
          Observed Holidays for the 2024-25 Academic Year at Param Institute of
          Education :
        </p>

        <table className="w-[100%] border-collapse border-[#D9D9D9] border">
          <tr>
            <th className="md:h-[69px] h-[64px] bg-[#003C9D] justify-center flex items-center text-[#FFFFFF] text-[20px] md:text-[24px]  leading-[24px] md:leading-[29px] font-[600] font-inter border-[#D9D9D9] border">
              Holiday and Program Schedule-2024
            </th>
          </tr>
          <tr className="h-[64px] justify-center flex items-center text-[#FFFFFF] text-[20px] font-[500] font-inter w-full ">
            <th className="h-full text-[#003C9D] w-full  font-[500] items-center flex justify-center border-[#D9D9D9] border">
              Holiday
            </th>
            <th className="h-full text-[#003C9D] w-full  font-[500] items-center flex justify-center border-[#D9D9D9] border">
              Date
            </th>
          </tr>
          {holidayList.map((item) => (
            <tr
              key={item.id}
              className="h-[54px] justify-center flex items-center text-[#FFFFFF] text-[18px] font-[400] font-inter w-full "
            >
              <td className="h-full text-[#5C616E]  font-[400]  w-full  items-center flex justify-center border-[#D9D9D9] border text-center">
                {item.title}
              </td>
              <td className="h-full text-[#5C616E]  font-[400]  w-full  items-center flex justify-center border-[#D9D9D9] border text-center">
                {item.date}
              </td>
            </tr>
          ))}
        </table>
        <div className="flex flex-col gap-6">
          <p className="font-[500] text-[20px] md:text-[24px] xl:text-[32px] leading-[30px] md:leading-[36px] xl:leading-[48px] text-[#003C9D]">
            Class Schedules
          </p>
          <p className="font-[400] text-[18px] md:text-[20px] xl:text-[20px] leading-[45px] text-[#5C616E] font-inter">
            The programs at Param Institute of Education are delivered using a
            hybrid learning environment that utilizes synchronous online
            classes. Below are the daily schedules for all online and in-person
            classes.
          </p>
          <div className="flex flex-col gap-8">
            {classScedule.map((item, i) => (
              <div className="flex flex-col gap-4" key={i}>
                <p className="font-[500] text-[20px] md:text-[24px] leading-[30px] xl:leading-[36px] text-[#F37021]">
                  {item.title}
                </p>
                <div className="flex flex-col gap-3">
                  <p className="font-[400] text-[18px] md:text-[24px] xl:text-[20px] leading-[22px] md:leading-[24px] text-[#5C616E] font-inter">
                    {item.day}
                  </p>
                  <p className="font-[400] text-[18px] md:text-[24px] xl:text-[20px] leading-[22px] md:leading-[24px] text-[#5C616E] font-inter">
                    {item.time}
                  </p>
                  <p className="font-[400] text-[18px] md:text-[24px] xl:text-[20px] leading-[22px] md:leading-[24px] text-[#5C616E] font-inter">
                    {item.breakTime}
                  </p>
                  {item.lunchTime && (
                    <p className="font-[400] text-[18px] md:text-[24px] xl:text-[20px] leading-[22px] md:leading-[24px] text-[#5C616E] font-inter">
                      {item.lunchTime}
                    </p>
                  )}
                </div>
              </div>
            ))}
            <p className="font-[400] text-[18px] md:text-[20px] leading-[45px] text-[#5C616E] font-inter italic">
              <span className="text-[#FF302F] font-[500]"> Note: </span>Class
              time may vary according to resource availability.
            </p>
          </div>
          <div className="flex flex-col gap-6 mt-4">
            <p className="font-[500] text-[20px] md:text-[24px] xl:text-[32px] leading-[30px] md:leading-[36px] xl:leading-[48px] text-[#003C9D]">
              Program Start and End Dates
            </p>
            <p className="font-[400] text-[18px] md:text-[20px] leading-[45px] text-[#5C616E] font-inter">
              The start date and end date for each program may vary due to the
              length of the program. Holidays and internship schedules may cause
              the program completion dates to vary as well. Please keep in mind
              that students enrolled in evening and weekend classes will
              typically take longer to complete their programs. The School
              offered two new batches in a month of every certificate program.
              Please contact the school for the actual start date of any
              program.{" "}
            </p>
            <p className="font-[400] text-[18px] md:text-[20px] leading-[45px] text-[#5C616E] font-inter italic -mt-6">
              <span className="text-[#FF302F] font-[500]"> Note: </span>Licensed
              Practical Nursing program will start in September 2024 please call
              us for more information.
            </p>
          </div>
          <div className="flex flex-col gap-6 mt-4">
            <p className="font-[500] text-[20px] md:text-[24px] xl:text-[32px] leading-[30px] md:leading-[36px] xl:leading-[48px] text-[#003C9D]">
              Clinical Internship Schedule
            </p>
            <p className="font-[400] text-[18px] md:text-[20px] leading-[45px] text-[#5C616E] font-inter">
              Students should expect the hours and days to vary depending on the
              host site. Shifts on externship or clinical experience can range
              up to 12 hours, occurring any hour of the day, afternoon, or
              evening and any day of the week. The student is expected to work
              at the clinical site in accordance with their start and end
              time. Externship students are encouraged and should be prepared to
              participate in their externship training on a full-time basis
              (30-40 hours per week); the hours may vary according to the
              program. Students must follow their assigned internship schedule,
              not the school class schedule, academic calendar, or the observed
              holidays and breaks listed above.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AcademicCalendar;
