import React, { useState } from "react";
import awcProgram from "../../assets/images/awcProgram.svg";
import coursePlayIcon from "../../assets/images/coursePlayIcon.svg";
import { useNavigate } from "react-router-dom";

const WelcomePie = () => {
  const navigation = useNavigate();
  return (
    <div className=" w-full flex flex-col items-center font-poppins mb-28">
      <div className="bg-welcomePIE-bg bg-cover w-full h-[400px] md:h-[600px] justify-center items-center flex flex-col gap-8 md:gap-12 ">
        <p className="font-[800] text-[32px] md:text-[50px] xl:text-[60px]  w-[calc(100%_-_32px)] md:w-[calc(100%_-_64px)] text-white text-center">
          Welcome to PIE a Healthcare <br className="block md:hidden" />{" "}
          Education School
        </p>
        <div className=" flex justify-center gap-4 md:gap-8 flex-col md:flex-row">
          <button
            className="orangeButton"
            onClick={() => navigation("../scheduleAppointment")}
          >
            Schedule a Visit
          </button>
          <button
            className="orangeButton"
            onClick={() => navigation("../requestInfo")}
          >
            Inquire Now
          </button>
          <button
            className="orangeButton"
            onClick={() => navigation("../enrollment")}
          >
            Enroll Now
          </button>
        </div>
      </div>

      <div className="w-[calc(100%_-_32px)] md:w-[calc(100%_-_64px)] xl:w-[90%] mt-[50px] md:mt-20">
        <div className="font-poppins text-[18px] md:text-[20px] leading-[45px] text-[#5C616E]">
          <div className="text-content md:ml-[20px] md:mb-[20px] xl:ml-[20px] xl:mb-[30px] xl:mt-[85px]  w-1/2 float-right hidden md:flex justify-center items-center relative">
            <img src={awcProgram} alt="Licensed Practical Nursing Program" />
            <img
              className="absolute"
              src={coursePlayIcon}
              alt="course play icon"
            />
          </div>
          <p className="font-[500] text-[20px] md:text-[24px] xl:text-[32px] leading-[36px] xl:leading-[48px] text-[#003C9D]">
            Welcome to PIE a Healthcare Education School
          </p>
          <div className="flex md:hidden justify-center items-center mt-10">
            <img src={awcProgram} alt="Welcome Pie" />
            <img
              className="absolute"
              src={coursePlayIcon}
              alt="course play icon"
            />
          </div>
          <p className="mt-10">
            On behalf of our school faculty, staff, and administration at Param
            Institute of Education, we welcome you to the PIE Allied healthcare,
            License Practical Nurse, and alternate therapy Programs! We are
            pleased you have selected our school for your healthcare education!
            Our Healthcare programs consistently rank in the upper tier within
            the education industry.
          </p>
          <p className="mt-4">
            PIE offers students an immersive learning environment that will
            provide you with the knowledge and skills necessary for a successful
            career in healthcare. Upon completing your education and training,
            you will be ready to start your career as a healthcare professional
            or pursue further education.
          </p>
          <p className="mt-4">
            Our school offers Hybrid/Blended education in many healthcare
            certification Programs. As we are student oriented school, we offer
            short-term to long terms programs which helps students to quick
            start in healthcare education.
          </p>
          <p className="mt-4">
            We understand how important it is to choose your career and at the
            same time find the right school. Part of our job is to make your
            life a little bit easier. Hence, we are here to guide you through
            the admission process.
          </p>
          <p className="mt-4">
            Our Admission Personnel is your first point of contact with the
            school. Our representatives are here to introduce you to PIE,
            explain our academic programs, and answer any questions you may
            have. Please{" "}
            <a className="underline text-[#0755b9]" href="./contactUs">
              contact us
            </a>{" "}
            for further information
          </p>
          <p className="mt-4">
            Again, welcome, and thank you for choosing Param Institute of
            Education for your education! We wish you all the best in your
            education and your future professional career!
          </p>
        </div>
        <div className="flex flex-col gap-5 md:gap-7 mt-10 md:mt-[60px]">
          <p className="font-[500] text-[20px] md:text-[24px] xl:text-[32px] leading-[36px] xl:leading-[48px] text-[#003C9D]">
            Visit Our School Facility & Information Session
          </p>
          <p className="text-[18px] md:text-[20px] leading-[45px] text-[#5C616E]">
            We are delighted to have you at our school facility for achieving
            your career goals. During your visit to the school, you will get a
            chance to meet our admission personnel and other Admission
            department teams. They will guide and provide you the right
            information about the school programs and key features of the
            facility. Also, the Admission Team will arrange your one to one
            meeting with educational specialist for selection of the right
            program for your better future in healthcare industry.  During this
            visit, you will explore our school Classroom, Lab settings, student
            amenities and other education facilities.{" "}
            <a className="underline text-[#0755b9]" href="../scheduleAppointment">
              Click here for scheduling visit for information session.
            </a>{" "}
          </p>
        </div>
      </div>
    </div>
  );
};

export default WelcomePie;
