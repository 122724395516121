import React from "react";

const CourseOutcomes = () => {
  return (
    <>
      <div className="leading-[35px]">
        <p className="font-[500] text-[20px] md:text-[24px] xl:text-[32px] leading-[36px] xl:leading-[48px] text-[#003C9D]">
          Upon the completion of this CCMA Program the student will be able to
        </p>

        <div className="text-content text-[18px] md:text-[20px] leading-[45px] flex flex-col gap-5 md:gap-10 mt-5 md:mt-10 text-[#5C616E]">
          <p className="">
            The Certified Clinical Medical Assistant Program is designed to
            ensure that students are well prepared to enter the profession as a
            Certified Clinical Medical Assistant and have the ability to
            continue their learning throughout their professional careers. At
            the completion of the CCMA program, graduates will be able to
            function as medical assistants who are competent in the following:
          </p>

          <ul className="list-disc ml-6 flex flex-col gap-4 leading-[45px]">
            <li>
              Manage and care for patients as individuals; recognize and attend
              to their needs
            </li>
            <li>Recognize ethical and legal responsibilities</li>
            <li>Perform a variety of clinical procedures</li>
            <li>Perform a variety of administrative duties</li>
            <li>Manage care of office property</li>
            <li>Communicate effectively</li>
            <li>Demonstrate professional characteristics</li>
            <li>
              Perform medical assisting administrative and clinical procedures
              competently and safely within their scope of practice.
            </li>
            <li>
              Specializes skills needed to contribute to a healthcare team in an
              ambulatory setting
            </li>
            <li>
              Ethical behavior, therapeutic communication, patient privacy laws,
              medical terminology, and clinical procedures
            </li>
            <li>Summarize the HIPAA Privacy Rule and HIPAA Security Rule</li>
            <li>
              Explain the information contained in different types of medical
              records
            </li>
            <li>Discuss government and private insurances</li>
            <li>Identify the functions of the major body systems</li>
            <li>
              Demonstrate how to write a chief complaint and obtain a patient
              history
            </li>
            <li>Describe how to measure vital signs</li>
            <li>Discuss the importance of medical and surgical asepsis</li>
            <li>
              Summarize the basic guidelines for administration of injections
            </li>
            <li>Identify the three methods used to perform venipuncture</li>
            <li>
              Managing patient visits, arranging follow-up care, and issuing
              prescriptions
            </li>
            <li>
              Keeping track of vital information such as patient allergies and
              medications
            </li>
            <li>
              Communicating with patients and reporting public health
              information
            </li>
          </ul>
        </div>
      </div>
      
    </>
  );
};

export default CourseOutcomes;
