import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const columnHeaders = [
  "Program Name",
  "Age Requirement",
  "Enrollment Application Form",
  "Signed Enrollment Agreement",
  "Proof of legal status in Unites States",
  "1 Government Issued Photo ID Proof",
  "1 Address Proof",
  "SSN",
  "Background Check",
  "High School Diploma or GED or ATB or Equivalent",
  "Medical Physical Examination & CPR Certification",
  "Interview with Admission Staff or Program Director",
  "Recommendation Letter",
  "Entrance Exam",
  "Minimum Requirement of Technical and Health Standards",
];
const rowData = [
  {
    programName: "Certified Nurse Assistant",
    ageRequirement: "17 Years",
    enrollmentAppForm: "X",
    signedEnrollAggrement: "X",
    proofLegalStatus: "X",
    governmentIssuedPhotoId: "X",
    addressProof: "X",
    SSN: "X",
    backgroundCheck: "X",
    highSchoolDiploma: "X",
    medicalPhysicalExamination: "",
    interviewWithAdmissionStaff: "",
    recommendationLetter: "",
    entranceExam: "X",
    minimunRequirmentOfStandards:
      "https://drive.google.com/file/d/1BWpW3nAR2R5VsKjegrgdJu-LhmxmHOYw/view",
  },
  {
    programName: "Medical Billing & Coding Specialist",
    ageRequirement: "17 Years",
    enrollmentAppForm: "X",
    signedEnrollAggrement: "X",
    proofLegalStatus: "X",
    governmentIssuedPhotoId: "X",
    addressProof: "X",
    SSN: "X",
    backgroundCheck: "X",
    highSchoolDiploma: "X",
    medicalPhysicalExamination: "",
    interviewWithAdmissionStaff: "",
    recommendationLetter: "",
    entranceExam: "X",
    minimunRequirmentOfStandards:
      "https://drive.google.com/file/d/1v7gCAATkIM-MYAQzf8atXwpBEXpUWYO1/view",
  },
  {
    programName: "Certified Electrocardiograph Technician ",
    ageRequirement: "17 Years",
    enrollmentAppForm: "X",
    signedEnrollAggrement: "X",
    proofLegalStatus: "X",
    governmentIssuedPhotoId: "X",
    addressProof: "X",
    SSN: "X",
    backgroundCheck: "X",
    highSchoolDiploma: "X",
    medicalPhysicalExamination: "",
    interviewWithAdmissionStaff: "",
    recommendationLetter: "",
    entranceExam: "X",
    minimunRequirmentOfStandards:
      "https://drive.google.com/file/d/1BWpW3nAR2R5VsKjegrgdJu-LhmxmHOYw/view",
  },
  {
    programName: "Certified Patient Care Technician",
    ageRequirement: "17 Years",
    enrollmentAppForm: "X",
    signedEnrollAggrement: "X",
    proofLegalStatus: "X",
    governmentIssuedPhotoId: "X",
    addressProof: "X",
    SSN: "X",
    backgroundCheck: "X",
    highSchoolDiploma: "X",
    medicalPhysicalExamination: "",
    interviewWithAdmissionStaff: "",
    recommendationLetter: "",
    entranceExam: "X",
    minimunRequirmentOfStandards:
      "https://drive.google.com/file/d/1BWpW3nAR2R5VsKjegrgdJu-LhmxmHOYw/view",
  },
  {
    programName: "Certified Phlebotomy Technician",
    ageRequirement: "17 Years",
    enrollmentAppForm: "X",
    signedEnrollAggrement: "X",
    proofLegalStatus: "X",
    governmentIssuedPhotoId: "X",
    addressProof: "X",
    SSN: "X",
    backgroundCheck: "X",
    highSchoolDiploma: "X",
    medicalPhysicalExamination: "",
    interviewWithAdmissionStaff: "",
    recommendationLetter: "",
    entranceExam: "X",
    minimunRequirmentOfStandards:
      "https://drive.google.com/file/d/1BWpW3nAR2R5VsKjegrgdJu-LhmxmHOYw/view",
  },
  {
    programName: "Certified Clinical Medical Assistant ",
    ageRequirement: "17 Years",
    enrollmentAppForm: "X",
    signedEnrollAggrement: "X",
    proofLegalStatus: "X",
    governmentIssuedPhotoId: "X",
    addressProof: "X",
    SSN: "X",
    backgroundCheck: "X",
    highSchoolDiploma: "X",
    medicalPhysicalExamination: "",
    interviewWithAdmissionStaff: "",
    recommendationLetter: "",
    entranceExam: "X",
    minimunRequirmentOfStandards:
      "https://drive.google.com/file/d/1BWpW3nAR2R5VsKjegrgdJu-LhmxmHOYw/view",
  },
  {
    programName: "Pharmacy Technician ",
    ageRequirement: "17 Years",
    enrollmentAppForm: "X",
    signedEnrollAggrement: "X",
    proofLegalStatus: "X",
    governmentIssuedPhotoId: "X",
    addressProof: "X",
    SSN: "X",
    backgroundCheck: "X",
    highSchoolDiploma: "X",
    medicalPhysicalExamination: "",
    interviewWithAdmissionStaff: "",
    recommendationLetter: "",
    entranceExam: "X",
    minimunRequirmentOfStandards:
      "https://drive.google.com/file/d/10ccYUau9j1coQ-93LZKHpgeNvdqUYUBU/view",
  },
  {
    programName: "Pharmacy Technician ",
    ageRequirement: "17 Years",
    enrollmentAppForm: "X",
    signedEnrollAggrement: "X",
    proofLegalStatus: "X",
    governmentIssuedPhotoId: "X",
    addressProof: "X",
    SSN: "X",
    backgroundCheck: "X",
    highSchoolDiploma: "X",
    medicalPhysicalExamination: "",
    interviewWithAdmissionStaff: "",
    recommendationLetter: "",
    entranceExam: "",
    minimunRequirmentOfStandards:
      "https://drive.google.com/file/d/10ccYUau9j1coQ-93LZKHpgeNvdqUYUBU/view",
  },
  {
    programName: "Licensed Practical Nursing",
    ageRequirement: "18 Years",
    enrollmentAppForm: "X",
    signedEnrollAggrement: "X",
    proofLegalStatus: "X",
    governmentIssuedPhotoId: "X",
    addressProof: "X",
    SSN: "X",
    backgroundCheck: "X",
    highSchoolDiploma: "X",
    medicalPhysicalExamination: "X",
    interviewWithAdmissionStaff: "X",
    recommendationLetter: "X",
    entranceExam: "X",
    minimunRequirmentOfStandards:
      "https://drive.google.com/file/d/1BWpW3nAR2R5VsKjegrgdJu-LhmxmHOYw/view",
  },
  {
    programName: "Massage Therapy",
    ageRequirement: "17 Years",
    enrollmentAppForm: "X",
    signedEnrollAggrement: "X",
    proofLegalStatus: "X",
    governmentIssuedPhotoId: "X",
    addressProof: "X",
    SSN: "X",
    backgroundCheck: "X",
    highSchoolDiploma: "X",
    medicalPhysicalExamination: "",
    interviewWithAdmissionStaff: "",
    recommendationLetter: "",
    entranceExam: "X",
    minimunRequirmentOfStandards:
      "https://drive.google.com/file/d/10ccYUau9j1coQ-93LZKHpgeNvdqUYUBU/view",
  },
  {
    programName: "Home-Maker Home Health Aide",
    ageRequirement: "17 Years",
    enrollmentAppForm: "X",
    signedEnrollAggrement: "X",
    proofLegalStatus: "X",
    governmentIssuedPhotoId: "X",
    addressProof: "X",
    SSN: "X",
    backgroundCheck: "X",
    highSchoolDiploma: "X",
    medicalPhysicalExamination: "",
    interviewWithAdmissionStaff: "",
    recommendationLetter: "",
    entranceExam: "",
    minimunRequirmentOfStandards: "",
  },
  {
    programName: "CAN to Home-Maker Home Health Aide",
    ageRequirement: "17 Years",
    enrollmentAppForm: "X",
    signedEnrollAggrement: "X",
    proofLegalStatus: "X",
    governmentIssuedPhotoId: "X",
    addressProof: "X",
    SSN: "X",
    backgroundCheck: "X",
    highSchoolDiploma: "X",
    medicalPhysicalExamination: "",
    interviewWithAdmissionStaff: "",
    recommendationLetter: "",
    entranceExam: "",
    minimunRequirmentOfStandards: "",
  },
  {
    programName: "Ayurvedic Wellness Practitioner Certification",
    ageRequirement: "17 Years",
    enrollmentAppForm: "X",
    signedEnrollAggrement: "X",
    proofLegalStatus: "X",
    governmentIssuedPhotoId: "X",
    addressProof: "X",
    SSN: "X",
    backgroundCheck: "X",
    highSchoolDiploma: "X",
    medicalPhysicalExamination: "",
    interviewWithAdmissionStaff: "",
    recommendationLetter: "",
    entranceExam: "X",
    minimunRequirmentOfStandards:
      "https://drive.google.com/file/d/10ccYUau9j1coQ-93LZKHpgeNvdqUYUBU/view",
  },
  {
    programName: "Ayurvedic Wellness Counselor Certification",
    ageRequirement: "17 Years",
    enrollmentAppForm: "X",
    signedEnrollAggrement: "X",
    proofLegalStatus: "X",
    governmentIssuedPhotoId: "X",
    addressProof: "X",
    SSN: "X",
    backgroundCheck: "X",
    highSchoolDiploma: "X",
    medicalPhysicalExamination: "",
    interviewWithAdmissionStaff: "",
    recommendationLetter: "",
    entranceExam: "X",
    minimunRequirmentOfStandards:
      "https://drive.google.com/file/d/10ccYUau9j1coQ-93LZKHpgeNvdqUYUBU/view",
  },
  {
    programName: "Yoga Teacher Training Certificate 200 Hours",
    ageRequirement: "17 Years",
    enrollmentAppForm: "X",
    signedEnrollAggrement: "X",
    proofLegalStatus: "X",
    governmentIssuedPhotoId: "X",
    addressProof: "X",
    SSN: "X",
    backgroundCheck: "X",
    highSchoolDiploma: "X",
    medicalPhysicalExamination: "",
    interviewWithAdmissionStaff: "",
    recommendationLetter: "",
    entranceExam: "X",
    minimunRequirmentOfStandards:
      "https://drive.google.com/file/d/10ccYUau9j1coQ-93LZKHpgeNvdqUYUBU/view",
  },
  {
    programName: "Yoga Teacher Training Certificate 300 Hours",
    ageRequirement: "17 Years",
    enrollmentAppForm: "X",
    signedEnrollAggrement: "X",
    proofLegalStatus: "X",
    governmentIssuedPhotoId: "X",
    addressProof: "X",
    SSN: "X",
    backgroundCheck: "X",
    highSchoolDiploma: "X",
    medicalPhysicalExamination: "",
    interviewWithAdmissionStaff: "",
    recommendationLetter: "",
    entranceExam: "X",
    minimunRequirmentOfStandards:
      "https://drive.google.com/file/d/10ccYUau9j1coQ-93LZKHpgeNvdqUYUBU/view",
  },
  {
    programName: "All IT Training Programs",
    ageRequirement: "17 Years",
    enrollmentAppForm: "X",
    signedEnrollAggrement: "X",
    proofLegalStatus: "X",
    governmentIssuedPhotoId: "X",
    addressProof: "X",
    SSN: "X",
    backgroundCheck: "X",
    highSchoolDiploma: "X",
    medicalPhysicalExamination: "",
    interviewWithAdmissionStaff: "",
    recommendationLetter: "",
    entranceExam: "X",
    minimunRequirmentOfStandards: "",
  },
];

const RequirementAndEligibility = () => {
  const navigation = useNavigate();
  return (
    <div className=" w-full flex flex-col items-center font-poppins mb-28">
      <div className="bg-requirementEligibility-bg bg-cover w-full h-[400px] md:h-[600px] justify-center items-center flex flex-col gap-8 md:gap-12 ">
        <p className="font-[800] text-[32px] md:text-[50px] xl:text-[60px]  w-[calc(100%_-_16px)] md:w-[calc(100%_-_64px)] text-white text-center">
          Admission Requirements/ Eligibility
        </p>
        <div className=" flex justify-center gap-4 md:gap-8 flex-col md:flex-row">
          <button
            className="orangeButton"
            onClick={() => navigation("../scheduleAppointment")}
          >
            Schedule a Visit
          </button>
          <button
            className="orangeButton"
            onClick={() => navigation("../requestInfo")}
          >
            Inquire Now
          </button>
          <button
            className="orangeButton"
            onClick={() => navigation("../enrollment")}
          >
            Enroll Now
          </button>
        </div>
      </div>

      <div className="w-[calc(100%_-_32px)] md:w-[calc(100%_-_64px)] xl:w-[90%] mt-[50px] md:mt-20">
        <div className="font-poppins text-[18px] md:text-[20px] leading-[45px] text-[#5C616E]">
          <p className="font-[500] text-[20px] md:text-[24px] xl:text-[32px] leading-[36px] xl:leading-[48px] text-[#003C9D]">
            General Requirements for All the Programs
          </p>
          <p className="mt-10 md:mt-14 xl:mt-7 p-[10px] pt-0">
            In order to enroll into one of the certificate programs at Param
            Institute of Education, students must meet certain requirements.
          </p>
          <p className="p-[10px] pt-0">
            To be enrolled in any program offered at our school the minimum
            admission requirements include a high school diploma or equivalent
            or G.E.D, a passing score on the required entrance exam, basic
            computer skills, and meeting the minimum technology requirements.
          </p>
          <p className="p-[10px] pt-0">
            Prospective students will be considered for admission if the
            following requirements are fulfilled. Students must have the
            following before the first day of the className. 
          </p>
        </div>
      </div>
      <div className="w-[calc(100%_-_32px)] md:w-[calc(100%_-_64px)] xl:w-[95%] mt-10 md:mt-[60px]">
        <span className="py-5 bg-[#003C9D] text-[#FFFFFF] text-center text-[18px] md:text-[24px] font-[600] block w-full">
          Pre- Requisites for Enrollment
        </span>
        <div className="overflow-x-auto">
          <table className=" w-full bg-white leading-0 ">
            <thead>
              <tr className="bg-gray-200"></tr>
              <tr className="text-[#003C9D] text-[16px] font-[500]">
                {columnHeaders.map((header) => (
                  <th className="border border-[#D9D9D9] px-2 py-2 font-[500]">
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {rowData.map((data) => (
                <tr className="text-[14px] text-center">
                  <td className="border border-[#D9D9D9] px-2 py-2 text-left whitespace-nowrap min-w-[325px] ">
                    {data.programName}
                  </td>
                  <td className="border border-[#D9D9D9] px-2 py-2 ">
                    {data.ageRequirement}
                  </td>
                  <td className="border border-[#D9D9D9] px-2 py-2 ">
                    {data.enrollmentAppForm}
                  </td>
                  <td className="border border-[#D9D9D9] px-2 py-2 ">
                    {data.signedEnrollAggrement}
                  </td>
                  <td className="border border-[#D9D9D9] px-2 py-2 whitespace-nowrap min-w-[87px]">
                    {data.proofLegalStatus}
                  </td>
                  <td className="border border-[#D9D9D9] px-2 py-2">
                    {data.governmentIssuedPhotoId}
                  </td>
                  <td className="border border-[#D9D9D9] px-2 py-2">
                    {data.addressProof}
                  </td>
                  <td className="border border-[#D9D9D9] px-2 py-2">
                    {data.SSN}
                  </td>
                  <td className="border border-[#D9D9D9] px-2 py-2">
                    {data.backgroundCheck}
                  </td>
                  <td className="border border-[#D9D9D9] px-2 py-2">
                    {data.highSchoolDiploma}
                  </td>
                  <td className="border border-[#D9D9D9] px-2 py-2">
                    {data.medicalPhysicalExamination}
                  </td>
                  <td className="border border-[#D9D9D9] px-2 py-2">
                    {data.interviewWithAdmissionStaff}
                  </td>
                  <td className="border border-[#D9D9D9] px-2 py-2">
                    {data.recommendationLetter}
                  </td>
                  <td className="border border-[#D9D9D9] px-2 py-2">
                    {data.entranceExam}
                  </td>
                  <td className="border border-[#D9D9D9] px-2 py-2">
                    <a
                      className="underline text-[#003C9D]"
                      href={data.minimunRequirmentOfStandards}
                    >
                      {data.minimunRequirmentOfStandards ? "Click Here" : ""}
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="w-[calc(100%_-_32px)] md:w-[calc(100%_-_64px)] xl:w-[90%] mt-10 md:mt-[60px]">
        <div className="font-poppins text-[18px] md:text-[20px] leading-[45px] text-[#5C616E] flex flex-col gap-6">
          <p className="font-[500] text-[20px] md:text-[24px] xl:text-[32px] leading-[36px] xl:leading-[48px] text-[#003C9D]">
            Information and Submission of Required Documents
          </p>
          <p className="text-[18px] md:text-[20px] leading-[45px] text-[#5C616E] font-[400]">
            Applicants are required to fulfill all required admission documents
            prior to enrollment. Admission documents include but are not limited
            to the following:
          </p>
          <ul className=" list-decimal list-outside flex flex-col gap-6 p-[10px] ml-5">
            <li className="text-[#F37021] text-[20px] md:text-[24px] leading-[30px] md:leading-[35px] font-[500]">
              <span>
                Enrollment Form - students should be able to complete online
              </span>
              <p className="text-[18px] md:text-[20px] leading-[21px] md:leading-[45px] text-[#5C616E] font-[400] ml-4 md:ml-8 mt-1">
                Please fill out the Online Application{" "}
                <a
                  className="underline text-[#003C9D] font-[500]"
                  href="../enrollment"
                >
                  Click Here.
                </a>
              </p>
            </li>

            <li className="text-[#F37021] text-[20px] md:text-[24px] leading-[30px] md:leading-[35px] font-[500]">
              Proof of Legal Status in Unites States
              <ul className="list-disc list-outside text-[18px] md:text-[20px] leading-[45px] text-[#5C616E] font-[400] ml-8 mt-1 flex flex-col gap-4">
                <li>U.S Citizen / Naturalization Certificate</li>
                <li>Legal Permanent Residential Status</li>
                <li>
                  For Non Immigrant students must provide legal immigration
                  document which is permits to attend the education
                </li>
              </ul>
            </li>

            <li className="text-[#F37021] text-[20px] md:text-[24px] leading-[30px] md:leading-[35px] font-[500]">
              Government Issued Photo ID Proof & 1 Address Proof ( 2 Forms of
              Document)
              <p className="text-[18px] md:text-[20px] leading-[45px] text-[#5C616E] font-[400] ml-4 md:ml-7 mt-1">
                Students must have United States residential proof. The
                government issued identification, or other documentation proving
                physical location. A valid driver’s license, passport, social
                security card, employee ID, student ID, birth certificate,
                certificate of citizenship, certificate of naturalization, U.S.
                government issued ID, etc. At least one form of ID must include
                a photograph, and all forms of ID must be current and
                Additionally for address proof you may submit electric or gas
                bill, lease document etc. 
              </p>
            </li>

            <li className="text-[#F37021] text-[20px] md:text-[24px] leading-[30px] md:leading-[35px] font-[500]">
              Social Security Number
              <p className="text-[18px] md:text-[20px] leading-[45px] text-[#5C616E] font-[400] ml-4 md:ml-7 mt-1">
                All students must have SSN to enroll in any program offered at
                Param Institute of Education.
              </p>
            </li>

            <li className="text-[#F37021] text-[20px] md:text-[24px] leading-[30px] md:leading-[35px] font-[500]">
              Proof of High School Education
              <p className="text-[18px] md:text-[20px] leading-[45px] text-[#5C616E] font-[400] ml-4 md:ml-7 mt-1 flex flex-col gap-[10px]">
                <p>
                  All students must show proof of high school completion or
                  higher. Valid proof of minimum educational requirements
                  includes but is not limited to the following:
                </p>
                <ul className="list-disc list-inside">
                  <li>High School Transcript,</li>
                  <li>High School Diploma,</li>
                  <li>College Degree,</li>
                  <li>GED certificate,</li>
                  <li>Homeschool Certification Letter,</li>
                  <li>Academic Credential Evaluation</li>
                </ul>
                <p>
                  Foreign credentials may require evaluation from a recognized
                  evaluation company.
                </p>
                <a
                  className="underline text-[#003C9D]"
                  href="https://www.meiservices.com/"
                >
                  https://www.meiservices.com/
                </a>
                <a
                  className="underline text-[#003C9D]"
                  href="https://www.naces.org/"
                >
                  https://www.naces.org/
                </a>
                <a
                  className="underline text-[#003C9D]"
                  href="https://aice-eval.org/"
                >
                  https://aice-eval.org/
                </a>
                <p>
                  Our admission staff (Ms. Munira Kalyani: munira@pcsionweb.com)
                  will help you for your educational document evaluation
                  process. You may contact her on phone or via email.{" "}
                  <span className="font-[600]">
                    Approx $75 will be additionally charge for the Credential
                    Evaluation Process.
                  </span>
                </p>
              </p>
            </li>

            <li className="text-[#F37021] text-[20px] md:text-[24px] leading-[30px] md:leading-[35px] font-[500]">
              Program Eligibility Entrance Test
              <div className="text-[18px] md:text-[20px] leading-[45px] text-[#5C616E] font-[400] ml-4 md:ml-7 mt-1 flex flex-col gap-[24px]">
                <div className="flex flex-col gap-[10px]">
                  <p className="font-[600]">
                    Entrance Exams for Certification programs:
                  </p>
                  <p>
                    This entrance test is for the students who is enrolling in
                    Certified Nurse Assistant; Medical Billing & Coding
                    Specialist; Certified Electrocardiograph Technician;
                    Certified Patient Care Technician; Certified Phlebotomy
                    Technician; Certified Clinical Medical Assistant; Pharmacy
                    Technician; Massage Therapy Pass the Wonderlic Scholastic
                    Level Exam SLE-Q entrance test.{" "}
                  </p>
                  <p>
                    Prospective students are required to pass the Wonderlic
                    Scholastic Level Exam SLE-Q{" "}
                    <a
                      className="underline text-[#003C9D]"
                      href="https://drive.google.com/file/d/136Jp9xe83cra1--eNl2lEcr_S7ypDD-9/view"
                    >
                      (Sample SLE-Q Test)
                    </a>{" "}
                    . Individuals would have 8 minutes to complete 30 questions.
                    Prospective students would be expected to get a minimum
                    score of 12 to apply for a particular program. The first
                    test fee is included in tuition fees and if you fail the
                    first test following re-test will be charged $15 each test.
                  </p>
                  <p>
                    The entrance exams SLE-Q for certification programs can be
                    taken by students any time before the enrollment. For LPN
                    programs entrance test conducted at the school only.
                  </p>
                </div>
                <div className="flex flex-col gap-[10px]">
                  <p className="font-[600]">
                    Entrance Exams Waivers for All Certification programs except
                    LPN:
                  </p>
                  <p>
                    If students submit valid IELTS, TOEFL or any other
                    competitive valid exams results.
                  </p>
                  <p className="font-[500]">
                    Licensed Practical Nursing  (LPN) program eligibility
                    entrance Test
                  </p>
                  <p>
                    All applicants must complete entrance testing prior to being
                    accepted as a student. Acceptable entrance tests may include
                    ATI TEAS, SAT,{" "}
                    <a
                      className="underline text-[#003C9D]"
                      href="https://accuplacer.collegeboard.org/students/prepare-for-accuplacer/practice"
                    >
                      ACCUPLACER tests 
                    </a>{" "}
                    , Wonderlic Basic Skill Test (WBST) from the paragraph of
                    entrance test for LPN or completion of approved school
                    coursework. Students must submit test scores and their
                    official academic transcripts to the admissions office for
                    consideration of an entrance test waiver. Prospective
                    students are required to pass entrance tests.
                  </p>
                </div>

                <div className="flex flex-col gap-[10px]">
                  <p className="font-[600]">
                    We are offering{" "}
                    <a
                      className="underline text-[#003C9D]"
                      href="https://wonderlic.com/student-admission-assessment/placement-insight/wbst/"
                    >
                      Wonderlic Basic Skill Test (WBST)
                    </a>{" "}
                    at our school in person:
                  </p>
                  <p>This test must meet the following requirement.</p>
                  <ul className="list-disc list-outside ml-6">
                    <li>Test will be conducted at school premises.</li>
                    <li>
                      The test consists of two parts: Verbal Skill and
                      Quantitative Skill{" "}
                      <a
                        className="underline text-[#003C9D] font-[500]"
                        href="https://drive.google.com/file/d/1Xn6YrE5VG9Yoy3PxKtOOOhh1NJXC9wbf/view"
                      >
                        (Sample WBST Test)
                      </a>{" "}
                      <br />
                      Student must get minimum Score 303 out of 500 in verbal
                      skills and 294 out of 500 in quantitative skills to
                      qualify for enrollment in PIE program.{" "}
                      <a
                        className="underline text-[#003C9D]"
                        href="https://drive.google.com/file/d/1P0t2g8EbdhD5ijL8P0BzrpYhYp4ywpir/view"
                      >
                        (Sample WBST score sheet)
                      </a>{" "}
                    </li>
                    <li>
                      The school will not provide any training or preparation
                      classes for this test, students will have to prepare on
                      their own.
                    </li>
                    <li>
                      To registered and schedule for Wonderlic Basic Skill Test
                      (WBST) Please contact school administrator.
                    </li>
                  </ul>
                </div>

                <div className="flex flex-col gap-[10px]">
                  <p className="font-[600]">
                    If you are planning to take the{" "}
                    <a
                      className="underline text-[#003C9D]"
                      href="https://www.atitesting.com/teas/exam-details"
                    >
                      ATI TEAS
                    </a>{" "}
                    test
                  </p>
                  <p>Register online at www.atitesting.com</p>
                  <p>
                    If you have already completed ATI TEAS LPN test at a
                    different location, please send your official transcript
                    from ATI to zarna@pcsionweb.com. See restrictions regarding
                    submission of the results. (51% minimum overall TEAS test
                    score result required) Your TEAS test must be current- taken
                    in the past two years. The official ATI transcript of your
                    TEAS test result must be received at the time of final
                    enrollment.
                  </p>
                </div>
              </div>
            </li>

            <li className="text-[#F37021] text-[20px] md:text-[24px] leading-[30px] md:leading-[35px] font-[500]">
              Recommendation Letter (Only if you are enrolled in License
              Practical Nurse)
              <p className="text-[18px] md:text-[20px] leading-[45px] text-[#5C616E] font-[400] ml-4 md:ml-7 mt-1 flex flex-col gap-[10px]">
                Applicants for LPN programs must submit a Recommendation Letter.
                The letter must be typed, signed, and specify your program of
                interest. Professional or academic reference letters are
                recommended (E.g., supervisors, teachers, professors, any co-
                employee, employer etc.).
              </p>
            </li>

            <li className="text-[#F37021] text-[20px] md:text-[24px] leading-[30px] md:leading-[35px] font-[500]">
              Interview
              <p className="text-[18px] md:text-[20px] leading-[45px] text-[#5C616E] font-[400] ml-4 md:ml-7 mt-1 flex flex-col gap-[10px]">
                The applicants are required to complete an interview with a
                school official. Applicants for certificate programs will
                generally interview with an admissions representative at time of
                enrollment and for LPN programs require an interview with the
                program director and/or school  director.
              </p>
            </li>

            <li className="text-[#F37021] text-[20px] md:text-[24px] leading-[30px] md:leading-[35px] font-[500]">
              Criminal Background Check
              <p className="text-[18px] md:text-[20px] leading-[45px] text-[#5C616E] font-[400] ml-4 md:ml-7 mt-1 flex flex-col gap-[10px]">
                A criminal background check is required for all students who
                enroll in any program at the time of enrollment. If it is
                discovered that a student has a criminal record, then the school
                reserves the right to dismiss the student. Students who are
                dismissed for cause are still liable for appropriate program
                costs up to the date of dismissal.
              </p>
            </li>

            <li className="text-[#F37021] text-[20px] md:text-[24px] leading-[30px] md:leading-[35px] font-[500]">
              Physical Examination and Immunizations
              <p className="text-[18px] md:text-[20px] leading-[45px] text-[#5C616E] font-[400] ml-4 md:ml-7 mt-1 flex flex-col gap-[10px]">
                <div>
                  Students are given a forms at the time of enrollment which
                  includes a physical examination form, tuberculosis (TB)
                  screening Chest X-ray etc. checklist. The physical form must
                  be submitted before the start of Clinical Externship. All
                  other student health documents must be submitted to the
                  admission team if they have any special health condition at
                  the time of enrollment. Students who do not submit the
                  required documentation may face suspension or dismissal from
                  their program.
                </div>
                <div>
                  <p className="font-[600]">
                    For Licensed Practical Nursing (LPN) Program :
                  </p>
                  <p>
                    Complete a medical physical form on or before the enrolment:
                    evidence of having received immunization for Rubella,
                    Tetanus, Hepatitis B series, (or signs a waiver of decline
                    for Hepatitis B Immunization) proof of a negative Tuberculin
                    skin test or chest x-ray and current CPR (cardiopulmonary
                    resuscitation) certification.
                  </p>
                </div>
                <div>
                  <p className="font-[500]">
                    Exempt Programs from{" "}
                    <span className="font-[400]">
                      Physical Examination and Immunizations <br />
                      The student health packet is not required for students
                      enrolled in the Medical Billing & Coding, Pharmacy
                      Technician, All IT Programs, All wellness programs, EKG
                      Technician , CHHA and CNA to CHHA.
                    </span>
                  </p>
                </div>
              </p>
            </li>
            <li className="text-[#F37021] text-[20px] md:text-[24px] leading-[30px] md:leading-[35px] font-[500]">
              Pending Admission Documents
              <p className="text-[18px] md:text-[20px] leading-[45px] text-[#5C616E] font-[400] ml-4 md:ml-7 mt-1 flex flex-col gap-[10px]">
                Students who have not submitted all required admission documents
                by the first day of className, or other provided deadlines, may
                be permitted provisional enrollment. Students who are
                provisionally enrolled must abide by the deadlines provided by
                the admissions department. Failure to submit all required
                documents by the provided deadlines may result in suspension or
                dismissal.
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default RequirementAndEligibility;
