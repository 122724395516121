import React, { useRef, useEffect } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import location from "../assets/images/Location.svg";
import email from "../assets/images/email.svg";
import phone from "../assets/images/phone.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  addContactus,
  addContactusSuccess,
} from "../redux/actions/common.action";
import { useNavigate } from "react-router-dom";
import { imageGeneratorWithoutResizing } from "../utils/imageGenerator";
import useLoader from "../hooks/useLoader";

function ContactUs() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const navigation = useNavigate();
  const { isLoading, setLoader, Loader } = useLoader();

  const formRef = useRef(null);
  const validationSchema = Yup.object().shape({
    fullName: Yup.string().required("Full Name is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    phoneNumber: Yup.string().required("Phone Number is required"),
    message: Yup.string().required("Message is required"),
  });
  const { addcontactus_data } = useSelector((state) => state.CommonReducer);

  useEffect(() => {
    if (addcontactus_data && addcontactus_data.status == 200) {
      setLoader(false);
      const message = addcontactus_data.data.message;
      dispatch(addContactusSuccess(null));
      navigate("../thankYou", {
        state: { message: message },
      });
    }
  }, [addcontactus_data]);

  const handleSubmit = async (values, { resetForm }) => {
    const pdf = await imageGeneratorWithoutResizing(formRef.current);
    const payload = {
      name: values?.fullName,
      email: values?.email,
      phonenumber: values?.phoneNumber,
      message: values?.message,
      pdfFile: pdf,
    };
    dispatch(addContactus(payload));
    setLoader(true);
    resetForm();
  };

  return (
    <div className="w-full flex flex-col items-center font-poppins mb-28 gap-12 md:gap-20">
      <div className="bg-contactUsBg-bg bg-cover w-full h-[400px] md:h-[600px] justify-center items-center flex flex-col gap-12 ">
        <p className="font-[800] text-[32px] md:text-[50px] xl:text-[60px] text-white text-center">
          Contact Information
        </p>
        <div className="flex justify-center gap-4 md:gap-8 flex-col md:flex-row">
          <button
            className="orangeButton"
            onClick={() => navigation("../scheduleAppointment")}
          >
            Schedule a Visit
          </button>
          <button
            className="orangeButton"
            onClick={() => navigation("../requestInfo")}
          >
            Inquire Now
          </button>
          <button
            className="orangeButton"
            onClick={() => navigation("../enrollment")}
          >
            Enroll Now
          </button>
        </div>
      </div>
      <div className="w-[calc(100%_-_32px)] md:w-[calc(100%_-_64px)] xl:w-[90%] flex flex-col 2xl:flex-row gap-12 md:gap-24 2xl:gap-36">
        <div className="w-full 2xl:w-[47%]" ref={formRef}>
          {isLoading ? (
            <Loader type={"component"} />
          ) : (
            <Formik
              initialValues={{
                fullName: "",
                email: "",
                phoneNumber: "",
                message: "",
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ errors, touched }) => (
                <Form className="gap-10 2xl:gap-11 flex flex-col">
                  <p className="font-[600] text-[24px] md:text-[40px] text-primary leading-[36px] md:leading-[60px]">
                    Don’t Hesitate to Contact us for any Information or
                    Inquiries
                  </p>
                  <div className="2xl:w-[95%] flex flex-col gap-8">
                    <div className="w-full flex flex-col gap-2">
                      <label className="font-[400] text-[16px] md:text-[18px] text-primary">
                        Full Name
                      </label>
                      <Field
                        type="text"
                        name="fullName"
                        className={`h-[50px] border border-[#989898] rounded-[5px] p-3 ${
                          errors.fullName && touched.fullName
                            ? "border-red-500"
                            : ""
                        }`}
                      />
                      <ErrorMessage
                        name="fullName"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                    <div className="w-full flex flex-col gap-2">
                      <label className="font-[400] text-[16px] md:text-[18px] text-primary">
                        Email
                      </label>
                      <Field
                        type="text"
                        name="email"
                        className={`h-[50px] border border-[#989898] rounded-[5px] p-3 ${
                          errors.email && touched.email ? "border-red-500" : ""
                        }`}
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                    <div className="w-full flex flex-col gap-2">
                      <label className="font-[400] text-[16px] md:text-[18px] text-primary">
                        Phone Number
                      </label>
                      <Field
                        type="text"
                        name="phoneNumber"
                        className={`h-[50px] border border-[#989898] rounded-[5px] p-3 ${
                          errors.phoneNumber && touched.phoneNumber
                            ? "border-red-500"
                            : ""
                        }`}
                      />
                      <ErrorMessage
                        name="phoneNumber"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                    <div className="w-full flex flex-col gap-2">
                      <label className="font-[400] text-[16px] md:text-[18px] text-primary">
                        Message
                      </label>
                      <Field
                        as="textarea"
                        name="message"
                        rows="5"
                        className={`border border-[#989898] rounded-[5px] p-3 ${
                          errors.message && touched.message
                            ? "border-red-500"
                            : ""
                        }`}
                      />
                      <ErrorMessage
                        name="message"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                  </div>
                  <div className="flex gap-3 w-[90%]">
                    <button type="submit" className="orangeHoverButton">
                      Submit
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </div>

        <div className="w-full 2xl:w-[53%] flex flex-col gap-12">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3030.6724126361346!2d-74.32900390982525!3d40.57091087254742!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c3b68f507992dd%3A0x80b21027f451a40b!2s200%20Middlesex%20Turnpike%20%23105%2C%20Iselin%2C%20NJ%2008830%2C%20USA!5e0!3m2!1sen!2sin!4v1716208235320!5m2!1sen!2sin"
            className="w-full h-[300px] md:[520px]"
            height="520"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
          <div className="w-full flex justify-between gap-[60px] flex-col items-center 991:items-start 991:flex-row 991:gap-20 ">
            <div className="w-[70%] md:w-[33%] items-center flex flex-col gap-4">
              <img src={location} alt="Location" />
              <p className="text-center  font-[400] text-[20px] text-primary">
                200, Middlesex Essex Tpk, Suite #105 Iselin, NJ, 08830.
              </p>
            </div>
            <a href="mailto:info@paramedu.co" className="w-[70%] md:w-[25%]">
              <div className=" items-center flex flex-col gap-4 cursor-pointer">
                <img src={email} alt="Email" />
                <p className="font-[400] text-[20px] text-primary">
                  info@paramedu.com
                </p>
              </div>
            </a>
            <a href="tel:+1 732-283-5656" className="w-[70%] md:w-[25%] ">
              <div className="items-center flex flex-col gap-4 cursor-pointer">
                <img src={phone} alt="Phone" />
                <p className="text-center font-[400] text-[20px] text-primary">
                  +1 732-283-5656
                </p>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
