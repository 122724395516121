import React from "react";
import coursePlayIcon from "../../../../assets/images/coursePlayIcon.svg";
import ptbCourse from "../../../../assets/images/ptbCourse.svg";

const Overview = () => {
  return (
    <>
      <div className="font-poppins text-[18px] md:text-[20px] leading-[45px] text-[#5C616E]">
        <div className="text-content md:ml-[20px] md:mb-[20px] xl:ml-[99px] xl:mb-[40px] xl:mt-[85px]  w-1/2 float-right hidden md:flex justify-center items-center relative">
          <img src={ptbCourse} alt="Pharmacy Technician Program" />
          <img
            className="absolute"
            src={coursePlayIcon}
            alt="course play icon"
          />
        </div>
        <p className="font-[500] text-[20px] md:text-[24px] xl:text-[32px] leading-[36px] xl:leading-[48px] text-[#003C9D]">
          Pharmacy Technician  Program
        </p>
        <div className="flex md:hidden justify-center items-center mt-10">
          <img src={ptbCourse} alt="Pharmacy Technician Program" />
          <img
            className="absolute"
            src={coursePlayIcon}
            alt="course play icon"
          />
        </div>
        <p className="text-[18px] md:text-[20px] leading-[45px] text-[#5C616E] mt-10">
          This course prepares students for the pharmacy technician
          certification licensure exams. The Training course is 160 Hours. The
          curriculum is designed in accordance with Pharmacy Technician
          Certification Board (PTCB) guidelines and prepares individual under
          the supervision of pharmacist, to Prepare Medications, provide
          medications and related assistance to patients and manage pharmacy
          clinical and business operation. The program also includes
          instructions in medical & pharmaceutical terminology, principles of
          Pharmacology & pharmaceutics, patient communications, safety
          procedures, record keeping, measurements and testing technics,
          pharmacy business operation, prescription preparation, logistic and
          dispensing operations and applicable standards and regulation.
        </p>

        <div className="flex flex-col 2xl:flex-row gap-4 mt-0 2xl:mt-10 ">
          <div className="text-content w-full 2xl:w-1/2 pr-0 2xl:pr-[99px] flex flex-col gap-8"></div>
        </div>
      </div>
      <div className="mt-[60px]">
        <p className="font-[500] text-[20px] md:text-[24px] xl:text-[32px] leading-[36px] xl:leading-[48px] text-[#003C9D]">
          Why Choose Pharmacy Technician Program?
        </p>
        <div className="flex flex-col gap-6 text-[18px] md:text-[20px] leading-[40px] mt-10 text-[#5C616E]">
          <p>
            Similar to other careers in the health field, pharmacy technicians
            help other people. As a pharmacy technician, you will be able to
            maintain patient safety, help people manage their healthcare needs,
            and make a difference in people’s lives daily.
            <ul className="mt-4 text-[#F37021] font-[500] text-[20px] md:text-[24px] leading-[30px] flex flex-col gap-6">
              <li className="flex flex-col relative before:content-[''] before:absolute before:left-0 before:w-[30px] pl-[54px] before:h-[30px] before:bg-checkmark-bg before:bg-no-repeat">
                You Can Become Certified in Less Than a Year
                <span className="mt-4 text-[#5C616E] text-[18px] md:text-[20px] leading-10 font-[400]">
                  According to the BLS, most pharmacy technician programs award
                  a certificate in less than a year.*** Training is jam-packed
                  and offers practical knowledge with hands-on experience. U.S.
                  Career Institute is a proud partner with Walgreens which
                  allows us to provide a Pharmacy Technician Externship
                  opportunity. Once you have completed the training program and
                  externship you will be prepared to take the Pharmacy
                  Technician Certification Exam (PTCE), which will attest to
                  your skills and knowledge.
                </span>
              </li>
              <li className="flex flex-col relative before:content-[''] before:absolute before:left-0 before:w-[30px] pl-[54px] before:h-[30px] before:bg-checkmark-bg before:bg-no-repeat">
                The Pharmacy Technician Job Market is Strong
                <span className="mt-4 text-[#5C616E] text-[18px] md:text-[20px] leading-10 font-[400] flex flex-col gap-2">
                  <p>
                    The pharmacy technician job market is expected to grow
                    12% in the coming years. The average population is getting
                    older, leading to an increased demand for prescriptions.
                    Advances in science will result in better medications all
                    around.
                  </p>
                  <p>
                    Pharmacists are getting more patient care requirements, like
                    administering the flu shot. That means that pharmacy
                    technicians will be required to perform tasks that
                    traditionally were performed by pharmacists. The job outlook
                    for pharmacy technicians is looking good.
                  </p>
                </span>
              </li>
              <li className="flex flex-col relative before:content-[''] before:absolute before:left-0 before:w-[30px] pl-[54px] before:h-[30px] before:bg-checkmark-bg before:bg-no-repeat">
                The Pharmacy Technician Job Market is Expected to Grow 12%
                <span className="mt-4 text-[#5C616E] text-[18px] md:text-[20px] leading-10 font-[400] flex flex-col gap-2">
                  <p>
                    The pharmacy technician job market will grow by 12%. They
                    attribute this prediction to 3 main reasons, an aging
                    population, an increase in chronic diseases, and pharmacy
                    technicians taking on greater roles! Pharmacy Technicians
                    will be needed to take on a greater role in pharmacy
                    operations because pharmacists are increasingly performing
                    more patient care activities, like giving flu shots.
                  </p>
                  <p>
                    According to the U.S .Bureau of Labor Statistics, 355,300
                    people currently hold employment in full or part-time
                    positions. The demand for Pharm Techs expects to grow,
                    despite the recession.  This is due in part to the expanding
                    number of aging Baby Boomers.  Additionally, the field’s
                    growth between 2006 and 2016 projects over 20%.   Some
                    states have even higher averages.
                  </p>
                  <p>
                    There’s no shortage of pharmacies in need of skilled
                    pharmacy technicians! This course will prepare you for the
                    Pharmacy Technician Certification Board (PTCB) National
                    Certification exam and help you complete an externship with
                    a leading pharmacy chain.  
                  </p>
                </span>
              </li>
              <li className="flex flex-col relative before:content-[''] before:absolute before:left-0 before:w-[30px] pl-[54px] before:h-[30px] before:bg-checkmark-bg before:bg-no-repeat">
                You’ll Have Many Roles
                <span className="mt-4 text-[#5C616E] text-[18px] md:text-[20px] leading-10 font-[400] flex flex-col gap-2">
                  <p>
                    Every Pharmacy Technician job is unique. Those working in a
                    community pharmacy will actively interact with patients.
                    These positions will be heavy on customer service.
                  </p>
                  <p>
                    If you’d prefer less public interaction, consider working at
                    a compounding pharmacy or in pharmacy automation. These
                    roles will be more focused on compounding and organizing
                    prescriptions. With so many job options, it’s easy to find
                    one best suited to your preferences.
                  </p>
                </span>
              </li>
            </ul>
          </p>
        </div>
      </div>
      <div className="flex flex-col gap-10 md:gap-10 mt-10 md:mt-[60px]">
        <p className="font-[500] text-[20px] md:text-[24px] xl:text-[32px] leading-[36px] xl:leading-[48px] text-[#003C9D]">
          Job Outlook for Certified Clinical Medical Assistant
        </p>
        <ul className=" list-disc font-[400] text-[18px] md:text-[20px] text-[#5C616E] leading-[45px] ml-8 flex flex-col gap-4">
          <li>
            The Bureau of Labor Statistics predicts a 20 percent increase in
            jobs nationally from 2012-2022. There is currently an unprecedented
            demand for pharmacists and for pharmaceutical care services, and
            pharmacists are assuming a more clinical role and delegating tasks
            to technicians. 
          </li>
        </ul>
      </div>
      <div className="mt-10 md:mt-[60px]">
        <p className="font-[500] text-[20px] md:text-[24px] xl:text-[32px] leading-[36px] xl:leading-[48px] text-[#003C9D]">
          Pharmacy Technician  Program Outline
        </p>
        <div className="p-6 border-[1px] rounded-[5px] border-[#D9D9D9] mt-5 md:mt-10 shadow-lg">
          <ul className="list-disc font-[400] text-[18px] md:text-[20px] text-[#1B2336] leading-[35px] flex flex-col gap-[10px] ml-6">
            <li>
              Total Hours:{" "}
              <span className="font-[500] text-[#003C9D]">160</span>
            </li>
            <li>
              Total weeks: <span className="font-[500] text-[#003C9D]">8</span>
            </li>
          </ul>
        </div>
        <div className="p-6 border-[1px] rounded-[5px] border-[#D9D9D9] mt-6 shadow-lg">
          <ul className="font-[400] text-[18px] md:text-[20px] text-[#1B2336] leading-[30px] flex flex-col gap-[10px]">
            <li>PT01: INTRODUCTION TO PHARMACY</li>
            <li>PT02: MEDICAL AND PHARMACEUTICAL TERMINOLOGY</li>
            <li>PT03: PHARMACOLOGY</li>
            <li>PT04: PRESCRIPTION INTERPRETATION AND MEDICATION ORDERS</li>
            <li>PT05: PHARMACY LAWS, ETHICS AND CONFIDENTIALITY</li>
            <li>PT06: PHARMACY ORGANIZATIONS</li>
            <li>PT07: TECHNICIAN CERTIFICATION & CAREER PREPAREDNESS</li>
          </ul>
        </div>

        <div className="w-full flex gap-10 flex-col">
          <div className="w-full  mt-[20px]">
            <p className="font-[500] text-[20px] md:text-[24px] xl:text-[32px] text-[#003C9D] pb-[40px] pt-10">
              What you get from us?
            </p>
            <div className="flex gap-8 xl:flex-row flex-col">
              <ul className=" w-full list-disc font-[400] text-[18px] md:text-[20px] text-[#5C616E] flex  list-inside gap-4 flex-col">
                <li>Online lectures and material access</li>
                <li>Live instructor for your queries</li>
                <li>Administrative support</li>
                <li>Onsite clinical training</li>
                <li>Field/Internship/Externship training</li>
                <li>Pharmacy Technician Certificate from school</li>

                <li>
                  Pharmacy Technician Certification Board (PTCB) Exam
                  Preparation
                </li>

                <li>Placement assistant</li>
                <li>Job opportunities</li>
                <li>Career pathways opportunities and guidelines</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Overview;
