//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//
import { useEffect, useState } from "react";
import Navigation from "./components/navigation/Navigation";
import { BrowserRouter } from "react-router-dom";
import { Toaster } from "react-hot-toast";

function App() {
  //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const [firstLoad, setFirstLoad] = useState(false);
  //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//

  useEffect(() => {
    setFirstLoad(() => true);
    document.title = "Param Institute of Education";
  }, []);
  return (
    <>
      <BrowserRouter>
        <Toaster />
        <div style={{ maxWidth: width, maxHeight: height }}></div>
        <div className="navRoutes">
          <Navigation anim={!firstLoad} />
        </div>
      </BrowserRouter>
    </>
  );
}

export default App;
