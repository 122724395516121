import React from "react";
import coursePlayIcon from "../../../../assets/images/coursePlayIcon.svg";
import CNAToCHHACourse from "../../../../assets/images/CNAToCHHACourse.svg";

const Overview = ({ setTab }) => {
  return (
    <>
      <div>
        <div className="">
          <div className="text-content md:ml-[20px] md:mb-[20px] xl:ml-[99px] xl:mb-[40px]  w-1/2 float-right hidden md:flex justify-center items-center relative">
            <img
              src={CNAToCHHACourse}
              alt="Certified Nurse Aide to Home-Maker Home Health Aide"
            />
            <img
              className="absolute"
              src={coursePlayIcon}
              alt="course play icon"
            />
          </div>
          <p className="font-[500] text-[20px] md:text-[24px] xl:text-[32px] leading-[36px] xl:leading-[48px] text-[#003C9D]">
            CNA to Certified Home-Maker Home Health Aide Program (Hybrid)
          </p>
          <div className="flex md:hidden justify-center items-center mt-10">
            <img
              src={CNAToCHHACourse}
              alt="Certified Nurse Aide to Home-Maker Home Health Aide"
            />
            <img
              className="absolute"
              src={coursePlayIcon}
              alt="course play icon"
            />
          </div>
          <p className="text-[18px] md:text-[20px] leading-[45px] text-[#5C616E] mt-6">
            This program is for Certified Nurse Assistants who are planning to
            do Home Care patients along with Nursing home patients. They will
            get education regarding their duties and role in transition from
            facilities to homes. This program will also help them improve their
            skills and knowledge to be implemented in homecare setting. This
            Curriculum is a{" "}
            <a
              className="underline text-[#0755b9]"
              href="#"
              onClick={(event) => {
                event.preventDefault();
                setTab("faq");
              }}
            >
              Hybrid
            </a>{" "}
            program consisting of approx 12 hours of self-paced online theory
            and 16 hours of Clinical skill training at the school location,
            which will help Certified Nurse Assistant to have better
            understanding of differences in environment. 
          </p>
        </div>

        <div className="mt-5 md:mt-10">
          <ul className="list-disc text-[18px] md:text-[20px] leading-[35px] flex flex-col gap-[10px] ml-6">
            <li className="text-[#003C9D] font-[500]">
              Prerequisites:
              <p className=" text-[#5C616E] font-[400] mt-[10px]">
                Student who receive training for conversion program Should have
                Active Certified Nurse Aide (CNA) licensure from NJ State or
                Must have successfully completed Certified Nurse Aide course
                within last 6 months and provides course completion certificate.
                Must be able to satisfactorily read, write and comprehend
                English and demonstrate mathematical skills.
              </p>
            </li>

            <li className="text-[#003C9D] font-[500]">
              Academic Credential Awarded
              <p className=" text-[#5C616E] font-[400] mt-[10px]">
                Certificate & Assistance for State license application
              </p>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Overview;
