import React from "react";

const Faqs = () => {
  return (
    <>
      <div>
        <p className="font-[500] text-[20px] md:text-[24px] xl:text-[32px] leading-[36px] xl:leading-[48px] text-[#003C9D]">
          Certified Clinical Medical Assistant  (CCMA-Hybrid) FAQs
        </p>
        <div className="mt-5 md:mt-10 leading-[30px] text-[18px] md:text-[20px]" id="hybrid-faq">
          <ul className="list-disc flex flex-col gap-6 ml-5 font-[500]">
            <li className="">
              What is Hybrid Programs?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  All hybrid programs will allow you to choose in-person and/or
                   online instruction. Lectures may be offered at the  School
                  location or remotely via a Web Conferencing Platform.
                  Laboratory/ clinical classes and practical training will be
                  held on at School site or at a clinical site. Synchronous
                  (Online synchronous classes) instruction is real-time
                  instruction that requires students to attend classes at
                  scheduled times.
                </p>
              </div>
            </li>

            <li className="">
              What is NHA and AMCA Certification
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  The{" "}
                  <a
                    className="underline text-[#0755b9]"
                    href="https://www.nhanow.com/"
                  >
                    National Healthcareer Association (NHA)
                  </a>{" "}
                  is the largest allied health certification agency in the
                  United States, with over 1 million certifications awarded
                  since 1989.
                </p>
                <p className="font-[400] text-[20px] text-[#5C616E] leading-[35px]">
                  The{" "}
                  <a
                    className="underline text-[#0755b9]"
                    href="https://www.amcaexams.com/amca-certification-board/"
                  >
                    AMCA
                  </a>{" "}
                  Certification Board was established in 2013 to be an
                  autonomous and independent board of the AMCA, LLC. The AMCA
                  Certification Board complies with national accreditation
                  standards set by the National Commission for Certifying
                  Agencies (NCCA).
                </p>
              </div>
            </li>

            <li className="">
              What does a certified clinical medical assistant do?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  As a clinical medical assistant, you will interact directly
                  with patients daily. You will likely be the first person a
                  patient encounters after passing reception and the last person
                  they see before exiting the facility. It will be your job to
                  put them at ease, assure them they will be properly cared for,
                  and leave them with a good impression. To be successful in
                  this job, you will need to learn Electronic Health Record
                  (EHR) software. You will be responsible for taking the medical
                  histories and recording patients' vital signs. Exact duties
                  will vary by location, healthcare specialty, and practice
                  size, but you will also likely complete clerical and
                  administrative tasks within physician's offices, hospitals,
                  and other healthcare facilities. You will also need to
                  understand how and what information can be shared and adhere
                  to confidentiality laws, like HIPAA, in patient treatment.
                </p>
              </div>
            </li>

            <li>
              Is certification necessary for CCMA Program?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  Over 90% of those hiring CCMA ‘s position require
                  certification. With our CCMA training Program, you will be
                  fully prepared to sit for one of two national certifications
                  after completing our course. You will have the option of
                  choosing between AMCA provisional certification exam OR the
                  National Healthcareer Association’s (NHA) Certification.
                </p>
              </div>
            </li>

            <li>
              Are clinical medical assistants in high demand?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  The demand for medical assistants is high, making it an
                  excellent time for students to begin their career training.
                  The U.S. Bureau of Labor Statistics (BLS) projects that demand
                  will increase by 18% over the next ten years for this
                  profession, much faster than the average for all occupations
                  in the U.S.
                </p>
              </div>
            </li>

            <li>
              How much does a certified clinical medical assistant make?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  Clinical medical assistants can make an above-average salary
                  and enjoy a prestigious medical career. According to BLS, the
                  median salary for Clinical Medical Assistants is $37,190 with
                  an hourly wage of $17.88 per hour and wages can be increased
                  based on your skill and experience.
                </p>
              </div>
            </li>

            <li>
              What is the Certified Clinical Medical Assistant (CCMA) Exam?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  The Certified Clinical Medical Assistant (CCMA) is a national
                  certification exam offered by the National Healthcareer
                  Association (NHA) and American Medical Certification
                  Association (AMCA), the largest allied health certification
                  organization in the U.S., with over one million certifications
                  awarded over more than 30 years. This exam serves to validate
                  the skills of medical assistants and demonstrates their
                  dedication to the profession. According to the 2020 Industry
                  Outlook produced by these certification agencies approximately
                  89% of employers require or encourage certification for
                  medical assistants, and 63% increase pay when certification is
                  obtained. This course includes a voucher for the cost of the
                  CCMA certification exam at no additional cost to you.
                </p>
              </div>
            </li>

            <li>
              When can I start the course?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  This course is open enrollment, so you can register and start
                  the course as soon as you are ready.
                </p>
              </div>
            </li>

            <li>
              How long does it take to complete this course?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  This course is online and open enrollment, so you can start as
                  soon as you can. The program will take 500 hours of training
                  only for certification.
                </p>
              </div>
            </li>

            <li>
              What kind of support will I receive?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  The course instructor will be available by email to answer any
                  questions and provide feedback on your performance.
                  Occasionally, your course may be supported by a team of
                  industry experts. You will also receive support from the
                  student advising team.
                </p>
              </div>
            </li>

            <li>
              What if I don't have enough time to complete my course within the
              time frame provided?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  The time allotted for course completion has been calculated
                  based on the number of course hours. However, if you are
                  unable to complete the course, contact the student advising
                  team to see what options you may have available to work out a
                  suitable completion date. Please note that an extension fee
                  may be charged.
                </p>
              </div>
            </li>

            <li>
              What happens when I complete the course?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  Upon successful completion of the course, you will be awarded
                  a Certificate of Completion.
                </p>
              </div>
            </li>
            <li>
              Am I guaranteed a job?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  This course will provide you with the skills you need to
                  obtain an entry-level position in most cases. We will assist
                  you with your resume preparation and finding a job. Also,
                  Potential students should always do research on the job market
                  in their area before registering.
                </p>
              </div>
            </li>
            <li>
              Can I get financial assistance?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  This course is non-credit, so it does not qualify for federal
                  aid, FAFSA and/or Pell Grant.
                </p>
              </div>
            </li>
            <li>
              How much does Program school cost?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  Tuition Fees at Param Institute of Education varies for
                  different program offered, but we assure you that our tuition
                  is very competitive among the other vocational schools in New
                  Jersey. Remember that attending program at Param institute of
                  Education is an investment in your future as a healthcare
                  professional. Plus, there are ways to fund your education
                  through scholarships offered at school making it easier than
                  ever to pay for your education.
                </p>
              </div>
            </li>

            <li>
              How can I get more information about this course?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  If you have questions that are not answered on our website,
                  representatives are available via EMAIL (info@paramedu.com).
                  You can also call us at +1 848 256 2737 during regular
                  business hours to have your questions promptly answered. If
                  you are visiting us during non-business hours, please send us
                  a question using the "Contact Us" form.
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Faqs;
