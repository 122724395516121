import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const AdmissionForm = () => {
  const navigation = useNavigate();
  return (
    <div className=" w-full flex flex-col items-center font-poppins mb-28">
      <div className="bg-admissionForm-bg bg-cover bg-center w-full h-[400px] md:h-[600px] justify-center items-center flex flex-col gap-8 md:gap-12 font-poppins">
        <p className="font-[800] text-[32px] md:text-[50px] xl:text-[60px]  w-[calc(100%_-_32px)] md:w-[calc(100%_-_64px)] text-white text-center">
          Admissions Form
        </p>
        <div className=" flex justify-center gap-4 md:gap-8 flex-col md:flex-row">
          <button
            className="orangeButton"
            onClick={() => navigation("../scheduleAppointment")}
          >
            Schedule a Visit
          </button>
          <button
            className="orangeButton"
            onClick={() => navigation("../requestInfo")}
          >
            Inquire Now
          </button>
          <button
            className="orangeButton"
            onClick={() => navigation("../enrollment")}
          >
            Enroll Now
          </button>
        </div>
      </div>

      <div className="w-[calc(100%_-_32px)] md:w-[calc(100%_-_64px)] xl:w-[90%] mt-[50px] md:mt-20">
        <div className="text-[18px] md:text-[20px] leading-[45px] text-[#5C616E]">
          <p className="font-[500] text-[24px] xl:text-[32px] leading-[36px] xl:leading-[48px] text-[#003C9D]">
            Documents and Forms
          </p>
          <p className="mt-10">
            Please download the documents or forms listed below by clicking on
            the link and saving to your computer:
          </p>
          <div className="mt-10 md:mt-11 xl:mt-8 flex flex-col gap-6">
            <div>
              <span className="text-[20px] md:text-[24px] text-[#F37021] font-[500]">
                Admissions Forms
              </span>
              <ul className="list-disc ml-16 text-[#003C9D] underline flex flex-col gap-[10px] mt-4">
                <li>
                  <a href="https://drive.google.com/file/d/1tYFfotm8N4zQWU0DIVZ1ZbZugQxh5yjg/view">
                    General Application Ckecklist & Enrollment Procedure
                  </a>
                </li>
                <li>
                  <a href="../enrollment">Enrollment Application Form</a>
                </li>
                <li>
                  <a href="https://drive.google.com/file/d/1RIXrA9TXRjEvjoU0_HLo0ZMLwkvU4B7y/view">
                    Enrollment Agreement Form
                  </a>
                </li>
                <li>
                  <a href="https://drive.google.com/file/d/1dK2raVkwevBCcG0fW5p3NRDyl04MRNHE/view">
                    Background Check Disclosure and Authorization
                  </a>
                </li>
                <li>
                  <a href="https://drive.google.com/file/d/1FM7shzyc4fyNsQFUnIzREjmXKX-klmVs/view">
                    Student Picture Consent Form
                  </a>
                </li>
                <li>
                  <a href="https://docs.google.com/document/d/1w_ARi3enAIbZOIOIvjKDNwvUfoOMMOKi/edit">
                    LPN Program Consent Form
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <span className="text-[24px] text-[#F37021] font-[500]">
                Clinical Forms
              </span>
              <ul className="list-disc ml-16 text-[#003C9D] underline flex flex-col gap-[10px] mt-4">
                <li>
                  <a href="https://drive.google.com/file/d/1yl33cyDVg4mAcGqpu3pA-wwt3H71OqfA/view">
                    Physical Examination Form for CPT & CCMA Programs
                  </a>
                </li>
                <li>
                  <a href="https://drive.google.com/file/d/1HZkamYWmk_hRfvnkwjhehz0pbh989RiS/view">
                    Procedure Consent Form for Certified Phlebotomy Technician &
                    CCMA Program
                  </a>
                </li>
                <li>
                  <a href="https://drive.google.com/file/d/1oPjUrV9D1stRBlkTmy2199qRJs0u5USW/view">
                    Procedure Consent Form for Certified EKG Technician Program
                  </a>
                </li>
                <li>
                  <a href="https://docs.google.com/document/d/1I-e-O1VsD6HJNs6smjY_5b3t8hrDW__k/edit">
                    Physical Examination Form For LPN Program
                  </a>
                </li>
                <li>
                  <a href="https://drive.google.com/file/d/1ca_arNnfIp6otAPt1AD6-lw1qDq5AuPt/view">
                    Healthcare Program Clinical Externship Attendance Sheet &
                    Performance Evaluation
                  </a>
                </li>
              </ul>
            </div>

            <div>
              <span className="text-[24px] text-[#F37021] font-[500]">
                Career Services
              </span>
              <ul className="list-disc ml-16 text-[#003C9D] underline flex flex-col gap-[10px] mt-4">
                <li>
                  <a href="https://drive.google.com/file/d/1fiM5x_XWwjx8i66aQZZ9ndz4yO1Mk_jf/view">
                    Resume Template
                  </a>
                </li>
                <li>
                  <a href="https://drive.google.com/file/d/1b7WiaVBdDGZMWD3bZlxUqNRomXJIvG6y/view">
                    Graduate Employment Verification Form
                  </a>
                </li>
                <li>
                  <a href="https://docs.google.com/document/d/1qhh3oJ1PPU_dkm1HObnXhQa5_qex4j_3/edit">
                    LPN Recommendation Form
                  </a>
                </li>
              </ul>
            </div>

            <div>
              <span className="text-[24px] text-[#F37021] font-[500]">
                Students Services
              </span>
              <ul className="list-disc ml-16 text-[#003C9D] underline flex flex-col gap-[10px] mt-4">
                <li>
                  <a href="../EnrollmentVerificationLetter">
                    Request for Enrollment Verification Letter
                  </a>
                </li>
                <li>
                  <a href="../LeaveOfAbsence">Apply for Leave of Absence</a>
                </li>
                <li>
                  <a href="../WithdrawalApplication">Apply for Withdrawal</a>
                </li>
                <li>
                  <a href="../TranscriptRequest">Request for Transcript</a>
                </li>
                <li>
                  <a href="../CertificateRequest">Request for Certificate</a>
                </li>
                <li>
                  <a href="../GrievanceForm">Student Grievance Form</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdmissionForm;
