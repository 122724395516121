import {
  GET_BLOG,
  GET_BLOG_FAILURE,
  GET_BLOG_SUCCESS,
  GET_BLOG_BY_ID,
  GET_BLOG_BY_ID_SUCCESS,
  GET_BLOG_BY_ID_FAILURE,
  GET_RECENT_BLOGS,
  GET_RECENT_BLOGS_SUCCESS,
  GET_RECENT_BLOGS_FAILURE,
  ADD_BLOG_COMMENT,
  ADD_BLOG_COMMENT_SUCCESS,
  ADD_BLOG_COMMENT_FAILURE,
} from "../ActionTypes";

export const getBlog = (payload) => {
  return {
    type: GET_BLOG,
    payload: payload,
  };
};
export const getBlogSuccess = (payload) => {
  return {
    type: GET_BLOG_SUCCESS,
    payload: payload,
  };
};
export const getBlogFailure = (payload) => {
  return {
    type: GET_BLOG_FAILURE,
    payload: payload,
  };
};

export const getBlogById = (payload) => {
  return {
    type: GET_BLOG_BY_ID,
    payload: payload,
  };
};
export const getBlogByIdSuccess = (payload) => {
  return {
    type: GET_BLOG_BY_ID_SUCCESS,
    payload: payload,
  };
};
export const getBlogByIdFailure = (payload) => {
  return {
    type: GET_BLOG_BY_ID_FAILURE,
    payload: payload,
  };
};

export const getRecentBlogs = (payload) => {
  return {
    type: GET_RECENT_BLOGS,
    payload: payload,
  };
};
export const getRecentBlogsSuccess = (payload) => {
  return {
    type: GET_RECENT_BLOGS_SUCCESS,
    payload: payload,
  };
};
export const getRecentBlogsFailure = (payload) => {
  return {
    type: GET_RECENT_BLOGS_FAILURE,
    payload: payload,
  };
};
export const addBlogComment = (payload) => {
  return {
    type: ADD_BLOG_COMMENT,
    payload: payload,
  };
};
export const addBlogCommentSuccess = (payload) => {
  return {
    type: ADD_BLOG_COMMENT_SUCCESS,
    payload: payload,
  };
};
export const addBlogCommentFailure = (payload) => {
  return {
    type: ADD_BLOG_COMMENT_FAILURE,
    payload: payload,
  };
};
