import {
  GET_EDUCATION_LEVEL,
  GET_EDUCATION_LEVEL_SUCCESS,
  GET_EDUCATION_LEVEL_FAILURE,
} from "../ActionTypes";
const initialState = {
  educationLevelData: null,
  educationLevelLoader: null,
  educationLevelError: null,
};
const educationLevelReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_EDUCATION_LEVEL:
      return { ...state, educationLevelLoader: true };
    case GET_EDUCATION_LEVEL_SUCCESS:
      return {
        ...state,
        educationLevelLoader: false,
        educationLevelData: action?.payload,
      };
    case GET_EDUCATION_LEVEL_FAILURE:
      return {
        ...state,
        educationLevelLoader: false,
        educationLevelError: action?.payload,
      };

    default:
      return state;
  }
};

export default educationLevelReducer;
