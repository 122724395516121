import { combineReducers } from "redux";
import CommonReducer from "./common.reducer";
import BlogReducer from "./blog.recucer";
import appointmentReducer from "./appointment.reducer";
import educationLevelReducer from './educationLevel.reducer'
import studentServicesReducer from "./StudentServices.reducer"

export const rootReducer = combineReducers({
  CommonReducer,
  BlogReducer,
  appointmentReducer,
  educationLevelReducer,
  studentServicesReducer
});
