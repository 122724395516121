import React from "react";

function FAQ() {
  const data1 = [
    {
      title: "What does an EKG technician do?",
      content:
        "Electrocardiography (EKG) technicians who apply, monitor, and measure electrocardiography testing are important to any healthcare team. They administer various cardiovascular tests including measuring basic vital signs, performing rapid response procedures, operating an EKG machine, and identifying rhythms.",
    },
    {
      title: "Is certification necessary for EKG technicians?",
      content:
        "Over 80% of those hiring EKG technicians require certification. With our EKG Technician training course, you will be fully prepared to sit for one of two national certifications after completing our course. You will have the option of choosing between AMCA provisional Certified EKG Technician (CET) certification exam OR the National Health career Association’s (NHA) Certified EKG Technician (CET) exam.",
    },
    {
      title: "Are Certified Electrocardiograph Technician  in high demand?",
      content:
        "The demand for EKG Technician is high, making it an excellent time for students to begin their career training. The U.S. Bureau of Labor Statistics (BLS) projects that demand will increase by 8% and growth of 24% over the next ten years for this profession, much faster than the average for all occupations in the U.S.",
    },
    {
      title: "How much does a EKG Technician make?",
      content:
        "According to the National Health career Association, the median annual salary for EKG technicians is $56,850 with top earners pulling in over $93,000 per year.",
    },
  ];
  const data2 = [
    {
      title: "When can I start the course?",
      content:
        "This course is open enrollment, so you can register and start the course as soon as you are ready. Other best best way to get started toward your new career in healthcare is to contact an Admissions Advisor. Our advisors will guide you through every step of the school enrollment process.",
    },
    {
      title: "How long does it take to complete this course?",
      content:
        "This course is online and open enrollment, so you can start as soon as you can. The program will take 70 hours of training only for certification.",
    },
    {
      title: "What kind of support will I receive?",
      content:
        "The course instructor will be available by email to answer any questions and provide feedback on your performance. Occasionally, your course may be supported by a team of industry experts. You will also receive support from the student advising team.",
    },
    {
      title:
        "What if I don't have enough time to complete my course within the time frame provided?",
      content:
        "The time allotted for course completion has been calculated based on the number of course hours. However, if you are unable to complete the course, contact the student advising team to see what options you may have available to work out a suitable completion date. Please note that an extension fee may be charged.",
    },
    {
      title: "What happens when I complete the course?",
      content:
        "Upon successful completion of the course, you will be awarded a Certificate of Completion.",
    },
    {
      title: "Am I guaranteed a job?",
      content:
        "This course will provide you with the skills you need to obtain an entry-level position in most cases. We will assist you for your resume preparation and finding a job. Also, Potential students should always do research on the job market in their area before registering.",
    },
    {
      title: "Can I get financial assistance?",
      content:
        "This course is non-credit, so it does not qualify for federal aid, FAFSA and Pell Grant. In some states, vocational rehab or workforce development boards will pay for qualified students to take our courses. ",
    },
    {
      title: "How much does Program school cost?",
      content:
        "Tuition Fees at Param Institute of Education varies for different program offered, but we assure you that our tuition is very competitive among the other vocational schools in New Jersey. Remember that attending program at Param institute of Education is an investment in your future as a healthcare professional. Plus, there are ways to fund your education through scholarships offered at school making it easier than ever to pay for your education.",
    },
    {
      title: "How can I get more information about this course?",
      content:
        'If you have questions that are not answered on our website, representatives are available via EMAIL (info@paramedu.com). You can also call us at +1 848 256 2737  during regular business hours to have your questions promptly answered. If you are visiting us during non-business hours, please send us a question using the "Contact Us" form.',
    },
    {
      title: "What skills are important for EKG technicians?",
      content:
        "EKG technicians need to be able to read, monitor, and report EKG tests precisely and accurately, communicate with doctors, nurses, and other professionals to provide essential healthcare, use electrocardiography equipment, interpret and calculate data, relate to patients and understand the difficulties and stress they may encounter.",
    },
  ];
  return (
    <div className=" w-full font-poppins flex flex-col gap-5 md:gap-10 items-center">
      <p className="w-full font-[500] text-[20px] md:text-[24px] xl:text-[32px] leading-[36px] xl:leading-[48px] text-[#003C9D] ">
        Certified Electrocardiograph Technician Program (Hybrid)  FAQs
      </p>
      <div className="flex flex-col gap-6">
        <div
          className="w-auto md:w-[98%] ml-7 flex flex-col gap-3"
          id="hybrid-faq"
        >
          <ul className=" list-disc font-[500] text-[18px] md:text-[20px] text-[#1B2336]">
            <li>What is Hybrid Programs?</li>
          </ul>
          <p className="font-[400] text-[18px] md:text-[20px] text-[#5C616E] leading-[35px]">
            All hybrid programs will allow you to choose in-person and/or
             online instruction. Lectures may be offered at the  School location
            or remotely via a Web Conferencing Platform. Laboratory/ clinical
            classes and practical training will be held on at School site or at
            a clinical site. Synchronous (Online synchronous classes)
            instruction is real-time instruction that requires students to
            attend classes at scheduled times.
          </p>
        </div>
        <div className="w-[98%] ml-7 flex flex-col gap-3">
          <ul className=" list-disc font-[500] text-[20px] text-[#1B2336]">
            <li>What is NHA and AMCA Certification?</li>
          </ul>
          <div className="flex flex-col gap-2">
            <p className="font-[400] text-[20px] text-[#5C616E] leading-[35px]">
              The{" "}
              <a
                className="underline text-[#0755b9]"
                href="https://www.nhanow.com/"
              >
                National Healthcareer Association (NHA)
              </a>{" "}
              is the largest allied health certification agency in the United
              States, with over 1 million certifications awarded since 1989.
            </p>
            <p className="font-[400] text-[20px] text-[#5C616E] leading-[35px]">
              The{" "}
              <a
                className="underline text-[#0755b9]"
                href="https://www.amcaexams.com/amca-certification-board/"
              >
                AMCA
              </a>{" "}
              Certification Board was established in 2013 to be an autonomous
              and independent board of the AMCA, LLC. The AMCA Certification
              Board complies with national accreditation standards set by the
              National Commission for Certifying Agencies (NCCA).
            </p>
          </div>
        </div>
        {data1.map((data) => (
          <div className="w-[98%] ml-7 flex flex-col gap-3">
            <ul className=" list-disc font-[500] text-[18px] md:text-[20px] text-[#1B2336]">
              <li>{data.title}</li>
            </ul>
            <p className="font-[400] text-[18px] md:text-[20px] text-[#5C616E] leading-[35px]">
              {data.content}
            </p>
          </div>
        ))}
        <div className="w-[98%] ml-7 flex flex-col gap-3">
          <ul className=" list-disc font-[500] text-[18px] md:text-[20px] text-[#1B2336]">
            <li>What titles are associated with an EKG technician?</li>
          </ul>
          <div className="flex flex-col gap-2">
            <p className="font-[400] text-[18px] md:text-[20px] text-[#5C616E] leading-[35px]">
              According to the National Healthcareer Association, some common
              titles may include:
            </p>
            <ul className="font-[400] text-[18px] md:text-[20px] text-[#5C616E] leading-[35px]">
              <li>·        Monitor Technician </li>
              <li>·        Telemetry Technician </li>
              <li>·        Biomedical Equipment Technician</li>
            </ul>
          </div>
        </div>

        {data2.map((data) => (
          <div className="w-[98%] ml-7 flex flex-col gap-3">
            <ul className=" list-disc font-[500] text-[18px] md:text-[20px] text-[#1B2336]">
              <li>{data.title}</li>
            </ul>
            <p className="font-[400] text-[18px] md:text-[20px] text-[#5C616E] leading-[35px]">
              {data.content}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default FAQ;
