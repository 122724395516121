import { call, put, takeLatest } from "redux-saga/effects";
import { GET_EDUCATION_LEVEL } from "../ActionTypes";
import { getEducationLevelAPI } from "../Api";
import {
  getEducationLevel,
  getEducationLevelFailure,
  getEducationLevelSuccess
} from "../actions/educationLevel.action";

function* getEducationLevelSaga(action) {
  try {
    const response = yield call(
      getEducationLevelAPI,
      action.payload
    );
    if (response) {
      yield put(getEducationLevelSuccess(response.data));
    }
  } catch (error) {
    yield put(getEducationLevelFailure(error));
    console.log("error", error);
  }
}



export function* educationLevelSaga() {
  yield takeLatest(GET_EDUCATION_LEVEL, getEducationLevelSaga);
}
