import domtoimage from "dom-to-image";
export const imageGenerator = async (formContent) => {
  return new Promise((resolve, reject) => {
    const content = formContent;

    // Set viewport size to simulate desktop resolution
    content.style.width = "1980px"; // Example width
    content.style.height = "auto"; // Example height

    domtoimage
      .toPng(content)
      .then(function (dataUrl) {
        const img = new Image();
        img.src = dataUrl;

        const imgWidth = content.offsetWidth;
        const imgHeight = content.offsetHeight;

        // // Reset viewport styles
        content.style.width = "100%";
        content.style.height = "100%";
        resolve({
          url: dataUrl,
          imgWidth: imgWidth,
          imgHeight: imgHeight,
        });
      })
      .catch(function (error) {
        console.error("Error capturing desktop view:", error);
      });
  });
};
export const imageGeneratorWithoutResizing = async (formContent) => {
  return new Promise((resolve, reject) => {
    const content = formContent;
    // Set viewport size to simulate desktop resolution
    content.style.padding = "40px";

    domtoimage
      .toPng(content)
      .then(function (dataUrl) {
        const img = new Image();
        img.src = dataUrl;

        const imgWidth = content.offsetWidth;
        const imgHeight = content.offsetHeight;
        content.style.padding = "0px";

        resolve({
          url: dataUrl,
          imgWidth: imgWidth,
          imgHeight: imgHeight,
        });
      })
      .catch(function (error) {
        console.error("Error capturing desktop view:", error);
      });
  });
};
