import React from "react";
import ecgOverview from "../../../../assets/images/ecgOverview.svg";
import coursePlayIcon from "../../../../assets/images/coursePlayIcon.svg";
import tick from "../../../../assets/images/tick.svg";

const Overview = ({ setTab }) => {
  return (
    <>
      <div className="font-poppins text-[18px] md:text-[20px] leading-[45px]  text-[#5C616E]">
        <div className="text-content md:ml-[20px] md:mb-[20px] xl:ml-[99px] xl:mb-[40px] xl:mt-[85px]  w-1/2 float-right  hidden md:flex justify-center items-center relative">
          <img
            src={ecgOverview}
            alt="Certified Electrocardiograph Technician  Program"
          />
          <img
            className="absolute"
            src={coursePlayIcon}
            alt="course play icon"
          />
        </div>
        <p className="font-[500] text-[20px] md:text-[24px] xl:text-[32px] leading-[36px] xl:leading-[48px] text-[#003C9D]">
          Certified Electrocardiograph Technician  Program{" "}
          <a
            className="underline"
            href="#"
            onClick={(event) => {
              event.preventDefault();
              setTab("faq");
            }}
          >
            Hybrid
          </a>
        </p>
        <div className="flex md:hidden justify-center items-center mt-10">
          <img
            src={ecgOverview}
            alt="Certified Electrocardiograph Technician  Program"
          />
          <img
            className="absolute"
            src={coursePlayIcon}
            alt="course play icon"
          />
        </div>
        <p className="mt-10">
          The objective of EKG Technician program{" "}
          <a
            className="underline text-[#0755b9]"
            href="#"
            onClick={(event) => {
              event.preventDefault();
              setTab("faq");
            }}
          >
            (Hybrid)
          </a>{" "}
          is to educate students performing electrocardiography.
          Electrocardiography (EKG) technicians are vital members of any
          healthcare team. This technique will help them to diagnose primary
          heart conditions. The students will be able to learn basic anatomy and
          physiology of the heart and common pathology of heart conditions. This
          knowledge will help students to communicate effectively with
          physicians as well as patients. They can further enhance their
          training in the field of telemetry medicine and onsite cardiac
          monitor. Additionally, student will also learn about how to operate
          and maintain the EKG Machine.
        </p>
        <p className="mt-4 ">
          This{" "}
          <a
            className="underline text-[#0755b9]"
            href="#"
            onClick={(event) => {
              event.preventDefault();
              setTab("faq");
            }}
          >
            hybrid
          </a>{" "}
          (where you can choose either an Online or In-person theory class) 
          Program is designed for 70  hours of Instructional and Lab. Upon
          completion of this program, students will be able to apply for jobs at
          Hospitals, Physicians offices and Urgent care clinic. Further, this
          program certification from the Param Institute of Education Prepares
          you to take the Certification exams of the{" "}
          <a
            className="underline text-[#0755b9]"
            href="https://www.nhanow.com/"
          >
            National Healthcareer Association (NHA)
          </a>{" "}
          and{" "}
          <a
            className="underline text-[#0755b9]"
            href="https://www.amcaexams.com/amca-certification-board/"
          >
            American Medical Certification Association (AMCA).
          </a>{" "}
            In addition to this, once you complete the program requirements you
          are entitled to appear in NHA and/or AMCA and/or other applicable
          national certification exams. 
        </p>

        <div className="flex flex-col gap-5 md:gap-10 w-full">
          <p className="font-[500] text-[20px] md:text-[24px] xl:text-[32px] leading-[36px] xl:leading-[48px] text-[#003C9D] mt-14">
            Why Choose Certified Electrocardiograph Technician  ?
          </p>
          <div className="flex flex-col gap-6 leading-[30px]">
            <div className="flex flex-col gap-4">
              <div className="flex gap-6 items-center">
                <img
                  src={tick}
                  alt=""
                  className="h-[22px] w-[22px] md:h-[30px] md:w-[30px]"
                />
                <p className="font-[500] text-[20px] md:text-[24px]  text-[#F37021] font-inter">
                  Great Career Choice
                </p>
              </div>
              <p className="font-[400] text-[18px] md:text-[20px] text-[#5C616E]  font-inter leading-[40px] ml-12">
                The medical industry is on a projected boom, therefore, it is a
                great career choice as a lot of jobs will be generated related
                to this field respectively. It offers various growth prospects
                that will help you in enhancing your skills and prosper in your
                career. An EKG technician can take up higher studies and conduct
                heart tests along with stress tests. They generally assist a
                physician but can operate privately as well.
              </p>
            </div>
            <div className="flex flex-col gap-4">
              <div className="flex gap-6 items-center">
                <img src={tick} alt="" className="h-[30px] w-[30px]" />
                <p className="font-[500] text-[20px] md:text-[24px]  text-[#F37021] font-inter">
                  Practical Experience
                </p>
              </div>
              <p className="font-[400] text-[18px] md:text-[20px] text-[#5C616E]  font-inter leading-[40px] ml-12">
                Since an EKG technician assists a physician in carrying out
                tests and analyzing the heart conditions, he gains practical
                knowledge which is helpful in polishing his skills and gain
                practical experience.
              </p>
            </div>
            <div className="flex flex-col gap-4">
              <div className="flex gap-6 items-center">
                <img src={tick} alt="" className="h-[30px] w-[30px]" />
                <p className="font-[500] text-[20px] md:text-[24px]  text-[#F37021] font-inter">
                  Good Package
                </p>
              </div>
              <p className="font-[400] text-[18px] md:text-[20px] text-[#5C616E]  font-inter leading-[40px] ml-12">
                An EKG technician is offered good salary packages. This entirely
                depends upon the level of education and the certification course
                that a prospective EKG technician undertakes.
              </p>
            </div>
            <div className="flex flex-col gap-4 mt-6">
              <div className="flex gap-6 items-center">
                <img src={tick} alt="" className="h-[30px] w-[30px]" />
                <p className="font-[500] text-[20px] md:text-[24px]  text-[#F37021] font-inter">
                  Quick Certification
                </p>
              </div>
              <p className="font-[400] text-[18px] md:text-[20px] text-[#5C616E]  font-inter leading-[40px] ml-12">
                This program is designed for 70 hours only and our school is
                associated with National Healthcareer Association (NHA) and
                American Medical Certification Association (AMCA) which will
                give you additional credential towards your certification.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-10 md:gap-10 mt-10 md:mt-[60px]">
        <p className="font-[500] text-[20px] md:text-[24px] xl:text-[32px] leading-[36px] xl:leading-[48px] text-[#003C9D]">
          Job Outlook for Certified Electrocardiograph Technician Program
        </p>
        <ul className=" list-disc font-[400] text-[18px] md:text-[20px] text-[#5C616E] leading-[45px] ml-8 flex flex-col gap-4">
          <li>
            The US Bureau of Labor Statistics (BLS) forecasts 24% growth in the
            demand for EKG technicians between now and 2029, much faster than
            the average for all occupations.
          </li>
          <li>
            According to the National Health career Association, the median
            annual salary for EKG technicians is $56,850 with top earners
            pulling in over $93,000 per year.
          </li>
        </ul>
      </div>
      <div className="mt-10 md:mt-[60px]">
        <p className="font-[500] text-[20px] md:text-[24px] xl:text-[32px] leading-[36px] xl:leading-[48px] text-[#003C9D]">
          Program Outline
        </p>
        <div className="p-6 border-[1px] rounded-[5px] border-[#D9D9D9] mt-10 shadow-lg">
          <ul className="list-disc font-[400] text-[18px] md:text-[20px] text-[#1B2336] leading-[35px] flex flex-col gap-[10px] ml-6">
            <li>
              Total Hours: <span className="font-[500] text-[#003C9D]">70</span>
            </li>
            <li>
              Hybrid/ In-Person Theory Study:{" "}
              <span className="font-[500] text-[#003C9D]">50 Hours </span>
            </li>
            <li>
              Practical/Clinical Study at School:{" "}
              <span className="font-[500] text-[#003C9D]">20 Hours </span>
            </li>
          </ul>
        </div>

        <div className="p-6 border-[1px] rounded-[5px] border-[#D9D9D9] mt-6 shadow-lg">
          <ul className="font-[400] text-[18px] md:text-[20px] text-[#1B2336] leading-[35px] flex flex-col gap-[10px] ml-6">
            <li>Module 1: MEDICAL TERMINOLOGY</li>
            <li>Module 2: ANATOMY & PHYSIOLOGY OF THE HEART</li>
            <li>Module 3: EKG MACHINE</li>
            <li>Module 4: INTERPRETATION OF EKG</li>
            <li>Module 5: INFECTION CONTROL</li>
          </ul>
        </div>

        <div className="w-full flex gap-10 flex-col">
          <div className="w-full  mt-[20px]">
            <p className="font-[500] text-[20px] md:text-[24px] xl:text-[32px] text-[#003C9D] pb-[40px] pt-10">
              What you get from us?
            </p>
            <div className="flex gap-8 xl:flex-row flex-col">
              <ul className=" w-full list-disc font-[400] text-[18px] md:text-[20px] text-[#5C616E] flex  list-inside gap-4 flex-col">
                <li>Online/live lectures and material access</li>
                <li>Live instructor for your queries</li>
                <li>Administrative support</li>
                <li>Onsite clinical training</li>
                <li>Field/Internship/Externship training</li>
                <li>EKG certificate from school</li>

                <li>National and state certification opportunities</li>
                <li>
                  NHA and AMCA certified EKG technician exam preparation
                  material
                </li>
                <li>
                  NHA or AMCA certification once you appear and pass the NHA or
                  AMCA exams.
                </li>
                <li>Placement Assistant</li>
                <li>Job opportunities</li>
                <li>Career pathways opportunities and guidelines</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Overview;
