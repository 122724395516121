import React from "react";

function FAQ() {
  const data1 = [
    {
      title: "How much does a Certified Patient Care Technician make?",
      content:
        "While the US Bureau of Labor Statistics (BLS) doesn’t have a specific listing for PCTs, it reported a median annual salary of $30,290 (about $14.56 per hour) for nursing assistants in 2021 . This number can vary based on your location, patient care setting, and experience level.",
    },
    {
      title: "When can I start the course?",
      content:
        "This course is open enrollment, so you can register and start the course as soon as you are ready. Other best beast way to get started toward your new career in healthcare is to contact an Admissions Advisor. Our advisors will guide you through every step of the school enrollment process.",
    },
    {
      title: "How long does it take to complete this course?",
      content:
        "This course is online and open enrollment, so you can start as soon as you can. The program will take 400 hours of training only for certification.",
    },
    {
      title: "What kind of support will I receive?",
      content:
        "The course instructor will be available by email to answer any questions and provide feedback on your performance. Occasionally, your course may be supported by a team of industry experts. You will also receive support from the student advising team.",
    },
    {
      title:
        "What if I don't have enough time to complete my course within the time frame provided?",
      content:
        "The time allotted for course completion has been calculated based on the number of course hours. However, if you are unable to complete the course, contact the student advising team to see what options you may have available to work out a suitable completion date. Please note that an extension fee may be charged.",
    },
    {
      title: "What happens when I complete the course?",
      content:
        "Upon successful completion of the course, you will be awarded a Certificate of Completion.",
    },
    {
      title: "Am I guaranteed a job?",
      content:
        "This course will provide you with the skills you need to obtain an entry-level position in most cases. We will assist you for your resume preparation and finding a job. Also, Potential students should always do research on the job market in their area before registering.",
    },
    {
      title: "Can I get financial assistance?",
      content:
        "This course is non-credit, so it does not qualify for federal aid, FAFSA and Pell Grant. In some states, vocational rehab or workforce development boards will pay for qualified students to take our courses",
    },
    {
      title: "How much does Program school cost?",
      content:
        "Tuition Fees at Param Institute of Education varies for different program offered, but we assure you that our tuition is very competitive among the other vocational schools in New Jersey. Remember that attending program at Param institute of Education is an investment in your future as a healthcare professional. Plus, there are ways to fund your education through scholarships offered at school making it easier than ever to pay for your education.",
    },
    {
      title: "How can I get more information about this course?",
      content:
        'If you have questions that are not answered on our website, representatives are available via EMAIL (info@paramedu.com). You can also call us at +1 848 256 2737 during regular business hours to have your questions promptly answered. If you are visiting us during non-business hours, please send us a question using the "Contact Us" form.',
    },
  ];
  return (
    <div className=" w-full font-poppins flex flex-col gap-5 md:gap-10 items-center">
      <p className="w-full font-[500] text-[20px] md:text-[24px] xl:text-[32px] text-[#003C9D] ">
        Certified Patient Care Technician (Hybrid) FAQs
      </p>
      <div className="flex flex-col gap-6">
        <div
          className="w-auto md:w-[98%] ml-7 flex flex-col gap-3"
          id="hybrid-faq"
        >
          <ul className=" list-disc font-[500] text-[18px] md:text-[20px] text-[#1B2336]">
            <li>What is Hybrid Programs?</li>
          </ul>
          <p className="font-[400] text-[18px] md:text-[20px] text-[#5C616E] leading-[35px]">
            All hybrid programs will allow you to choose in-person and/or
             online instruction. Lectures may be offered at the  School location
            or remotely via a Web Conferencing Platform. Laboratory/ clinical
            classes and practical training will be held on at School site or at
            a clinical site. Synchronous (Online synchronous classes)
            instruction is real-time instruction that requires students to
            attend classes at scheduled times.
          </p>
        </div>
        <div className="w-[98%] ml-7 flex flex-col gap-3">
          <ul className=" list-disc font-[500] text-[20px] text-[#1B2336]">
            <li>What is NHA and AMCA Certification?</li>
          </ul>
          <div className="flex flex-col gap-2">
            <p className="font-[400] text-[20px] text-[#5C616E] leading-[35px]">
              The{" "}
              <a
                className="underline text-[#0755b9]"
                href="https://www.nhanow.com/"
              >
                National Healthcareer Association (NHA)
              </a>{" "}
              is the largest allied health certification agency in the United
              States, with over 1 million certifications awarded since 1989.
            </p>
            <p className="font-[400] text-[20px] text-[#5C616E] leading-[35px]">
              The{" "}
              <a
                className="underline text-[#0755b9]"
                href="https://www.amcaexams.com/amca-certification-board/"
              >
                AMCA
              </a>{" "}
              Certification Board was established in 2013 to be an autonomous
              and independent board of the AMCA, LLC. The AMCA Certification
              Board complies with national accreditation standards set by the
              National Commission for Certifying Agencies (NCCA).
            </p>
          </div>
        </div>
        <div className="w-auto md:w-[98%] ml-7 flex flex-col gap-3">
          <ul className=" list-disc font-[500] text-[18px] md:text-[20px] text-[#1B2336]">
            <li>What does a Certified Patient Care Technician do?</li>
          </ul>
          <div className="flex flex-col gap-2">
            <p className="font-[400] text-[18px] md:text-[20px] text-[#5C616E] leading-[35px]">
              Patient care technicians are medical professionals who provide
              daily care for patients. Under the supervision of a registered
              nurse or nursing team, they assist with taking vital signs,
              helping to move patients around a clinic, and more.
            </p>
            <p className="font-[400] text-[18px] md:text-[20px] text-[#5C616E] leading-[35px]">
              Their ultimate goal is to make sure the patient feels comfortable
              and nurtured in a health care setting, by communicating any
              changes in the patient's medical condition and potential issues to
              the nurse. Specific duties include:  
            </p>
            <ul className="list-disc font-[400] text-[18px] md:text-[20px] text-[#5C616E] ml-8 flex flex-col gap-3">
              <li>
                Providing emotional support for patients and their families
              </li>
              <li>
                Collecting blood, urine, and stool samples and delivering them
                to the lab
              </li>
              <li>
                Checking vital signs, like temperature, pulse, and blood
                pressure 
              </li>
              <li>Conducting certain medical tests and recording results </li>
              <li>
                Serving meals to patients and monitoring their food and beverage
                intake
              </li>
              <li>
                Reporting changes in a patient's condition to the nurse or
                medical team 
              </li>
              <li>
                Escorting patients for an x-ray or when getting discharged from
                the clinic
              </li>
              <li>
                Helping patients with exercise and mobility, such as getting out
                of bed and to the bathroom
              </li>
            </ul>
          </div>
        </div>
        <div className="w-auto md:w-[98%] ml-7 flex flex-col gap-3">
          <ul className=" list-disc font-[500] text-[18px] md:text-[20px] text-[#1B2336]">
            <li>Is certification necessary for CCMA Program?</li>
          </ul>
          <p className="font-[400] text-[18px] md:text-[20px] text-[#5C616E] leading-[35px]">
            Over 90% of those hiring PCT ‘s position require certification. With
            our PCT training Program, you will be fully prepared to sit for one
            of two national certifications after completing our course. You will
            have the option of choosing between AMCA provisional certification
            exam OR the National Healthcareer Association’s (NHA) Certification.
          </p>
        </div>
        <div className="w-auto md:w-[98%] ml-7 flex flex-col gap-3">
          <ul className=" list-disc font-[500] text-[18px] md:text-[20px] text-[#1B2336]">
            <li>Are Certified Patient Care Technician in high demand?</li>
          </ul>
          <p className="font-[400] text-[18px] md:text-[20px] text-[#5C616E] leading-[35px]">
            As the baby-boom population ages, nursing assistants and orderlies
            will be needed to help care for an increasing number of older people
            who have chronic or progressive diseases, such as heart disease and
            diabetes.
          </p>
          <p className="font-[400] text-[18px] md:text-[20px] text-[#5C616E] leading-[35px]">
            Demand for nursing assistants may be constrained by financial
            pressures on nursing homes, which might lead some facilities to
            close or reduce staff. However, increased opportunities are expected
            in home- and community-based settings as patient preferences and
            shifts in federal and state funding generate demand for care in
            these settings.
          </p>
          <p className="font-[400] text-[18px] md:text-[20px] text-[#5C616E] leading-[35px]">
            Health care jobs are in demand, as it is projected to have more
            growth (16 percent between 2020 and 2030) than any other industry,
            with the addition of 2.6 million new jobs . A career in health care
            can also be rewarding, especially if you enjoy interacting with and
            caring for patients on a daily basis.
          </p>
        </div>

        {data1.map((data) => (
          <div className="w-auto md:w-[98%] ml-7 flex flex-col gap-3">
            <ul className=" list-disc font-[500] text-[18px] md:text-[20px] text-[#1B2336]">
              <li>{data.title}</li>
            </ul>
            <p className="font-[400] text-[18px] md:text-[20px] text-[#5C616E] leading-[35px]">
              {data.content}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default FAQ;
