import React from "react";
import LPNOverview from "../../../assets/images/LPN-course.svg";
import coursePlayIcon from "../../../assets/images/coursePlayIcon.svg";

const Overview = () => {
  return (
    <>
      <div className="font-poppins text-[18px] md:text-[20px] leading-[45px] text-[#5C616E]">
        <div className="text-content md:ml-[20px] md:mb-[20px] xl:ml-[99px] xl:mb-[40px] xl:mt-[85px]  w-1/2 float-right hidden md:flex justify-center items-center relative">
          <img src={LPNOverview} alt="Licensed Practical Nursing Program" />
          <img
            className="absolute"
            src={coursePlayIcon}
            alt="course play icon"
          />
        </div>
        <p className="font-[500] text-[20px] md:text-[24px] xl:text-[32px] leading-[36px] xl:leading-[48px] text-[#003C9D]">
          Licensed Practical Nursing Program
        </p>
        <div className="flex md:hidden justify-center items-center mt-10">
          <img src={LPNOverview} alt="Licensed Practical Nursing Program" />
          <img
            className="absolute"
            src={coursePlayIcon}
            alt="course play icon"
          />
        </div>
        <p className="mt-10">
          PARAM INSTITUTE OF EDUCATION offers a Licensed Practical Nursing (LPN)
          program in the state of New Jersey. The program consisting of 1,554
          hours of theory and clinical practicum. The program length is 12-15
          months divided into four quarters for the in person day class, 12-15
          months into four quarters for the evening classes and 24 months
          divided in four quarters for the weekend classes.
        </p>
        <p className="mt-4">
          The Practical Nursing Program believes that Practical Nursing (PN) is
          grounded in the biological, psychological, sociological, and spiritual
          sciences. Licensed Practical Nurses (LPNs) care for diverse
          individuals and families in a variety of inpatient and community-based
          settings by providing safe, culturally sensitive, individualized
          patient/relationship centered care and by participating as a member of
          the health care team.
        </p>
        <p className="mt-4">
          This curriculum prepares the students for employment as a licensed
          practical nurse (LPN/LVN). It reflects the objective of the nursing
          education. It includes classroom, laboratory and supervised
          client-care learning experience. The faculty believes the practical
          nursing program will prepare the student with the basic knowledge and
          skills required to perform as a beginning practitioner in the field of
          Practical Nursing.
        </p>
        <p className="mt-4">
          The Practical nursing program believes that students graduating from
          Param Institute of Education’s must be ready to practice in a complex
          and changing clinical environment. From gradual leveling and
          evaluation in the didactic, lab, and clinical courses, students grow
          knowledge, skills, and values/attitudes to prepare for the LPN scope
          of practice. The student is given the opportunity to reflect on the
          importance of patient/relationship centered care, teamwork and
          collaboration, leadership skills, quality improvement, safety,
          informatics/technology, professional identify/behaviors, and nursing
          judgment/evidence-based care within individual practice as a future
          LPN.
        </p>
        <p className="mt-4">
          After LPN students complete their coursework, they must pass the
          National Council Licensure Examination or{" "}
          <a
            className="underline text-[#0755b9]"
            href="https://www.practicalnursing.org/nclex-pn-exam"
          >
            NCLEX-PN
          </a>{" "}
          . If they pass, they can pursue specialized certificates in certain
          areas of healthcare, including gerontology, IV therapy, long-term
          care, pharmacology, and more. LPNs aren't just supposed to work at
          nursing homes.
        </p>
        <p className="mt-4">
          Upon satisfactory completion of the program requirement , the student
          is eligible to apply to the state of New Jersey for license by
          examination, as a licensed practical nurse.
        </p>
      </div>
      <div className="flex flex-col gap-10 md:gap-10 mt-10 md:mt-[60px]">
        <p className="font-[500] text-[20px] md:text-[24px] xl:text-[32px] leading-[36px] xl:leading-[48px] text-[#003C9D]">
          Job Outlook for LPN
        </p>
        <ul className=" list-disc font-[400] text-[18px] md:text-[20px] text-[#5C616E] leading-[45px] ml-8 flex flex-col gap-4">
          <li>
            Employment of licensed practical and licensed vocational nurses is
            projected to grow 5 percent from 2022 to 2032, faster than the
            average for all occupations.
          </li>
          <li>
            About 54,400 openings for licensed practical and licensed vocational
            nurses are projected each year, on average, over the decade. Many of
            those openings are expected to result from the need to replace
            workers who transfer to different occupations or exit the labor
            force, such as to retire.
          </li>
          <li>
            As the baby-boom population ages, the overall need for healthcare
            services is expected to increase. LPNs and LVNs will be needed in
            residential care facilities and in home health environments to care
            for older patients.
          </li>
          <li>
            A number of chronic conditions, such as diabetes and obesity, have
            become more prevalent in recent years. LPNs and LVNs will be needed
            to assist and care for patients with these and other conditions. In
            addition, many procedures that once could be done only in hospitals
            are now being done outside of hospitals, creating demand in other
            settings, such as outpatient care centers.
          </li>
        </ul>
      </div>
      <div className="mt-10 md:mt-[60px]">
        <p className="font-[500] text-[20px] md:text-[24px] xl:text-[32px] leading-[36px] xl:leading-[48px] text-[#003C9D]">
          LPN Program Outline
        </p>
        <div className="p-6 border-[1px] rounded-[5px] border-[#D9D9D9] mt-5 md:mt-10 shadow-lg">
          <ul className="list-disc font-[400] text-[18px] md:text-[20px] text-[#1B2336] leading-[35px] flex flex-col gap-[10px] ml-6">
            <li>
              Total Hours:{" "}
              <span className="font-[500] text-[#003C9D]">1554</span>
            </li>
            <li>
              Practical/Clinical Study at School:{" "}
              <span className="font-[500] text-[#003C9D]">48 Hours </span>
            </li>
            <li>
              Clinical Externship at Healthcare Site:{" "}
              <span className="font-[500] text-[#003C9D]">400 Hours</span>
            </li>
          </ul>
        </div>

        <div className="p-6 border-[1px] rounded-[5px] border-[#D9D9D9] mt-6 shadow-lg">
          <ul className="font-[400] text-[18px] md:text-[20px] text-[#1B2336] leading-[35px] flex flex-col gap-[10px] ml-6 list-decimal">
            <li>Medical Terminology</li>
            <li>Anatomy and Physiology</li>
            <li>Nutrition</li>
            <li>Nursing in society</li>
            <li>Microbiology</li>
            <li>Fundamental of Nursing</li>
            <li>Medical Surgical Nursing I</li>
            <li>Mental Health Nursing</li>
            <li>Geriatrics Nursing</li>
            <li>Medical Surgical Nursing II</li>
            <li>Maternal Health Nursing</li>
            <li>Pediatric Nursing</li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Overview;
