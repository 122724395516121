import { call, put, takeLatest } from "redux-saga/effects";
import { ADD_APPOINTMENT, GET_BOOKING_SLOTS } from "../ActionTypes";
import { getBookingSlotsAPI, addAppointmentAPI } from "../Api";
import {
  getBookingSlots,
  getBookingSlotsSuccess,
  getBookingSlotsFailure,
  addAppointment,
  addAppointmentSuccess,
  addAppointmentFailure,
} from "../actions/appointmentBooking.action";

function* bookingSlotsSaga(action) {
  try {
    const response = yield call(
      getBookingSlotsAPI,
      action.payload.formattedDate
    );
    if (response) {
      yield put(getBookingSlotsSuccess(response.data));
    }
  } catch (error) {
    yield put(getBookingSlotsFailure(error));
    console.log("error", error);
  }
}

function* addAppointmentSaga(action) {
  try {
    const response = yield call(
      addAppointmentAPI,
      action.payload
    );
    if (response) {
      yield put(addAppointmentSuccess(response));
    }
  } catch (error) {
    yield put(addAppointmentFailure(error));
    console.log("error", error);
  }
}

export function* appointmentBookingSaga() {
  yield takeLatest(GET_BOOKING_SLOTS, bookingSlotsSaga);
  yield takeLatest(ADD_APPOINTMENT, addAppointmentSaga);
}
