import { React, useEffect, useRef } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { getCourses } from "../../../redux/actions/common.action";
import { useDispatch, useSelector } from "react-redux";
import {
  getWithdrawalReasons,
  addAdmissionWithdrawal,
  addAdmissionWithdrawalSuccess,
} from "../../../redux/actions/StudentServices.action";
import { imageGenerator } from "../../../utils/imageGenerator";
import useLoader from "../../../hooks/useLoader";

function WithdrawalApplication() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formRef = useRef(null);
  const { getcourses_data } = useSelector((state) => state.CommonReducer);
  const { withdrawalReasonsData, addAdmissionWithdrawalData } = useSelector(
    (state) => state.studentServicesReducer
  );
  const { isLoading, setLoader, Loader } = useLoader();

  useEffect(() => {
    if (
      addAdmissionWithdrawalData &&
      addAdmissionWithdrawalData.status == 201
    ) {
      setLoader(false);
      const message = addAdmissionWithdrawalData.data.message;
      dispatch(addAdmissionWithdrawalSuccess(null));
      navigate("../thankYou", {
        state: { message: message },
      });
    }
  }, [addAdmissionWithdrawalData]);

  //----------------------------------------------------------USE EFFECT FOR GET COURSES-----------------------------------------------------------------//
  useEffect(() => {
    dispatch(getCourses());
    dispatch(getWithdrawalReasons());
  }, [dispatch]);
  //----------------------------------------------------------USE EFFECT FOR GET COURSES-----------------------------------------------------------------//
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    email: Yup.string()
      .email("Please enter valid email")
      .required("Email is required"),
    phone: Yup.string().required("Phone Number is required"),
    interest: Yup.string().required("Please select your program of interest."),
    dateofbirth: Yup.string().required("Date of Birth is required"),
    withdrawal: Yup.string().required("Withdrawal is required"),
    withdrawalreason: Yup.string().required("Withdrawal reason is required"),
    name: Yup.string().required("Name is required"),
    todaydate: Yup.string().required("Today Date is required"),
  });

  //------------------------------------------------------- Function to Handle Form Submission Starts ---------------------------------------------------//
  const handleSubmit = async (values, { resetForm }) => {
   
    const pdf = await imageGenerator(formRef.current);
    const payloadData = {
      firstname: values.firstName,
      lastname: values.lastName,
      email: values.email,
      phonenumber: values.phone,
      course_id: values.interest,
      dob: values.dateofbirth,
      reason_id: values.withdrawal,
      reason: values.withdrawalreason,
      name: values.name,
      submittedDate: values.todaydate,
      pdfFile: pdf,
    };

    try {
      dispatch(addAdmissionWithdrawal(payloadData));
      resetForm();
      setLoader(true);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };
  //------------------------------------------------------- Function to Handle Form Submission Ends ---------------------------------------------------//
  return (
    <div
      className="bg-[#F5F5F5] font-poppins px-4 md:px-8 xl:px-10 py-12 flex flex-col items-center gap-12 -mb-10"
      ref={formRef}
    >
      {isLoading ? (
        <Loader type={"component"} />
      ) : (
        <>
          <p className="font-[600] text-[32px] md:text-[40px] xl:text-[42px] text-[#F37021]">
            Apply for Withdrawal of Admission
          </p>
          <Formik
            initialValues={{
              firstName: "",
              lastName: "",
              email: "",
              phone: "",
              interest: "",
              dateofbirth: "",
              withdrawal: "",
              withdrawalreason: "",
              other: "",
              name: "",
              todaydate: "",
            }}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            {({ errors, touched }) => (
              <Form className="w-full flex flex-col items-center gap-8">
                <div className="w-full xl:w-[90%] flex gap-8 md:gap-4 xl:gap-5 flex-col md:flex-row">
                  <div className=" w-full flex flex-col gap-2">
                    <label className="font-[400] text-[16px] md:text-[18px] text-[#1B2336]">
                      First Name <span className="text-[#BB2727]">*</span>
                    </label>
                    <Field
                      type="text"
                      name="firstName"
                      className={`h-[50px] border border-[#989898] rounded-[5px] p-3 ${
                        errors.firstName && touched.firstName
                          ? "border-red-500"
                          : ""
                      }`}
                    />
                    <ErrorMessage
                      name="firstName"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                  <div className=" w-full flex flex-col gap-2">
                    <label className="font-[400] text-[16px] md:text-[18px] text-[#1B2336]">
                      Last Name <span className="text-[#BB2727]">*</span>
                    </label>
                    <Field
                      type="text"
                      name="lastName"
                      className={`h-[50px] border border-[#989898] rounded-[5px] p-3 ${
                        errors.lastName && touched.lastName
                          ? "border-red-500"
                          : ""
                      }`}
                    />
                    <ErrorMessage
                      name="lastName"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                </div>
                <div className="w-full xl:w-[90%] flex gap-8 md:gap-5 flex-col md:flex-row">
                  <div className=" w-full flex flex-col gap-2">
                    <label className="font-[400] text-[16px] md:text-[18px] text-[#1B2336]">
                      Email Address <span className="text-[#BB2727]">*</span>
                    </label>
                    <Field
                      type="text"
                      name="email"
                      className={`h-[50px] border border-[#989898] rounded-[5px] p-3 ${
                        errors.email && touched.email ? "border-red-500" : ""
                      }`}
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                  <div className=" w-full flex flex-col gap-2">
                    <label className="font-[400] text-[16px] md:text-[18px] text-[#1B2336]">
                      Phone <span className="text-[#BB2727]">*</span>
                    </label>
                    <Field
                      type="text"
                      name="phone"
                      className={`h-[50px] border border-[#989898] rounded-[5px] p-3 ${
                        errors.phone && touched.phone ? "border-red-500" : ""
                      }`}
                    />
                    <ErrorMessage
                      name="phone"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                </div>
                <div className="w-full xl:w-[90%] flex gap-8 md:gap-5 flex-col md:flex-row">
                  <div className=" md:w-1/2 flex flex-col gap-2">
                    <label className="font-[400] text-[16px] md:text-[18px] text-[#1B2336]">
                      Program Of Interest{" "}
                      <span className="text-[#BB2727]">*</span>
                    </label>
                    <Field
                      as="select"
                      name="interest"
                      type="text"
                      className={`h-[50px] border border-[#989898] rounded-[5px] p-3 ${
                        errors.interest && touched.interest
                          ? "border-red-500"
                          : ""
                      }`}
                    >
                      <option value="" disabled hidden>
                        Select Program
                      </option>
                      {getcourses_data?.data?.map((course) => (
                        <option value={course.id}>{course.coursename}</option>
                      ))}
                    </Field>
                    <ErrorMessage
                      name="interest"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                  <div className=" md:w-1/2 flex flex-col gap-2">
                    <label className="font-[400] text-[16px] md:text-[18px] text-[#1B2336]">
                      Date of Birth <span className="text-[#BB2727]">*</span>
                    </label>
                    <Field
                      type="date"
                      name="dateofbirth"
                      className={`h-[50px] border border-[#989898] rounded-[5px] p-3 ${
                        errors.dateofbirth && touched.dateofbirth
                          ? "border-red-500"
                          : ""
                      }`}
                    />
                    <ErrorMessage
                      name="dateofbirth"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                </div>
                <div className="w-full xl:w-[90%] flex gap-8 md:gap-5 flex-col md:flex-row">
                  <div className=" w-full flex flex-col gap-2">
                    <label className="font-[400] text-[16px] md:text-[18px] text-[#1B2336]">
                      Withdrawal <span className="text-[#BB2727]">*</span>
                    </label>
                    <Field
                      as="select"
                      name="withdrawal"
                      type="text"
                      className={`h-[50px] border border-[#989898] rounded-[5px] p-3 ${
                        errors.withdrawal && touched.withdrawal
                          ? "border-red-500"
                          : ""
                      }`}
                    >
                      <option value="" disabled hidden>
                        Select Withdrawal Option
                      </option>
                      {withdrawalReasonsData?.data?.map((option) => (
                        <option value={option.id}>{option.name}</option>
                      ))}
                    </Field>
                    <ErrorMessage
                      name="withdrawal"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                </div>
                <div className="w-full xl:w-[90%] flex gap-8 md:gap-5 flex-col md:flex-row">
                  <div className=" w-full flex flex-col gap-2">
                    <label className="font-[400] text-[16px] md:text-[18px] text-[#1B2336]">
                      Reason for Withdrawal{" "}
                      <span className="text-[#BB2727]">*</span>
                    </label>
                    <Field
                      type="textarea"
                      rows="5"
                      as="textarea"
                      name="withdrawalreason"
                      className={` border border-[#989898] rounded-[5px] p-3 ${
                        errors.withdrawalreason && touched.withdrawalreason
                          ? "border-red-500"
                          : ""
                      }`}
                    />
                    <ErrorMessage
                      name="withdrawalreason"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                  <div className=" w-full flex flex-col gap-2">
                    <label className="font-[400] text-[16px] md:text-[18px] text-[#1B2336]">
                      Other
                    </label>
                    <Field
                      type="textarea"
                      rows="5"
                      as="textarea"
                      name="other"
                      className={` border border-[#989898] rounded-[5px] p-3 ${
                        errors.other && touched.other ? "border-red-500" : ""
                      }`}
                    />
                    <ErrorMessage
                      name="other"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                </div>
                <div className="w-full xl:w-[90%] flex gap-8 md:gap-5 flex-col md:flex-row">
                  <div className=" w-full flex flex-col gap-2">
                    <label className="font-[400] text-[14px] md:text-[16px] text-[#1B2336] italic">
                      I understand that although I am applying for withdrawal
                      from enrolled program of the school I may still have a
                      financial obligation to the school and that I am
                      responsible for payment of any outstanding balances.
                    </label>
                    <label className="font-[400] text-[14px] md:text-[16px] text-[#1B2336]">
                      This is just an form for the withdrawal from the program.
                      The final decision and pending dues will be notify from
                      the admin office after final decision on your withdrawal
                      by the officials. This form does not withdraw your
                      Admission.
                    </label>
                  </div>
                </div>
                <div className="w-full xl:w-[90%] flex gap-8 md:gap-5 flex-col md:flex-row">
                  <div className=" w-full flex flex-col gap-2">
                    <label className="font-inter font-[600] text-[20px] md:text-[24px] text-[#5C616E]">
                      E Signature
                    </label>
                  </div>
                </div>

                <div className="w-full xl:w-[90%] flex gap-8 md:gap-5 flex-col md:flex-row">
                  <div className=" w-full flex flex-col gap-2">
                    <label className="font-[400] text-[16px] md:text-[18px] text-[#1B2336]">
                      Name <span className="text-[#BB2727]">*</span>
                    </label>
                    <Field
                      type="text"
                      name="name"
                      className={`h-[50px] border border-[#989898] rounded-[5px] p-3 ${
                        errors.name && touched.name ? "border-red-500" : ""
                      }`}
                    />
                    <ErrorMessage
                      name="name"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                  <div className=" w-full flex flex-col gap-2">
                    <label className="font-[400] text-[16px] md:text-[18px] text-[#1B2336]">
                      Today’s Date <span className="text-[#BB2727]">*</span>
                    </label>
                    <Field
                      type="date"
                      name="todaydate"
                      className={`h-[50px] border border-[#989898] rounded-[5px] p-3 ${
                        errors.todaydate && touched.todaydate
                          ? "border-red-500"
                          : ""
                      }`}
                    />
                    <ErrorMessage
                      name="todaydate"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                </div>

                <div className="flex gap-3 w-full xl:w-[90%] justify-center mt-3">
                  <button type="submit" className="orangeHoverButton">
                    Submit
                  </button>
                  <button
                    type="button"
                    className="orangeOutlineButton"
                    onClick={() => navigate(-1)}
                  >
                    Cancel
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </>
      )}
    </div>
  );
}

export default WithdrawalApplication;
