import React from "react";
import phlebotonyOverview from "../../../../assets/images/phlebotonyOverview.webp";
import coursePlayIcon from "../../../../assets/images/coursePlayIcon.svg";
import tick from "../../../../assets/images/tick.svg";
import QuickFactsTable from "../../../../assets/images/QuickFacts.svg";

const Overview = ({ setTab }) => {
  return (
    <>
      <div className="font-poppins text-[18px] md:text-[20px] leading-[45px] text-[#5C616E]">
        <div className="text-content md:ml-[20px] md:mb-[20px] xl:ml-[99px] xl:mb-[40px] xl:mt-[85px]  w-1/2 float-right hidden md:flex justify-center items-center relative">
          <img src={phlebotonyOverview} alt="phlebotony Overview" />
          <img
            className="absolute"
            src={coursePlayIcon}
            alt="course play icon"
          />
        </div>
        <p className="font-[500] text-[20px] md:text-[24px] xl:text-[32px] leading-[36px] xl:leading-[48px] text-[#003C9D]">
          Certified Phlebotomy Technician Program
        </p>
        <div className="flex md:hidden justify-center items-center mt-10">
          <img src={phlebotonyOverview} alt="phlebotony Overview" />
          <img
            className="absolute"
            src={coursePlayIcon}
            alt="course play icon"
          />
        </div>
        <p className="mt-10">
          The Certified Phlebotomy Technician program{" "}
          <a
            className="underline text-[#0755b9]"
            href="#"
            onClick={(event) => {
              event.preventDefault();
              setTab("faq");
            }}
          >
            (Hybrid)
          </a>{" "}
          at Param Institute of Technology (PIE) pursues to prepare entry-level
          phlebotomists who demonstrate acceptable workplace behaviors and who
          are eligible to sit for the national certification examination.
          Phlebotomists are health care professionals who work in hospitals,
          blood banks and other diagnostic health care facilities, where they
          draw blood from patients. They take these blood samples to send to
          laboratory for further checkup. In the meantime, phlebotomists can
          work in all kinds of medical facilities, from hospitals to private
          laboratories. This{" "}
          <a
            className="underline text-[#0755b9]"
            href="#"
            onClick={(event) => {
              event.preventDefault();
              setTab("faq");
            }}
          >
            hybrid
          </a>{" "}
          (where you can choose either an Online or In-person theory class)
          program is designed for 100 hours of Theory and Clinical.
        </p>
        <p className="mt-4">
          The phlebotomist is a vital member of the clinical laboratory team,
          whose main function is to obtain patient’s blood specimens by
          venipuncture and micro collection for testing purposes. Phlebotomists
          are employed throughout the healthcare system including in hospitals,
          neighborhood health centers, medical group practices, HMO’s, public
          health facilities, veteran hospitals, insurance carriers, and in other
          healthcare settings. Further, this program certification from the
          Param Institute of Education Prepares you to take the Certification
          exams of the{" "}
          <a
            className="underline text-[#0755b9]"
            href="https://www.nhanow.com/"
          >
            National Healthcareer Association (NHA)
          </a>{" "}
          and{" "}
          <a
            className="underline text-[#0755b9]"
            href="https://www.amcaexams.com/amca-certification-board/"
          >
            American Medical Certification Association (AMCA).
          </a>{" "}
          In addition to this, once you complete the program requirements you
          are entitled to appear in NHA and/or AMCA and/or other applicable
          national certification exams. 
        </p>
        <div className="text-content w-full text-[18px] md:text-[20px] leading-[45px] flex flex-col gap-4 text-[#5C616E] mt-4">
          <p>
            The demand for phlebotomy technicians has increased substantially
            with the overall complexity of healthcare services and the risks of
            infectious disease. Current healthcare industry experts predict a
            15% increase in phlebotomy jobs by 2030.
          </p>
        </div>
        <div className="flex flex-col gap-5 md:gap-10">
          <p className="font-[500] text-[20px] md:text-[24px] xl:text-[32px] leading-[36px] xl:leading-[48px] text-[#003C9D] mt-10 md:mt-14">
            Why Choose Certified Phlebotomy Technician Program?
          </p>
          <p className="text-content w-full text-[18px] md:text-[20px] leading-[45px] flex flex-col gap-4 text-[#5C616E]">
            The field of phlebotomy is constantly changing and growing. With an
            increasing demand for phlebotomists, this position guarantees
            relatively steady employment and exposure to new opportunities. If
            you are a fast learner who enjoys learning new techniques and wants
            to grow professionally, phlebotomy may be the career for you. Let’s
            go over a few major reasons to become a phlebotomist.
          </p>
          <div className=" flex flex-col gap-6 leading-[40px]">
            <div className="flex flex-col gap-4">
              <div className="flex gap-6 items-center">
                <img
                  src={tick}
                  alt=""
                  className="h-[22px] w-[22px] md:h-[30px] md:w-[30px]"
                />
                <p className="font-[500] text-[20px] md:text-[24px]  text-[#F37021] font-inter">
                  Fast Licensing
                </p>
              </div>
              <p className="font-[400] text-[18px] md:text-[20px] text-[#5C616E]  font-inter leading-[40px] ml-12">
                Phlebotomists tend to get licensed faster when compared to other
                entry level positions in the medical workplace, such as medical
                assistants or certified nursing assistants. The length of time
                to get your phlebotomy certification can take as little as 5
                weeks. For phlebotomy, students can receive an in-depth courses
                cost a few thousand dollars, which is relatively inexpensive in
                the grand scheme of things.
              </p>
            </div>
            <div className="flex flex-col gap-4">
              <div className="flex gap-6 items-center">
                <img src={tick} alt="" className="h-[30px] w-[30px]" />
                <p className="font-[500] text-[20px] md:text-[24px]  text-[#F37021] font-inter">
                  Job Security & In-Demand Career with a Short Training Program
                </p>
              </div>
              <p className="font-[400] text-[18px] md:text-[20px] text-[#5C616E]  font-inter leading-[40px] ml-12">
                Phlebotomy is an increasingly essential career path. According
                to the U.S. Bureau of Labor Statistics (BLS), phlebotomy
                positions are projected to increase by 23% from 2018 to 2028,
                higher than the growth rates for many other professions. Part of
                the reason may be due to increased precautions when handling
                blood samples becoming a norm across the industry. Obtaining
                such samples are essential to diagnostic testing for diseases
                and illnesses in either medical or clinical settings.
              </p>
              <p className="font-[400] text-[18px] md:text-[20px] text-[#5C616E]  font-inter leading-[40px] ml-12">
                Having a career in the medical field almost guarantees job
                security. In the dynamic world of healthcare, the medical
                industry will likely always need phlebotomists to obtain and
                process blood specimens. As seen from the BLS statistic, the
                demand for this work is increasing.
              </p>
            </div>
            <div className="flex flex-col gap-4">
              <div className="flex gap-6 items-center">
                <img src={tick} alt="" className="h-[30px] w-[30px]" />
                <p className="font-[500] text-[20px] md:text-[24px]  text-[#F37021] font-inter">
                  Great Job Opportunities & Earn A Great Income for Yourself
                </p>
              </div>
              <p className="font-[400] text-[18px] md:text-[20px] text-[#5C616E]  font-inter leading-[40px] ml-12">
                As a phlebotomist, you are granted unique experiences to work in
                a variety of settings with diverse groups of people.
                Phlebotomists may work in hospitals, clinics, and research
                laboratories or serve as a mobile phlebotomist. Either way,
                phlebotomists tend to have flexible work schedules which allows
                enough time to pursue additional professional growth.
              </p>
              <p className="font-[400] text-[18px] md:text-[20px] text-[#5C616E]  font-inter leading-[40px] ml-12 -mt-2">
                Furthermore, phlebotomists are offered a generous salary in
                comparison to other entry level positions. The cost of a
                phlebotomy education is worthwhile and better than paying
                thousands of dollars for other degrees. According to the U.S.
                Bureau of Labor Statistics, the median annual wage for
                phlebotomists was $37,380 in 2021, which is about $17.97 per
                hour. This wage rate has been growing through 2020, and is only
                expected to increase into the future.
              </p>
            </div>
            <div className="flex justify-center h-[430px]">
              <img src={QuickFactsTable} />
            </div>

            <div className="flex flex-col gap-4 mt-6">
              <div className="flex gap-6 items-center">
                <img src={tick} alt="" className="h-[30px] w-[30px]" />
                <p className="font-[500] text-[20px] md:text-[24px]  text-[#F37021] font-inter">
                  Opportunities for Professional Growth
                </p>
              </div>
              <p className="font-[400] text-[18px] md:text-[20px] text-[#5C616E]  font-inter leading-[40px] ml-12">
                Going to phlebotomy school does not create a finite career path.
                Oftentimes, phlebotomy is a stepping stone into other medical
                fields. Many phlebotomists gain experience in the field,
                finetune their interests, and then pursue new healthcare roles.
                Some careers that phlebotomists advance to include medical
                laboratory technicians, clinical laboratory scientists, and
                registered nurses.
              </p>
              <p className="font-[400] text-[18px] md:text-[20px] text-[#5C616E]  font-inter leading-[40px] ml-12 -mt-2">
                {" "}
                Phlebotomy allows people to practice in the medical field and
                further their professional growth before applying to more
                competitive and expensive programs. In fact, most of these
                positions use phlebotomist strategies. Sometimes, practicing
                phlebotomy may be a prerequisite for future career positions,
                such as medical laboratory technicians.
              </p>
              <p className="font-[400] text-[18px] md:text-[20px] text-[#5C616E]  font-inter leading-[40px] ml-12 -mt-2">
                In terms of applying to other medical courses, an additional
                certification would increase your chances of being hired or
                receive a more competitive salary. Or, it may lead to a higher
                likelihood of getting into other medical programs given the time
                you’ve worked in a medical profession. You will get 100 hours of
                credit for Certified clinical Assistant Program.
              </p>
              <p className="font-[400] text-[18px] md:text-[20px] text-[#5C616E]  font-inter leading-[40px] ml-12 -mt-2">
                You will get 100 hours of credit for Certified clinical
                Assistant Program.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-10 md:gap-10 mt-10 md:mt-[60px]">
        <p className="font-[500] text-[20px] md:text-[24px] xl:text-[32px] leading-[36px] xl:leading-[48px] text-[#003C9D]">
          Job Outlook for Certified Phlebotomy Technician
        </p>
        <ul className=" list-disc font-[400] text-[18px] md:text-[20px] text-[#5C616E] leading-[45px] ml-8 flex flex-col gap-4">
          <li>
            As per U.S. Bureau of Labor Statistic (BLS) Employment of
            phlebotomists is projected to grow 10 percent from 2021 to 2031,
            faster than the average for all occupations.
          </li>
          <li>
            About 21,500 openings for phlebotomists are projected each year, on
            average, over the decade. Many of those openings are expected to
            result from the need to replace workers who transfer to different
            occupations or exit the labor force, such as to retire.
          </li>
        </ul>
      </div>
      <div className="mt-10 md:mt-[60px]">
        <p className="font-[500] text-[20px] md:text-[24px] xl:text-[32px] leading-[36px] xl:leading-[48px] text-[#003C9D]">
          Program Outline
        </p>
        <div className="p-6 border-[1px] rounded-[5px] border-[#D9D9D9] mt-5 md:mt-10 shadow-lg">
          <ul className="list-disc font-[400] text-[18px] md:text-[20px] text-[#1B2336] leading-[35px] flex flex-col gap-[10px] ml-6">
            <li>
              Total Hours:{" "}
              <span className="font-[500] text-[#003C9D]">100</span>
            </li>
            <li>
              Hybrid / In Person Study:{" "}
              <span className="font-[500] text-[#003C9D]">30 Hours </span>
            </li>
            <li>
              Practical/Clinical Study at School:{" "}
              <span className="font-[500] text-[#003C9D]">25 Hours </span>
            </li>
            <li>
              Clinical Externship at Healthcare Site:{" "}
              <span className="font-[500] text-[#003C9D]">45 Hours</span>
            </li>
          </ul>
        </div>

        <div className="p-6 border-[1px] rounded-[5px] border-[#D9D9D9] mt-6 shadow-lg">
          <ul className="font-[400] text-[18px] md:text-[20px] text-[#1B2336] leading-[35px] flex flex-col gap-[10px] ml-6">
            <li>
              Module 1: A PATIENT CARE AND PREPARATION RELATED TO PHLEBOTOMY
            </li>
            <li>Module 2: COLLECTION TECHNIQUES</li>
            <li>Module 3: FOLLOW ORDER OF DRAW FOR CAPILLARY COLLECTION</li>
            <li>
              Module 4: PHLEBOTOMY SAMPLE COLLECTION AND TRANSPORT TECHNIQUES
            </li>
            <li>Module 5: EXTERNSHIP AND CERTIFICATION EXAM REVIEW</li>
          </ul>
        </div>

        <div className="w-full flex gap-10 flex-col">
          <div className="w-full  mt-[20px]">
            <p className="font-[500] text-[20px] md:text-[24px] xl:text-[32px] text-[#003C9D] pb-[40px] pt-10">
              What you get from us?
            </p>
            <div className="flex gap-8 xl:flex-row flex-col">
              <ul className=" w-full list-disc font-[400] text-[18px] md:text-[20px] text-[#5C616E] flex  list-inside gap-4 flex-col">
                <li>Online lectures and material access</li>
                <li>Live instructor for your queries</li>
                <li>Administrative support</li>
                <li>Onsite clinical training</li>
                <li>Field/Internship/Externship training</li>
                <li>CPT Certificate from school</li>

                <li>National and state certification opportunities</li>
                <li>
                  NHA or AMCA Certified Phlebotomy Technician (CPT) exam
                  preparation material
                </li>
                <li>
                  NHA or AMCA certification once you appear and pass the NHA or
                  AMCA exams
                </li>
                <li>Placement Assistant</li>
                <li>Job opportunities</li>
                <li>Career pathways opportunities and guidelines</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Overview;
