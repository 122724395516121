import React from "react";
import coursePlayIcon from "../../../../assets/images/coursePlayIcon.svg";
import awcProgram from "../../../../assets/images/awcProgram.svg";

const Overview = () => {
  return (
    <>
      <div className="font-poppins inline-block">
        <div className="text-content md:ml-[20px] md:mb-[20px] xl:ml-[99px] xl:mb-[40px] 2xl:mb-0 xl:mt-[85px]  w-1/2 float-right hidden md:flex justify-center items-center relative">
          <img
            src={awcProgram}
            alt="Ayurvedic Wellness Counselor (AWC) Program"
          />
          <img
            className="absolute"
            src={coursePlayIcon}
            alt="course play icon"
          />
        </div>
        <p className="font-[500] text-[20px] md:text-[24px] xl:text-[32px] leading-[36px] xl:leading-[48px] text-[#003C9D]">
          Ayurvedic Wellness Counselor Program (Hybrid)
        </p>
        <div className="flex md:hidden justify-center items-center mt-10">
          <img
            src={awcProgram}
            alt="Ayurvedic Wellness Counselor (AWC) Program"
          />
          <img
            className="absolute"
            src={coursePlayIcon}
            alt="course play icon"
          />
        </div>
        <p className="text-[18px] md:text-[20px] leading-[45px] text-[#5C616E] mt-10">
          The essence of the Ayurvedic Wellness Counselor (AWC) Program lies in
          its commitment to the fundamental principle of Ayurveda—Swasthasya
          Swasthya Rakshanam, which emphasizes preserving the well-being of the
          healthy individual. Through this program, students will acquire
          essential tools to effectively steward health and wellness. These
          tools encompass an integrated approach, including comprehensive
          nutritional knowledge (Ahara), daily habits and routines to optimize
          well-being (Vihar), and a deep comprehension of the mind and its
          influence on health (Vichara). By engaging with this curriculum,
          participants will gain invaluable insights into Ayurvedic
          methodologies and wellness counseling, empowering them to make
          meaningful contributions to health promotion and holistic care.
        </p>
        <p className="text-[18px] md:text-[20px] leading-[45px] text-[#5C616E] mt-4">
          The Ayurvedic Wellness Counselor Program at Param Institute of
          Education is designed for{" "}
          <a className="underline text-[#0755b9]" href="#">
            Hybrid
          </a>{" "}
          (where you can choose either an Online or In-person theory class) 625
          hours of comprehensive training including classroom instructions and
          skill training to prepare the student for ancient healing tradition of
          Ayurveda.
        </p>
      </div>
      <div className="mt-10 md:mt-[60px]">
        <p className="font-[500] text-[20px] md:text-[24px] xl:text-[32px] leading-[36px] xl:leading-[48px] text-[#003C9D]">
          What are the Key Reasons for Pursuing an Ayurvedic Wellness Counselor
          Program?
        </p>
        <p className="text-[18px] md:text-[20px] leading-[40px] mt-5 md:mt-10 text-[#5C616E]">
          Embarking on an Ayurvedic counselor program serves several vital
          purposes:
        </p>
        <ul className="mt-6 text-[#F37021] font-[500] text-[20px] md:text-[24px] leading-[30px] flex flex-col gap-6">
          <li className="flex flex-col relative before:content-[''] before:bg-contain before:absolute before:left-0 before:top-[2px] before:md:top-0 before:w-[22px] before:md:w-[30px] pl-[54px] before:h-[22px] before:md:h-[30px] before:bg-checkmark-bg before:bg-no-repeat">
            Holistic Health Management
            <span className="mt-4 text-[#5C616E] text-[18px] md:text-[20px] leading-10 font-[400]">
              Ayurveda offers a comprehensive approach to health, considering
              physical, mental, emotional, and spiritual aspects. By undergoing
              a counselor program, individuals gain comprehensive knowledge and
              skills to address health concerns from a holistic perspective.
            </span>
          </li>
          <li className="flex flex-col relative before:content-[''] before:bg-contain before:absolute before:left-0 before:top-[2px] before:md:top-0 before:w-[22px] before:md:w-[30px] pl-[54px] before:h-[22px] before:md:h-[30px] before:bg-checkmark-bg before:bg-no-repeat">
            Personal Well-being
            <span className="mt-4 text-[#5C616E] text-[18px] md:text-[20px] leading-10 font-[400]">
              Participants in Ayurvedic counselor programs often experience
              personal transformation and enhanced well-being. They learn
              self-care practices, dietary adjustments, and lifestyle
              modifications that promote balance and vitality in their own
              lives.
            </span>
          </li>
          <li className="flex flex-col relative before:content-[''] before:bg-contain before:absolute before:left-0 before:top-[2px] before:md:top-0 before:w-[22px] before:md:w-[30px] pl-[54px] before:h-[22px] before:md:h-[30px] before:bg-checkmark-bg before:bg-no-repeat">
            Client Support
            <span className="mt-4 text-[#5C616E] text-[18px] md:text-[20px] leading-10 font-[400]">
              Trained Ayurvedic counselors can provide invaluable support to
              clients seeking alternative or complementary approaches to health
              and wellness. They offer personalized guidance, tailored
              recommendations, and ongoing encouragement to individuals
              navigating health challenges.
            </span>
          </li>
          <li className="flex flex-col relative before:content-[''] before:bg-contain before:absolute before:left-0 before:top-[2px] before:md:top-0 before:w-[22px] before:md:w-[30px] pl-[54px] before:h-[22px] before:md:h-[30px] before:bg-checkmark-bg before:bg-no-repeat">
            Preventive Healthcare
            <span className="mt-4 text-[#5C616E] text-[18px] md:text-[20px] leading-10 font-[400]">
              Ayurveda emphasizes preventive healthcare by promoting healthy
              lifestyle habits and early detection of imbalances. Counselors
              equipped with Ayurvedic knowledge can empower individuals to take
              initiative-taking steps to maintain their health and prevent
              disease.
            </span>
          </li>
          <li className="flex flex-col relative before:content-[''] before:bg-contain before:absolute before:left-0 before:top-[2px] before:md:top-0 before:w-[22px] before:md:w-[30px] pl-[54px] before:h-[22px] before:md:h-[30px] before:bg-checkmark-bg before:bg-no-repeat">
            Cultural Preservation
            <span className="mt-4 text-[#5C616E] text-[18px] md:text-[20px] leading-10 font-[400] flex flex-col gap-[10px]">
              <p>
                Ayurveda is deeply rooted in Indian culture and tradition. By
                undertaking Ayurvedic counselor training, individuals contribute
                to the preservation and promotion of this ancient healing
                system, ensuring its continued relevance and accessibility in
                modern times.
              </p>
              <p>
                Overall, Ayurvedic counselor programs serve as a bridge between
                ancient wisdom and contemporary healthcare needs, empowering
                individuals to promote holistic wellness and contribute to the
                well-being of themselves and others.
              </p>
            </span>
          </li>
        </ul>
      </div>
      <div className="mt-10 md:mt-[60px]">
        <p className="font-[500] text-[20px] md:text-[24px] xl:text-[32px] leading-[36px] xl:leading-[48px] text-[#003C9D]">
          Program Outline
        </p>
        <div className="p-6 border-[1px] rounded-[5px] border-[#D9D9D9] mt-5 md:mt-10 shadow-lg">
          <ul className="list-disc font-[400] text-[18px] md:text-[20px] text-[#1B2336] leading-[35px] flex flex-col gap-[10px] ml-6">
            <li>
              Program Length:{" "}
              <span className="font-[500] text-[#003C9D]">52 Weeks</span>
            </li>
            <li>
              Total Hours of Program:{" "}
              <span className="font-[500] text-[#003C9D]">625 hours</span>
            </li>
          </ul>
        </div>
        <div className="p-6 border-[1px] rounded-[5px] border-[#D9D9D9] mt-6 shadow-lg">
          <ul className="font-[400] text-[18px] md:text-[20px] text-[#1B2336] leading-[30px] flex flex-col gap-[10px]">
            <li>
              Module 1: The Essence of Ayurveda (Tarka Sankhya and Sharir)
            </li>
            <li>Module 2: Ayurvedic Physiology (Dosha Dhatu Mal Vigyan)</li>
            <li>Module 3: Ayurvedic Psychology (Manasa Shastra)</li>
            <li>Module 4: Ayurvedic Nutrition & Cooking (Ahara Vigyan)</li>
            <li>Module 5: Ayurvedic Prevention Science (Swastha Vritta)</li>
            <li>Module 6: Ayurvedic Herbology (Dravya Guna vritta)</li>
            <li>Module 7: Yoga Therapy (Yoga Vigyan)</li>
            <li>Module 8: Introduction to Ayurvedic Bodywork</li>
            <li>Module 9: Disease Pathology (Roga Nidan)</li>
            <li>Module 10: Disease Management (Kaya Chikitsa)</li>
            <li>Module 11: Review, Clinical Management</li>
            <li>Weekly Virtual Classroom Session</li>
            <li>E-learning & Video Instruction</li>
            <li>Special Project</li>
            <li>Home Study</li>
            <li>Faculty Mentored Phone Conferences</li>
            <li>Externship: Clinical Encounters / Internship</li>
          </ul>
        </div>
        <div className="mt-10 md:mt-[60px]">
          <div className="">
            <p className="font-[500] text-[20px] md:text-[32px] leading-[36px] text-[#003C9D]">
              What you get from us?
            </p>
            <ul className="list-disc font-[400] text-[18px] md:text-[20px] text-[#5C616E] leading-[30px] mt-10 flex flex-col gap-4 ml-6">
              <li>Lectures and material access</li>
              <li>Live instructor for your queries</li>
              <li>Administrative support</li>
              <li>Onsite clinical training</li>
              <li>Career pathways opportunities and guidelines</li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Overview;
