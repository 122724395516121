import React from "react";

const CourseOutcomes = () => {
  return (
    <>
      <div>
        <p className="font-[500] text-[20px] md:text-[24px] xl:text-[32px] leading-[36px] xl:leading-[48px] text-[#003C9D]">
          Upon the Completion of the Yoga Teacher Training Certificate Program
          the Student will be Able to
        </p>

        <ul className="mt-5 md:mt-10 text-[#F37021] font-[500] text-[20px] md:text-[24px] leading-[30px] flex flex-col gap-6">
          <li className="flex flex-col relative before:content-[''] before:absolute before:left-0 before:w-[30px] pl-[54px] before:h-[30px] before:bg-checkmark-bg before:bg-no-repeat">
            Ability in Teaching
            <span className="mt-4 text-[#5C616E] text-[18px] md:text-[20px] leading-10 font-[400]">
              Students will develop the ability to lead yoga classes
              effectively, with confidence, and in a manner that fosters a
              positive and supportive environment for students. This includes
              learning how to cue poses, offer adjustments, and sequence classes
              in a cohesive and safe manner.
            </span>
          </li>
          <li className="flex flex-col relative before:content-[''] before:absolute before:left-0 before:w-[30px] pl-[54px] before:h-[30px] before:bg-checkmark-bg before:bg-no-repeat">
            Yoga Philosophy
            <span className="mt-4 text-[#5C616E] text-[18px] md:text-[20px] leading-10 font-[400]">
              Participants will delve into the philosophical foundations of
              yoga, exploring ancient texts such as the Yoga Sutras of Patanjali
              and the Bhagavad Gita. They will gain an understanding of key
              concepts such as the Eight Limbs of Yoga, the Yamas and Niyamas
              (ethical guidelines), and the concept of self-realization (Atman).
            </span>
          </li>
          <li className="flex flex-col relative before:content-[''] before:absolute before:left-0 before:w-[30px] pl-[54px] before:h-[30px] before:bg-checkmark-bg before:bg-no-repeat">
            Asana Practice
            <span className="mt-4 text-[#5C616E] text-[18px] md:text-[20px] leading-10 font-[400]">
              Students will master a variety of yoga asanas, including standing,
              seated, balancing, and inverted poses. They will learn proper
              alignment principles, modifications, and variations to accommodate
              different body types and levels of experience.
            </span>
          </li>
          <li className="flex flex-col relative before:content-[''] before:absolute before:left-0 before:w-[30px] pl-[54px] before:h-[30px] before:bg-checkmark-bg before:bg-no-repeat">
            Pranayama and Meditation
            <span className="mt-4 text-[#5C616E] text-[18px] md:text-[20px] leading-10 font-[400]">
              The program will cover various pranayama techniques (breathing
              exercises) aimed at cultivating awareness, calming the mind, and
              enhancing energy flow. Additionally, students will learn different
              meditation practices to promote mental clarity, concentration, and
              inner peace.
            </span>
          </li>
          <li className="flex flex-col relative before:content-[''] before:absolute before:left-0 before:w-[30px] pl-[54px] before:h-[30px] before:bg-checkmark-bg before:bg-no-repeat">
            Anatomy and Physiology
            <span className="mt-4 text-[#5C616E] text-[18px] md:text-[20px] leading-10 font-[400]">
              Understanding the human body is essential for safe and effective
              yoga practice. Students will learn about key anatomical structures
              and physiological principles relevant to yoga, such as the
              musculoskeletal system, respiratory system, and nervous system.
            </span>
          </li>

          <li className="flex flex-col relative before:content-[''] before:absolute before:left-0 before:w-[30px] pl-[54px] before:h-[30px] before:bg-checkmark-bg before:bg-no-repeat">
            Modifications and Adaptations
            <span className="mt-4 text-[#5C616E] text-[18px] md:text-[20px] leading-10 font-[400]">
              Teachers will learn how to change yoga practices to suit
              individuals with different abilities, limitations, and health
              conditions. This includes adapting poses, using props, and
              offering alternative movements to ensure inclusivity and
              accessibility in their classes.
            </span>
          </li>
          <li className="flex flex-col relative before:content-[''] before:absolute before:left-0 before:w-[30px] pl-[54px] before:h-[30px] before:bg-checkmark-bg before:bg-no-repeat">
            Ethical Guidelines and Professional Standards
            <span className="mt-4 text-[#5C616E] text-[18px] md:text-[20px] leading-10 font-[400]">
              Students will be introduced to the ethical responsibilities of
              being a yoga teacher, including respecting student boundaries,
              keeping confidentiality, and upholding the integrity of the
              practice. They will also learn about business practices, such as
              marketing, client communication, and studio management.
            </span>
          </li>

          <li className="flex flex-col relative before:content-[''] before:absolute before:left-0 before:w-[30px] pl-[54px] before:h-[30px] before:bg-checkmark-bg before:bg-no-repeat">
            Continued Learning and Growth
            <span className="mt-4 text-[#5C616E] text-[18px] md:text-[20px] leading-10 font-[400] flex flex-col gap-[10px]">
              <p>
                The training will emphasize the importance of lifelong learning
                and personal development as a yoga teacher. Graduates will be
                encouraged to continue their education through workshops,
                advanced training programs, and self-study to deepen their
                understanding and refine their teaching skills.
              </p>
              <p>
                The 200-hour Yoga Teacher Training Certificate Program provides
                a comprehensive foundation for aspiring yoga teachers, equipping
                them with the knowledge, skills, and confidence to share the
                transformative practice of yoga with others.
              </p>
            </span>
          </li>
        </ul>
      </div>
    </>
  );
};

export default CourseOutcomes;
