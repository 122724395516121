import React, { useEffect, useState } from "react";
import Blog1 from "../assets/images/Blog1.svg";
import Blog2 from "../assets/images/Blog2.svg";
import Blog3 from "../assets/images/Blog3.svg";
import Blog4 from "../assets/images/Blog4.svg";
import LatestPost from "../assets/images/LatestPost.svg";
import latestPostArrow from "../assets/images/latestPostArrow.svg";
import { useNavigate } from "react-router-dom";
import Pagination from "../components/Pagination";
import { getBlog, getRecentBlogs } from "../redux/actions/blog.action";
import { useDispatch, useSelector } from "react-redux";
import defaultBlog from "../assets/images/defaultBlog.webp";

function Blog() {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const navigateto = "";
  const blogdata = useSelector((state) => state.BlogReducer.getblog_data);
  const pageSize = useSelector((state) => state.BlogReducer.page_size);
  const current_page = useSelector((state) => state.BlogReducer.current_page);
  const [currentPage, setCurrentPage] = useState(current_page);
  const recentBlogsLimit = useSelector(
    (state) => state.BlogReducer.recent_blog_limit
  );
  const latestPosts = useSelector(
    (state) => state.BlogReducer.recent_blogs_data
  );

  //----------------------------------------------------------USE EFFECT FOR GET  Blog-----------------------------------------------------------------//
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getBlog({ pageSize, currentPage }));
    dispatch(getRecentBlogs(recentBlogsLimit));
  }, [dispatch, currentPage]);
  //----------------------------------------------------------USE EFFECT FOR GET  Blog-----------------------------------------------------------------//

  const blogdata1 = [
    {
      id: 1,
      blog_img: Blog1,
      date: "Mar 21,2024",
      type: "Business",
      title: "Should You Consider Medical Assisting as Your Next Career Move?",
      content:
        "Thinking about a career in healthcare? Medical assisting is a great option to consider! It offers a unique blend of challenge, reward, and opportunity. But is it the right fit for you?  Let’s explore some .........",
    },
    {
      id: 2,
      blog_img: Blog2,
      date: "Mar 21,2024",
      type: "Business",
      title: "Importance of Phlebotomy and Blood bank in patient care",
      content:
        "Phlebotomy and blood banks play indispensable roles in patient care, serving as vital components of modern healthcare systems. Phlebotomy, the practice of drawing blood for diagnostic testing........",
    },
    {
      id: 3,
      blog_img: Blog3,
      date: "Mar 21,2024",
      type: "Business",
      title: "The Licensed Massage Therapist: A Holistic wellness",
      content:
        "The Massage Therapy License Program in New Jersey stands as a cornerstone for individuals aspiring to embark on a fulfilling career in holistic wellness. With its comprehensive curriculum and stringent........",
    },
    {
      id: 4,
      blog_img: Blog4,
      date: "Mar 21,2024",
      type: "Business",
      title:
        "Navigating the Path: Exploring Medical Billing and Coding Careers",
      content:
        "In the realm of healthcare administration, Medical Billing and Coding Specialist (MBCS) professionals serve as the essential, ensuring the seamless flow of financial transactions and accurate........",
    },
  ];
  const latestPost = [
    {
      img: LatestPost,
      title: "Should You Consider Medical Assisting as Your Next Career Move?",
    },
    {
      img: LatestPost,
      title:
        "Navigating the Path: Exploring Medical Billing and Coding Careers",
    },
    {
      img: LatestPost,
      title:
        "Healthcare Certifications: Boost Your Career and Elevate Patient Care",
    },
    {
      img: LatestPost,
      title: "The Licensed Massage Therapist: A Holistic wellness",
    },
    {
      img: LatestPost,
      title: "The Pathways to success: A Private Vocational School",
    },
  ];
  const handleNavigate = (id) => {
    navigation(`/blogDetail/${id}`);
  };
  return (
    <div className=" w-full flex flex-col items-center font-poppins mb-28 gap-12 md:gap-20">
      <div className="bg-blogBg-bg w-full h-[400px] md:h-[600px] justify-center items-center flex flex-col gap-8 md:gap-12 ">
        <p className="font-[800] text-[32px] md:text-[50px] xl:text-[60px] w-[calc(100%_-_32px)] md:w-[calc(100%_-_64px)] text-white text-center">
          Welcome to Our Blog
        </p>
        <div className=" flex justify-center gap-4 md:gap-8 flex-col md:flex-row">
          <button
            className="orangeButton"
            onClick={() => navigation("../scheduleAppointment")}
          >
            Schedule a Visit
          </button>
          <button
            className="orangeButton"
            onClick={() => navigation("../requestInfo")}
          >
            Inquire Now
          </button>
          <button
            className="orangeButton"
            onClick={() => navigation("../enrollment")}
          >
            Enroll Now
          </button>
        </div>
      </div>
      <div className="w-[calc(100%_-_32px)] md:w-[calc(100%_-_64px)] xl:w-[90%] flex flex-col 991:flex-row gap-8 xl:gap-24 2xl:gap-[112px]">
        <div className="w-full flex gap-16 991:gap-20 flex-col">
          <div className="w-full gap-8  md:gap-5 flex-wrap grid grid-cols-1 1400:grid-cols-2">
            {blogdata?.data?.map((blog, index) => (
              <div
                id="Card"
                className="cursor-pointer drop-shadow-md bg-white items-center flex flex-col"
                onClick={() => {
                  navigation(`/blogDetail/${blog?.id}`);
                }}
              >
                <div className="w-full">
                  <img
                    src={
                      blog.blog_img.includes("undefined")
                        ? defaultBlog
                        : blog.blog_img
                    }
                    className="w-full h-[300px] object-cover"
                  />
                </div>
                <div className="flex flex-col gap-2 p-4 w-full">
                  <p className="font-[500] text-[16px] md:text-[18px] text-[#5C616E]">
                    {blog.categoryname} |{" "}
                    <span className="font-[400] text-[14px]">
                      {" "}
                      {blog.published_date}
                    </span>
                  </p>
                  <p className="font-[500] text-[20px] md:text-[24px] text-primary hover:text-[#F37021] overflow-hidden text-ellipsis line-clamp-3">
                    {blog.blog_title}
                  </p>
                  <p className="font-[400] text-[14px] text-[#5C616E] overflow-hidden text-ellipsis line-clamp-4">
                    {blog.blog_content?.replace(/(<([^>]+)>)/gi, "")}
                  </p>
                </div>
              </div>
            ))}

            {/* {blogdata1.map((blog, index) => (
                            <div id="Card" className='  drop-shadow-md bg-white items-center flex flex-col' onClick={() => { navigation(`/blogDetail/${blog?.id}`); }}>
                                <div className='w-full'>
                                    <img src={blog.blog_img} className='w-full' />
                                </div>
                                <div className='flex flex-col gap-2 p-4'>
                                    <p className='font-[500] text-[16px] md:text-[18px] text-[#5C616E]'>{blog.type} | <span className='font-[400] text-[14px]'> {blog.date}</span></p>
                                    <p className='font-[500] text-[20px] md:text-[24px] text-primary hover:text-[#F37021] '>{blog.title}</p>
                                    <p className='font-[400] text-[14px] text-[#5C616E] '>{blog.content}</p>
                                </div>
                            </div>
                        ))} */}
          </div>
          <div className="w-full md:w-[90%] flex justify-center 991:justify-start">
            <div>
              <Pagination
                totalPages={blogdata?.total_pages}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </div>
          </div>
        </div>
        <div className="">
          <p className="font-[600] text-[32px] text-[#F37021] underline mt-14 991:mt-0 ">
            Latest Posts
          </p>
          <div className="flex flex-col gap-6 mt-[30px]">
            {latestPosts?.data?.map((post, index) => (
              <div className="w-full 991:w-[411px] 991:h-full items-start 991:items-center shadow-lg border border-[#EBEBEB] rounded-[5px] p-3 flex gap-8">
                {/* <img src={post.img} /> */}

                <img
                  src={post?.blog_img}
                  className="w-[100px] h-[100px] object-cover self-start"
                />

                <div className="flex flex-col gap-4">
                  <p className="font-[500] text-[18px] text-[#1B2336] overflow-hidden text-ellipsis line-clamp-2">
                    {post.blog_title}
                  </p>
                  <div className="flex gap-2 group items-center">
                    <p
                      className="font-[400] text-[14px] text-[#F37021] underline group-hover:text-[#003C9D]"
                      onClick={() => {
                        navigation(`/blogDetail/${post?.id}`);
                      }}
                    >
                      Read More
                    </p>
                    <svg
                      width="18"
                      height="12"
                      viewBox="0 0 18 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="fill-[#F37021] group-hover:fill-[#003C9D]  transform group-hover:translate-x-2 transition duration-150 ease-in-out"
                    >
                      <path d="M12.039 0.343018L10.628 1.76102L13.898 5.01602L0.292969 5.02902L0.294969 7.02902L13.863 7.01602L10.648 10.246L12.065 11.656L17.709 5.98602L12.039 0.343018Z" />
                    </svg>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Blog;
