import {
  ADD_ENROLLMENT_VERIFICATION,
  ADD_ENROLLMENT_VERIFICATION_SUCCESS,
  ADD_ENROLLMENT_VERIFICATION_FAILURE,
  ADD_LEAVE_ABSENCE,
  ADD_LEAVE_ABSENCE_SUCCESS,
  ADD_LEAVE_ABSENCE_FAILURE,
  GET_WITHDRAWAL_REASONS,
  GET_WITHDRAWAL_REASONS_SUCCESS,
  GET_WITHDRAWAL_REASONS_FAILURE,
  ADD_ADMISSION_WITHDRAWAL,
  ADD_ADMISSION_WITHDRAWAL_SUCCESS,
  ADD_ADMISSION_WITHDRAWAL_FAILURE,
  ADD_TRANSCRIPT,
  ADD_TRANSCRIPT_SUCCESS,
  ADD_TRANSCRIPT_FAILURE,
  ADD_CERTIFICATE,
  ADD_CERTIFICATE_SUCCESS,
  ADD_CERTIFICATE_FAILURE,
  ADD_ENROLLMENT,
  ADD_ENROLLMENT_SUCCESS,
  ADD_ENROLLMENT_FAILURE,
  GET_HOLIDAYS,
  GET_HOLIDAYS_SUCCESS,
  GET_HOLIDAYS_FAILURE,
  ADD_GRIEVANCE,
  ADD_GRIEVANCE_SUCCESS,
  ADD_GRIEVANCE_FAILURE,
} from "../ActionTypes";
const initialState = {
  addEnrollmentVerificationData: null,
  addEnrollmentVerificationLoader: null,
  addEnrollmentVerificationError: null,
  addLeaveAbsenceData: null,
  addLeaveAbsenceLoader: null,
  addLeaveAbsenceError: null,
  withdrawalReasonsData: null,
  withdrawalReasonsLoader: null,
  withdrawalReasonsError: null,
  addAdmissionWithdrawalData: null,
  addAdmissionWithdrawalLoader: null,
  addAdmissionWithdrawalError: null,
  addTranscriptData: null,
  addTranscriptLoader: null,
  addTranscriptError: null,
  addCertificateData: null,
  addCertificateLoader: null,
  addCertificateError: null,
  addEnrollmentData: null,
  addEnrollmentLoader: null,
  addEnrollmentError: null,
  getHolidayData: null,
  getHolidayLoader: null,
  getHolidayError: null,
  addGrievanceData: null,
  addGrievanceLoader: null,
  addGrievanceError: null,
};
const studentServicesReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_ENROLLMENT_VERIFICATION:
      return { ...state, addEnrollmentVerificationLoader: true };
    case ADD_ENROLLMENT_VERIFICATION_SUCCESS:
      return {
        ...state,
        addEnrollmentVerificationLoader: false,
        addEnrollmentVerificationData: action?.payload,
      };
    case ADD_ENROLLMENT_VERIFICATION_FAILURE:
      return {
        ...state,
        addEnrollmentVerificationLoader: false,
        addEnrollmentVerificationError: action?.payload,
      };
    case ADD_LEAVE_ABSENCE:
      return { ...state, addLeaveAbsenceLoader: true };
    case ADD_LEAVE_ABSENCE_SUCCESS:
      return {
        ...state,
        addLeaveAbsenceLoader: false,
        addLeaveAbsenceData: action?.payload,
      };
    case ADD_LEAVE_ABSENCE_FAILURE:
      return {
        ...state,
        addLeaveAbsenceLoader: false,
        addLeaveAbsenceError: action?.payload,
      };

    case GET_WITHDRAWAL_REASONS:
      return { ...state, withdrawalReasonsLoader: true };
    case GET_WITHDRAWAL_REASONS_SUCCESS:
      return {
        ...state,
        withdrawalReasonsLoader: false,
        withdrawalReasonsData: action?.payload,
      };
    case GET_WITHDRAWAL_REASONS_FAILURE:
      return {
        ...state,
        withdrawalReasonsLoader: false,
        addLeaveAbsenceError: action?.payload,
      };

    case ADD_ADMISSION_WITHDRAWAL:
      return {
        ...state,
        addAdmissionWithdrawalLoader: true,
        addAdmissionWithdrawalError: null,
      };
    case ADD_ADMISSION_WITHDRAWAL_SUCCESS:
      return {
        ...state,
        addAdmissionWithdrawalLoader: false,
        addAdmissionWithdrawalData: action?.payload,
      };
    case ADD_ADMISSION_WITHDRAWAL_FAILURE:
      return {
        ...state,
        addAdmissionWithdrawalLoader: false,
        addAdmissionWithdrawalError: action?.payload,
      };

    case ADD_TRANSCRIPT:
      return {
        ...state,
        addTranscriptLoader: true,
        addTranscriptError: null,
      };
    case ADD_TRANSCRIPT_SUCCESS:
      return {
        ...state,
        addTranscriptLoader: false,
        addTranscriptData: action?.payload,
      };
    case ADD_TRANSCRIPT_FAILURE:
      return {
        ...state,
        addTranscriptLoader: false,
        addTranscriptError: action?.payload,
      };

    case ADD_CERTIFICATE:
      return {
        ...state,
        addCertificateLoader: true,
        addCertificateError: null,
      };
    case ADD_CERTIFICATE_SUCCESS:
      return {
        ...state,
        addCertificateLoader: false,
        addCertificateData: action?.payload,
      };
    case ADD_CERTIFICATE_FAILURE:
      return {
        ...state,
        addCertificateLoader: false,
        addCertificateError: action?.payload,
      };

    case ADD_ENROLLMENT:
      return {
        ...state,
        addEnrollmentLoader: true,
        addEnrollmentError: null,
      };
    case ADD_ENROLLMENT_SUCCESS:
      return {
        ...state,
        addEnrollmentLoader: false,
        addEnrollmentData: action?.payload,
      };
    case ADD_ENROLLMENT_FAILURE:
      return {
        ...state,
        addEnrollmentLoader: false,
        addEnrollmentError: action?.payload,
      };

    case GET_HOLIDAYS:
      return { ...state, getHolidayLoader: true };
    case GET_HOLIDAYS_SUCCESS:
      return {
        ...state,
        getHolidayLoader: false,
        getHolidayData: action?.payload,
      };
    case GET_HOLIDAYS_FAILURE:
      return {
        ...state,
        getHolidayLoader: false,
        getHolidayError: action?.payload,
      };
    case ADD_GRIEVANCE:
      return {
        ...state,
        addGrievanceLoader: true,
        addGrievanceError: null,
      };
    case ADD_GRIEVANCE_SUCCESS:
      return {
        ...state,
        addGrievanceLoader: false,
        addGrievanceData: action?.payload,
      };
    case ADD_GRIEVANCE_FAILURE:
      return {
        ...state,
        addGrievanceLoader: false,
        addGrievanceError: action?.payload,
      };

    default:
      return state;
  }
};

export default studentServicesReducer;
