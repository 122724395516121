import React from "react";

const CourseOutcomes = () => {
  return (
    <>
      <div>
        <ul className=" text-[#F37021] font-[500] text-[20px] md:text-[24px] leading-[30px] flex flex-col gap-6">
          <li className="flex flex-col relative before:content-[''] before:bg-contain before:absolute before:left-0 before:top-[2px] before:md:top-0 before:w-[22px] before:md:w-[30px] pl-[54px] before:h-[22px] before:md:h-[30px] before:bg-checkmark-bg before:bg-no-repeat">
            Affordable Training Program
            <span className="mt-4 text-[#5C616E] text-[18px] md:text-[20px] leading-10 font-[400]">
              A nursing program at a NJ college can be expensive and take several years to complete. Whereas becoming an
              HHA is affordable and training can be completed quickly allowing you to get to work faster.
            </span>
          </li>
          <li className="flex flex-col relative before:content-[''] before:bg-contain before:absolute before:left-0 before:top-[2px] before:md:top-0 before:w-[22px] before:md:w-[30px] pl-[54px] before:h-[22px] before:md:h-[30px] before:bg-checkmark-bg before:bg-no-repeat">
            Caregivers are in High Demand
            <span className="mt-4 text-[#5C616E] text-[18px] md:text-[20px] leading-10 font-[400]">
              The need for nursing care to help our New Jersey seniors is continuously on the rise as a large number of
              our population continues to age. Resulting in many opportunities to find employment as a home health aide.
            </span>
          </li>
          <li className="flex flex-col relative before:content-[''] before:bg-contain before:absolute before:left-0 before:top-[2px] before:md:top-0 before:w-[22px] before:md:w-[30px] pl-[54px] before:h-[22px] before:md:h-[30px] before:bg-checkmark-bg before:bg-no-repeat">
            Flexible Work Schedule
            <span className="mt-4 text-[#5C616E] text-[18px] md:text-[20px] leading-10 font-[400]">
              Because of the growing demand for quality aides in NJ and the desire for people to age in their own homes,
              caregivers are being offered tremendous flexibility in scheduling. This allows caregivers to schedule work
              within their available hours.
            </span>
          </li>
          <li className="flex flex-col relative before:content-[''] before:bg-contain before:absolute before:left-0 before:top-[2px] before:md:top-0 before:w-[22px] before:md:w-[30px] pl-[54px] before:h-[22px] before:md:h-[30px] before:bg-checkmark-bg before:bg-no-repeat">
            Job Security
            <span className="mt-4 text-[#5C616E] text-[18px] md:text-[20px] leading-10 font-[400]">
              Since there is so much demand for home health aides, those who make the effort to get certified have great
              job security.
            </span>
          </li>
          <li className="flex flex-col relative before:content-[''] before:bg-contain before:absolute before:left-0 before:top-[2px] before:md:top-0 before:w-[22px] before:md:w-[30px] pl-[54px] before:h-[22px] before:md:h-[30px] before:bg-checkmark-bg before:bg-no-repeat">
            Resume Builder
            <span className="mt-4 text-[#5C616E] text-[18px] md:text-[20px] leading-10 font-[400]">
              Being an HHA is an excellent way to learn new skills and grow your resume. The skills you learn and use as
              an HHA are not only applicable to your job, they are transferable to other careers in the medical field as
              well as things you can take with you for the rest of your life!
            </span>
          </li>
        </ul>
      </div>
    </>
  );
};

export default CourseOutcomes;
