import React from "react";

const CourseOutcomes = () => {
  return (
    <>
      <div>
        <p className="font-[500] text-[20px] md:text-[24px] xl:text-[32px] leading-[36px] xl:leading-[48px] text-[#003C9D]">
          Upon the completion of the Yoga Teacher Training Certificate Program
          the student will be able to
        </p>
        <p className="text-[18px] md:text-[20px] leading-[40px] mt-5 md:mt-10 text-[#5C616E]">
          Upon completing the 300-hour Yoga Teacher Training Certificate
          Program, students will gain the following abilities:
        </p>
        <ul className="mt-6 text-[#F37021] font-[500] text-[20px] md:text-[24px] leading-[30px] flex flex-col gap-6">
          <li className="flex flex-col relative before:content-[''] before:absolute before:left-0 before:w-[30px] pl-[54px] before:h-[30px] before:bg-checkmark-bg before:bg-no-repeat">
            Enhanced Mastery of Teaching
            <span className="mt-4 text-[#5C616E] text-[18px] md:text-[20px] leading-10 font-[400]">
              Graduates will have refined their teaching skills to confidently
              lead advanced yoga classes, incorporating intricate sequences and
              advanced techniques.
            </span>
          </li>
          <li className="flex flex-col relative before:content-[''] before:absolute before:left-0 before:w-[30px] pl-[54px] before:h-[30px] before:bg-checkmark-bg before:bg-no-repeat">
            Deepened Understanding of Yoga Philosophy
            <span className="mt-4 text-[#5C616E] text-[18px] md:text-[20px] leading-10 font-[400]">
              Students will have a comprehensive understanding of yoga
              philosophy, including advanced concepts from ancient texts such as
              the Yoga Sutras and the Bhagavad Gita.
            </span>
          </li>
          <li className="flex flex-col relative before:content-[''] before:absolute before:left-0 before:w-[30px] pl-[54px] before:h-[30px] before:bg-checkmark-bg before:bg-no-repeat">
            Advanced Asana Practice
            <span className="mt-4 text-[#5C616E] text-[18px] md:text-[20px] leading-10 font-[400]">
              Participants will have mastered advanced yoga poses (asanas),
              including inversions, arm balances, and complex backbends, with a
              focus on alignment and safety.
            </span>
          </li>
          <li className="flex flex-col relative before:content-[''] before:absolute before:left-0 before:w-[30px] pl-[54px] before:h-[30px] before:bg-checkmark-bg before:bg-no-repeat">
            Proficiency in Pranayama and Meditation
            <span className="mt-4 text-[#5C616E] text-[18px] md:text-[20px] leading-10 font-[400]">
              Graduates will be adept at teaching advanced pranayama techniques
              and guiding students in deeper meditation practices for enhanced
              mental clarity and spiritual growth.
            </span>
          </li>
          <li className="flex flex-col relative before:content-[''] before:absolute before:left-0 before:w-[30px] pl-[54px] before:h-[30px] before:bg-checkmark-bg before:bg-no-repeat">
            Advanced Anatomy and Biomechanics
            <span className="mt-4 text-[#5C616E] text-[18px] md:text-[20px] leading-10 font-[400]">
              Students will possess an in-depth knowledge of anatomy and
              biomechanics as they relate to advanced yoga poses, injury
              prevention, and alignment adjustments.
            </span>
          </li>

          <li className="flex flex-col relative before:content-[''] before:absolute before:left-0 before:w-[30px] pl-[54px] before:h-[30px] before:bg-checkmark-bg before:bg-no-repeat">
            Specialization in Yogic Studies
            <span className="mt-4 text-[#5C616E] text-[18px] md:text-[20px] leading-10 font-[400]">
              The program offers opportunities for students to specialize in
              specific areas of interest within yoga, such as therapeutic yoga,
              yoga for athletes, or yoga philosophy.
            </span>
          </li>
          <li className="flex flex-col relative before:content-[''] before:absolute before:left-0 before:w-[30px] pl-[54px] before:h-[30px] before:bg-checkmark-bg before:bg-no-repeat">
            Advanced Teaching Methodology
            <span className="mt-4 text-[#5C616E] text-[18px] md:text-[20px] leading-10 font-[400]">
              Graduates will be equipped with advanced teaching methodologies,
              including hands-on adjustments, sequencing for specific
              populations, and adapting practices for individual needs.
            </span>
          </li>

          <li className="flex flex-col relative before:content-[''] before:absolute before:left-0 before:w-[30px] pl-[54px] before:h-[30px] before:bg-checkmark-bg before:bg-no-repeat">
            Continued Personal and Professional Growth
            <span className="mt-4 text-[#5C616E] text-[18px] md:text-[20px] leading-10 font-[400]">
              <p>
                The program fosters ongoing personal and professional
                development, empowering graduates to continue deepening their
                practice, expanding their knowledge, and evolving as yoga
                teachers.
              </p>
            </span>
          </li>
        </ul>
      </div>
    </>
  );
};

export default CourseOutcomes;
