import React from "react";
import Professionalism from "../../assets/images/Professionalism.svg";
import Respect from "../../assets/images/Respect.svg";
import Diversity from "../../assets/images/Diversity.svg";
import Excellence from "../../assets/images/Excellence.svg";
import Cooperation from "../../assets/images/Cooperation.svg";
import { useNavigate } from "react-router-dom";

function SchoolObjectives() {
  const navigation = useNavigate();
  return (
    <div className=" w-full flex flex-col items-center font-poppins mb-28">
      <div className="bg-schoolobjective-bg w-full h-[400px] md:h-[600px] justify-center items-center flex flex-col gap-8 md:gap-12 ">
        <p className="font-[800] text-[32px] md:text-[50px] xl:text-[60px] text-white w-[calc(100%_-_32px)] md:w-[calc(100%_-_64px)] text-center">
          School Objectives & Core Values
        </p>
        <div className=" flex justify-center gap-4 md:gap-8 flex-col md:flex-row">
          <button
            className="orangeButton"
            onClick={() => navigation("../scheduleAppointment")}
          >
            Schedule a Visit
          </button>
          <button
            className="orangeButton"
            onClick={() => navigation("../requestInfo")}
          >
            Inquire Now
          </button>
          <button
            className="orangeButton"
            onClick={() => navigation("../enrollment")}
          >
            Enroll Now
          </button>
        </div>
      </div>
      <div className="w-[calc(100%_-_32px)] md:w-[calc(100%_-_64px)] xl:w-[90%] mt-12 md:mt-24">
        <p className="font-[500] text-[24px] md:text-[32px] text-[#003C9D]">
          Our Values
        </p>
        <div className="gap-4 xl:gap-10 flex-wrap justify-center mt-10 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3">
          <div className=" bg-white shadow-lg p-[40px] flex flex-col gap-6 ">
            <div>
              <img
                src={Professionalism}
                className="h-[60px] w-[55px] md:w-auto md:h-auto"
              />
            </div>
            <p className="font-[500] text-[20px] md:text-[24px] text-[#1B2336]">
              Professionalism
            </p>
            <p className="font-[400] text-[18px] md:text-[20px] text-[#5C616E] leading-9">
              Integrity, honest and accountability are core values of our staff.
              We established positive connection to being flexible, proactive
              and trustworthy.
            </p>
          </div>
          <div className=" bg-white shadow-lg p-[40px] flex flex-col gap-6 ">
            <div>
              <img
                src={Respect}
                className="h-[60px] w-[55px] md:w-auto md:h-auto"
              />
            </div>
            <p className="font-[500] text-[20px] md:text-[24px] text-[#1B2336]">
              Respect
            </p>
            <p className="font-[400] text-[18px] md:text-[20px] text-[#5C616E] leading-9">
              We respect all people and treat them with unbiased approach and
              dignity.
            </p>
          </div>
          <div className=" bg-white shadow-lg p-[40px] flex flex-col gap-6">
            <div>
              <img
                src={Diversity}
                className="h-[60px] w-[55px] md:w-auto md:h-auto"
              />
            </div>
            <p className="font-[500] text-[20px] md:text-[24px] text-[#1B2336]">
              Diversity
            </p>
            <p className="font-[400] text-[18px] md:text-[20px] text-[#5C616E] leading-9">
              We believe in diversity to improve administration, teaching and
              services.
            </p>
          </div>
          <div className=" bg-white shadow-lg p-[40px] flex flex-col gap-6">
            <div>
              <img
                src={Excellence}
                className="h-[60px] w-[55px] md:w-auto md:h-auto"
              />
            </div>
            <p className="font-[500] text-[20px] md:text-[24px] text-[#1B2336]">
              Excellence
            </p>
            <p className="font-[400] text-[18px] md:text-[20px] text-[#5C616E] leading-9">
              Developing organization culture of continuous improvement,
              innovative, well- planning and excellent execution.
            </p>
          </div>
          <div className=" bg-white shadow-lg p-[40px] flex flex-col gap-6">
            <div>
              <img
                src={Cooperation}
                className="h-[60px] w-[55px] md:w-auto md:h-auto"
              />
            </div>
            <p className="font-[500] text-[20px] md:text-[24px] text-[#1B2336]">
              Cooperation
            </p>
            <p className="font-[400] text-[18px] md:text-[20px] text-[#5C616E] leading-9">
              We believe in teamwork, cooperation and collaboration to achieve
              individual goals.
            </p>
          </div>
        </div>
      </div>
      <div className="w-[calc(100%_-_32px)] md:w-[calc(100%_-_64px)] xl:w-[90%] mt-[60px] md:mt-20 flex flex-col gap-8 ">
        <p className="font-[500] text-[24px] md:text-[32px] text-[#003C9D]">
          Institutional Learning Objectives
        </p>
        <ul className="list-disc font-[400] text-[18px] md:text-[20px] text-[#5C616E] flex  flex-col gap-6  ml-10">
          <li>
            <span className="font-[500] text-[20px]text-[18px] md:text-[20px] text-[#F37021]">
              Param Institute of Education
            </span>{" "}
            is committed to preparing its graduates for a world of increasing
            complexity, innovation, change and opportunities.
          </li>
          <li>
            Learn the skills necessary to function as a member of the healthcare
            team in an ambulatory setting.
          </li>
          <li>
            Know the standards for ethical behavior, therapeutic communication,
            protecting the privacy of patient information.
          </li>
          <li>
            Understand the importance of a first impression and a safe office
            environment.
          </li>
          <li>
            Prepare to take and earn the Certifications from the
            Accredited/certified body such as American Medical Certification
            Associations and/or National Health career Association.
          </li>
        </ul>
      </div>
      <div className="w-[calc(100%_-_32px)] md:w-[calc(100%_-_64px)] xl:w-[90%] mt-12 md:mt-20 flex flex-col gap-8 ">
        <p className="font-[500] text-[24px] md:text-[32px] text-[#003C9D]">
          Our School’s Key Objectives
        </p>
        <ul className="list-disc font-[400] text-[18px] md:text-[20px] text-[#5C616E] flex  flex-col gap-6  ml-10">
          <li>
            Our efforts will remain focused to achieve uncompromising success in
            imparting knowledge and professional skills to our candidates and to
            ensure that they obtain excellent career
          </li>
          <li>
            Respect is at the center of School’s core values reflected in the
            professional team and students, in programs and results.
          </li>
          <li>
            School is dedicated to the evolution of education whether
            facilitated in synchronous, asynchronous, residential, or blended
            learning environments.
          </li>
          <li>
            Our school is student-oriented which focuses on learning
            preferences, reinforcement loops and feedback enhancing the student
            experience.
          </li>
          <li>
            PIE’s committed administrative focus provides students with a
            pathway from admissions through classroom learning to productive
            employment.
          </li>
          <li>
            PIE is dedicated to assisting students for their career goal and
            improve their  skills with education development.
          </li>
        </ul>
      </div>
    </div>
  );
}

export default SchoolObjectives;
