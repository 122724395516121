import React from "react";

const Faqs = () => {
  return (
    <>
      <div>
        <p className="font-[500] text-[20px] md:text-[24px] xl:text-[32px] leading-[36px] xl:leading-[48px] text-[#003C9D]">
          300 Hours Yoga Teacher Training Certificate (Hybrid) Overview FAQ
        </p>
        <div className="mt-5 md:mt-10 leading-[30px] text-[18px] md:text-[20px]">
          <ul className="list-disc flex flex-col gap-6 ml-5 font-[500]">
            <li>
              What is Hybrid Programs?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  All hybrid programs will allow you to choose in-person and/or
                   online instruction. Lectures may be offered at the  School
                  location or remotely via a Web Conferencing Platform.
                  Laboratory/ clinical classes and practical training will be
                  held on at School site or at a clinical site. Synchronous
                  (Online synchronous classes) instruction is real-time
                  instruction that requires students to attend classes at
                  scheduled times.
                </p>
              </div>
            </li>
            <li className="">
              What does a Yoga Teacher do with 300-Hours Yoga Teacher Training
              Certification?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  A Yoga Teacher with a 300-hour certification typically
                  possesses advanced knowledge, skills, and teaching abilities
                  compared to someone with a 200-hour certification. Here's what
                  a Yoga Teacher with a 300-hour certification might do:
                </p>

                <ul className="list-disc marker:text-[#003C9D]  leading-[35px] ml-8 flex flex-col gap-[10px]">
                  <li>
                    <span className="text-[#003C9D]">
                      Teach Advanced Yoga Classes:{" "}
                    </span>{" "}
                    They lead advanced-level yoga classes, incorporating complex
                    asanas, advanced sequencing, pranayama techniques, and
                    meditation practices.
                  </li>
                  <li>
                    <span className="text-[#003C9D]">
                      Specialize in Specific Areas:{" "}
                    </span>{" "}
                    They may specialize in particular styles of yoga (such as
                    Vinyasa, Yin, or Ashtanga), or they may focus on teaching
                    specific populations, such as athletes, seniors, pregnant
                    women, or individuals with injuries or health conditions.
                  </li>

                  <li>
                    <span className="text-[#003C9D]">
                      Offer Workshops and Retreats:{" "}
                    </span>{" "}
                    They organize, and lead workshops, retreats, and specialized
                    training programs focused on advanced yoga practices,
                    meditation, mindfulness, or yoga philosophy.
                  </li>

                  <li>
                    <span className="text-[#003C9D]">
                      Provide Private Instruction:{" "}
                    </span>{" "}
                    They offer one-on-one or small group sessions tailored to
                    individual needs, goals, and abilities, providing
                    personalized instruction, alignment corrections, and
                    modifications, as necessary.
                  </li>

                  <li>
                    <span className="text-[#003C9D]">
                      Continued Education and Training:{" "}
                    </span>{" "}
                    They engage in ongoing education and training to deepen
                    their knowledge, refine their teaching skills, and stay
                    updated on developments in the field of yoga and wellness.
                  </li>

                  <li>
                    <span className="text-[#003C9D]">
                      Mentor Other Teachers:{" "}
                    </span>{" "}
                    They may serve as mentors or faculty members in yoga teacher
                    training programs, sharing their expertise, providing
                    guidance, and supporting the growth and development of
                    aspiring yoga teachers.
                  </li>

                  <li>
                    <span className="text-[#003C9D]">
                      Contribute to the Yoga Community:
                    </span>{" "}
                    They contribute to the yoga community by participating in
                    events, conferences, and gatherings, sharing their knowledge
                    and experiences, and fostering connections within the yoga
                    community.
                  </li>

                  <li>
                    <span className="text-[#003C9D]">
                      Maintain Ethical and Professional Standards:
                    </span>{" "}
                    They uphold ethical and professional standards in their
                    teaching practice, maintaining boundaries, respecting
                    student confidentiality, and adhering to the principles of
                    integrity and authenticity.
                  </li>
                </ul>
                <p>
                  A Yoga Teacher with a 300-hour certification plays a
                  significant role in guiding students on their yoga journey,
                  sharing advanced practices, deepening their understanding of
                  yoga philosophy, and inspiring personal growth and
                  transformation.
                </p>
              </div>
            </li>

            <li>
              What is the Yoga Teacher Training Program (300 Hrs.)?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  The Yoga Teacher Training Program (300 Hrs.) is an advanced
                  training program designed for yoga practitioners who have
                  already completed a 200-hour yoga teacher training
                  certification. This program builds upon the foundational
                  knowledge and skills acquired in the 200-hour training,
                  providing a deeper exploration of yoga philosophy, advanced
                  asana (poses), teaching methodology, anatomy, and other
                  related subjects.
                </p>
                <p>
                  Key features of a 300-hour Yoga Teacher Training Program
                  typically include:
                </p>
                <ul className="list-disc marker:text-[#003C9D]  leading-[35px] ml-8 flex flex-col gap-[10px]">
                  <li>
                    <span className="text-[#003C9D]">
                      Advanced Curriculum:{" "}
                    </span>{" "}
                    The program offers a comprehensive curriculum covering
                    advanced topics in yoga philosophy, anatomy, biomechanics,
                    pranayama (breathwork), meditation, and teaching
                    methodology. Students delve deeper into the principles and
                    practices of yoga to enhance their understanding and
                    teaching abilities.
                  </li>
                  <li>
                    <span className="text-[#003C9D]">
                      Specialization Options:{" "}
                    </span>{" "}
                    Participants may have the opportunity to specialize in
                    specific areas of interest within the field of yoga, such as
                    therapeutic yoga, prenatal yoga, yoga for athletes, or
                    advanced asana practices like inversions or arm balances.
                  </li>
                  <li>
                    <span className="text-[#003C9D]">
                      Practical Teaching Experience:{" "}
                    </span>{" "}
                    Students gain hands-on teaching experience through practicum
                    sessions, where they lead classes under the guidance of
                    experienced instructors. This allows them to refine their
                    teaching skills, receive feedback, and build confidence in
                    their ability to lead advanced yoga classes.
                  </li>
                  <li>
                    <span className="text-[#003C9D]">
                      In-depth Study of Yoga Philosophy:{" "}
                    </span>{" "}
                    The program includes a deeper exploration of yoga
                    philosophy, including the study of ancient texts such as the
                    Yoga Sutras of Patanjali, the Bhagavad Gita, and other
                    philosophical teachings. Students examine the philosophical
                    underpinnings of yoga and learn how to integrate these
                    teachings into their practice and teaching.
                  </li>
                  <li>
                    <span className="text-[#003C9D]">
                      Advanced Anatomy and Biomechanics:{" "}
                    </span>{" "}
                    Participants study advanced concepts in anatomy and
                    biomechanics as they relate to yoga practice and teaching.
                    This includes understanding how the body moves in different
                    yoga poses, how to prevent injuries, and how to modify poses
                    for different body types and abilities.
                  </li>
                  <li>
                    <span className="text-[#003C9D]">
                      Ethics and Professionalism:{" "}
                    </span>{" "}
                    Students learn about the ethical responsibilities of yoga
                    teachers, including maintaining professional boundaries,
                    respecting student confidentiality, and upholding the
                    integrity of the practice. They also learn about the
                    business aspects of teaching yoga, such as marketing, studio
                    management, and legal considerations.
                  </li>
                  <li>
                    <span className="text-[#003C9D]">
                      Continued Personal Development:{" "}
                    </span>{" "}
                    The program fosters personal growth and self-discovery,
                    encouraging students to deepen their own practice, cultivate
                    self-awareness, and explore their unique teaching style and
                    voice.
                    <p>
                      The Yoga Teacher Training Program (300 Hrs.) offers an
                      advanced level of study and practice for yoga
                      practitioners who are committed to deepening their
                      knowledge, refining their teaching skills, and continuing
                      their journey as lifelong students of yoga.
                    </p>
                  </li>
                </ul>
              </div>
            </li>

            <li>
              For whom is the 300-Hrs. Yoga Teacher Training program suitable?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  The 300-hour Yoga Teacher Training program is suitable for a
                  diverse range of individuals who have already completed a
                  200-hour yoga teacher training certification and wish to
                  further deepen their knowledge, skills, and teaching abilities
                  in yoga. Here are some specific groups for whom the 300-hour
                  program may be particularly beneficial: Certified Yoga
                  Teachers, Yoga Practitioners, Career Advancers: Yoga
                  professionals who are looking to advance their careers in the
                  field of yoga and wellness. The 300-hour certification can
                  enhance their credibility, open up new career opportunities,
                  and allow them to specialize in specific areas of interest
                  within the yoga community, Healthcare Professionals,
                  Continuing Education Seekers, Specialized Instructors: Yoga
                  teachers who want to specialize in specific areas of yoga,
                  such as prenatal yoga, yoga for seniors, restorative yoga,
                  trauma-informed yoga, or yoga therapy, can benefit from
                  advanced training and certification in these specialized
                  fields, Yoga Enthusiasts.
                </p>
                <p>
                  The 300-hour Yoga Teacher Training program is suitable for
                  individuals who are committed to advancing their knowledge,
                  skills, and teaching abilities in yoga and are ready to embark
                  on a deeper journey of personal and professional development
                  within the field of yoga and wellness.
                </p>
              </div>
            </li>

            <li>
              What are the prerequisites for enrolling in the Yoga Teacher
              Training (300 Hrs.) program?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  Eligible candidates must have successfully completed the
                  200-Hours Yoga Teacher Training Certification program that
                  Param Institute of Education offers or its equivalent before
                  applying.
                </p>
              </div>
            </li>

            <li>
              What topics are covered in the Yoga Teacher Training (300 Hrs.)
              curriculum?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  Topics will include foundation on sacred yogic spaces, heart
                  of sacred yogic spaces, wisdom of sacred yogic spaces.
                  Understand topics include advance asana 1 &2, refining & your
                  personal practice, mentoring for mixed levels of experience,
                  teaching inner practice, advance sacred life 1 &2, teacher
                  tools, and practicum. strategic life planning, learning how to
                  manifest sankalpa, yoga as a path to life coaching, Ayurveda
                  spring cleaning or Ayurveda Fall Rejuvenation, working with
                  Therapeutic Modifications or Holistic Approach to Healing,
                  Structural Yoga Assessment & Practice Protocols, Body of Sound
                  & Consciousness, Advanced Anatomy & Physiology.
                </p>
              </div>
            </li>

            <li>
              What learning formats are available for the Yoga Teacher Training
              (300 Hrs.)  program?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  Yoga Teacher Training (300 Hrs.) program may be offered in
                  various formats, including in-person classes, hybrid formats
                  combining both online and in-person learning, and self-paced
                  study options.
                </p>
              </div>
            </li>

            <li>
              What are the career opportunities for graduates of the Yoga
              Teacher Training (300 Hrs.) program?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  Graduates of a Yoga Teacher Training (300-Hour) program are
                  equipped with advanced knowledge and skills, exploring various
                  career opportunities in the field of yoga and wellness. Some
                  potential career paths for graduates include:
                </p>

                <ul className="list-disc marker:text-[#003C9D]  leading-[35px] ml-8 flex flex-col gap-[10px]">
                  <li>
                    <span className="text-[#003C9D]">
                      Advanced Yoga Instructor:{" "}
                    </span>
                    With their enhanced training, graduates can teach
                    advanced-level yoga classes at studios, gyms, retreat
                    centers, and other venues, offering specialized instruction
                    in advanced asana, pranayama, and meditation.
                  </li>
                  <li>
                    <span className="text-[#003C9D]">
                      Specialized Yoga Teacher:{" "}
                    </span>{" "}
                    Graduates may choose to specialize in specific areas of
                    yoga, such as prenatal yoga, therapeutic yoga, or yoga for
                    athletes, offering tailored instruction to meet the unique
                    needs of different populations.
                  </li>
                  <li>
                    <span className="text-[#003C9D]">Teacher Trainer: </span>{" "}
                    Experienced graduates can become teacher trainers
                    themselves, leading yoga teacher training programs and
                    sharing their expertise with aspiring instructors.
                  </li>
                  <li>
                    <span className="text-[#003C9D]">Yoga Therapist: </span>{" "}
                    With additional training in yoga therapy, graduates can
                    collaborate with individuals dealing with specific health
                    issues or injuries, using yoga practices to promote healing
                    and well-being.
                  </li>
                  <li>
                    <span className="text-[#003C9D]">
                      Workshop and Retreat Leader:{" "}
                    </span>{" "}
                    Graduates can organize and lead workshops, immersions, and
                    retreats focusing on advanced practices, specialized topics,
                    or transformative experiences.
                  </li>
                  <li>
                    <span className="text-[#003C9D]">
                      Corporate Wellness Consultant:{" "}
                    </span>{" "}
                    Graduates can collaborate with corporations to develop
                    workplace wellness programs, offering yoga classes,
                    mindfulness sessions, and stress management techniques to
                    support employee health and productivity.
                  </li>
                  <li>
                    <span className="text-[#003C9D]">
                      Online Yoga Entrepreneur:{" "}
                    </span>{" "}
                    Graduates can create and teach online yoga classes, courses,
                    or memberships, reaching a global audience and offering
                    accessible yoga instruction through digital platforms.
                  </li>
                  <li>
                    <span className="text-[#003C9D]">Community Leader: </span>{" "}
                    Graduates can become leaders in their local yoga
                    communities, organizing events, gatherings, and charity
                    fundraisers to foster connection and support among
                    practitioners.
                  </li>
                  <li>
                    <span className="text-[#003C9D]">
                      Holistic Health Practitioner:{" "}
                    </span>{" "}
                    Graduates may integrate yoga teaching with other holistic
                    health practices, such as Ayurveda, nutrition, or energy
                    healing, offering an integrated approach to wellness.
                  </li>
                  <li>
                    <span className="text-[#003C9D]">
                      Yoga Blogger/Influencer:{" "}
                    </span>{" "}
                    Graduates can share their knowledge and experiences with a
                    wider audience through blogs, social media, and other
                    platforms, inspiring others on their yoga journey and
                    building a personal brand within the yoga community.
                    <p>
                      These are examples of the diverse career opportunities
                      available to graduates of a Yoga Teacher Training
                      (300-Hour) program, allowing individuals to pursue paths
                      aligned with their interests, skills, and aspirations
                      within the yoga and wellness industry.
                    </p>
                  </li>
                </ul>
              </div>
            </li>

            <li>
              Is certification available upon completion of the Yoga Teacher
              Training (300 Hrs.) program?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  Yes, Yoga Teacher Training (300 Hrs.) program offers
                  certification from the Param Institute of Education upon
                  successful completion of the coursework and any required
                  assessments. Certification demonstrates proficiency in Yoga
                  principles and may enhance career opportunities.
                </p>
              </div>
            </li>

            <li>
              How long does it take to complete the Yoga Teacher Training (300
              Hrs.) program?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  The duration of the program may be completed in 14-15 weeks.
                </p>
              </div>
            </li>

            <li>
              When can I start the course?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  This course is open enrollment, so you can register and start
                  the course as soon as you are ready.
                </p>
              </div>
            </li>

            <li>
              What kind of support will I receive?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  During the course, you can reach out to your instructor via
                  email for any questions or feedback on your performance.
                  Occasionally, industry experts may also contribute to your
                  learning experience. You will have the support of a student
                  advising team available to assist you throughout your journey.
                </p>
              </div>
            </li>

            <li>
              What if I do not have enough time to complete my course within the
              time provided?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  The duration for completing the course has been determined
                  according to the total number of course hours. If you find
                  yourself unable to finish within the allotted time, you can
                  reach out to the student advising team to explore potential
                  options for extending your completion date. Please be aware
                  that there may be an extension fee associated with this.
                </p>
              </div>
            </li>

            <li>
              Can I be assured of employment after completing the program?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  This course equips you with the necessary skills for securing
                  a job position in many cases. We offer support with resume
                  preparation and job search strategies to aid you in finding
                  employment. Additionally, prospective students are advised to
                  research the job market in their area before enrolling.
                </p>
              </div>
            </li>

            <li>
              Can I get financial assistance?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  This course is non-credit, so it does not qualify for federal
                  aid, FAFSA and/or Pell Grant.
                </p>
              </div>
            </li>

            <li>
              How can I get more information about this course?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  If you have questions that are not answered on our website,
                  representatives are available via EMAIL (info@paramedu.com).
                  You can also call us at +1 848 256 2737 during regular
                  business hours to have your questions promptly answered. If
                  you are visiting us during non-business hours, please send us
                  a question using the "Contact Us" form.
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Faqs;
