import React from "react";
import caretDown from "../assets/images/caret-down.svg";
import caretUp from "../assets/images/caret-up.svg";
import { useNavigate } from "react-router-dom";
import { type } from "@testing-library/user-event/dist/type";

const accordionData = [
  {
    title: "Home",
    path: "./",
    type: "primary",
  },
  {
    title: "About Us",
    type: "primary",
    children: [
      { title: "Param Institute of Education History", path: "./historyPIE" },
      { title: "Approval & Accreditation", path: "./accreditation" },
      { title: "Mission and Vision", path: "./mission&vision" },
      { title: "School Objectives & Core values", path: "./schoolobjectives" },
      {
        title: "School President & Director Message",
        path: "./schoolpresident",
      },
      { title: "Faculty & Staffs", path: "./facultyStaff" },
      { title: "Why Param Institute of Education?", path: "./whyParam" },
      { title: "Who Is Hiring Our Graduates?", path: "./hiring" },
    ],
  },
  {
    title: "Courses",
    type: "primary",
    children: [
      {
        title: "Licensed Practical Nursing Program",
        type: "tertiary",
        path: "./LPN",
      },
      {
        title: "Allied Healthcare Programs",
        type: "tertiary",
        children: [
          { title: "Medical Billing and Coding Specialist", path: "./mbcs" },
          {
            title: "Certified Phlebotomy Technician Program",
            path: "./phlebotomy",
          },
          { title: "Certified Electrocardiograph Technician", path: "./ecg" },
          { title: "Certified Patient Care Technician", path: "./patientCare" },
          {
            title: "Certified Clinical Medical Assistant (CCMA)",
            path: "./CCMA",
          },
          {
            title:
              "Certified Nurse Aide (CNA) to Homemaker Home Health aide (CHHA) conversion program",
            path: "./CNAToCHHA",
          },
          { title: "Certified Nurse Assistant (CNA)", path: "./CNA" },
          { title: "Home-Maker Home Health Aide (CHHA)", path: "./CHHA" },
          { title: "Pharmacy Technician (In-Person Program)", path: "./PTP" },
        ],
      },
      {
        title: "Alternate Therapy / Wellness Programs",
        type: "tertiary",
        children: [
          {
            title: "Ayurvedic Wellness Counselor Program (AWC)",
            path: "./AWC",
          },
          {
            title: "Ayurvedic Wellness Practitioner Program (AWP)",
            path: "./AWP",
          },
          { title: "Yoga 200-Hrs", path: "./yoga200" },
          { title: "Yoga 300-Hrs", path: "./yoga300" },
          { title: "Massage Therapy", path: "./massageTherapy" },
        ],
      },
      {
        title: "IT Training Program",
        type: "tertiary",
        children: [
          {
            title: "Project Management Professional Program",
            path: "./projectManagement",
          },
          { title: "iOS Developer Program", path: "./iOSDevelopment" },
          { title: "Agile/ Scrum Master Program", path: "./agileScrumMaster" },
          {
            title: "Core Java Developer Program",
            path: "./coreJavaDeveloperProgram",
          },
          {
            title: "Advance Java Developer Program",
            path: "./advJavaDeveloperProgram",
          },
        ],
      },
    ],
  },
  {
    title: "Admissions",
    type: "primary",
    children: [
      {
        title: "Welcome to PIE A Healthcare Education School",
        path: "./welcomePIE",
      },
      {
        title: "Admission Requirements/ Eligibility",
        path: "./requirementAndEligibility",
      },
      { title: "Admissions Process", path: "./admissionProcess" },
      { title: "Admissions Forms", path: "./admissionForm" },
      { title: "Request Information", path: "./requestInfo" },
    ],
  },
  {
    title: "Student Resources",
    type: "primary",
    children: [
      { title: "Academic Calendar", path: "./academicCalendar" },
      { title: "School Catalog", path: "./schoolCatalog" },
      {
        title: "Students Services",
        type: "tertiary",
        children: [
          {
            title: "Enrollment Verification Letter",
            path: "./EnrollmentVerificationLetter",
          },
          { title: "Leave of Absence", path: "./LeaveOfAbsence" },
          { title: "Withdrawal", path: "./WithdrawalApplication" },
          { title: "Transcript", path: "./TranscriptRequest" },
          { title: "Certificate", path: "./CertificateRequest" },
          { title: "Student Grievance Form", path: "./GrievanceForm" },
        ],
      },
    ],
  },
  {
    title: "Blog",
    type: "primary",
    path: "./blog",
  },
  {
    title: "Contact Us",
    type: "primary",
    path: "./contactUs",
  },
  {
    title: "Student Login",
    type: "primary",
    externalPath: "https://parameducational.com/account/login",
  },
];

const SubLevelComp = ({ item, renderNestedLevels, closeMenu }) => {
  const [selected, setselected] = React.useState("");
  const navigate = useNavigate();
  const toggle = () => {
    setselected(selected === "" ? "active" : "");
  };

  const hasChidlren = (item) => {
    return Array.isArray(item?.children) && item?.children.length > 0;
  };

  return (
    <div
      className={`flex flex-col gap-9 items-start font-[600] text-[20px] leading-[24px] text-[#1B2336] w-full cursor-pointer`}
    >
      <p
        onClick={() => {
          if (hasChidlren(item)) {
            toggle();
          }
          if (!hasChidlren(item) && item?.externalPath) {
            window.location.href = item?.externalPath;
            closeMenu();
          }
          if (!hasChidlren(item) && item?.path) {
            navigate(item?.path);
            closeMenu();
          }
        }}
        style={{ cursor: hasChidlren(item) ? "pointer" : "" }}
        className={`flex justify-between w-full text-left ${
          item?.type != "primary"
            ? "pr-10 font-[400] text-[16px] leading-5"
            : ""
        } ${
          selected === "active" && hasChidlren(item) ? "text-[#003C9D]" : ""
        }`}
      >
        {item.title}{" "}
        {hasChidlren(item) && (
          <span className="flex items-center">
            {selected === "active" ? (
              <img src={caretUp} />
            ) : (
              <img src={caretDown} />
            )}
          </span>
        )}
      </p>
      {selected && hasChidlren(item) && (
        <div
          className={`flex flex-col gap-5 w-full mr-8 ${
            item?.type == "tertiary" ? "ml-4" : ""
          }`}
        >
          {hasChidlren(item) && renderNestedLevels(item.children)}
        </div>
      )}
    </div>
  );
};
const AccordionItem = ({ closeMenu }) => {
  const renderNestedLevels = (data) => {
    return data.map((item, itemIndex) => (
      <SubLevelComp
        item={item}
        renderNestedLevels={renderNestedLevels}
        key={itemIndex}
        closeMenu={closeMenu}
      />
    ));
  };

  return renderNestedLevels(accordionData);
};
export default AccordionItem;
