import React from "react";
import coursePlayIcon from "../../../../assets/images/coursePlayIcon.svg";
import yoga300Course from "../../../../assets/images/yoga300Course.svg";

const Overview = () => {
  return (
    <>
      <div className="">
        <div className="text-content md:ml-[20px] md:mb-[20px] xl:ml-[99px] xl:mb-[40px] xl:mt-[85px]  w-1/2 float-right hidden md:flex justify-center items-center relative">
          <img
            src={yoga300Course}
            alt="Yoga Teacher Training Certificate Program (300-HOURS)"
          />
          <img
            className="absolute"
            src={coursePlayIcon}
            alt="course play icon"
          />
        </div>
        <p className="font-[500] text-[20px] md:text-[24px] xl:text-[32px] leading-[36px] xl:leading-[48px] text-[#003C9D]">
          300 Hours Yoga Teacher Training Certificate (Hybrid) Program
        </p>
        <div className="flex md:hidden justify-center items-center mt-10">
          <img
            src={yoga300Course}
            alt="Yoga Teacher Training Certificate Program (300-HOURS)"
          />
          <img
            className="absolute"
            src={coursePlayIcon}
            alt="course play icon"
          />
        </div>
        <p className="text-[18px] md:text-[20px] leading-[45px] text-[#5C616E] mt-10">
          The 300-Hour Advanced Teacher Certification program is tailored for
          yoga instructors seeking to elevate their professional journey.
          Designed for those already certified at the 200-hour level, this
          program empowers teachers to deepen both their personal practice and
          teaching expertise. Through an immersive curriculum, participants
          delve into advanced postures, anatomy, biomechanics, pranayama,
          meditation, and Yogic psychology, among other subjects.
        </p>
        <p className="text-[18px] md:text-[20px] leading-[45px] text-[#5C616E] mt-4">
          The primary objective of the program is to equip students with
          advanced techniques for harnessing, dispensing, and channeling energy,
          fostering self-healing, and facilitating transformative experiences
          for their students through group instruction.
        </p>
        <p className="text-[18px] md:text-[20px] leading-[45px] text-[#5C616E] mt-4">
          The 300 hours Yoga Teacher Training Certificate   Program at Param
          Institute of Education is designed for{" "}
          <a className="underline text-[#0755b9]" href="#">
            Hybrid
          </a>{" "}
          (where you can choose either an Online or In-person theory class) 200
          hours of Comprehensive training including classroom instructions and
          skill training to prepare the student for the ability to teach yoga
          Philosophy.
        </p>
      </div>
      <div className="mt-10 md:mt-[60px]">
        <p className="font-[500] text-[20px] md:text-[24px] xl:text-[32px] leading-[36px] xl:leading-[48px] text-[#003C9D]">
          What are the Key Reasons for Pursuing a Yoga Teacher Training
          Certificate Program?
        </p>
        <p className="text-[18px] md:text-[20px] leading-[40px] mt-5 md:mt-10 text-[#5C616E]">
          Pursuing a Yoga Teacher Training Certificate Program-300 Hrs. offers
          several compelling reasons for individuals passionate about yoga and
          teaching. Here are some key motivations:
        </p>
        <ul className="mt-6 text-[#F37021] font-[500] text-[20px] md:text-[24px] leading-[30px] flex flex-col gap-6">
          <li className="flex flex-col relative before:content-[''] before:absolute before:left-0 before:w-[30px] pl-[54px] before:h-[30px] before:bg-checkmark-bg before:bg-no-repeat">
            Deepen Personal Practice
            <span className="mt-4 text-[#5C616E] text-[18px] md:text-[20px] leading-10 font-[400]">
              The advanced curriculum of a 300-hour program provides an
              opportunity for students to deepen their own understanding and
              practice of yoga. Through advanced asana, pranayama, and
              meditation techniques, participants can enhance their physical,
              mental, and spiritual well-being.
            </span>
          </li>
          <li className="flex flex-col relative before:content-[''] before:absolute before:left-0 before:w-[30px] pl-[54px] before:h-[30px] before:bg-checkmark-bg before:bg-no-repeat">
            Expand Teaching Skills
            <span className="mt-4 text-[#5C616E] text-[18px] md:text-[20px] leading-10 font-[400]">
              Graduates of a 300-hour program gain advanced teaching skills and
              methodologies, enabling them to offer more comprehensive and
              impactful classes to their students. They learn how to design
              advanced sequences, offer precise alignment cues, and adapt
              practices for diverse populations.
            </span>
          </li>
          <li className="flex flex-col relative before:content-[''] before:absolute before:left-0 before:w-[30px] pl-[54px] before:h-[30px] before:bg-checkmark-bg before:bg-no-repeat">
            Enhance Knowledge Base
            <span className="mt-4 text-[#5C616E] text-[18px] md:text-[20px] leading-10 font-[400]">
              The program delves into advanced topics such as anatomy,
              biomechanics, Yogic philosophy, and psychology, expanding
              students' knowledge base and providing a deeper understanding of
              the principles and practices of yoga.
            </span>
          </li>
          <li className="flex flex-col relative before:content-[''] before:absolute before:left-0 before:w-[30px] pl-[54px] before:h-[30px] before:bg-checkmark-bg before:bg-no-repeat">
            Specialize in Areas of Interest
            <span className="mt-4 text-[#5C616E] text-[18px] md:text-[20px] leading-10 font-[400]">
              Students can specialize in specific areas of interest within the
              field of yoga, such as Yin Yoga, Restorative Yoga, Yoga Therapy,
              or Yoga for specific populations like athletes, seniors, or
              individuals with injuries or health conditions.
            </span>
          </li>
          <li className="flex flex-col relative before:content-[''] before:absolute before:left-0 before:w-[30px] pl-[54px] before:h-[30px] before:bg-checkmark-bg before:bg-no-repeat">
            Professional Development
            <span className="mt-4 text-[#5C616E] text-[18px] md:text-[20px] leading-10 font-[400]">
              Obtaining a 300-hour certification demonstrates a commitment to
              professional development and lifelong learning as a yoga teacher.
              It can enhance credibility, explore new career opportunities, and
              attract more students seeking advanced instruction.
            </span>
          </li>

          <li className="flex flex-col relative before:content-[''] before:absolute before:left-0 before:w-[30px] pl-[54px] before:h-[30px] before:bg-checkmark-bg before:bg-no-repeat">
            Join a Community of Peers
            <span className="mt-4 text-[#5C616E] text-[18px] md:text-[20px] leading-10 font-[400]">
              Participating in a 300-hour program allows students to connect
              with like-minded individuals who share a passion for yoga and
              teaching. The supportive community provides encouragement,
              inspiration, and opportunities for collaboration and networking.
            </span>
          </li>
          <li className="flex flex-col relative before:content-[''] before:absolute before:left-0 before:w-[30px] pl-[54px] before:h-[30px] before:bg-checkmark-bg before:bg-no-repeat">
            Personal Growth and Transformation
            <span className="mt-4 text-[#5C616E] text-[18px] md:text-[20px] leading-10 font-[400]">
              <p>
                Beyond the physical and technical aspects of yoga, a 300-hour
                program offers opportunities for profound personal growth and
                transformation. Students explore their own strengths,
                limitations, and inner wisdom, leading to increased
                self-awareness and self-empowerment.
              </p>
            </span>
          </li>
          <li className="flex flex-col relative before:content-[''] before:absolute before:left-0 before:w-[30px] pl-[54px] before:h-[30px] before:bg-checkmark-bg before:bg-no-repeat">
            Contribute to the Yoga Community
            <span className="mt-4 text-[#5C616E] text-[18px] md:text-[20px] leading-10 font-[400] flex flex-col gap-[10px]">
              <p>
                Graduates of a 300-hour program are equipped to make a positive
                impact on their local yoga communities and beyond. By sharing
                their advanced knowledge and teaching skills, they can inspire
                and uplift others on their yoga journey.
              </p>
              <p>
                Overall, pursuing a Yoga Teacher Training Certificate
                Program-300 Hrs. offers a pathway to personal enrichment,
                professional advancement, and deeper connection to the practice
                and teachings of yoga.
              </p>
            </span>
          </li>
        </ul>
      </div>
      <div className="mt-10 md:mt-[60px]">
        <p className="font-[500] text-[20px] md:text-[24px] xl:text-[32px] leading-[36px] xl:leading-[48px] text-[#003C9D]">
          Yoga Teacher Training Certificate Program Outline
        </p>
        <div className="p-6 border-[1px] rounded-[5px] border-[#D9D9D9] mt-10 shadow-lg">
          <ul className="list-disc font-[400] text-[18px] md:text-[20px] text-[#1B2336] leading-[35px] flex flex-col gap-[10px] ml-6">
            <li>
              Program Length:{" "}
              <span className="font-[500] text-[#003C9D]">14-15 Weeks</span>
            </li>
            <li>
              Total Hours of Program:{" "}
              <span className="font-[500] text-[#003C9D]">300 hours</span>
            </li>
            <li>
              Academy Credential Award:{" "}
              <span className="font-[500] text-[#003C9D]">Certificate</span>
            </li>
          </ul>
        </div>
        <div className="p-6 border-[1px] rounded-[5px] border-[#D9D9D9] mt-6 shadow-lg">
          <ul className="font-[400] text-[18px] md:text-[20px] text-[#1B2336] leading-[30px] flex flex-col gap-[10px]">
            <li className="flex flex-col gap-6">
              <div>
                Module 1: <span className="font-[500]">Segment A</span>
                <p>
                  Topics will include foundation on sacred yogic spaces, heart
                  of sacred yogic spaces, wisdom of sacred yogic spaces.
                </p>
              </div>
              <div>
                Module 1: <span className="font-[500]">Segment B</span>
                <p>
                  Understand topics include advance asana 1 &2, refining & your
                  personal practice, mentoring for mixed levels of experience,
                  teaching inner practice, advance sacred life 1 &2, teacher
                  tools, and practicum.
                </p>
              </div>
              <div>
                Module 1: <span className="font-[500]">Segment C</span>
                <p>
                  This segment will include topics of strategic life planning,
                  learning how to manifest sankalpa, yoga as a path to life
                  coaching, Ayurveda spring cleaning or Ayurveda Fall
                  Rejuvenation.
                </p>
              </div>
              <div>
                Module 1: <span className="font-[500]">Segment D</span>
                <p>
                  For this final segment, students will be asked to complete the
                  four of the following electives for graduation. Options will
                  include, working with Therapeutic Modifications or Holistic
                  Approach to Healing, Structural Yoga Assessment & Practice
                  Protocols, Body of Sound & Consciousness, Advanced Anatomy &
                  Physiology.
                </p>
              </div>
            </li>
            <li>Business skills Homework Packet</li>
            <li>Homework/ Exam</li>
            <li>Lifestyle Journals Sadhana Log</li>
          </ul>
        </div>
        <div className="mt-10 md:mt-[60px]">
          <div className="">
            <p className="font-[500] text-[20px] md:text-[32px] leading-[36px] text-[#003C9D]">
              What you get from us?
            </p>
            <ul className="list-disc font-[400] text-[18px] md:text-[20px] text-[#5C616E] leading-[30px] mt-10 flex flex-col gap-4 ml-6">
              <li>Lectures and material access</li>
              <li>Live instructor for your queries</li>
              <li>Administrative support</li>
              <li>Onsite clinical training</li>
              <li>Career pathways opportunities and guidelines</li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Overview;
