import React from 'react'

function TestimonialVideos() {
  return (
    <div className='flex flex-col items-center w-full h-[50%] gap-16 justify-center flex-wrap'>
      <div className='w-full flex gap-8 md:gap-5 flex-col md:flex-row flex-wrap items-center justify-center'>
          <div className='md:min-w-[560px] w-full md:w-[50%] xl:w-[32.5%] h-full flex flex-col gap-2'>
            <iframe width="100%" height="315" className='rounded-xl' src="https://www.youtube.com/embed/GC2A1LieBRI?si=jOOBru-jqGqZUfto" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          </div>
          <div className='md:min-w-[560px]  w-full md:w-[50%] xl:w-[32.5%] h-full flex flex-col gap-2'>
            <iframe width="100%" height="315" className='rounded-xl' src="https://www.youtube.com/embed/pNp-sKnF8tw?si=lyltdshWxqxut0Fm" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          </div>
          <div className='md:min-w-[560px]  w-full md:w-[50%] xl:w-[32.5%] h-full flex flex-col gap-2'>
            <iframe width="100%" height="315" className='rounded-xl' src="https://www.youtube.com/embed/tNViC0lx6YE?si=JoMAKrf_I6cT4-xa" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          </div>
      </div>
    </div>
  )
}

export default TestimonialVideos