import React from "react";

const Faqs = () => {
  return (
    <>
      <div>
        <p className="font-[500] text-[20px] md:text-[24px] xl:text-[32px] leading-[36px] xl:leading-[48px] text-[#003C9D]">Certified Nurse Assistant  FAQs</p>
        <div className="mt-5 md:mt-10 leading-[30px] text-[18px] md:text-[20px]">
          <ul className="list-disc flex flex-col gap-6 ml-5 font-[500]">
            <li className="">
              What does a certified Nurse Assistant do?
              <div className="flex flex-col gap-[10px] mt-5 md:mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  ​​​​Certified Nursing A​ssistant in the physical, psychologic​al, social and spiritual needs of
                  clients. CNAs work in healthcare settings such as hospitals, home care, nursing homes or
                  assisted-living facilities. 
                </p>
                <p>
                  Nursing assistants and orderlies work as part of a healthcare team under the supervision of licensed
                  practical or licensed vocational nurses and registered nurses.
                </p>
                <p>
                  Nursing assistants provide basic care and help with activities of daily living. They typically do the
                  following:
                </p>

                <ul className="list-disc flex flex-col gap-6 ml-7">
                  <li className="">Clean and bathe patients</li>
                  <li>Help patients use the toilet and dress</li>
                  <li>Turn, reposition, and transfer patients between beds and wheelchairs</li>
                  <li>Listen to and record patients’ health concerns and report that information to nurses</li>
                  <li>Measure patients’ vital signs, such as blood pressure and temperature</li>
                  <li>Serve meals and help patients eat</li>
                </ul>
                <p>
                  Depending on their training level and the state in which they work, nursing assistants also may
                  dispense medication.
                </p>
                <p>
                  Nursing assistants are often the principal caregivers in nursing and residential care facilities.
                  Nursing assistants often develop relationships with their patients because some patients stay in these
                  facilities for months or years.
                </p>
                <p>Orderlies typically do the following:</p>
                <ul className="list-disc flex flex-col gap-6 ml-7">
                  <li>Help patients to move around the facility, such as by pushing their wheelchairs</li>
                  <li>Clean equipment and facilities</li>
                  <li>Change linens</li>
                  <li>Stock supplies</li>
                </ul>
              </div>
            </li>

            <li>
              Is certification necessary for CNA Program?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  Nursing assistants typically must complete a state-approved education program and pass their state’s
                  competency exam. Orderlies typically have at least a high school diploma or equivalent.
                </p>
                <p>
                  Nursing assistants often need to complete a state-approved education program that includes both
                  instruction on the principles of nursing and supervised clinical work.
                </p>
              </div>
            </li>

            <li>
              Are CNA in high demand?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  The demand for CAN  is high, making it an excellent time for students to begin their career training.
                  The U.S. Bureau of Labor Statistics (BLS) projects that the Overall employment of nursing assistants
                  and orderlies is projected to grow 4 percent from 2022 to 2032, about as fast as the average for all
                  occupations in the U.S.
                </p>
              </div>
            </li>

            <li>
              How much does a CNA make?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  The median annual wage for nursing assistants was $38,200 in May 2023. The median wage is the wage at
                  which half the workers in an occupation earned more than that amount and half earned less. The lowest
                  10 percent earned less than $30,020, and the highest 10 percent earned more than $48,780.
                </p>
                <p>
                  The median annual wage for orderlies was $36,830 in May 2023. The lowest 10 percent earned less than
                  $30,260, and the highest 10 percent earned more than $48,000.
                </p>
              </div>
            </li>

            <li>
              What is the CNA State  Exam?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  ​Once you have successfully passed our school final exam (which is not the NJ State exam) and our
                  skills competency test, then you can register for the NJ CNA State exam via PSI services. However
                  before you apply, please download the following PSI document called the NJ CNA Examination Candidate
                  Information bulletin
                </p>
                <p>
                  (
                  <a
                    className="underline"
                    href="https://candidate.psiexams.com/bulletin/display_bulletin.jsp?ro=yes&actionname=83&bulletinid=493&bulletinurl=.pdf">
                    https://candidate.psiexams.com/bulletin/display_bulletin.jsp?ro=yes&actionname=83&bulletinid=493&bulletinurl=.pdf 
                  </a>
                  ).
                </p>
                <p>
                  Then you can apply for the NJ State CNA exam via this PSI weblink (
                  <a
                    className="underline"
                    href="https://candidate.psiexams.com/catalog/fti_agency_license_details.jsp?fromwhere=findtest&testid=2414">
                    https://candidate.psiexams.com/catalog/fti_agency_license_details.jsp?fromwhere=findtest&testid=2414
                  </a>
                  ).
                </p>
              </div>
            </li>

            <li>
              When can I start the course?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  This course is open enrollment, so you can register and start the course as soon as you are ready.
                </p>
              </div>
            </li>

            <li>
              How long does it take to complete this course?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  This course has open enrollment, so you can start as soon as you can. The program will take 90 hours
                  of training only for certification.
                </p>
              </div>
            </li>

            <li>
              What kind of support will I receive?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  The course instructor will be available by email to answer any questions and provide feedback on your
                  performance. Occasionally, your course may be supported by a team of industry experts. You will also
                  receive support from the student advising team.
                </p>
              </div>
            </li>

            <li>
              What if I don't have enough time to complete my course within the time frame provided?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  The time allotted for course completion has been calculated based on the number of course hours.
                  However, if you are unable to complete the course, contact the student advising team to see what
                  options you may have available to work out a suitable completion date. Please note that an extension
                  fee may be charged.
                </p>
              </div>
            </li>

            <li>
              What happens when I complete the course?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  Upon successful completion of the course, you will be awarded a Certificate of Completion and eligible
                  for NJ state licensure exams.
                </p>
              </div>
            </li>
            <li>
              Am I guaranteed a job?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  This course will provide you with the skills you need to obtain an entry-level position in most cases.
                  We will assist you with your resume preparation and finding a job. Also, Potential students should
                  always do research on the job market in their area before registering.
                </p>
              </div>
            </li>
            <li>
              Can I get financial assistance?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  This course is non-credit, so it does not qualify for federal aid, FAFSA and Pell Grant. In some
                  states, vocational rehab or workforce development boards will pay for qualified students to take our
                  courses.  
                </p>
              </div>
            </li>
            <li>
              How much does Program school cost?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  Tuition Fees at Param Institute of Education varies for different program offered, but we assure you
                  that our tuition is very competitive among the other vocational schools in New Jersey. Remember that
                  attending program at Param institute of Education is an investment in your future as a healthcare
                  professional. Plus, there are ways to fund your education through scholarships offered at school
                  making it easier than ever to pay for your education.
                </p>
              </div>
            </li>

            <li>
              How can I get more information about this course?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  If you have questions that are not answered on our website, representatives are available via EMAIL
                  (info@paramedu.com). You can also call us at +1 848 256 2737 during regular business hours to have
                  your questions promptly answered. If you are visiting us during non-business hours, please send us a
                  question using the "Contact Us" form.
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Faqs;
