import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="bg-[#F5F5F5] font-poppins px-4 md:px-8 xl:px-20 py-12 flex flex-col items-center gap-12 -mb-10">
      <p className="font-[600] text-[32px] md:text-[32px] xl:text-[42px] text-[#F37021] underline">
        Privacy Policy
      </p>
      <div className="text-[20px] md:text-[24px] text-[#5C616E] flex flex-col gap-8">
        <div>
          <p className="text-[#003C9D] font-[500] leading-[35px]">
            Introduction:
          </p>
          <p className="text-[18px] md:text-[20px] leading-[45px] mt-4">
            This revised Privacy Policy is effective from July 2024 and governs
            how the Param Institute of Education (hereinafter "PIE," "we," "us,"
            or "our") collects, uses, maintains, and discloses information
            collected from users (each, a "User") of the [website URL] website
            (the "Site"). This policy applies to the Site and all products and
            services offered by PIE. Please read the following privacy policy
            carefully. By using this Site, you agree to the privacy policy. If
            you do not consent to this policy, you are not permitted to use this
            Site.
          </p>
        </div>
        <div>
          <p className="text-[#003C9D] font-[500] leading-[35px]">
            Information Collection:
          </p>
          <p className="text-[18px] md:text-[20px] leading-[45px] mt-4">
            We may collect personal identification and information from Users in
            various ways, including, but not limited to, when Users visit our
            Site, register on the Site, request information, schedule an
            appointment subscribe to our newsletter, fill out a form, and in
            connection with other activities, services, features, or resources
            we make available on our Site. Users may be asked for, as
            appropriate, their name, email address, mailing address, phone
            number, credit card, and other information. We will collect personal
            identification information from Users only if they voluntarily
            submit such information to us. We may collect non-personal
            identification information also.
          </p>
        </div>
        <div>
          <p className="text-[#003C9D] font-[500] leading-[35px]">
            What Do We Use Your Information For?
          </p>
          <p className="text-[18px] md:text-[20px] leading-[45px] mt-4">
            Any of the information we collect from you may be used in one of the
            following ways:
          </p>
          <ul className="text-[18px] md:text-[20px] leading-[45px] mt-4 flex flex-col gap-6">
            <li className="flex flex-col relative before:content-[''] before:bg-contain before:absolute before:left-0 before:top-[2px] before:md:top-2 before:w-[22px] before:md:w-[24px] pl-[54px] before:h-[22px] before:md:h-[24px] before:bg-black-checkmark-bg before:bg-no-repeat">
              Deepen Personal Practice
            </li>
            <li className="flex flex-col relative before:content-[''] before:bg-contain before:absolute before:left-0 before:top-[2px] before:md:top-2 before:w-[22px] before:md:w-[24px] pl-[54px] before:h-[22px] before:md:h-[24px] before:bg-black-checkmark-bg before:bg-no-repeat">
              To personalize your experience: Your information helps us better
              respond to your individual needs.
            </li>
            <li className="flex flex-col relative before:content-[''] before:bg-contain before:absolute before:left-0 before:top-[2px] before:md:top-2 before:w-[22px] before:md:w-[24px] pl-[54px] before:h-[22px] before:md:h-[24px] before:bg-black-checkmark-bg before:bg-no-repeat">
              To administer contests, promotions, surveys, or other site
              features
            </li>
            <li className="flex flex-col relative before:content-[''] before:bg-contain before:absolute before:left-0 before:top-[2px] before:md:top-2 before:w-[22px] before:md:w-[24px] pl-[54px] before:h-[22px] before:md:h-[24px] before:bg-black-checkmark-bg before:bg-no-repeat">
              To process transactions efficiently
            </li>
            <li className="flex flex-col relative before:content-[''] before:bg-contain before:absolute before:left-0 before:top-[2px] before:md:top-2 before:w-[22px] before:md:w-[24px] pl-[54px] before:h-[22px] before:md:h-[24px] before:bg-black-checkmark-bg before:bg-no-repeat">
              To improve our website and program offerings: We continually
              strive to improve based on the information and feedback we receive
              from you.
            </li>
            <li className="flex flex-col relative before:content-[''] before:bg-contain before:absolute before:left-0 before:top-[2px] before:md:top-2 before:w-[22px] before:md:w-[24px] pl-[54px] before:h-[22px] before:md:h-[24px] before:bg-black-checkmark-bg before:bg-no-repeat">
              To improve our service: Your information helps us more effectively
              respond to your requests and support needs.
            </li>
            <li className="flex flex-col relative before:content-[''] before:bg-contain before:absolute before:left-0 before:top-[2px] before:md:top-2 before:w-[22px] before:md:w-[24px] pl-[54px] before:h-[22px] before:md:h-[24px] before:bg-black-checkmark-bg before:bg-no-repeat">
              To send periodic emails: The email address you provide for
              appointments and information requests may be used to send you
              information and updates about your inquiry, along with occasional
              school news, updates, related information, etc. Note: You can
              unsubscribe from future emails by following the detailed
              unsubscribe instructions at the bottom of each email.
            </li>
          </ul>
        </div>
        <div>
          <p className="text-[#003C9D] font-[500] leading-[35px]">
            How Do We Protect Your Information?
          </p>
          <p className="text-[18px] md:text-[20px] leading-[45px] mt-4">
            We implement various strictest security measures to maintain the
            safety of your personal information when you enter, submit, or
            access it.
          </p>
        </div>
        <div>
          <p className="text-[#003C9D] font-[500] leading-[35px]">
            Do We Use Cookies?
          </p>
          <p className="text-[18px] md:text-[20px] leading-[45px] mt-4">
            Yes, we use cookies to enhance your experience, gather general
            visitor information, and track visits to our website. You can choose
            to set your web browser to refuse cookies or to alert you when
            cookies are being sent. We use cookies to:
          </p>
          <ul className="text-[18px] md:text-[20px] leading-[45px] mt-4 list-disc ml-5">
            <li>Keep track of advertisements.</li>
            <li>
              Compile aggregate data about site traffic and site interaction to
              offer better site experiences and tools in the future.
            </li>
            <li>
              We may contract with third-party service providers to assist us in
              better understanding our site visitors. These service providers
              are not permitted to use the information collected on our behalf
              except to help us conduct and improve our business.
            </li>
          </ul>
        </div>
        <div>
          <p className="text-[#003C9D] font-[500] leading-[35px]">
            Do We Disclose Any Information to Outside Parties?
          </p>
          <p className="text-[18px] md:text-[20px] leading-[45px] mt-4">
            We do not sell, trade, or otherwise transfer your personally
            identifiable information to outside parties except for trusted third
            parties who assist us in operating our website, conducting our
            business, or servicing you, so long as those parties agree to keep
            this information confidential. We may also release your information
            when we believe release is appropriate to comply with the law,
            enforce our site policies, or protect our or others' rights,
            property, or safety. Non-personally identifiable visitor information
            may be provided to other parties for marketing, advertising, or
            other uses.
          </p>
        </div>
        <div>
          <p className="text-[#003C9D] font-[500] leading-[35px]">
            Children’s Online Privacy Protection Act Compliance:
          </p>
          <p className="text-[18px] md:text-[20px] leading-[45px] mt-4">
            We comply with the requirements of COPPA (Children’s Online Privacy
            Protection Act), and we do not knowingly collect any information
            from anyone under 13 years of age. Our website, products, and
            services are all directed to people who are at least 13 years old or
            older.
          </p>
        </div>
        <div>
          <p className="text-[#003C9D] font-[500] leading-[35px]">
            Google Analytics:
          </p>
          <p className="text-[18px] md:text-[20px] leading-[45px] mt-4">
            We use Google Analytics to collect data about our website traffic
            via Google advertising cookies and anonymous identifiers. This data
            is used to provide better services and more relevant content to our
            users. You can opt out of Google Analytics by using the Google
            Analytics Opt-out Browser Add-on. Google Analytics is a web analysis
            service provided by Google. Google uses the data collected to track
            and examine the use of our website, prepare reports on its
            activities, and share them with other Google services. Google may
            use the data collected to contextualize and personalize ads within
            its advertising network.
          </p>
        </div>
        <div>
          <p className="text-[#003C9D] font-[500] leading-[35px]">
            Google Remarketing:
          </p>
          <p className="text-[18px] md:text-[20px] leading-[45px] mt-4">
            We use Remarketing with Google Analytics to advertise online.
            Third-party vendors, including Google, may show our ads on sites
            across the Internet. This site and third-party vendors, including
            Google, use first-party cookies (such as the Google Analytics
            cookie) and third-party cookies (such as the DoubleClick cookie)
            together to inform, optimize, and serve ads based on past visits to
            this website. This site and third-party vendors, including Google,
            use first-party cookies and third-party cookies together to report
            how ad impressions, other uses of ad services, and interactions with
            these ad impressions and ad services are related to visits to this
            site.
          </p>
        </div>
        <div>
          <p className="text-[#003C9D] font-[500] leading-[35px]">
            Online Privacy Policy Only:
          </p>
          <p className="text-[18px] md:text-[20px] leading-[45px] mt-4">
            This online privacy policy applies only to information collected
            through our website and not to information collected offline.
          </p>
        </div>
        <div>
          <p className="text-[#003C9D] font-[500] leading-[35px]">
            Terms and Conditions:
          </p>
          <p className="text-[18px] md:text-[20px] leading-[45px] mt-4">
            Please visit our Terms and Conditions section establishing the use,
            disclaimers, and limitations of liability governing the use of our
            website.
          </p>
        </div>
        <div>
          <p className="text-[#003C9D] font-[500] leading-[35px]">
            Changes to Our Privacy Policy:
          </p>
          <p className="text-[18px] md:text-[20px] leading-[45px] mt-4">
            PIE reserves the right to update or change this privacy policy at
            any time. Any changes will be posted on this page with an updated
            effective date. You are encouraged to review this privacy policy
            periodically to stay informed about how we are protecting the
            personal information we collect.
          </p>
        </div>
        <div>
          <p className="text-[#003C9D] font-[500] leading-[35px]">
            Contacting Us:
          </p>
          <p className="text-[18px] md:text-[20px] leading-[45px] mt-4">
            If you have any questions regarding this privacy policy, you may
            contact us at:
          </p>
          <p className="text-[18px] md:text-[20px] leading-[45px] mt-4">
            <div className="font-[500] ">
              <p>Admission Department:</p>
              <p>Param Institute of Education</p>
              <p>200 Middlesex Essex Tpke, Suite 105</p>
              <p>Iselin, NJ 08830</p>
            </div>
          </p>
          <p className="text-[18px] md:text-[20px] leading-[45px] mt-4">
            By using this Site, you acknowledge that you have read, understood,
            and agree to the terms and conditions of this privacy policy. If you
            do not agree, you are not permitted to use this Site.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
