import React from "react";
import tick from "../../../../assets/images/tick.svg";

function CourseOutcomes() {
  const data = [
    {
      title: "Knowledge of Medical Terminology",
      content:
        "Coders and billers must know common medical terms relating to body systems, injury, and disease. This knowledge helps them match terms with the right software codes. It also helps them communicate with patients, peers, and medical professionals. Many programs require a medical terminology class in the first module or semester.",
    },
    {
      title: "Computer Literacy and Medisoft Software Training",
      content:
        "Medical coders and billers spend much of their day on computers. They must keep up with industry software and the latest best practices. Computer skills help workers troubleshoot problems and recognize incomplete data. Students learn these skills in coding basics classes. Medisoft software training for billing.",
    },
    {
      title: "ICD and CPT Coding",
      content:
        "You’ll learn how to use the Healthcare Common procedure Coding System (HCPCS) and the CPT Category II and ICD-10 codes.",
    },
    {
      title: "Bookkeeping",
      content:
        "Medical billers apply their bookkeeping skills daily. Bookkeeping involves processing financial information, such as patients' bills and payments. Workers also use bookkeeping to determine insurance obligations and payouts. Experienced bookkeepers recognize errors affecting their employer or patients. Mastering bookkeeping skills involves paying close attention to facts and figures.",
    },
    {
      title: "Accuracy",
      content:
        "Mistakes in medical billing or coding may lead to treatment delays or incorrect patient charges. Workers can maintain accuracy by double-checking their work and not rushing through required tasks. Certificate- and degree-seekers learn other accuracy best practices while performing coding and billing exercises.",
    },
    {
      title: "Strong Communication Skills",
      content:
        "Medical billers and coders need strong communication skills. These skills allow them to help patients and collaborate with healthcare professionals. Answering questions, resolving issues, and completing tasks all involve communication. Effective communication also reduces mistakes, as workers can ask for help when necessary.",
    },
    {
      title: "Teamwork",
      content:
        "Advanced communication and interpersonal skills make medical billers and coders effective team members. They use these skills to collaborate with peers, patients, and healthcare providers. Teamwork leads to positive outcomes, including accurate patient records and billing statements. Top medical billing and coding certifications promote teamwork skills through group projects.",
    },
    {
      title: "Adaptability",
      content:
        "Medical billers and coders should be able to adapt quickly to changes. These changes may include sudden problems, increased workloads, or longer hours. Medical billers and coders with this flexibility do not make careless mistakes when under stress. They can also apply this skill in other professions.",
    },
    {
      title: "Reading and Listening",
      content:
        "Medical coders use their medical terminology expertise to input medical reports into databases. Performing this task well requires them to read documents closely. Medical transcriptionists use listening skills to turn healthcare professionals' recordings into text. Medical billing and coding degrees train learners in these skills with classes in coding, healthcare, and insurance.",
    },
    {
      title: "Attention to Detail",
      content:
        "Detail-oriented workers make fewer mistakes. This means better patient outcomes and correct billing statements. Medical billing and coding classes offer learners specialized feedback. This helps professionals become more attentive workers. Professionals can further develop this skill by double-checking their work.",
    },
    {
      title: "Strong Organization Skills",
      content:
        "Many medical billers and coders work from home. This setting requires strong organization skills. Professionals should create a dedicated workspace and create a digital filing system to keep documents organized.",
    },
  ];
  return (
    <div className="font-poppins flex flex-col gap-5 md:gap-10">
      <p className="font-[500] text-[20px] md:text-[24px]  xl:text-[32px] leading-[36px] xl:leading-[48px] text-[#003C9D]">
        Medical Billing and coding Specialist (Hybrid) Certification Program
        Outcome
      </p>
      <p className="font-[400] text-[18px] md:text-[20px] text-[#5C616E]  font-inter">
        Upon the completion of this course the student will be able to:
      </p>
      <div className="flex flex-col gap-6">
        {data.map((data) => (
          <div className="flex flex-col gap-4">
            <div className="flex gap-6 items-center">
              <img
                src={tick}
                alt=""
                className="h-[22px] w-[22px] md:h-[30px] md:w-[30px]"
              />
              <p className="font-[500] text-[20px] md:text-[24px]  text-[#F37021] font-inter">
                {data.title}
              </p>
            </div>
            <p className="font-[400] text-[18px] md:text-[20px] text-[#5C616E]  font-inter leading-[40px] ml-12">
              {data.content}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default CourseOutcomes;
