import React from 'react'
import scrollTopImage from "../assets/images/scrollImage.svg"

const ScrollToTopButton = (props) => {
    return (
        <>
            <div className={'scrollTopButton fixed bottom-6 right-6 rounded-[50%]'} onClick={props.scrollUp}>
                <button className="goTop">
                    {/* <img src={scrollTopImage} alt="scroll-button" /> */}

                    <svg width="45" height="45" className='h-[34px] w-[34px] md:h-[45px] md:w-[45px] ' viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="1.5" y="1.5" width="42" height="42" rx="21" fill="white" />
                        <rect x="1.5" y="1.5" width="42" height="42" rx="21" stroke="#003C9D" stroke-width="3" />
                        <path d="M23.531 10.3025C23.2575 10.0291 22.8867 9.87549 22.5 9.87549C22.1133 9.87549 21.7424 10.0291 21.4689 10.3025L13.2191 18.5523C13.0799 18.6868 12.9688 18.8477 12.8923 19.0256C12.8159 19.2036 12.7757 19.3949 12.774 19.5886C12.7723 19.7822 12.8092 19.9742 12.8825 20.1534C12.9558 20.3327 13.0641 20.4955 13.2011 20.6324C13.338 20.7693 13.5008 20.8776 13.68 20.951C13.8593 21.0243 14.0513 21.0612 14.2449 21.0595C14.4386 21.0578 14.6299 21.0176 14.8079 20.9412C14.9858 20.8647 15.1467 20.7536 15.2812 20.6143L21.0416 14.8539V34.1666C21.0416 34.5534 21.1953 34.9243 21.4688 35.1978C21.7423 35.4713 22.1132 35.625 22.5 35.625C22.8867 35.625 23.2577 35.4713 23.5312 35.1978C23.8047 34.9243 23.9583 34.5534 23.9583 34.1666V14.8539L29.7187 20.6143C29.9938 20.88 30.3621 21.027 30.7445 21.0237C31.1269 21.0203 31.4927 20.867 31.763 20.5966C32.0334 20.3262 32.1868 19.9604 32.1901 19.5781C32.1934 19.1957 32.0465 18.8273 31.7808 18.5523L23.531 10.3025Z" fill="#003C9D" />
                    </svg>

                </button>
            </div>
        </>
    );
}

export default ScrollToTopButton

