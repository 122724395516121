import {
  GET_BOOKING_SLOTS,
  GET_BOOKING_SLOTS_SUCCESS,
  GET_BOOKING_SLOTS_FAILURE,
  ADD_APPOINTMENT,
  ADD_APPOINTMENT_SUCCESS,
  ADD_APPOINTMENT_FAILURE,
} from "../ActionTypes";

export const getBookingSlots = (payload) => {
  return {
    type: GET_BOOKING_SLOTS,
    payload: payload,
  };
};
export const getBookingSlotsSuccess = (payload) => {
  return {
    type: GET_BOOKING_SLOTS_SUCCESS,
    payload: payload,
  };
};
export const getBookingSlotsFailure = (payload) => {
  return {
    type: GET_BOOKING_SLOTS_FAILURE,
    payload: payload,
  };
};
export const addAppointment = (payload) => {
  return {
    type: ADD_APPOINTMENT,
    payload: payload,
  };
};
export const addAppointmentSuccess = (payload) => {
  return {
    type: ADD_APPOINTMENT_SUCCESS,
    payload: payload
  };
};
export const addAppointmentFailure = (payload) => {
  return {
    type: ADD_APPOINTMENT_FAILURE,
    payload: payload,
  };
};
