import React, { useEffect } from "react";

function Pagination({ totalPages, currentPage, setCurrentPage }) {
  let numbers = [];

  const maxPagesToShow = 4;
  const halfMaxPagesToShow = Math.floor(maxPagesToShow / 2);

  // Add ellipsis if needed at the beginning
  if (currentPage - halfMaxPagesToShow > 1) {
    numbers.push(1);
    if (currentPage - halfMaxPagesToShow > 2) {
      numbers.push("...");
    }
  }

  // Generate numbers around the current page
  const start = Math.max(1, currentPage - halfMaxPagesToShow);
  const end = Math.min(totalPages, start + maxPagesToShow - 1);
  for (let i = start; i <= end; i++) {
    numbers.push(i);
  }

  // Add ellipsis if needed at the end
  if (currentPage + halfMaxPagesToShow < totalPages) {
    if (currentPage + halfMaxPagesToShow < totalPages) {
      numbers.push("...");
    }
    numbers.push(totalPages);
  }

  return (
    <div className="w-full font-poppins flex items-center gap-2 md:gap-6 flex-wrap">
      <div className="flex gap-2 items-center group ">
        <svg
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={`${
            currentPage <= 1
              ? "cursor-none pointer-events-none fill-[#d1d5db]"
              : "group-hover:fill-[#F37021] fill-[#003C9D]"
          }`}
        >
          <path d="M8.96103 6.84302L10.372 8.26102L7.10203 11.516L20.707 11.529L20.705 13.529L7.13703 13.516L10.352 16.746L8.93503 18.156L3.29103 12.486L8.96103 6.84302Z" />
        </svg>
        <p
          className={`font-[400] text-[16px] ${
            currentPage <= 1
              ? "cursor-none pointer-events-none text-[#d1d5db]"
              : "cursor-pointer text-[#003C9D] group-hover:text-[#F37021]"
          }`}
          onClick={(e) => setCurrentPage(currentPage - 1)}
        >
          Previous
        </p>
      </div>
      <div className="flex gap-3 md:gap-6 items-center flex-wrap">
        {numbers.map((number) => (
          <p
            className={`${
              currentPage == number
                ? "bg-[#003C9D] text-white"
                : "text-[#003C9D] hover:bg-[#F37021] "
            }  ${
              number == "..." ? "cursor-none pointer-events-none" : ""
            }  px-3 py-[6px] hover:bg-[#F37021] cursor-pointer`}
            onClick={() => setCurrentPage(number)}
          >
            {number}
          </p>
        ))}

        {/* <p className="bg-[#003C9D] text-white px-3 py-[6px] hover:bg-[#F37021]">
          1
        </p>
        <p className="font-[400] text-[18px] text-[#003C9D] cursor-pointer">
          2
        </p>
        <p className="font-[400] text-[18px] text-[#003C9D] cursor-pointer">
          3
        </p>
        <p className="font-[400] text-[18px] text-[#003C9D] cursor-pointer">
          4
        </p>
        <p className="font-[400] text-[18px] text-[#003C9D] cursor-pointer">
          ...
        </p>
        <p className="font-[400] text-[18px] text-[#003C9D] cursor-pointer">
          8
        </p>
        <p className="font-[400] text-[18px] text-[#003C9D] cursor-pointer">
          9
        </p>
        <p className="font-[400] text-[18px] text-[#003C9D] cursor-pointer">
          10
        </p> */}
      </div>
      <div className="flex gap-2 items-center group">
        <p
          className={`font-[400] text-[16px] ${
            currentPage >= totalPages
              ? "cursor-none pointer-events-none text-[#d1d5db]"
              : "cursor-pointer group-hover:text-[#F37021] text-[#003C9D]"
          }`}
          onClick={(e) => setCurrentPage(currentPage + 1)}
        >
          Next
        </p>
        <svg
          width="18"
          height="13"
          viewBox="0 0 18 13"
          fill="none"
          className={`${
            currentPage >= totalPages
              ? "cursor-none pointer-events-none fill-[#d1d5db]"
              : "group-hover:fill-[#F37021] fill-[#003C9D]"
          }`}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M12.039 0.843018L10.628 2.26102L13.898 5.51602L0.292969 5.52902L0.294969 7.52902L13.863 7.51602L10.648 10.746L12.065 12.156L17.709 6.48602L12.039 0.843018Z" />
        </svg>
      </div>
    </div>
  );
}

export default Pagination;
