import {
  ADD_CONTACTUS,
  ADD_CONTACTUS_FAILURE,
  ADD_CONTACTUS_SUCCESS,
  ADD_REQUESTINFO,
  ADD_REQUESTINFO_FAILURE,
  ADD_REQUESTINFO_SUCCESS,
  GET_COURSES,
  GET_COURSES_FAILURE,
  GET_COURSES_SUCCESS,
  GET_COMBO_COURSES,
  GET_COMBO_COURSES_SUCCESS,
  GET_COMBO_COURSES_FAILURE,
} from "../ActionTypes";

const initialState = {
  loader: true,
  addcontactus_data: null,
  addcontactus_error: null,
  getcourses_data: null,
  getcourses_error: null,
  addrequestinfo_data: null,
  addrequestinfo_error: null,
  getComboCoursesLoader: null,
  getComboCoursesData: null,
  getComboCoursesError: null,
};
const CommonReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_CONTACTUS:
      return {
        ...state,
        loader: true,
      };
    case ADD_CONTACTUS_SUCCESS:
      return {
        ...state,
        loader: false,
        addcontactus_data: action?.payload,
      };
    case ADD_CONTACTUS_FAILURE:
      return {
        ...state,
        loader: false,
        addcontactus_error: action?.payload,
      };

    case GET_COURSES:
      return {
        ...state,
        loader: true,
      };
    case GET_COURSES_SUCCESS:
      return {
        ...state,
        loader: false,
        getcourses_data: action?.payload,
      };
    case GET_COURSES_FAILURE:
      return {
        ...state,
        loader: false,
        getcourses_error: action?.payload,
      };
    case ADD_REQUESTINFO:
      return {
        ...state,
        loader: true,
      };
    case ADD_REQUESTINFO_SUCCESS:
      return {
        ...state,
        loader: false,
        addrequestinfo_data: action?.payload,
      };
    case ADD_REQUESTINFO_FAILURE:
      return {
        ...state,
        loader: false,
        addrequestinfo_error: action?.payload,
      };

    case GET_COMBO_COURSES:
      return {
        ...state,
        getComboCoursesLoader: true,
      };
    case GET_COMBO_COURSES_SUCCESS:
      return {
        ...state,
        getComboCoursesLoader: false,
        getComboCoursesData: action?.payload,
      };
    case GET_COMBO_COURSES_FAILURE:
      return {
        ...state,
        getComboCoursesLoader: false,
        getComboCoursesError: action?.payload,
      };

    default:
      return state;
  }
};

export default CommonReducer;
