import React from "react";
import { Link } from "react-router-dom";
import profile from "../../assets/images/testimonialProfile.svg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../assets/styles/Slider.css";
import defaultImage from "../../assets/images/defaultImage.svg";

function Section3() {
  const data = [
    {
      img: defaultImage,
      name: "Sucheta Sardesai",
      title: "Medical Billing and Coding Program (MBCS)",
      content:
        "I have completed Medical Billing and Coding Program (MBCS) from Param Institute of Education, I found the  services offered at PIE to be excellent and helpful, the staff is very supportive and polite. They also provide excellent services with externship and placement in the medical field. I highly recommend this Institute for great education and learning in healthcare industry",
    },
    {
      img: defaultImage,
      name: "Kezia Samuel",
      title: "Certified Home Health Aid (CHHA)",
      content:
        "I completed my CHHA (Certified Home Health Aid) program from Param Institute of Education. The program was easy to complete. The faculty was very nice, friendly, and helpful. I absolutely loved the process and experience with them! Would highly recommend anyone that is interested to give them a try.",
    },
    {
      img: defaultImage,
      name: "Ally Licks",
      title: "Certified Phlebotomy Technician (CPT)",
      content:
        "My name is Ally Licks, and I just completed my Certified Phlebotomy Technician (CPT) class exam, the admissions process was very easily navigated. The class was great! Small class sizes, and great individualized instruction. I would highly recommend this program, and cannot thank the instructors and staff enough!",
    },
    {
        img: defaultImage,
        name: "Jignasa Patel",
        title: "Certified Clinical Medical Assistant (CCMA)",
        content: "My name is Jignasa Patel, and I've recently completed my studies in the Certified Clinical Medical Assistant (CCMA) program at PARAM Institute of Education. I must say, my experience at PARAM has been absolutely remarkable. The faculty and instructors provided unwavering support, ensuring a seamless and enriching journey every step of the way. Throughout my externship at a local physician's office, I gained invaluable hands-on experience, seamlessly connecting theory with practice. The program's comprehensive curriculum left no stone unturned, equipping me with the knowledge and skills necessary to excel in patient care and ace the NHA exam. I wholeheartedly recommend PARAM Institute of Education to anyone considering a career in healthcare. With its outstanding CCMA program, dedicated staff, and emphasis on practical learning, PARAM truly stands out as the ideal choice."
    },
    {
        img: defaultImage,
        name: "Shefali Makhesana",
        title: "Massage therapy",
        content: "Completing the Massage therapy course at Param Institute of Education has been an enriching journey. The comprehensive curriculum, expert instructors, and hands on training have equipped me with a deep understanding of massage techniques and holistic wellness practices. The institute's dedication to excellence in education truly shines through, and I highly recommend it to anyone considering a career in Massage Therapy Program. Thank you Param Institute of Education, for helping me achieve my goals in this field."
    },
  ];

  const settings = {
    dots: true,
    arrows: false,
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    speed: 10,
    cssEase: "ease-in",
    appendDots: (dots) => <ul>{dots}</ul>,
    customPaging: (i) => (
      <div className="ft-slick__dots--custom">
        <div className="loading" />
      </div>
    ),
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 1.7,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="w-full font-poppins flex items-center bg-[#003C9D1A] pt-10 pb-20 md:pb-16 flex-col gap-10">
      <p className="font-[600] text-[24px] md:text-[48px] text-primary">
        Testimonials
      </p>
      <div className="w-[calc(100%_-_32px)] md:w-[calc(100%_-_64px)] xl:w-[90%] flex gap-5 max-h-[400px]">
        <Slider {...settings} className="w-full ">
          {data.map((data) => (
            <div
              id="Card"
              className="w-[560px] bg-white rounded-[10px]  shadow-md flex flex-col items-center p-5 cardBorder min-h-[324px]"
            >
              <div className="flex justify-center">
                <img src={data.img} />
              </div>
              <p className="font-[500] text-[24px] text-primary flex justify-center">
                {data.name}
              </p>
              <p className="font-[400] text-[16px] text-primary flex justify-center">
                {data.title}
              </p>
              <p className="font-[400] text-[16px] text-[#5C616E] text-center mt-4 overflow-hidden text-ellipsis line-clamp-5">
                {data.content}
              </p>
            </div>
          ))}
        </Slider>
      </div>
      <div className="mt-10">
        <Link to={"/Testimonial"}>
          <button className="orangeHoverButton">View More</button>
          {/* <li>Leave of Absence</li> */}
        </Link>
      </div>
    </div>
  );
}

export default Section3;
