import React, { useEffect } from "react";
import LatestPost from "../assets/images/LatestPost.svg";
import blogDetails from "../assets/images/blogDetails.svg";
import { useNavigate, useParams } from "react-router-dom";
import { getBlogById, getRecentBlogs } from "../redux/actions/blog.action";
import { useDispatch, useSelector } from "react-redux";
import defaultBlog from "../assets/images/defaultBlog.webp";
import * as Yup from "yup";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { addBlogComment } from "../redux/actions/blog.action";

function BlogDetails() {
  const { id } = useParams();
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const recentBlogsLimit = useSelector(
    (state) => state.BlogReducer.recent_blog_limit
  );
  const blogdata = useSelector(
    (state) => state.BlogReducer.blog_by_id_data?.data[0]
  );
  const latestPosts = useSelector(
    (state) => state.BlogReducer.recent_blogs_data
  );

  useEffect(() => {
    dispatch(getBlogById(id));
    dispatch(getRecentBlogs(recentBlogsLimit));
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [id]);

  const latestPost = [
    {
      img: LatestPost,
      title: "Should You Consider Medical Assisting as Your Next Career Move?",
    },
    {
      img: LatestPost,
      title:
        "Navigating the Path: Exploring Medical Billing and Coding Careers",
    },
    {
      img: LatestPost,
      title:
        "Healthcare Certifications: Boost Your Career and Elevate Patient Care",
    },
    {
      img: LatestPost,
      title: "The Licensed Massage Therapist: A Holistic wellness",
    },
    {
      img: LatestPost,
      title: "The Pathways to success: A Private Vocational School",
    },
  ];

  const validationSchema = Yup.object().shape({
    fullName: Yup.string().required("Full name is required."),
    email: Yup.string()
      .email("Please enter valid email")
      .required("Email is required"),
    comment: Yup.string().required("Comment is required"),
  });

  const handleSubmit = async (values, { resetForm }) => {
    const payload = {
      fullname: values.fullName,
      email: values.email,
      comment: values.comment,
      blog_id: id,
    };
    dispatch(addBlogComment(payload));
    resetForm();
  };

  return (
    <>
      <div className=" w-full flex flex-col items-center font-poppins gap-12 md:gap-20 ">
        <div className="bg-blogBg-bg w-full h-[400px] md:h-[600px] justify-center items-center flex flex-col gap-8 md:gap-12 ">
          <p className="font-[800] text-[32px] md:text-[50px] xl:text-[60px] w-[calc(100%_-_32px)] md:w-[calc(100%_-_64px)] text-white text-center">
            Welcome To Our Blog
          </p>
          <div className=" flex justify-center gap-4 md:gap-8 flex-col md:flex-row">
            <button
              className="orangeButton"
              onClick={() => navigation("../scheduleAppointment")}
            >
              Schedule a Visit
            </button>
            <button
              className="orangeButton"
              onClick={() => navigation("../requestInfo")}
            >
              Inquire Now
            </button>
            <button
              className="orangeButton"
              onClick={() => navigation("../enrollment")}
            >
              Enroll Now
            </button>
          </div>
        </div>
        <div className="hidden xl:flex gap-6 items-center w-[90%]">
          <p
            className="font-[500] text-[20px] text-[#5D6169] cursor-pointer"
            onClick={() => {
              navigation("/");
            }}
          >
            Home
          </p>
          <div className="h-[10px] w-[10px] bg-[#003C9D] rounded-full"></div>
          <p className="font-[500] text-[20px] text-[#003C9D]">Blog</p>
        </div>
        <div className="w-[calc(100%_-_32px)] md:w-[calc(100%_-_64px)] xl:w-[90%] flex gap-12 991:gap-4 xl:gap-0 flex-col 991:flex-row">
          <div className="991:w-[75%]">
            <div className="w-full xl:w-[90%] flex gap-4 xl:gap-8 flex-wrap">
              <div className="w-full h-full relative pb-[44%] top-0 left-0">
                <div className="absolute top-0 left-0 w-full h-full">
                  <img
                    src={
                      blogdata?.blog_img.includes("undefined")
                        ? defaultBlog
                        : blogdata?.blog_img
                    }
                    className="w-full h-full object-cover"
                  />
                </div>
              </div>
              <div className="flex gap-2 items-center">
                <p className="font-[500] text-[18px] text-primary">
                  {blogdata?.categoryname}
                </p>
                <div className="border border-r-[#989898] h-[15px] mr-2 hidden md:block" />
                <p className="font-[400] text-[14px] text-[#5C616E] hidden md:block">
                  {blogdata?.blog_published_by}
                </p>
                <div className="border border-r-[#989898] h-[15px] mr-2" />
                <p className="font-[400] text-[14px] text-primary">
                  {new Date(blogdata?.published_date).toLocaleDateString(
                    "en-US",
                    { month: "short", day: "2-digit", year: "numeric" }
                  )}
                </p>
              </div>
              {/* <div className='flex gap-6 flex-col w-[97%]'>
                    <p className='font-[600] text-[20px] xl:text-[40px] text-black leading-[30px] xl:leading-[50px]'>{blogdata?.blog_title}</p>
                    <p className='font-[400] text-[18px] text-primary leading-[40px] -mt-2'>If you’re ready to strike a fulfilling career in healthcare administration, Param Institute of Education’s MBC program is the perfect place to start. With our comprehensive curriculum, expert faculty, and commitment to student success, we’ll help you unlock your full potential in the field of medical billing and coding.</p>
                    <p className='font-[400] text-[18px] text-primary leading-[40px]'>In the fast-paced world of healthcare, behind every successful medical practice lies a crucial administrative backbone: medical billing and coding specialists. As the healthcare industry continues to evolve, the demand for skilled professionals in medical billing and coding is higher than ever before. If you’re considering a rewarding career in healthcare administration, look no further than Param Institute of Education’s comprehensive Medical Billing and Coding (MBC) Certification Program in New Jersey.</p>
                    <p className='font-[600] text-[20px] text-[#5C616E] '>Why Choose Param Institute of Education?</p>
                    <p className='font-[400] text-[18px] text-primary leading-[40px]'>Param Institute of Education stands out as a beacon of excellence in the field of healthcare education. With a commitment to providing top-tier training and practical skills, Param Institute of Education empowers students to excel in their careers. Here’s why our MBC program is the ultimate choice for aspiring healthcare professionals:</p>
                    <ul className='list-disc ml-6 font-[400] text-[18px] text-primary leading-[40px] flex gap-4 flex-col'>
                        <li>Accredited Curriculum: Our MBCS program is designed by industry standards and is accredited by a reputed National Health Career Association (NHA) agency. You can trust that your education meets the highest quality standards.</li>
                        <li>Expert Faculty: Learn from experienced instructors who are seasoned professionals in the field of medical billing and coding. Our faculty members are dedicated to guiding you through every step of your educational journey.</li>
                        <li>Hands-On Training: At Param Institute of Education, we believe in learning by doing. Our MBC program offers hands-on training opportunities that allow students to apply their knowledge in real-world scenarios, ensuring they are well-prepared for the workforce.</li>
                        <li>Flexible Learning Options: We understand that many of our students have busy schedules. That’s why we offer flexible learning options, including evening and hybrid classes, as well as online courses, so you can pursue your education on your own terms.</li>
                        <li>Career Support Services: From resume building to job placement assistance, Param Institute of Education is dedicated to helping you succeed beyond the classroom. Our career support services are designed to connect graduates with employment opportunities in the healthcare industry.</li>
                    </ul>
                    <p className='font-[500] text-[20px] text-[#5C616E] '>What Sets Our CMBC Program Apart?</p>
                    <p className='font-[500] text-[18px] text-primary'>Param Institute of Education’s MBC program goes above and beyond to prepare students for successful careers in medical billing and coding. Here are some key highlights of our program:</p>
                    <ul className='list-disc ml-6 font-[400] text-[18px] text-primary leading-[40px] flex gap-4 flex-col'>
                        <li>Comprehensive Curriculum: Our MBC program covers a wide range of topics, including medical terminology, insurance claims processing, coding systems, healthcare regulations, and more. Students graduate with a deep understanding of the entire medical billing and coding process.</li>
                        <li>Industry-Relevant Skills: We equip our students with the practical skills and knowledge they need to thrive in the fast-paced world of healthcare administration. From coding accuracy to revenue cycle management, our program focuses on the skills that are in high demand by employers.</li>
                        <li>Certification Preparation: Upon completion of our MBC program, students are prepared to sit for healthcare industry-recognized certification exams, conducted by NHA. Certification can significantly enhance job prospects and earning potential in the field of medical billing and coding, in any state of the USA’</li>
                        <li>Networking Opportunities: At Param Institute of Education, students have the opportunity to network with industry professionals and peers, building valuable connections that can open doors to future career opportunities.</li>
                    </ul>
                    <p className='font-[500] text-[18px] text-primary leading-[40px]'>Don’t wait any longer to pursue your dreams. Enroll in Param Institute of Education’s MBC program today and take the first step towards a brighter future in healthcare. Your success starts here.</p>
                </div> */}

              <div className="flex gap-6 flex-col w-[97%] leading-[40px]">
                <p className="font-[600] text-[20px] xl:text-[40px] text-black leading-[30px] xl:leading-[50px]">
                  {blogdata?.blog_title}
                </p>
                <div
                  className="blog-content prose lg:prose-xl"
                  dangerouslySetInnerHTML={{ __html: blogdata?.blog_content }}
                ></div>
              </div>
            </div>
          </div>
          <div className="">
            <p className="font-[600] text-[24px] xl:text-[32px] text-[#F37021] underline">
              Latest Posts
            </p>
            <div className="flex flex-col gap-6 mt-[30px]">
              {latestPosts?.data?.map((post, index) => (
                <div className="w-full  991:w-[350px] xl:w-[411px] items-start 991:items-center  shadow-lg border border-[#EBEBEB] rounded-[5px] p-3 flex gap-8">
                  {/* <img src={post.blog_img} /> */}

                  <img
                    src={post?.blog_img}
                    className="h-[100px] w-[100px] object-cover self-start"
                  />

                  <div className="flex flex-col gap-4">
                    <p className="font-[500] text-[18px] text-[#1B2336] overflow-hidden text-ellipsis line-clamp-2">
                      {post.blog_title}
                    </p>
                    <div className="flex gap-2 group items-center">
                      <p
                        className="font-[400] text-[14px] text-[#F37021] underline group-hover:text-[#003C9D]"
                        onClick={() => {
                          navigation(`/blogDetail/${post?.id}`);
                        }}
                      >
                        Read More
                      </p>
                      <svg
                        width="18"
                        height="12"
                        viewBox="0 0 18 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="fill-[#F37021] group-hover:fill-[#003C9D]  transform group-hover:translate-x-2 transition duration-150 ease-in-out"
                      >
                        <path d="M12.039 0.343018L10.628 1.76102L13.898 5.01602L0.292969 5.02902L0.294969 7.02902L13.863 7.01602L10.648 10.246L12.065 11.656L17.709 5.98602L12.039 0.343018Z" />
                      </svg>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className=" flex bg-[#F37021] bg-opacity-10 py-[68px] justify-center mb-[-40px] mt-20">
        <div className="w-[calc(100%_-_32px)] md:w-[calc(100%_-_64px)] xl:w-[90%] flex justify-center items-center flex-col gap-[50px]">
          <span className="flex font-[600] text-[32px] leading-[35px] text-[#003C9D] text-left w-full">
            Leave a Comment
          </span>
          <Formik
            initialValues={{
              fullName: "",
              comment: "",
              email: "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ errors, touched }) => (
              <Form className="w-full flex flex-col items-center gap-8">
                <div className="w-full flex gap-8 md:gap-4 xl:gap-5 flex-col md:flex-row">
                  <div className=" w-full flex flex-col gap-2">
                    <label className="font-[400] text-[16px] md:text-[18px] text-[#5D6169]">
                      Full Name
                    </label>
                    <Field
                      type="text"
                      name="fullName"
                      className={`h-[50px] border border-[#989898] rounded-[5px] p-3 ${
                        errors.fullName && touched.fullName
                          ? "border-red-500"
                          : ""
                      }`}
                    />
                    <ErrorMessage
                      name="fullName"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                  <div className="w-full flex flex-col gap-2">
                    <label className="font-[400] text-[16px] md:text-[18px] text-[#5D6169]">
                      Email
                    </label>
                    <Field
                      type="text"
                      name="email"
                      className={`h-[50px] border border-[#989898] rounded-[5px] p-3 ${
                        errors.email && touched.email ? "border-red-500" : ""
                      }`}
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                </div>

                <div className="w-full flex gap-8 md:gap-5 flex-col md:flex-row">
                  <div className=" w-full flex flex-col gap-2">
                    <label className="font-[400] text-[16px] md:text-[18px] text-[#5D6169]">
                      Comment
                    </label>
                    <Field
                      as="textarea"
                      name="comment"
                      placeholder="Comment Here......."
                      rows="5"
                      className={` border border-[#989898] rounded-[5px] p-3 ${
                        errors.comment && touched.comment
                          ? "border-red-500"
                          : ""
                      }`}
                    />
                    <ErrorMessage
                      name="comment"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                </div>

                <div className="flex gap-3 w-full mt-3 text-left">
                  <button type="submit" className="orangeHoverButton">
                    Submit
                  </button>
                  {/* <button
                type="button"
                className="orangeOutlineButton"
                onClick={() => navigate(-1)}
              >
                Cancel
              </button> */}
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}

export default BlogDetails;
