//--------------------------------------------------------- All Imports Starts -------------------------------------------------------------------------------//
import React, { useState } from "react";
import AppointmentTime from "../../components/scheduleAppointment/AppointmentTime";
import AppointmentForm from "../../components/scheduleAppointment/AppointmentForm";
import { useNavigate } from "react-router-dom";
//--------------------------------------------------------- All Imports Ends -------------------------------------------------------------------------------//

const ScheduleAppointment = () => {
  //------------------------------------------------ States, Variables Initialiation Starts ----------------------------------------------------------------//
  const [formStep, setFormStep] = useState(1);
  const navigation=useNavigate()
  //------------------------------------------------ States, Variables Initialiation Ends ----------------------------------------------------------------//

  return (
    <div className=" w-full flex flex-col items-center font-poppins mb-28 gap-12 md:gap-20">
      <div className="bg-appointment-bg w-full h-[400px] md:h-[600px] justify-center items-center flex flex-col">
        <p className="font-[800] text-[32px] md:text-[50px] xl:text-[60px] w-[calc(100%_-_32px)] md:w-[calc(100%_-_64px)] text-white text-center">
          Schedule an Appointment
        </p>
        <p className="font-[500] text-[20px] md:text-[36px]  w-[calc(100%_-_32px)] md:w-[calc(100%_-_64px)] text-white text-center mt-4">
          Start your career <span className="font-[800]">TODAY!</span>
        </p>
        <div className=" flex justify-center gap-6 md:gap-8 flex-col md:flex-row mt-12">
        <button
            className="orangeButton"
            onClick={() => navigation("../requestInfo")}
          >
            Inquire Now
          </button>
          <button
            className="orangeButton"
            onClick={() => navigation("../enrollment")}
          >
            Enroll Now
          </button>
        </div>
      </div>
      <div className="flex items-center justify-center flex-col 991:flex-col 1600:flex-row w-full">
        {formStep == 1 ? (
          <AppointmentTime setFormStep={setFormStep} />
        ) : (
          <AppointmentForm setFormStep={setFormStep} />
        )}
      </div>
    </div>
  );
};

export default ScheduleAppointment;
