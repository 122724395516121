import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { useNavigate } from "react-router-dom";
import {
  addEnrollment,
  addEnrollmentSuccess,
} from "../../../redux/actions/StudentServices.action";
import { getEducationLevel } from "../../../redux/actions/educationLevel.action";
import { imageGenerator } from "../../../utils/imageGenerator";
import {
  getCourses,
  getComboCourses,
} from "../../../redux/actions/common.action";
import useLoader from "../../../hooks/useLoader";

function Enrollment() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading, setLoader, Loader } = useLoader();
  const { getcourses_data, getComboCoursesData } = useSelector(
    (state) => state.CommonReducer
  );
  const formRef = useRef(null);
  const iframeRef = useRef(null);
  const { educationLevelData } = useSelector(
    (state) => state.educationLevelReducer
  );
  const { addEnrollmentData, addEnrollmentError } = useSelector(
    (state) => state.studentServicesReducer
  );
  const [count, setCount] = useState(0);
 
  useEffect(() => {
    const iframe = iframeRef.current;
    if (iframe) {
      iframe.onload = () => {
        if (window.innerWidth < 576) {
          // Execute code if screen width is less than 767 pixels
          iframeRef.current.style.height = `2300px`;
        } else {
          // Execute code if screen width is 767 pixels or more
          iframeRef.current.style.height = `2100px`;
        }
        if (count < 1) {
          setCount((n) => n + 1);
        } else if (count < 3 && count > 0) {
         
          iframeRef.current.style.height = `90vh`;
          window.scrollTo(0, 0)
          setCount(100);
        }
      };
    }
  }, [count]);

  useEffect(() => {
    if (addEnrollmentData && addEnrollmentData.status == 201) {
      setLoader(false);
      const message = addEnrollmentData.data.message;
      dispatch(addEnrollmentSuccess(null));
      navigate("../thankYou", {
        state: { message: message },
      });
    }
  }, [addEnrollmentData]);
  //----------------------------------------------------------USE EFFECT FOR GET COURSES-----------------------------------------------------------------//
  useEffect(() => {
    dispatch(getCourses());
    dispatch(getEducationLevel());
    dispatch(getComboCourses());
  }, [dispatch]);

  //----------------------------------------------------------USE EFFECT FOR GET COURSES-----------------------------------------------------------------//
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First Name is required."),
    lastName: Yup.string().required("Last Name is required."),
    middleName: Yup.string().required("Middle Name is required."),
    email: Yup.string()
      .email("Please enter valid email")
      .required("Email is required."),
    phone: Yup.string().required("Phone number is required."),
    cell: Yup.string().required("Cell number is required."),
    driverLicence: Yup.string().required("License number is required."),
    ssn: Yup.string().required("SSN number is required."),
    gender: Yup.string().required("Please select one option."),
    ageConformation: Yup.string().required("Please select one option."),
    disabilityStatus: Yup.string().required("Please select one option."),
    offenseStatus: Yup.string().required("Please select one option."),
    citizenStatus: Yup.string().required("Please select one option."),
    employementStatus: Yup.string().required(
      "Please select atleast one option."
    ),
    levelOfEducation: Yup.array().required("Please select one option."),
    raceEthnicity: Yup.array().required("Please select one option."),
    techProgramEnrolled: Yup.string().required("Please select one option."),

    address: Yup.string().required("Address is required"),
    city: Yup.string().required("City is required."),
    state: Yup.string().required("State is required."),
    zip: Yup.string().required("Zip is required."),
    dateofbirth: Yup.string().required("Date of Birth is required."),
    todayDate: Yup.string().required("Today's date is required."),
    signName: Yup.string().required("Name is required."),

    techProgramEnrolled: Yup.string().required("Please select one option."),
    singleParent: Yup.string().required("Please select one option."),
    literacyProgramEnrolled: Yup.string().required("Please select one option."),
    displacedHomemaker: Yup.string().required("Please select one option."),

    healthcarePrograms: Yup.array().required(
      "Please select atleast one option."
    ),
    comboPrograms: Yup.array().required("Please select atleast one option."),

    USACertifications: Yup.string()
      .typeError("You must fill this field")
      .when("healthcarePrograms", {
        is: (healthcarePrograms) => healthcarePrograms?.includes("12"),
        then: (schema) => schema.required("This is a required field."),
        otherwise: (schema) => schema.optional(),
      }),
    certificationState: Yup.string()
      .typeError("You must fill this field")
      .when("healthcarePrograms", {
        is: (healthcarePrograms) => healthcarePrograms?.includes("12"),
        then: (schema) => schema.required("State is required"),
        otherwise: (schema) => schema.optional(),
      }),
    cnaWrittenAppeared: Yup.string()
      .typeError("You must fill this field")
      .when("healthcarePrograms", {
        is: (healthcarePrograms) => healthcarePrograms?.includes("12"),
        then: (schema) => schema.required("Please select any one option."),
        otherwise: (schema) => schema.optional(),
      }),
    cnaSkillsAppeared: Yup.string()
      .typeError("You must fill this field")
      .when("healthcarePrograms", {
        is: (healthcarePrograms) => healthcarePrograms?.includes("12"),
        then: (schema) => schema.required("Please select any one option."),
        otherwise: (schema) => schema.optional(),
      }),
    cnaWrittenAttempts: Yup.string()
      .typeError("You must fill this field")
      .when("healthcarePrograms", {
        is: (healthcarePrograms) => healthcarePrograms?.includes("12"),
        then: (schema) => schema.required("This is a required field."),
        otherwise: (schema) => schema.optional(),
      }),
    cnaSkillsAttempts: Yup.string()
      .typeError("You must fill this field")
      .when("healthcarePrograms", {
        is: (healthcarePrograms) => healthcarePrograms?.includes("12"),
        then: (schema) => schema.required("This is a required field."),
        otherwise: (schema) => schema.optional(),
      }),

    chhaAgencyName: Yup.string()
      .typeError("Agency name is required")
      .when("healthcarePrograms", {
        is: (healthcarePrograms) => healthcarePrograms?.includes("13"),
        then: (schema) => schema.required("Agency name is required"),
        otherwise: (schema) => schema.optional(),
      }),
    chhaAddress: Yup.string()
      .typeError("Address is required")
      .when("healthcarePrograms", {
        is: (healthcarePrograms) => healthcarePrograms?.includes("13"),
        then: (schema) => schema.required("Address is required"),
        otherwise: (schema) => schema.optional(),
      }),
    chhaState: Yup.string()
      .typeError("State is required")
      .when("healthcarePrograms", {
        is: (healthcarePrograms) => healthcarePrograms?.includes("13"),
        then: (schema) => schema.required("State is required"),
        otherwise: (schema) => schema.optional(),
      }),
    chhaPhone: Yup.string()
      .typeError("Phone number is required")
      .when("healthcarePrograms", {
        is: (healthcarePrograms) => healthcarePrograms?.includes("13"),
        then: (schema) => schema.required("Phone number is required"),
        otherwise: (schema) => schema.optional(),
      }),
    cnaZIP: Yup.string()
      .typeError("Zip is required")
      .when("healthcarePrograms", {
        is: (healthcarePrograms) => healthcarePrograms?.includes("13"),
        then: (schema) => schema.required("Zip is required"),
        otherwise: (schema) => schema.optional(),
      }),
    cnaHPNumber: Yup.string()
      .typeError("HP number is required")
      .when("healthcarePrograms", {
        is: (healthcarePrograms) => healthcarePrograms?.includes("13"),
        then: (schema) => schema.required("HP number is required"),
        otherwise: (schema) => schema.optional(),
      }),
    chhaCity: Yup.string()
      .typeError("City is required")
      .when("healthcarePrograms", {
        is: (healthcarePrograms) => healthcarePrograms?.includes("13"),
        then: (schema) => schema.required("City is required"),
        otherwise: (schema) => schema.optional(),
      }),

    cmaCertificationType: Yup.string()
      .typeError("Type is required")
      .when("healthcarePrograms", {
        is: (healthcarePrograms) => healthcarePrograms?.includes("10"),
        then: (schema) => schema.required("Type is required"),
        otherwise: (schema) => schema.optional(),
      }),
    cmaCertificationNumber: Yup.string()
      .typeError("Certification number is required")
      .when("healthcarePrograms", {
        is: (healthcarePrograms) => healthcarePrograms?.includes("10"),
        then: (schema) => schema.required("Certification number is required"),
        otherwise: (schema) => schema.optional(),
      }),
    cmaIssuedState: Yup.string()
      .typeError("Issued state is required")
      .when("healthcarePrograms", {
        is: (healthcarePrograms) => healthcarePrograms?.includes("10"),
        then: (schema) => schema.required("Issued state is required"),
        otherwise: (schema) => schema.optional(),
      }),
    cmaExpirationDate: Yup.string()
      .typeError("Expiration date is required")
      .when("healthcarePrograms", {
        is: (healthcarePrograms) => healthcarePrograms?.includes("10"),
        then: (schema) => schema.required("Expiration date is required"),
        otherwise: (schema) => schema.optional(),
      }),
  });

  //------------------------------------------------------- Function to Handle Form Submission Starts ---------------------------------------------------//
  const handleSubmit = async (values, { resetForm }) => {
    const pdf = await imageGenerator(formRef.current);
    if (values.levelOfEducation.includes("3")) {
      const index = values.levelOfEducation.indexOf("3");
      values.levelOfEducation.splice(index, 1, values.levelOfEducationOther);
    }
    const payloadData = {
      firstname: values.firstName,
      lastname: values.lastName,
      middlename: values.middleName,
      email: values.email,
      address: values.address,
      city: values.city,
      state: values.state,
      zip: values.zip,
      phonenumber: values.phone,
      dob: values.dateofbirth,
      cell: values.cell,
      ssn: values.ssn,
      driverLicense: values.driverLicence,
      gender: values.gender,
      above18: values.ageConformation,
      disability: values.disabilityStatus,
      offenseStatus: values.offenseStatus,
      citizenshipStatus: values.citizenStatus,
      employmentStatus: values.employementStatus.toString(),
      education_level_id: values.levelOfEducation.toString(),
      raceEthnicity: values.raceEthnicity.toString(),
      techProgramEnrolled: values.techProgramEnrolled,
      displacedHomemaker: values.displacedHomemaker,
      singleParent: values.singleParent,
      literacyProgramEnrolled: values.literacyProgramEnrolled,
      healthcarePrograms: values.healthcarePrograms.toString(),
      comboPrograms: values.comboPrograms.toString(),

      cnaCertification: values.USACertifications,
      cnaCertificationState: values.certificationState,
      cnaWrittenAppeared: values.cnaWrittenAppeared
        ? values.cnaWrittenAppeared
        : null,
      cnaSkillsAppeared: values.cnaSkillsAppeared
        ? values.cnaSkillsAppeared
        : null,
      cnaWrittenAttempts: values.cnaWrittenAttempts,
      cnaSkillsAttempts: values.cnaSkillsAttempts,
      chhaAgencyName: values.chhaAgencyName,
      chhaAddress: values.chhaAddress,
      chhaCity: values.chhaCity,
      chhaState: values.chhaState,
      chhaZip: values.cnaZIP,
      chhaPhone: values.chhaPhone,
      chhaHpNumber: values.cnaHPNumber,
      cmaCertificateType: values.cmaCertificationType
        ? values.cmaCertificationType
        : null,
      cmaCertificationNumber: values.cmaCertificationNumber,
      cmaCertificationIssuedState: values.cmaIssuedState,
      cmaCertificationExpirationDate: values.cmaExpirationDate
        ? values.cmaExpirationDate
        : null,
      name: values.signName,
      submittedDate: values.todayDate,
      pdfFile: pdf,
    };

    try {
      dispatch(addEnrollment(payloadData));
      resetForm();
      setLoader(true);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };
  //------------------------------------------------------- Function to Handle Form Submission Ends ---------------------------------------------------//

  const raceEthnicity = [
    "White/Non-Hispanic",
    "Hispanic",
    "African-American/Non-Hispanic",
    "American Indian/Alaskan Native",
    "Asian",
    "Native Hawaiian/Pacific Islander",
    "Multiracial",
  ];

  const test = () => {
   
  };
  return (
    <div className="mb-[-40px] flex justify-center">
      <iframe
        ref={iframeRef}
        id="JotFormIFrame-241914280471151"
        title="Enrollment"
        onload="window.parent.scrollTo(0,0)"
        allowtransparency="true"
        allowfullscreen="true"
        allow="geolocation; microphone; camera"
        src="https://form.jotform.com/241914280471151?isIframeEmbed=1"
        frameborder="0"
        // style="min-width: 100%; max-width: 100%; height: 1320px; border: none;"
        scrolling="yes"
        className="min-w-full h-[2300px] 576:h-[2100px]"
        height="100%"
        width="100%"
      ></iframe>
    </div>

    // <div
    //   className="bg-[#F5F5F5] font-poppins py-12 flex flex-col items-center gap-12 -mb-10"
    //   ref={formRef}
    // >
    //   {isLoading ? (
    //     <Loader type={"component"} />
    //   ) : (
    //     <>
    //       <p className="font-[600] text-[32px] md:text-[40px] xl:text-[42px] text-[#F37021] text-center 991:text-left w-[calc(100%_-_32px)] md:w-[calc(100%_-_64px)] xl:w-[90%]">
    //         Enrollment Application Form
    //       </p>
    //       <Formik
    //         initialValues={{
    //           firstName: "",
    //           lastName: "",
    //           middleName: "",
    //           email: "",
    //           phone: "",
    //           cell: "",
    //           driverLicence: "",
    //           ssn: "",

    //           address: "",
    //           city: "",
    //           state: "",
    //           zip: "",
    //           dateofbirth: "",

    //           name: "",
    //           todaydate: "",
    //           gender: "",
    //           ageConformation: "",
    //           disabilityStatus: "",
    //           offenseStatus: "",
    //           citizenStatus: "",
    //           employementStatus: "",
    //           levelOfEducation: "",
    //           raceEthnicity: "",
    //           techProgramEnrolled: "",
    //           techProgramEnrolled: "",
    //           singleParent: "",
    //           literacyProgramEnrolled: "",
    //           displacedHomemaker: "",
    //           healthcarePrograms: "",
    //           comboPrograms: "",
    //           signName: "",
    //           todayDate: "",
    //           USACertifications: "",
    //           certificationState: "",
    //           cnaWrittenAppeared: "",
    //           cnaSkillsAppeared: "",
    //           cnaWrittenAttempts: "",
    //           cnaSkillsAttempts: "",
    //           chhaAgencyName: "",
    //           chhaAddress: "",
    //           chhaState: "",
    //           chhaPhone: "",
    //           cnaZIP: "",
    //           cnaHPNumber: "",
    //           chhaCity: "",
    //           cmaCertificationNumber: "",
    //           cmaIssuedState: "",
    //           cmaExpirationDate: "",
    //           cmaCertificationType: "",
    //           signName: "",
    //           todayDate: "",
    //           levelOfEducationOther: "",
    //         }}
    //         onSubmit={handleSubmit}
    //         validationSchema={validationSchema}
    //       >
    //         {({ values, errors, touched }) => (
    //           <Form className="flex flex-col gap-[60px] w-[calc(100%_-_32px)] md:w-[calc(100%_-_64px)] xl:w-[90%]">
    //             <div className="w-full flex flex-col items-center gap-8">
    //               <span className="text-[24px] font-[500] leading-[24px] text-[#003C9D] text-left w-full">
    //                 SECTION 1 : PERSONAL DATA
    //               </span>
    //               <div className="w-full flex gap-8 md:gap-4 xl:gap-5 flex-col 991:flex-row">
    //                 <div className=" w-full flex flex-col gap-2">
    //                   <label className="font-[400] text-[16px] md:text-[18px] text-[#1B2336]">
    //                     Last Name <span className="text-[#BB2727]">*</span>
    //                   </label>
    //                   <Field
    //                     type="text"
    //                     name="lastName"
    //                     className={`h-[50px] border border-[#989898] rounded-[5px] p-3 ${
    //                       errors.lastName && touched.lastName
    //                         ? "border-red-500"
    //                         : ""
    //                     }`}
    //                   />
    //                   <ErrorMessage
    //                     name="lastName"
    //                     component="div"
    //                     className="text-red-500"
    //                   />
    //                 </div>
    //                 <div className=" w-full flex flex-col gap-2">
    //                   <label className="font-[400] text-[16px] md:text-[18px] text-[#1B2336]">
    //                     First Name <span className="text-[#BB2727]">*</span>
    //                   </label>
    //                   <Field
    //                     type="text"
    //                     name="firstName"
    //                     className={`h-[50px] border border-[#989898] rounded-[5px] p-3 ${
    //                       errors.firstName && touched.firstName
    //                         ? "border-red-500"
    //                         : ""
    //                     }`}
    //                   />
    //                   <ErrorMessage
    //                     name="firstName"
    //                     component="div"
    //                     className="text-red-500"
    //                   />
    //                 </div>
    //                 <div className=" w-full flex flex-col gap-2">
    //                   <label className="font-[400] text-[16px] md:text-[18px] text-[#1B2336]">
    //                     Middle Name <span className="text-[#BB2727]">*</span>
    //                   </label>
    //                   <Field
    //                     type="text"
    //                     name="middleName"
    //                     className={`h-[50px] border border-[#989898] rounded-[5px] p-3 ${
    //                       errors.middleName && touched.middleName
    //                         ? "border-red-500"
    //                         : ""
    //                     }`}
    //                   />
    //                   <ErrorMessage
    //                     name="middleName"
    //                     component="div"
    //                     className="text-red-500"
    //                   />
    //                 </div>
    //               </div>
    //               <div className="w-full  flex gap-8 md:gap-4 xl:gap-5 flex-col 991:flex-row">
    //                 <div className=" w-full flex flex-col gap-2">
    //                   <label className="font-[400] text-[16px] md:text-[18px] text-[#1B2336]">
    //                     Address <span className="text-[#BB2727]">*</span>
    //                   </label>
    //                   <Field
    //                     type="text"
    //                     name="address"
    //                     className={`h-[50px] border border-[#989898] rounded-[5px] p-3 ${
    //                       errors.address && touched.address
    //                         ? "border-red-500"
    //                         : ""
    //                     }`}
    //                   />
    //                   <ErrorMessage
    //                     name="address"
    //                     component="div"
    //                     className="text-red-500"
    //                   />
    //                 </div>
    //                 <div className=" w-full flex flex-col gap-2">
    //                   <label className="font-[400] text-[16px] md:text-[18px] text-[#1B2336]">
    //                     City <span className="text-[#BB2727]">*</span>
    //                   </label>
    //                   <Field
    //                     type="text"
    //                     name="city"
    //                     className={`h-[50px] border border-[#989898] rounded-[5px] p-3 ${
    //                       errors.city && touched.city ? "border-red-500" : ""
    //                     }`}
    //                   />
    //                   <ErrorMessage
    //                     name="city"
    //                     component="div"
    //                     className="text-red-500"
    //                   />
    //                 </div>
    //               </div>
    //               <div className="w-full flex gap-8 md:gap-4 xl:gap-5 flex-col 991:flex-row">
    //                 <div className=" w-full flex flex-col gap-2">
    //                   <label className="font-[400] text-[16px] md:text-[18px] text-[#1B2336]">
    //                     State <span className="text-[#BB2727]">*</span>
    //                   </label>
    //                   <Field
    //                     type="text"
    //                     name="state"
    //                     className={`h-[50px] border border-[#989898] rounded-[5px] p-3 ${
    //                       errors.state && touched.state ? "border-red-500" : ""
    //                     }`}
    //                   />
    //                   <ErrorMessage
    //                     name="state"
    //                     component="div"
    //                     className="text-red-500"
    //                   />
    //                 </div>
    //                 <div className=" w-full flex flex-col gap-2">
    //                   <label className="font-[400] text-[16px] md:text-[18px] text-[#1B2336]">
    //                     ZIP <span className="text-[#BB2727]">*</span>
    //                   </label>
    //                   <Field
    //                     type="text"
    //                     name="zip"
    //                     className={`h-[50px] border border-[#989898] rounded-[5px] p-3 ${
    //                       errors.zip && touched.zip ? "border-red-500" : ""
    //                     }`}
    //                   />
    //                   <ErrorMessage
    //                     name="zip"
    //                     component="div"
    //                     className="text-red-500"
    //                   />
    //                 </div>
    //               </div>
    //               <div className="w-full flex gap-8 md:gap-5 flex-col 991:flex-row">
    //                 <div className=" w-full flex flex-col gap-2">
    //                   <label className="font-[400] text-[16px] md:text-[18px] text-[#1B2336]">
    //                     Phone <span className="text-[#BB2727]">*</span>
    //                   </label>
    //                   <Field
    //                     type="text"
    //                     name="phone"
    //                     className={`h-[50px] border border-[#989898] rounded-[5px] p-3 ${
    //                       errors.phone && touched.phone ? "border-red-500" : ""
    //                     }`}
    //                   />
    //                   <ErrorMessage
    //                     name="phone"
    //                     component="div"
    //                     className="text-red-500"
    //                   />
    //                 </div>
    //                 <div className=" w-full flex flex-col gap-2">
    //                   <label className="font-[400] text-[16px] md:text-[18px] text-[#1B2336]">
    //                     Cell <span className="text-[#BB2727]">*</span>
    //                   </label>
    //                   <Field
    //                     type="text"
    //                     name="cell"
    //                     className={`h-[50px] border border-[#989898] rounded-[5px] p-3 ${
    //                       errors.cell && touched.cell ? "border-red-500" : ""
    //                     }`}
    //                   />
    //                   <ErrorMessage
    //                     name="cell"
    //                     component="div"
    //                     className="text-red-500"
    //                   />
    //                 </div>
    //                 <div className=" w-full flex flex-col gap-2">
    //                   <label className="font-[400] text-[16px] md:text-[18px] text-[#1B2336]">
    //                     Email <span className="text-[#BB2727]">*</span>
    //                   </label>
    //                   <Field
    //                     type="text"
    //                     name="email"
    //                     className={`h-[50px] border border-[#989898] rounded-[5px] p-3 ${
    //                       errors.email && touched.email ? "border-red-500" : ""
    //                     }`}
    //                   />
    //                   <ErrorMessage
    //                     name="email"
    //                     component="div"
    //                     className="text-red-500"
    //                   />
    //                 </div>
    //               </div>
    //               <div className="w-full flex gap-8 md:gap-5 flex-col 991:flex-row">
    //                 <div className=" w-full flex flex-col gap-2">
    //                   <label className="font-[400] text-[16px] md:text-[18px] text-[#1B2336]">
    //                     SSN <span className="text-[#BB2727]">*</span>
    //                   </label>
    //                   <Field
    //                     type="text"
    //                     name="ssn"
    //                     className={`h-[50px] border border-[#989898] rounded-[5px] p-3 ${
    //                       errors.ssn && touched.ssn ? "border-red-500" : ""
    //                     }`}
    //                   />
    //                   <ErrorMessage
    //                     name="ssn"
    //                     component="div"
    //                     className="text-red-500"
    //                   />
    //                 </div>
    //                 <div className=" w-full flex flex-col gap-2">
    //                   <label className="font-[400] text-[16px] md:text-[18px] text-[#1B2336]">
    //                     Date of Birth <span className="text-[#BB2727]">*</span>
    //                   </label>
    //                   <Field
    //                     type="date"
    //                     name="dateofbirth"
    //                     className={`h-[50px] border border-[#989898] rounded-[5px] p-3 ${
    //                       errors.dateofbirth && touched.dateofbirth
    //                         ? "border-red-500"
    //                         : ""
    //                     }`}
    //                   />
    //                   <ErrorMessage
    //                     name="dateofbirth"
    //                     component="div"
    //                     className="text-red-500"
    //                   />
    //                 </div>
    //                 <div className=" w-full flex flex-col gap-2">
    //                   <label className="font-[400] text-[16px] md:text-[18px] text-[#1B2336]">
    //                     Driver License #{" "}
    //                     <span className="text-[#BB2727]">*</span>
    //                   </label>
    //                   <Field
    //                     type="text"
    //                     name="driverLicence"
    //                     className={`h-[50px] border border-[#989898] rounded-[5px] p-3 ${
    //                       errors.driverLicence && touched.driverLicence
    //                         ? "border-red-500"
    //                         : ""
    //                     }`}
    //                   />
    //                   <ErrorMessage
    //                     name="driverLicence"
    //                     component="div"
    //                     className="text-red-500"
    //                   />
    //                 </div>
    //               </div>
    //               <div className="w-full flex gap-8 md:gap-10 flex-col 991:flex-row flex-wrap">
    //                 <div className="w-full lg:w-[28%] xl:w-[33%] flex flex-col gap-2 max-w-max">
    //                   <label className="font-[400] text-[16px] md:text-[18px] text-[#1B2336]">
    //                     Gender <span className="text-[#BB2727]">*</span>
    //                   </label>
    //                   <div id="my-radio-group"></div>
    //                   <div
    //                     role="group"
    //                     aria-labelledby="my-radio-group"
    //                     className="flex gap-2 flex-col"
    //                   >
    //                     <div className="flex gap-[32px]">
    //                       <label className="font-[400] text-[14px] md:text-[16px] text-primary flex gap-[8px] custom-radio-container">
    //                         <Field
    //                           type="radio"
    //                           name="gender"
    //                           value="male"
    //                           className={`h-[21px] w-[21px] ${
    //                             errors.gender && touched.gender
    //                               ? "border-red-500"
    //                               : ""
    //                           }`}
    //                         />
    //                         <span class="checkmark"></span>
    //                         <span className="label">Male</span>
    //                       </label>
    //                       <label className="font-[400] text-[14px] md:text-[16px] text-primary flex gap-[8px] custom-radio-container">
    //                         <Field
    //                           type="radio"
    //                           name="gender"
    //                           value="female"
    //                           className={`h-[21px] w-[21px] ${
    //                             errors.gender && touched.gender
    //                               ? "border-red-500"
    //                               : ""
    //                           }`}
    //                         />
    //                         <span class="checkmark"></span>
    //                         <span className="label">Female</span>
    //                       </label>
    //                     </div>

    //                     <ErrorMessage
    //                       name="gender"
    //                       component="div"
    //                       className="text-red-500"
    //                     />
    //                   </div>
    //                 </div>
    //                 <div className="w-full lg:w-[31%] xl:w-[30%] flex flex-col gap-2 max-w-max">
    //                   <label className="font-[400] text-[16px] md:text-[18px] text-[#1B2336]">
    //                     Are you at least 18 years of age?{" "}
    //                     <span className="text-[#BB2727]">*</span>
    //                   </label>
    //                   <div id="my-radio-group"></div>
    //                   <div
    //                     role="group"
    //                     aria-labelledby="my-radio-group"
    //                     className="flex gap-2 flex-col"
    //                   >
    //                     <div className="flex gap-[32px]">
    //                       <label className="font-[400] text-[14px] md:text-[16px] text-primary flex gap-[8px] custom-radio-container">
    //                         <Field
    //                           type="radio"
    //                           name="ageConformation"
    //                           value="1"
    //                           className={`h-[21px] w-[21px] ${
    //                             errors.ageConformation &&
    //                             touched.ageConformation
    //                               ? "border-red-500"
    //                               : ""
    //                           }`}
    //                         />
    //                         <span class="checkmark"></span>
    //                         <span className="label">Yes</span>
    //                       </label>
    //                       <label className="font-[400] text-[14px] md:text-[16px] text-primary flex gap-[8px] custom-radio-container">
    //                         <Field
    //                           type="radio"
    //                           name="ageConformation"
    //                           value="0"
    //                           className={`h-[21px] w-[21px] ${
    //                             errors.ageConformation &&
    //                             touched.ageConformation
    //                               ? "border-red-500"
    //                               : ""
    //                           }`}
    //                         />
    //                         <span class="checkmark"></span>
    //                         <span className="label">No</span>
    //                       </label>
    //                     </div>

    //                     <ErrorMessage
    //                       name="ageConformation"
    //                       component="div"
    //                       className="text-red-500"
    //                     />
    //                   </div>
    //                 </div>
    //                 <div className="w-full lg:w-[33%] xl:w-[30%] flex flex-col gap-2 max-w-max">
    //                   <label className="font-[400] text-[16px] md:text-[18px] text-[#1B2336]">
    //                     Disability Status{" "}
    //                     <span className="text-[#BB2727]">*</span>
    //                   </label>
    //                   <div id="my-radio-group"></div>
    //                   <div
    //                     role="group"
    //                     aria-labelledby="my-radio-group"
    //                     className="flex flex-col gap-2"
    //                   >
    //                     <div className="flex gap-[32px]">
    //                       <label className="font-[400] text-[14px] md:text-[16px] text-primary flex gap-[8px] custom-radio-container">
    //                         <Field
    //                           type="radio"
    //                           name="disabilityStatus"
    //                           value="1"
    //                           className={`h-[21px] w-[21px] ${
    //                             errors.disabilityStatus &&
    //                             touched.disabilityStatus
    //                               ? "border-red-500"
    //                               : ""
    //                           }`}
    //                         />
    //                         <span class="checkmark"></span>
    //                         <span className="label">Disable</span>
    //                       </label>
    //                       <label className="font-[400] text-[14px] md:text-[16px] text-primary flex gap-[8px] custom-radio-container">
    //                         <Field
    //                           type="radio"
    //                           name="disabilityStatus"
    //                           value="0"
    //                           className={`h-[21px] w-[21px] ${
    //                             errors.disabilityStatus &&
    //                             touched.disabilityStatus
    //                               ? "border-red-500"
    //                               : ""
    //                           }`}
    //                         />
    //                         <span class="checkmark"></span>
    //                         <span className="label">Non-Disable</span>
    //                       </label>
    //                     </div>

    //                     <ErrorMessage
    //                       name="disabilityStatus"
    //                       component="div"
    //                       className="text-red-500"
    //                     />
    //                   </div>
    //                 </div>
    //                 <div className="w-full lg:w-[100%] xl:w-[100%] flex flex-col gap-2 max-w-max">
    //                   <label className="font-[400] text-[16px] md:text-[18px] text-[#1B2336] flex-1">
    //                     Have You Ever been Convicted of any offense other than a
    //                     traffic violation?{" "}
    //                     <span className="text-[#BB2727]">*</span>
    //                   </label>
    //                   <div id="my-radio-group"></div>
    //                   <div
    //                     role="group"
    //                     aria-labelledby="my-radio-group"
    //                     className="flex flex-col gap-2"
    //                   >
    //                     <div className="flex gap-[32px]">
    //                       <label className="font-[400] text-[14px] md:text-[16px] text-primary flex gap-[8px] custom-radio-container">
    //                         <Field
    //                           type="radio"
    //                           name="offenseStatus"
    //                           value="1"
    //                           className={`h-[21px] w-[21px] ${
    //                             errors.offenseStatus && touched.offenseStatus
    //                               ? "border-red-500"
    //                               : ""
    //                           }`}
    //                         />
    //                         <span class="checkmark"></span>
    //                         <span className="label">Yes</span>
    //                       </label>
    //                       <label className="font-[400] text-[14px] md:text-[16px] text-primary flex gap-[8px] custom-radio-container">
    //                         <Field
    //                           type="radio"
    //                           name="offenseStatus"
    //                           value="0"
    //                           className={`h-[21px] w-[21px] ${
    //                             errors.offenseStatus && touched.offenseStatus
    //                               ? "border-red-500"
    //                               : ""
    //                           }`}
    //                         />
    //                         <span class="checkmark"></span>
    //                         <span className="label">No</span>
    //                       </label>
    //                     </div>

    //                     <ErrorMessage
    //                       name="offenseStatus"
    //                       component="div"
    //                       className="text-red-500"
    //                     />
    //                   </div>
    //                 </div>
    //                 <div className="w-full lg:w-[100%] xl:w-[100%] flex flex-col gap-2 max-w-max">
    //                   <label className="font-[400] text-[16px] md:text-[18px] text-[#1B2336] flex-1">
    //                     Are You? <span className="text-[#BB2727]">*</span>
    //                   </label>
    //                   <div id="my-radio-group"></div>
    //                   <div
    //                     role="group"
    //                     aria-labelledby="my-radio-group"
    //                     className="flex flex-col gap-2"
    //                   >
    //                     <div className="flex gap-[32px] flex-wrap">
    //                       <label className="font-[400] text-[14px] md:text-[16px] text-primary flex gap-[8px] custom-radio-container">
    //                         <Field
    //                           type="radio"
    //                           name="citizenStatus"
    //                           value="U.S. Citizen"
    //                           className={`h-[21px] w-[21px] ${
    //                             errors.citizenStatus && touched.citizenStatus
    //                               ? "border-red-500"
    //                               : ""
    //                           }`}
    //                         />
    //                         <span class="checkmark"></span>
    //                         <span className="label">U.S. Citizen</span>
    //                       </label>
    //                       <label className="font-[400] text-[14px] md:text-[16px] text-primary flex gap-[8px] custom-radio-container">
    //                         <Field
    //                           type="radio"
    //                           name="citizenStatus"
    //                           value="GC Holder"
    //                           className={`h-[21px] w-[21px] ${
    //                             errors.citizenStatus && touched.citizenStatus
    //                               ? "border-red-500"
    //                               : ""
    //                           }`}
    //                         />
    //                         <span class="checkmark"></span>
    //                         <span className="label">GC Holder</span>
    //                       </label>
    //                       <label className="font-[400] text-[14px] md:text-[16px] text-primary flex gap-[8px] custom-radio-container">
    //                         <Field
    //                           type="radio"
    //                           name="citizenStatus"
    //                           value="Have Valid Work Permit"
    //                           className={`h-[21px] w-[21px] ${
    //                             errors.citizenStatus && touched.citizenStatus
    //                               ? "border-red-500"
    //                               : ""
    //                           }`}
    //                         />
    //                         <span class="checkmark"></span>
    //                         <span className="label">
    //                           Have Valid Work Permit
    //                         </span>
    //                       </label>
    //                       <label className="font-[400] text-[14px] md:text-[16px] text-primary flex gap-[8px] custom-radio-container">
    //                         <Field
    //                           type="radio"
    //                           name="citizenStatus"
    //                           value="Need Work Permit"
    //                           className={`h-[21px] w-[21px] ${
    //                             errors.citizenStatus && touched.citizenStatus
    //                               ? "border-red-500"
    //                               : ""
    //                           }`}
    //                         />
    //                         <span class="checkmark"></span>
    //                         <span className="label">Need Work Permit</span>
    //                       </label>
    //                     </div>

    //                     <ErrorMessage
    //                       name="citizenStatus"
    //                       component="div"
    //                       className="text-red-500"
    //                     />
    //                   </div>
    //                 </div>
    //               </div>
    //               <div className="w-full flex gap-8 md:gap-5 flex-col  991:flex-row">
    //                 <div className="w-full flex flex-col gap-2">
    //                   <label className="font-[400] text-[16px] md:text-[18px] text-[#1B2336]">
    //                     Employment Status at Enrollment{" "}
    //                     <span className="text-[#BB2727]">*</span>
    //                   </label>
    //                   <div id="my-checkbox-group"></div>
    //                   <div
    //                     role="group"
    //                     aria-labelledby="my-checkbox-group"
    //                     className="flex flex-col gap-2"
    //                   >
    //                     <div className="flex gap-[10px] 991:gap-[32px] flex-col 991:flex-row 991:flex-wrap">
    //                       <label className="font-[400] text-[14px] md:text-[16px] text-primary flex gap-[8px] custom-radio-container">
    //                         <Field
    //                           type="radio"
    //                           name="employementStatus"
    //                           value="employed-full-time"
    //                           className={`h-[21px] w-[21px] ${
    //                             errors.employementStatus &&
    //                             touched.employementStatus
    //                               ? "border-red-500"
    //                               : ""
    //                           }`}
    //                         />
    //                         <span class="checkmark"></span>
    //                         <span className="label">Employed Full-Time</span>
    //                       </label>
    //                       <label className="font-[400] text-[14px] md:text-[16px] text-primary flex gap-[8px] custom-radio-container">
    //                         <Field
    //                           type="radio"
    //                           name="employementStatus"
    //                           value="employed-part-time"
    //                           className={`h-[21px] w-[21px] ${
    //                             errors.employementStatus &&
    //                             touched.employementStatus
    //                               ? "border-red-500"
    //                               : ""
    //                           }`}
    //                         />
    //                         <span class="checkmark"></span>
    //                         <span className="label">Employed Part-Time</span>
    //                       </label>
    //                       <label className="font-[400] text-[14px] md:text-[16px] text-primary flex gap-[8px] custom-radio-container">
    //                         <Field
    //                           type="radio"
    //                           name="employementStatus"
    //                           value="unemployed"
    //                           className={`h-[21px] w-[21px] ${
    //                             errors.employementStatus &&
    //                             touched.employementStatus
    //                               ? "border-red-500"
    //                               : ""
    //                           }`}
    //                         />
    //                         <span class="checkmark"></span>
    //                         <span className="label">Unemployed</span>
    //                       </label>
    //                       <label className="font-[400] text-[14px] md:text-[16px] text-primary flex gap-[8px] custom-radio-container">
    //                         <Field
    //                           type="radio"
    //                           name="employementStatus"
    //                           value="unknown"
    //                           className={`h-[21px] w-[21px] ${
    //                             errors.employementStatus &&
    //                             touched.employementStatus
    //                               ? "border-red-500"
    //                               : ""
    //                           }`}
    //                         />
    //                         <span class="checkmark"></span>
    //                         <span className="label">Unknown</span>
    //                       </label>
    //                       <label className="font-[400] text-[14px] md:text-[16px] text-primary flex gap-[8px] custom-radio-container">
    //                         <Field
    //                           type="radio"
    //                           name="employementStatus"
    //                           value="not-in-labour-force"
    //                           className={`h-[21px] w-[21px] ${
    //                             errors.employementStatus &&
    //                             touched.employementStatus
    //                               ? "border-red-500"
    //                               : ""
    //                           }`}
    //                         />
    //                         <span class="checkmark"></span>
    //                         <span className="label">
    //                           Not in the labor force
    //                         </span>
    //                       </label>
    //                     </div>
    //                     <ErrorMessage
    //                       name="employementStatus"
    //                       component="div"
    //                       className="text-red-500"
    //                     />
    //                   </div>
    //                 </div>
    //               </div>
    //               <div className="w-full flex gap-8 md:gap-5 flex-col 991:flex-row">
    //                 <div className="w-full flex flex-col gap-2">
    //                   <label className="font-[400] text-[16px] md:text-[18px] text-[#1B2336]">
    //                     Level of Education{" "}
    //                     <span className="text-[#BB2727]">*</span>
    //                   </label>

    //                   <div id="my-checkbox-group"></div>
    //                   <div
    //                     role="group"
    //                     aria-labelledby="my-checkbox-group"
    //                     className="flex flex-col gap-2"
    //                   >
    //                     <div className="flex gap-[10px] md:gap-[19px] 991:gap-[32px] flex-col 991:flex-row 991:flex-wrap">
    //                       {educationLevelData?.data?.map((item) => (
    //                         <>
    //                           <label
    //                             className={`font-[400] text-[14px] md:text-[16px] text-primary flex gap-[8px] ${
    //                               item.id == "3" &&
    //                               values.levelOfEducation.includes("3") &&
    //                               "hidden"
    //                             } `}
    //                           >
    //                             <Field
    //                               type="checkbox"
    //                               name="levelOfEducation"
    //                               value={`${item.id}`}
    //                               className={`h-[21px] w-[21px] ${
    //                                 errors.levelOfEducation &&
    //                                 touched.levelOfEducation
    //                                   ? "border-red-500"
    //                                   : ""
    //                               }`}
    //                             />
    //                             {item.educationName || item.educationname}
    //                           </label>
    //                           <Field
    //                             type="text"
    //                             name="levelOfEducationOther"
    //                             className={`h-[30px] border border-[#989898] rounded-[5px] p-3 ${
    //                               item.id == "3" &&
    //                               values.levelOfEducation.includes("3")
    //                                 ? "block"
    //                                 : "hidden"
    //                             } ${
    //                               errors.levelOfEducationOther &&
    //                               touched.levelOfEducationOther
    //                                 ? "border-red-500"
    //                                 : ""
    //                             }`}
    //                           />
    //                         </>
    //                       ))}
    //                     </div>

    //                     <ErrorMessage
    //                       name="levelOfEducation"
    //                       component="div"
    //                       className="text-red-500"
    //                     />
    //                   </div>
    //                 </div>
    //               </div>
    //               <div className="w-full flex gap-8 md:gap-5 flex-col 991:flex-row">
    //                 <div className="w-full flex flex-col gap-2">
    //                   <label className="font-[400] text-[16px] md:text-[18px] text-[#1B2336]">
    //                     Race or Ethnicity{" "}
    //                     <span className="text-[#BB2727]">*</span>
    //                   </label>
    //                   <div id="my-checkbox-group"></div>
    //                   <div
    //                     role="group"
    //                     aria-labelledby="my-checkbox-group"
    //                     className="flex flex-col gap-2"
    //                   >
    //                     <div className="flex gap-[10px] md:gap-[19px] 991:gap-[32px] flex-col 991:flex-row 991:flex-wrap">
    //                       {raceEthnicity.map((item) => (
    //                         <label className="font-[400] text-[14px] md:text-[16px] text-primary flex gap-[8px] ">
    //                           <Field
    //                             type="checkbox"
    //                             name="raceEthnicity"
    //                             value={item}
    //                             className={`h-[21px] w-[21px] ${
    //                               errors.raceEthnicity && touched.raceEthnicity
    //                                 ? "border-red-500"
    //                                 : ""
    //                             }`}
    //                           />
    //                           {item}
    //                         </label>
    //                       ))}
    //                     </div>

    //                     <ErrorMessage
    //                       name="raceEthnicity"
    //                       component="div"
    //                       className="text-red-500"
    //                     />
    //                   </div>
    //                 </div>
    //               </div>
    //               <div className="w-full flex gap-4 xl:gap-8 flex-col 991:flex-row flex-wrap">
    //                 <div className="w-full lg:w-[28%] xl:w-[23.5%] flex flex-col gap-2 min-w-max">
    //                   <label className="font-[400] text-[16px] md:text-[18px] text-[#1B2336]">
    //                     Enrolled in a Tech-Prep Program?{" "}
    //                     <span className="text-[#BB2727]">*</span>
    //                   </label>
    //                   <div id="my-radio-group"></div>
    //                   <div
    //                     role="group"
    //                     aria-labelledby="my-radio-group"
    //                     className="flex flex-col gap-2"
    //                   >
    //                     <div className="flex gap-[32px]">
    //                       <label className="font-[400] text-[14px] md:text-[16px] text-primary flex gap-[8px] custom-radio-container">
    //                         <Field
    //                           type="radio"
    //                           name="techProgramEnrolled"
    //                           value="1"
    //                           className={`h-[21px] w-[21px] ${
    //                             errors.techProgramEnrolled &&
    //                             touched.techProgramEnrolled
    //                               ? "border-red-500"
    //                               : ""
    //                           }`}
    //                         />
    //                         <span class="checkmark"></span>
    //                         <span className="label">Yes</span>
    //                       </label>
    //                       <label className="font-[400] text-[14px] md:text-[16px] text-primary flex gap-[8px] custom-radio-container">
    //                         <Field
    //                           type="radio"
    //                           name="techProgramEnrolled"
    //                           value="0"
    //                           className={`h-[21px] w-[21px] ${
    //                             errors.techProgramEnrolled &&
    //                             touched.techProgramEnrolled
    //                               ? "border-red-500"
    //                               : ""
    //                           }`}
    //                         />
    //                         <span class="checkmark"></span>
    //                         <span className="label">No</span>
    //                       </label>
    //                     </div>

    //                     <ErrorMessage
    //                       name="techProgramEnrolled"
    //                       component="div"
    //                       className="text-red-500"
    //                     />
    //                   </div>
    //                 </div>
    //                 <div className="w-full lg:w-[31%] xl:w-[23.5%] flex flex-col gap-2 min-w-max">
    //                   <label className="font-[400] text-[16px] md:text-[18px] text-[#1B2336]">
    //                     Are You a Displaced Homemaker?{" "}
    //                     <span className="text-[#BB2727]">*</span>
    //                   </label>
    //                   <div id="my-radio-group"></div>
    //                   <div
    //                     role="group"
    //                     aria-labelledby="my-radio-group"
    //                     className="flex flex-col gap-2"
    //                   >
    //                     <div className="flex gap-[32px]">
    //                       <label className="font-[400] text-[14px] md:text-[16px] text-primary flex gap-[8px] custom-radio-container">
    //                         <Field
    //                           type="radio"
    //                           name="displacedHomemaker"
    //                           value="1"
    //                           className={`h-[21px] w-[21px] ${
    //                             errors.displacedHomemaker &&
    //                             touched.displacedHomemaker
    //                               ? "border-red-500"
    //                               : ""
    //                           }`}
    //                         />
    //                         <span class="checkmark"></span>
    //                         <span className="label">Yes</span>
    //                       </label>
    //                       <label className="font-[400] text-[14px] md:text-[16px] text-primary flex gap-[8px] custom-radio-container">
    //                         <Field
    //                           type="radio"
    //                           name="displacedHomemaker"
    //                           value="0"
    //                           className={`h-[21px] w-[21px] ${
    //                             errors.displacedHomemaker &&
    //                             touched.displacedHomemaker
    //                               ? "border-red-500"
    //                               : ""
    //                           }`}
    //                         />
    //                         <span class="checkmark"></span>
    //                         <span className="label">No</span>
    //                       </label>
    //                     </div>

    //                     <ErrorMessage
    //                       name="displacedHomemaker"
    //                       component="div"
    //                       className="text-red-500"
    //                     />
    //                   </div>
    //                 </div>
    //                 <div className="w-full lg:w-[33%] xl:w-[23.5%] flex flex-col gap-2 min-w-max">
    //                   <label className="font-[400] text-[16px] md:text-[18px] text-[#1B2336]">
    //                     Are you a Single Parent?{" "}
    //                     <span className="text-[#BB2727]">*</span>
    //                   </label>
    //                   <div id="my-radio-group"></div>
    //                   <div
    //                     role="group"
    //                     aria-labelledby="my-radio-group"
    //                     className="flex flex-col gap-2"
    //                   >
    //                     <div className="flex gap-[32px]">
    //                       <label className="font-[400] text-[14px] md:text-[16px] text-primary flex gap-[8px] custom-radio-container">
    //                         <Field
    //                           type="radio"
    //                           name="singleParent"
    //                           value="1"
    //                           className={`h-[21px] w-[21px] ${
    //                             errors.singleParent && touched.singleParent
    //                               ? "border-red-500"
    //                               : ""
    //                           }`}
    //                         />
    //                         <span class="checkmark"></span>
    //                         <span className="label">Yes</span>
    //                       </label>
    //                       <label className="font-[400] text-[14px] md:text-[16px] text-primary flex gap-[8px] custom-radio-container">
    //                         <Field
    //                           type="radio"
    //                           name="singleParent"
    //                           value="0"
    //                           className={`h-[21px] w-[21px] ${
    //                             errors.singleParent && touched.singleParent
    //                               ? "border-red-500"
    //                               : ""
    //                           }`}
    //                         />
    //                         <span class="checkmark"></span>
    //                         <span className="label">No</span>
    //                       </label>
    //                     </div>

    //                     <ErrorMessage
    //                       name="singleParent"
    //                       component="div"
    //                       className="text-red-500"
    //                     />
    //                   </div>
    //                 </div>
    //                 <div className="w-full lg:w-[100%] xl:w-[23.5%] flex flex-col gap-2 min-w-max">
    //                   <label className="font-[400] text-[16px] md:text-[18px] text-[#1B2336]">
    //                     Enrolled in a Literacy Program?{" "}
    //                     <span className="text-[#BB2727]">*</span>
    //                   </label>
    //                   <div id="my-radio-group"></div>
    //                   <div
    //                     role="group"
    //                     aria-labelledby="my-radio-group"
    //                     className="flex flex-col gap-2"
    //                   >
    //                     <div className="flex gap-[32px]">
    //                       <label className="font-[400] text-[14px] md:text-[16px] text-primary flex gap-[8px] custom-radio-container">
    //                         <Field
    //                           type="radio"
    //                           name="literacyProgramEnrolled"
    //                           value="1"
    //                           className={`h-[21px] w-[21px] ${
    //                             errors.literacyProgramEnrolled &&
    //                             touched.literacyProgramEnrolled
    //                               ? "border-red-500"
    //                               : ""
    //                           }`}
    //                         />
    //                         <span class="checkmark"></span>
    //                         <span className="label">Yes</span>
    //                       </label>
    //                       <label className="font-[400] text-[14px] md:text-[16px] text-primary flex gap-[8px] custom-radio-container">
    //                         <Field
    //                           type="radio"
    //                           name="literacyProgramEnrolled"
    //                           value="0"
    //                           className={`h-[21px] w-[21px] ${
    //                             errors.literacyProgramEnrolled &&
    //                             touched.literacyProgramEnrolled
    //                               ? "border-red-500"
    //                               : ""
    //                           }`}
    //                         />
    //                         <span class="checkmark"></span>
    //                         <span className="label">No</span>
    //                       </label>
    //                     </div>

    //                     <ErrorMessage
    //                       name="literacyProgramEnrolled"
    //                       component="div"
    //                       className="text-red-500"
    //                     />
    //                   </div>
    //                 </div>
    //               </div>
    //             </div>

    //             <div className="w-full flex flex-col items-center gap-8">
    //               <span className="text-[24px] font-[500] leading-[24px] text-[#003C9D] text-left w-full">
    //                 SECTION 2 : PROGRAMS OFFERED
    //               </span>

    //               <div className="w-full  flex gap-8 md:gap-5 flex-col md:flex-row">
    //                 <div className="w-full flex flex-col gap-2">
    //                   <label className="font-[400] text-[16px] md:text-[18px] text-[#1B2336]">
    //                     Healthcare Program{" "}
    //                     <span className="text-[#BB2727]">*</span>
    //                   </label>
    //                   <div id="my-checkbox-group"></div>
    //                   <div
    //                     role="group"
    //                     aria-labelledby="my-checkbox-group"
    //                     className="flex flex-col gap-"
    //                   >
    //                     <div className="flex gap-[15px] md:gap-[19px] 991:gap-[32px] flex-wrap">
    //                       {getcourses_data?.data?.map((item) => (
    //                         <label className="font-[400] text-[14px] md:text-[16px] text-primary flex gap-[8px] ">
    //                           <Field
    //                             type="checkbox"
    //                             name="healthcarePrograms"
    //                             value={`${item.id}`}
    //                             className={`h-[21px] w-[21px] ${
    //                               errors.healthcarePrograms &&
    //                               touched.healthcarePrograms
    //                                 ? "border-red-500"
    //                                 : ""
    //                             }`}
    //                           />
    //                           {item.coursename}
    //                         </label>
    //                       ))}
    //                     </div>

    //                     <ErrorMessage
    //                       name="healthcarePrograms"
    //                       component="div"
    //                       className="text-red-500"
    //                     />
    //                   </div>
    //                 </div>
    //               </div>
    //               <div className="w-full flex gap-8 md:gap-5 flex-col md:flex-row">
    //                 <div className="w-full flex flex-col gap-2">
    //                   <label className="font-[400] text-[16px] md:text-[18px] text-[#1B2336]">
    //                     Combo Program <span className="text-[#BB2727]">*</span>
    //                   </label>
    //                   <div id="my-checkbox-group"></div>
    //                   <div
    //                     role="group"
    //                     aria-labelledby="my-checkbox-group"
    //                     className="flex flex-col gap-2"
    //                   >
    //                     <div className="flex gap-[15px] md:gap-[18.9px] flex-wrap">
    //                       {getComboCoursesData?.data?.map((item) => (
    //                         <label className="font-[400] text-[14px] md:text-[16px] text-primary flex gap-[8px] min-w-max">
    //                           <Field
    //                             type="checkbox"
    //                             name="comboPrograms"
    //                             value={`${item.id}`}
    //                             className={`h-[21px] w-[21px] ${
    //                               errors.comboPrograms && touched.comboPrograms
    //                                 ? "border-red-500"
    //                                 : ""
    //                             }`}
    //                           />
    //                           {item.courseNames}
    //                         </label>
    //                       ))}
    //                     </div>

    //                     <ErrorMessage
    //                       name="comboPrograms"
    //                       component="div"
    //                       className="text-red-500"
    //                     />
    //                   </div>
    //                 </div>
    //               </div>
    //             </div>

    //             {/* CNA */}
    //             {values.healthcarePrograms.includes("12") && (
    //               <div className="w-full flex flex-col items-center gap-8">
    //                 <span className="text-[24px] font-[500] leading-[24px] text-[#003C9D] text-left w-full">
    //                   SECTION 3 : FILL OUT ONLY IF YOU OPTED CNA IN SECTION 2
    //                 </span>
    //                 <div className="w-full flex gap-8 md:gap-4 xl:gap-5 flex-col md:flex-row items-end">
    //                   <div className="w-full 991:w-[50%] xl:w-[70%] flex flex-col gap-2">
    //                     <label className="font-[400] text-[16px] md:text-[18px] text-[#1B2336]">
    //                       Do you currently have any Certification/License as
    //                       Home Health Aide/Nurse Aide/Others from USA?{" "}
    //                       <span className="text-[#BB2727]">*</span>
    //                     </label>
    //                     <Field
    //                       type="text"
    //                       name="USACertifications"
    //                       className={`h-[50px] border border-[#989898] rounded-[5px] p-3 ${
    //                         errors.USACertifications &&
    //                         touched.USACertifications
    //                           ? "border-red-500"
    //                           : ""
    //                       }`}
    //                     />
    //                     <ErrorMessage
    //                       name="USACertifications"
    //                       component="div"
    //                       className="text-red-500"
    //                     />
    //                   </div>
    //                   <div className="w-full 991:w-[50%] xl:w-[30%] flex flex-col gap-2">
    //                     <label className="font-[400] text-[16px] md:text-[18px] text-[#1B2336]">
    //                       State <span className="text-[#BB2727]">*</span>
    //                     </label>
    //                     <Field
    //                       type="text"
    //                       name="certificationState"
    //                       className={`h-[50px] border border-[#989898] rounded-[5px] p-3 ${
    //                         errors.certificationState &&
    //                         touched.certificationState
    //                           ? "border-red-500"
    //                           : ""
    //                       }`}
    //                     />
    //                     <ErrorMessage
    //                       name="certificationState"
    //                       component="div"
    //                       className="text-red-500"
    //                     />
    //                   </div>
    //                 </div>

    //                 <div className="w-full flex gap-8 flex-col md:flex-row flex-wrap">
    //                   <div className="w-full 991:w-[49%] flex flex-col gap-2">
    //                     <label className="font-[400] text-[16px] md:text-[18px] text-[#1B2336]">
    //                       Have you ever appeared for CNA/CHHA Written exam
    //                       before? <span className="text-[#BB2727]">*</span>
    //                     </label>
    //                     <div id="my-radio-group"></div>
    //                     <div
    //                       role="group"
    //                       aria-labelledby="my-radio-group"
    //                       className="flex gap-[32px]"
    //                     >
    //                       <label className="font-[400] text-[14px] md:text-[16px] text-primary flex gap-[8px] custom-radio-container">
    //                         <Field
    //                           type="radio"
    //                           name="cnaWrittenAppeared"
    //                           value="1"
    //                           className={`h-[21px] w-[21px] ${
    //                             errors.cnaWrittenAppeared &&
    //                             touched.cnaWrittenAppeared
    //                               ? "border-red-500"
    //                               : ""
    //                           }`}
    //                         />
    //                         <span class="checkmark"></span>
    //                         <span className="label">Yes</span>
    //                       </label>
    //                       <label className="font-[400] text-[14px] md:text-[16px] text-primary flex gap-[8px] custom-radio-container">
    //                         <Field
    //                           type="radio"
    //                           name="cnaWrittenAppeared"
    //                           value="0"
    //                           className={`h-[21px] w-[21px] ${
    //                             errors.cnaWrittenAppeared &&
    //                             touched.cnaWrittenAppeared
    //                               ? "border-red-500"
    //                               : ""
    //                           }`}
    //                         />
    //                         <span class="checkmark"></span>
    //                         <span className="label">No</span>
    //                       </label>
    //                     </div>
    //                     <ErrorMessage
    //                       name="cnaWrittenAppeared"
    //                       component="div"
    //                       className="text-red-500"
    //                     />
    //                   </div>
    //                   <div className="w-full 991:w-[49%] flex flex-col gap-2">
    //                     <label className="font-[400] text-[16px] md:text-[18px] text-[#1B2336]">
    //                       Have you ever appeared for CNA Skills exam before?{" "}
    //                       <span className="text-[#BB2727]">*</span>
    //                     </label>
    //                     <div id="my-radio-group"></div>
    //                     <div
    //                       role="group"
    //                       aria-labelledby="my-radio-group"
    //                       className="flex gap-[32px]"
    //                     >
    //                       <label className="font-[400] text-[14px] md:text-[16px] text-primary flex gap-[8px] custom-radio-container">
    //                         <Field
    //                           type="radio"
    //                           name="cnaSkillsAppeared"
    //                           value="1"
    //                           className={`h-[21px] w-[21px] ${
    //                             errors.cnaSkillsAppeared &&
    //                             touched.cnaSkillsAppeared
    //                               ? "border-red-500"
    //                               : ""
    //                           }`}
    //                         />
    //                         <span class="checkmark"></span>
    //                         <span className="label">Yes</span>
    //                       </label>
    //                       <label className="font-[400] text-[14px] md:text-[16px] text-primary flex gap-[8px] custom-radio-container">
    //                         <Field
    //                           type="radio"
    //                           name="cnaSkillsAppeared"
    //                           value="0"
    //                           className={`h-[21px] w-[21px] ${
    //                             errors.cnaSkillsAppeared &&
    //                             touched.cnaSkillsAppeared
    //                               ? "border-red-500"
    //                               : ""
    //                           }`}
    //                         />
    //                         <span class="checkmark"></span>
    //                         <span className="label">No</span>
    //                       </label>
    //                     </div>
    //                     <ErrorMessage
    //                       name="cnaSkillsAppeared"
    //                       component="div"
    //                       className="text-red-500"
    //                     />
    //                   </div>
    //                 </div>
    //                 <div className="w-full flex gap-8 md:gap-4 xl:gap-5 flex-col md:flex-row">
    //                   <div className="w-full flex flex-col gap-2">
    //                     <label className="font-[400] text-[16px] md:text-[18px] text-[#1B2336]">
    //                       How many times?{" "}
    //                       <span className="text-[#BB2727]">*</span>
    //                     </label>
    //                     <Field
    //                       type="text"
    //                       name="cnaWrittenAttempts"
    //                       className={`h-[50px] border border-[#989898] rounded-[5px] p-3 ${
    //                         errors.cnaWrittenAttempts &&
    //                         touched.cnaWrittenAttempts
    //                           ? "border-red-500"
    //                           : ""
    //                       }`}
    //                     />
    //                     <ErrorMessage
    //                       name="cnaWrittenAttempts"
    //                       component="div"
    //                       className="text-red-500"
    //                     />
    //                   </div>
    //                   <div className="w-full flex flex-col gap-2">
    //                     <label className="font-[400] text-[16px] md:text-[18px] text-[#1B2336]">
    //                       How many times?{" "}
    //                       <span className="text-[#BB2727]">*</span>
    //                     </label>
    //                     <Field
    //                       type="text"
    //                       name="cnaSkillsAttempts"
    //                       className={`h-[50px] border border-[#989898] rounded-[5px] p-3 ${
    //                         errors.cnaSkillsAttempts &&
    //                         touched.cnaSkillsAttempts
    //                           ? "border-red-500"
    //                           : ""
    //                       }`}
    //                     />
    //                     <ErrorMessage
    //                       name="cnaSkillsAttempts"
    //                       component="div"
    //                       className="text-red-500"
    //                     />
    //                   </div>
    //                 </div>
    //               </div>
    //             )}

    //             {/* CHHA */}
    //             {values.healthcarePrograms.includes("13") && (
    //               <div className="w-full flex flex-col items-center gap-8">
    //                 <span className="text-[24px] font-[500] leading-[24px] text-[#003C9D] text-left w-full">
    //                   SECTION {values.healthcarePrograms.includes("12") ? 4 : 3}{" "}
    //                   : FILL OUT ONLY IF YOU OPTED CHHA IN SECTION 2
    //                 </span>
    //                 <span className="text-[16px] font-[500] leading-[24px] text-[#5C616E] text-left w-full italic">
    //                   Please provide the Name, Address, HP Number and Telephone
    //                   Number of the Agency/Healthcare Agency/Firm
    //                 </span>
    //                 <div className="w-full flex gap-8 md:gap-4 xl:gap-5 flex-col md:flex-row">
    //                   <div className="w-full flex flex-col gap-2">
    //                     <label className="font-[400] text-[16px] md:text-[18px] text-[#1B2336]">
    //                       Agency Name <span className="text-[#BB2727]">*</span>
    //                     </label>
    //                     <Field
    //                       type="text"
    //                       name="chhaAgencyName"
    //                       className={`h-[50px] border border-[#989898] rounded-[5px] p-3 ${
    //                         errors.chhaAgencyName && touched.chhaAgencyName
    //                           ? "border-red-500"
    //                           : ""
    //                       }`}
    //                     />
    //                     <ErrorMessage
    //                       name="chhaAgencyName"
    //                       component="div"
    //                       className="text-red-500"
    //                     />
    //                   </div>
    //                 </div>
    //                 <div className="w-full flex gap-8 md:gap-4 xl:gap-5 flex-col md:flex-row">
    //                   <div className="w-full flex flex-col gap-2">
    //                     <label className="font-[400] text-[16px] md:text-[18px] text-[#1B2336]">
    //                       Address <span className="text-[#BB2727]">*</span>
    //                     </label>
    //                     <Field
    //                       type="text"
    //                       name="chhaAddress"
    //                       className={`h-[50px] border border-[#989898] rounded-[5px] p-3 ${
    //                         errors.chhaAddress && touched.chhaAddress
    //                           ? "border-red-500"
    //                           : ""
    //                       }`}
    //                     />
    //                     <ErrorMessage
    //                       name="chhaAddress"
    //                       component="div"
    //                       className="text-red-500"
    //                     />
    //                   </div>
    //                   <div className="w-full flex flex-col gap-2">
    //                     <label className="font-[400] text-[16px] md:text-[18px] text-[#1B2336]">
    //                       City <span className="text-[#BB2727]">*</span>
    //                     </label>
    //                     <Field
    //                       type="text"
    //                       name="chhaCity"
    //                       className={`h-[50px] border border-[#989898] rounded-[5px] p-3 ${
    //                         errors.chhaCity && touched.chhaCity
    //                           ? "border-red-500"
    //                           : ""
    //                       }`}
    //                     />
    //                     <ErrorMessage
    //                       name="chhaCity"
    //                       component="div"
    //                       className="text-red-500"
    //                     />
    //                   </div>
    //                 </div>
    //                 <div className="w-full flex gap-8 md:gap-4 xl:gap-5 flex-col md:flex-row">
    //                   <div className="w-full flex flex-col gap-2">
    //                     <label className="font-[400] text-[16px] md:text-[18px] text-[#1B2336]">
    //                       State <span className="text-[#BB2727]">*</span>
    //                     </label>
    //                     <Field
    //                       type="text"
    //                       name="chhaState"
    //                       className={`h-[50px] border border-[#989898] rounded-[5px] p-3 ${
    //                         errors.chhaState && touched.chhaState
    //                           ? "border-red-500"
    //                           : ""
    //                       }`}
    //                     />
    //                     <ErrorMessage
    //                       name="chhaState"
    //                       component="div"
    //                       className="text-red-500"
    //                     />
    //                   </div>
    //                   <div className="w-full flex flex-col gap-2">
    //                     <label className="font-[400] text-[16px] md:text-[18px] text-[#1B2336]">
    //                       ZIP <span className="text-[#BB2727]">*</span>
    //                     </label>
    //                     <Field
    //                       type="text"
    //                       name="cnaZIP"
    //                       className={`h-[50px] border border-[#989898] rounded-[5px] p-3 ${
    //                         errors.cnaZIP && touched.cnaZIP
    //                           ? "border-red-500"
    //                           : ""
    //                       }`}
    //                     />
    //                     <ErrorMessage
    //                       name="cnaZIP"
    //                       component="div"
    //                       className="text-red-500"
    //                     />
    //                   </div>
    //                 </div>
    //                 <div className="w-full flex gap-8 md:gap-4 xl:gap-5 flex-col md:flex-row">
    //                   <div className="w-full flex flex-col gap-2">
    //                     <label className="font-[400] text-[16px] md:text-[18px] text-[#1B2336]">
    //                       Phone <span className="text-[#BB2727]">*</span>
    //                     </label>
    //                     <Field
    //                       type="text"
    //                       name="chhaPhone"
    //                       className={`h-[50px] border border-[#989898] rounded-[5px] p-3 ${
    //                         errors.chhaPhone && touched.chhaPhone
    //                           ? "border-red-500"
    //                           : ""
    //                       }`}
    //                     />
    //                     <ErrorMessage
    //                       name="chhaPhone"
    //                       component="div"
    //                       className="text-red-500"
    //                     />
    //                   </div>
    //                   <div className="w-full flex flex-col gap-2">
    //                     <label className="font-[400] text-[16px] md:text-[18px] text-[#1B2336]">
    //                       HP Number <span className="text-[#BB2727]">*</span>
    //                     </label>
    //                     <Field
    //                       type="text"
    //                       name="cnaHPNumber"
    //                       className={`h-[50px] border border-[#989898] rounded-[5px] p-3 ${
    //                         errors.cnaHPNumber && touched.cnaHPNumber
    //                           ? "border-red-500"
    //                           : ""
    //                       }`}
    //                     />
    //                     <ErrorMessage
    //                       name="cnaHPNumber"
    //                       component="div"
    //                       className="text-red-500"
    //                     />
    //                   </div>
    //                 </div>
    //               </div>
    //             )}

    //             {/* CMA */}
    //             {values.healthcarePrograms.includes("10") && (
    //               <div className="w-full flex flex-col items-center gap-8">
    //                 <span className="text-[24px] font-[500] leading-[24px] text-[#003C9D] text-left w-full">
    //                   SECTION{" "}
    //                   {values.healthcarePrograms.includes("12") &&
    //                   values.healthcarePrograms.includes("13")
    //                     ? 5
    //                     : values.healthcarePrograms.includes("12") ||
    //                       values.healthcarePrograms.includes("13")
    //                     ? 4
    //                     : 3}{" "}
    //                   : FILL OUT ONLY IF YOU OPTED CCMA IN SECTION 2
    //                 </span>

    //                 <div className="w-full flex gap-8 flex-col md:flex-row flex-wrap">
    //                   <div className="w-full 991:w-[48%] xl:w-[49%] flex flex-col gap-2">
    //                     <label className="font-[400] text-[16px] md:text-[18px] text-[#1B2336]">
    //                       Certificate Type{" "}
    //                       <span className="text-[#BB2727]">*</span>
    //                     </label>
    //                     <div id="my-radio-group"></div>
    //                     <div
    //                       role="group"
    //                       aria-labelledby="my-radio-group"
    //                       className="flex gap-[32px]"
    //                     >
    //                       <label className="font-[400] text-[14px] md:text-[16px] text-primary flex gap-[8px] custom-radio-container">
    //                         <Field
    //                           type="radio"
    //                           name="cmaCertificationType"
    //                           value="cna"
    //                           className={`h-[21px] w-[21px] ${
    //                             errors.cmaCertificationType &&
    //                             touched.cmaCertificationType
    //                               ? "border-red-500"
    //                               : ""
    //                           }`}
    //                         />
    //                         <span class="checkmark"></span>
    //                         <span className="label">CNA</span>
    //                       </label>
    //                       <label className="font-[400] text-[14px] md:text-[16px] text-primary flex gap-[8px] custom-radio-container">
    //                         <Field
    //                           type="radio"
    //                           name="cmaCertificationType"
    //                           value="chha"
    //                           className={`h-[21px] w-[21px] ${
    //                             errors.cmaCertificationType &&
    //                             touched.cmaCertificationType
    //                               ? "border-red-500"
    //                               : ""
    //                           }`}
    //                         />
    //                         <span class="checkmark"></span>
    //                         <span className="label">CHHA</span>
    //                       </label>
    //                       <label className="font-[400] text-[14px] md:text-[16px] text-primary flex gap-[8px] custom-radio-container">
    //                         <Field
    //                           type="radio"
    //                           name="cmaCertificationType"
    //                           value="pca"
    //                           className={`h-[21px] w-[21px] ${
    //                             errors.cmaCertificationType &&
    //                             touched.cmaCertificationType
    //                               ? "border-red-500"
    //                               : ""
    //                           }`}
    //                         />
    //                         <span class="checkmark"></span>
    //                         <span className="label">PCA</span>
    //                       </label>
    //                     </div>
    //                     <ErrorMessage
    //                       name="cmaCertificationType"
    //                       component="div"
    //                       className="text-red-500"
    //                     />
    //                   </div>
    //                   <div className="w-full 991:w-[48%] xl:w-[49%] flex flex-col gap-2">
    //                     <label className="font-[400] text-[16px] md:text-[18px] text-[#1B2336]">
    //                       Certification #{" "}
    //                       <span className="text-[#BB2727]">*</span>
    //                     </label>
    //                     <Field
    //                       type="text"
    //                       name="cmaCertificationNumber"
    //                       className={`h-[50px] border border-[#989898] rounded-[5px] p-3 ${
    //                         errors.cmaCertificationNumber &&
    //                         touched.cmaCertificationNumber
    //                           ? "border-red-500"
    //                           : ""
    //                       }`}
    //                     />
    //                     <ErrorMessage
    //                       name="cmaCertificationNumber"
    //                       component="div"
    //                       className="text-red-500"
    //                     />
    //                   </div>
    //                 </div>
    //                 <div className="w-full flex gap-8 md:gap-4 xl:gap-5 flex-col md:flex-row">
    //                   <div className="w-full flex flex-col gap-2">
    //                     <label className="font-[400] text-[16px] md:text-[18px] text-[#1B2336]">
    //                       State of Issued By{" "}
    //                       <span className="text-[#BB2727]">*</span>
    //                     </label>
    //                     <Field
    //                       type="text"
    //                       name="cmaIssuedState"
    //                       className={`h-[50px] border border-[#989898] rounded-[5px] p-3 ${
    //                         errors.cmaIssuedState && touched.cmaIssuedState
    //                           ? "border-red-500"
    //                           : ""
    //                       }`}
    //                     />
    //                     <ErrorMessage
    //                       name="cmaIssuedState"
    //                       component="div"
    //                       className="text-red-500"
    //                     />
    //                   </div>
    //                   <div className="w-full flex flex-col gap-2">
    //                     <label className="font-[400] text-[16px] md:text-[18px] text-[#1B2336]">
    //                       Expiration Date{" "}
    //                       <span className="text-[#BB2727]">*</span>
    //                     </label>
    //                     <Field
    //                       type="date"
    //                       name="cmaExpirationDate"
    //                       className={`h-[50px] border border-[#989898] rounded-[5px] p-3 ${
    //                         errors.cmaExpirationDate &&
    //                         touched.cmaExpirationDate
    //                           ? "border-red-500"
    //                           : ""
    //                       }`}
    //                     />
    //                     <ErrorMessage
    //                       name="cmaExpirationDate"
    //                       component="div"
    //                       className="text-red-500"
    //                     />
    //                   </div>
    //                 </div>
    //               </div>
    //             )}

    //             <div className="w-full flex flex-col items-center gap-8">
    //               <span className="text-[24px] font-[500] leading-[24px] text-[#003C9D] text-left w-full">
    //                 SECTION{" "}
    //                 {values.healthcarePrograms.includes("12") &&
    //                 values.healthcarePrograms.includes("13") &&
    //                 values.healthcarePrograms.includes("10")
    //                   ? 6
    //                   : (values.healthcarePrograms.includes("12") &&
    //                       values.healthcarePrograms.includes("13")) ||
    //                     (values.healthcarePrograms.includes("12") &&
    //                       values.healthcarePrograms.includes("10")) ||
    //                     (values.healthcarePrograms.includes("13") &&
    //                       values.healthcarePrograms.includes("10"))
    //                   ? 5
    //                   : (values.healthcarePrograms.includes("12") &&
    //                       values.healthcarePrograms.includes("13")) ||
    //                     values.healthcarePrograms.includes("12") ||
    //                     values.healthcarePrograms.includes("10")
    //                   ? 4
    //                   : 3}{" "}
    //                 : APPLICANT’S STATEMENT
    //               </span>

    //               <div className="text-[16px] leading-[35px] text-[#5C616E] flex flex-col gap-4">
    //                 <p>
    //                   School does not discriminate on the basis of race, creed,
    //                   color, religion, national origin, citizenship, age,
    //                   gender, sexual orientation, disability, marital status,
    //                   veteran status or any other status protected by law. Param
    //                   Institute of Education(PIE) reserves the right to solicit
    //                   information relative to your suitability for the training
    //                   for which you are being considered.
    //                 </p>
    //                 <p>
    //                   To be in compliance with the New Jersey State law
    //                   regulations regarding criminal history background check,
    //                   Param Institute of Education (PIE) requires that all
    //                   students enrolled in all the training programs obtain a
    //                   Fingerprint/Criminal Background Check. By signing below,
    //                   you are giving consent to Param Institute of Education to
    //                   run the background check in your name using your SSN
    //                   number and address provided in this form.
    //                 </p>
    //                 <p>
    //                   Please note that New Jersey State law provides that a
    //                   person shall be disqualified from certification if that
    //                   person’s criminal history record background check has not
    //                   been cleared. This confirms that PIE reserves the right to
    //                   drop such student from the class with no tuition fund
    //                   refunded.
    //                 </p>
    //                 <p>
    //                   To be in compliance with the Certification Program, the
    //                   student will have to adhere to mandatory Monteux Testing
    //                   as required by the program standards. The student will
    //                   also need to provide her/ his photo for school ID.
    //                 </p>
    //                 <p>
    //                   This application is for training and does not constitute
    //                   an offer of employment.
    //                 </p>
    //                 <p>
    //                   I understand that omissions and/or misrepresentations made
    //                   on the application form may be cause for my removal from
    //                   training. I therefore certify that the information
    //                   contained therein is true and complete to the best of my
    //                   knowledge.
    //                 </p>
    //                 <p>
    //                   By signing below, I understand and accept the above
    //                   requirements.
    //                 </p>
    //               </div>
    //               <div className="w-full text-left mt-4">
    //                 <span className="font-[600] text-[24px] leading-[45px]">
    //                   E Signature
    //                 </span>
    //               </div>
    //               <div className="w-full flex gap-8 md:gap-4 xl:gap-5 flex-col md:flex-row">
    //                 <div className="w-full flex flex-col gap-2">
    //                   <label className="font-[400] text-[16px] md:text-[18px] text-[#1B2336]">
    //                     Name <span className="text-[#BB2727]">*</span>
    //                   </label>
    //                   <Field
    //                     type="text"
    //                     name="signName"
    //                     className={`h-[50px] border border-[#989898] rounded-[5px] p-3 ${
    //                       errors.signName && touched.signName
    //                         ? "border-red-500"
    //                         : ""
    //                     }`}
    //                   />
    //                   <ErrorMessage
    //                     name="signName"
    //                     component="div"
    //                     className="text-red-500"
    //                   />
    //                 </div>
    //                 <div className="w-full flex flex-col gap-2">
    //                   <label className="font-[400] text-[16px] md:text-[18px] text-[#1B2336]">
    //                     Today’s Date <span className="text-[#BB2727]">*</span>
    //                   </label>
    //                   <Field
    //                     type="date"
    //                     name="todayDate"
    //                     className={`h-[50px] border border-[#989898] rounded-[5px] p-3 ${
    //                       errors.todayDate && touched.todayDate
    //                         ? "border-red-500"
    //                         : ""
    //                     }`}
    //                   />
    //                   <ErrorMessage
    //                     name="todayDate"
    //                     component="div"
    //                     className="text-red-500"
    //                   />
    //                 </div>
    //               </div>
    //             </div>

    //             <div className="flex gap-3 w-full justify-center mt-3">
    //               <button type="submit" className="orangeHoverButton">
    //                 Submit
    //               </button>
    //               <button
    //                 type="button"
    //                 className="orangeOutlineButton"
    //                 onClick={() => navigate(-1)}
    //               >
    //                 Cancel
    //               </button>
    //             </div>
    //           </Form>
    //         )}
    //       </Formik>
    //     </>
    //   )}
    // </div>
  );
}

export default Enrollment;
