import React from "react";
import tick from "../../../../assets/images/tick.svg";

function CourseOutcomes() {
  return (
    <div className="font-poppins flex flex-col gap-5 md:gap-10">
      <p className="font-[500] text-[20px] md:text-[24px] xl:text-[32px] leading-[36px] xl:leading-[48px] text-[#003C9D]">
        Upon the completion of this course the student will be able to
      </p>
      <div className="flex flex-col gap-6">
        <ul className="list-disc ml-8 font-[400] text-[18px] md:text-[20px] text-[#5C616E] flex flex-col gap-4 leading-[45px]">
          <li>
          Understand what an EKG Technician does and how to perform on the job
          </li>
          <li>
          Work with doctors, nurses and other professionals to provide essential healthcare
          </li>
          <li>
          Become eligible to sit for the provisional Certified EKG Technician Specialist (CETS) exam through the AMCA or the Certified EKG Technician (CET) certification exam through the NHA
          </li>
          <li>
          Receive job assistance from our Externship Coordinator and access to our Externship Starter Kit
          </li>
          <li>Able to effectively communicate the results with physicians</li>
          <li>Able to properly maintain the EKG Machine</li>
          <li>Able to practice basic aseptic techniques</li>
        </ul>
      </div>
    </div>
  );
}

export default CourseOutcomes;
