import React from "react";
import whyParam from "../../assets/images/whyParam.svg";
import tick from "../../assets/images/tick.svg";
import { useNavigate } from "react-router-dom";

function WhyParam() {
  const navigation = useNavigate();
  return (
    <div className=" w-full flex flex-col items-center font-poppins mb-28 gap-12 md:gap-20">
      <div className="bg-whyParamBg-bg bg-cover w-full h-[400px] md:h-[600px] justify-center items-center flex flex-col gap-8 md:gap-12 ">
        <p className="font-[800] text-[32px] md:text-[50px] xl:text-[60px] text-white w-[calc(100%_-_32px)] md:w-[calc(100%_-_64px)] text-center">
          Why Param Institute of Education
        </p>
        <div className=" flex justify-center gap-4 md:gap-8 flex-col md:flex-row">
          <button
            className="orangeButton"
            onClick={() => navigation("../scheduleAppointment")}
          >
            Schedule a Visit
          </button>
          <button
            className="orangeButton"
            onClick={() => navigation("../requestInfo")}
          >
            Inquire Now
          </button>
          <button
            className="orangeButton"
            onClick={() => navigation("../enrollment")}
          >
            Enroll Now
          </button>
        </div>
      </div>
      <div className="w-[calc(100%_-_32px)] md:w-[calc(100%_-_64px)] xl:w-[90%]">
        <div className="w-[100%]">
          <div className="text-content w-full md:w-[50%] ml-4 2xl:ml-8 2xl:mt-20 float-right hidden md:flex justify-center items-center relative">
            <img src={whyParam} />
          </div>
          <p className="font-[500] text-[24px] md:text-[32px] text-[#003C9D] mb-4">
            Why Param Institute of Education?
          </p>
          <p className="font-[400] text-[16px] md:text-[20px] text-[#5C616E] leading-[40px]  font-inter">
            Hybrid (Online Theory Classes and Hands on practice at School site)
            and In-person Certification programs with Param Institute of
            Education are a flexible and convenient way to get educational
            career training that could help you build a better, brighter future.
            Many programs feature hybrid courses that make it easier to fit
            education into your lifestyle.
          </p>
          <div className="w-full block md:hidden mt-10">
            <img src={whyParam} />
          </div>
          <div className="flex flex-col gap-4 mt-10 md:mt-8">
            <div className="flex gap-6 items-center">
              <img
                src={tick}
                alt=""
                className="h-[21px] w-[23px] md:h-[30px] md:w-[30px]"
              />
              <p className="font-[500] text-[20px] md:text-[24px]  text-[#F37021] font-inter">
                Practical, Real-World Curriculum
              </p>
            </div>

            <p className="font-[400] text-[18px] md:text-[20px] text-[#5C616E] leading-[40px]  font-inter ml-12 md:ml-20">
              Since all of Param Institute of Education’s certification programs
              were written by professionals who have actually worked in these
              career fields, our students can gain real-world, practical
              knowledge that can they can quickly apply in their current job or
              everyday life. We maintain a 10:1 student-to-faculty ratio so that
              each student can make the most of their experience.
            </p>
          </div>
          <div className="flex flex-col gap-4 mt-8">
            <div className="flex gap-6 items-center">
              <img
                src={tick}
                alt=""
                className="h-[21px] w-[23px] md:h-[30px] md:w-[30px]"
              />
              <p className="font-[500] text-[20px] md:text-[24px]  text-[#F37021] font-inter">
                Flexible Study Schedule
              </p>
            </div>
            <p className="font-[400]  text-[18px] md:text-[20px] text-[#5C616E] leading-[40px]  font-inter ml-12 md:ml-20">
              You study when it is most convenient for you, progressing at your
              own pace. You can complete majority of offered course in as little
              as 3-4 months. In-person classes for Clinical skills and
              externship would help you to upgrade your skills and education.
            </p>
          </div>
          <div className="flex flex-col gap-4 mt-8">
            <div className="flex gap-6 items-center">
              <img
                src={tick}
                alt=""
                className="h-[21px] w-[23px] md:h-[30px] md:w-[30px]"
              />
              <p className="font-[500] text-[20px] md:text-[24px]  text-[#F37021] font-inter">
                Affordable Tuition and Scholarship
              </p>
            </div>
            <p className="font-[400]  text-[18px] md:text-[20px] text-[#5C616E] leading-[40px]  font-inter ml-12 md:ml-20">
              You can pay your tuition fee all at one time, or conveniently
              pay-as-you-go with affordable 0% interest monthly payments based
              on your program. Your one low tuition fee covers everything you
              need to complete your course. Also, eligible students will get
              some amount of scholarship. We assure you that our tuition is very
              competitive among the other vocational schools in New Jersey.
              Remember that attending program at Param institute of Education is
              an investment in your future as a healthcare professional.
            </p>
          </div>
          <div className="flex flex-col gap-4 mt-8">
            <div className="flex gap-6 items-center">
              <img
                src={tick}
                alt=""
                className="h-[21px] w-[23px] md:h-[30px] md:w-[30px]"
              />
              <p className="font-[500] text-[20px] md:text-[24px]  text-[#F37021] font-inter">
                Instructor Support
              </p>
            </div>
            <p className="font-[400]  text-[18px] md:text-[20px] text-[#5C616E] leading-[40px]  font-inter ml-12 md:ml-20">
              Even though you are taking an independent study course, you are
              never alone! PIE's educational staff are available on zoom call or
              e-mail if you need assistance at any time during your studies. For
              in-person you may have face to face appointment with instructor or
              any admin staff assistance.
            </p>
          </div>
          <div className="flex flex-col gap-4 mt-8">
            <div className="flex gap-6 items-center">
              <img
                src={tick}
                alt=""
                className="h-[21px] w-[23px] md:h-[30px] md:w-[30px]"
              />
              <p className="font-[500] text-[20px] md:text-[24px]  text-[#F37021] font-inter">
                Externship at Site
              </p>
            </div>
            <p className="font-[400]  text-[18px] md:text-[20px] text-[#5C616E] leading-[40px]  font-inter ml-12 md:ml-20">
              The school has contract with many healthcare industry agencies
              such as LabCorp, Dr Offices, medical billing and coding companies
              etc. Every program will give you real time field experience to
              prepare students for placement. Our students see the fast-paced
              industry first-hand through clinical experience and externships.
            </p>
          </div>
          <div className="flex flex-col gap-4 mt-8">
            <div className="flex gap-6 items-center">
              <img
                src={tick}
                alt=""
                className="h-[21px] w-[23px] md:h-[30px] md:w-[30px]"
              />
              <p className="font-[500] text-[20px] md:text-[24px]  text-[#F37021] font-inter">
                Placement Assistance & Job opportunities
              </p>
            </div>
            <p className="font-[400]  text-[18px] md:text-[20px] text-[#5C616E] leading-[40px]  font-inter ml-12 md:ml-20">
              The school will provide you with the skills you need to obtain an
              entry-level position in most cases. We will assist you for your
              resume preparation and finding a job. Also, Potential students
              should always do research on the job market in their area before
              registering. Our Skilled professionals offer both career
              assistance as well as personalized guidance to both current and
              former students. Some of the many services you can expect to
              receive which may include Job search Techniques,
              Internship/externship, Job interview tips, Placement referrals,
              Interview tips and preparations etc.
            </p>
          </div>
          <div className="flex flex-col gap-4 mt-8">
            <div className="flex gap-6 items-center">
              <img
                src={tick}
                alt=""
                className="h-[21px] w-[23px] md:h-[30px] md:w-[30px]"
              />
              <p className="font-[500] text-[20px] md:text-[24px]  text-[#F37021] font-inter">
                Student Success
              </p>
            </div>
            <p className="font-[400]  text-[18px] md:text-[20px] text-[#5C616E] leading-[40px]  font-inter ml-12 md:ml-20">
              Most of Param Institute of Education's students are serious,
              career-minded individuals who have come to realize the true value
              of marketable skills in today’s increasingly demanding work force.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WhyParam;
