import React from "react";
import { useNavigate } from "react-router-dom";

function FacultyStaff() {
  const navigation = useNavigate();
  return (
    <div className=" w-full flex flex-col items-center font-poppins mb-28 gap-12 md:gap-20">
      <div className="bg-facultyBg-bg bg-cover w-full h-[400px] md:h-[600px] justify-center items-center flex flex-col gap-8 md:gap-12 ">
        <p className="font-[800] text-[32px] md:text-[50px] xl:text-[60px] text-white w-[calc(100%_-_32px)] md:w-[50%] text-center">
          Faculty & Staff
        </p>
        <div className=" flex justify-center gap-4 md:gap-8 flex-col md:flex-row">
          <button
            className="orangeButton"
            onClick={() => navigation("../scheduleAppointment")}
          >
            Schedule a Visit
          </button>
          <button
            className="orangeButton"
            onClick={() => navigation("../requestInfo")}
          >
            Inquire Now
          </button>
          <button
            className="orangeButton"
            onClick={() => navigation("../enrollment")}
          >
            Enroll Now
          </button>
        </div>
      </div>
      <div className="w-[calc(100%_-_32px)] md:w-[calc(100%_-_64px)] xl:w-[90%] flex gap-10 flex-col">
        <div className="w-full shadow-lg py-[40px] px-4 md:px-[40px]">
          <p className="font-[500] text-[20px] md:text-[24px] text-[#003C9D] pb-6 md:pb-[30px]">
            Core Team
          </p>
          <ul className=" list-disc font-[400] text-[18px] md:text-[20px] text-[#5C616E] flex flex-wrap list-outside md:list-inside gap-8 md:gap-6 ml-7 md:ml-5 w-auto">
            <li>Vipul Amin – President</li>
            <li>Bhruna Amin – Vice President/ Co Director</li>
            <li>Dr. Riteshkumar Patel – School Director</li>
          </ul>
        </div>
        <div className="w-full shadow-lg py-[40px] px-4 md:px-[40px]">
          <p className="font-[500] text-[20px] md:text-[24px] text-[#003C9D] pb-6 md:pb-[30px]">
            Program Director
          </p>
          <ul className=" list-disc font-[400] text-[18px] md:text-[20px] text-[#5C616E] flex list-outside md:list-inside gap-8 md:gap-6 ml-7 md:ml-5 w-auto flex-wrap">
            <li>Ms. Rupal Babaria</li>
            <li>Ms. Isha Bhavsar</li>
            <li>Dr. Riteshkumar Patel</li>
          </ul>
        </div>
        <div className="w-full shadow-lg py-[40px] px-4 md:px-[40px]">
          <p className="font-[500] text-[20px] md:text-[24px] text-[#003C9D] pb-6 md:pb-[30px]">
            List of Instructors
          </p>
          <ul className=" list-disc font-[400] text-[18px] md:text-[20px] text-[#5C616E] flex list-outside md:list-inside gap-8 md:gap-6 ml-7 md:ml-5 w-auto md:flex-wrap flex-col md:flex-row">
            <li>Ms. Rupal Babaria, RN</li>
            <li>Mr. Vishvanath Guddadar</li>
            <li>Ms. Isha Bhavsar</li>
            <li>Mr. Nandish Pathak</li>
            <li>Ms. Savita Sharma</li>
            <li>Ms. Pratixa Bhatt</li>
            <li>Ms. Rinki Patel</li>
            <li>Dr. Ashok Kakkad</li>
            <li>Navin Sharma</li>
            <li>Dr. Aarti Pandya</li>
            <li>Ms. Sruthi Bhat</li>
          </ul>
        </div>
        <div className="w-full shadow-lg py-[40px] px-4 md:px-[40px]">
          <p className="font-[500] text-[20px] md:text-[24px] text-[#003C9D] pb-6 md:pb-[30px] break-words">
            Administrative/HR/Technical Team
          </p>
          <ul className=" list-disc font-[400] text-[18px] md:text-[20px] text-[#5C616E] flex list-outside md:list-inside gap-8 md:gap-6 ml-7 md:ml-5 w-auto md:flex-wrap flex-col md:flex-row">
            <li>Dr. Jaykumar Patel</li>
            <li>Dr. Zarna Shah</li>
            <li>Ms. Tejal Bavaria</li>
            <li>Ms. Sweta Modi</li>
            <li>Ms. Munira Kalyani</li>
            <li>Ms. Vaisali Desai</li>
            <li>Mr. Devang Pathak</li>
            <li>Ms. Nima Kulkarni</li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default FacultyStaff;
