import React from "react";

const Faqs = () => {
  return (
    <>
      <div>
        <p className="font-[500] text-[20px] md:text-[24px] xl:text-[32px] leading-[36px] xl:leading-[48px] text-[#003C9D]">
          Home-Maker Home Health Aide FAQs
        </p>
        <div
          className="mt-5 md:mt-10 leading-[30px] text-[18px] md:text-[20px]"
          id="hybrid-faq"
        >
          <ul className="list-disc flex flex-col gap-6 ml-5 font-[500]">
            <li className="">
              What is Hybrid Programs?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  To obtain the HHA certification, you will need to complete
                  training in basic safety techniques.
                </p>
                <p>
                  All hybrid programs will allow you to choose in-person and/or
                   online instruction. Lectures may be offered at the  School
                  location or remotely via a Web Conferencing Platform.
                  Laboratory/ clinical classes and practical training will be
                  held on at School site or at a clinical site. Synchronous
                  (Online synchronous classes) instruction is real-time
                  instruction that requires students to attend classes at
                  scheduled times.
                </p>
              </div>
            </li>

            <li className="">
              What is a self-paced Training? 
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  To obtain the HHA certification, you will need to complete
                  training in basic safety techniques.
                </p>
                <p>
                  The training you may complete at your own selected time and
                  you have to complete that training in a specific time. The
                  school will provide you with the login credentials to access
                  the 60 hours of self-paced New Jersey HHA  theory modules.   
                   
                </p>
              </div>
            </li>

            <li className="">
              What Can You Expect in HHA Training
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  To obtain the HHA certification, you will need to complete
                  training in basic safety techniques.
                </p>
                <p>
                  Not only is it necessary to keep the patients safe but also to
                  prevent injuries to HHAs when performing such tasks as moving,
                  dressing, or bathing patients.
                </p>
                <p>
                  Training also covers emergency response skills, such as
                  cardiopulmonary resuscitation (CPR) and basic first aid.
                </p>
              </div>
            </li>

            <li>
              What are the Typical Home Health Aide Duties
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  Home health aides are usually responsible for the following:
                </p>

                <ul className="list-disc flex flex-col gap-6 ml-7">
                  <li>
                    Checking vital signs, such as temperature or pulse rate
                    under the supervision of a health care professional such as
                    a registered nurse (RN).
                  </li>
                  <li>
                    Administering prescription medication and assisting with
                    prescribed exercises.
                  </li>
                  <li>Dressing wounds, patching scrapes, and cuts.</li>
                  <li>
                    Helping clients in their daily personal activities, such as
                    dressing or bathing.
                  </li>
                  <li>
                    Giving massages, caring for the skin, or helping with
                    artificial limbs and braces.
                  </li>
                  <li>
                    Performing light housekeeping, such as washing dishes,
                    laundry, and vacuuming in a client’s home.
                  </li>
                  <li>
                    Physically moving the patient from one area to another.
                  </li>
                  <li>
                    Organizing a client’s schedule and planning appointments.
                  </li>
                  <li>
                    Shopping for groceries and preparing meals to the dietary
                    specifications of the client.
                  </li>
                  <li>
                    Experienced home health aides may also help with medical
                    equipment such as ventilators, which help clients breathe,
                    with special training.
                  </li>
                  <li>Providing companionship.</li>
                </ul>
              </div>
            </li>

            <li>
              Home Health Aide Salary
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  According to the Bureau of Labor Statistics, the average
                  hourly rate for an HHA was $11.00 with the average annual wage
                  of $22,870, as of 2015.
                </p>
                <p>
                  About 820,630 people were employed as an HHA, and the top 10%
                  of them earned $29,950 per year.
                </p>
              </div>
            </li>

            <li>
              Home Health Aide Jobs
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>The prospects are bright for aspiring home health aides.</p>
                <p>
                  With the aging baby-boomer population, the demand for HHA
                  services continues to increase.
                </p>
                <p>
                  In 2014, approximately 913,000 positions were held by HHAs in
                  the US with around 59,000 job openings.
                </p>
                <p>
                  The employment rates for HHAs are projected to grow by 38%
                  through 2024, which is a lot faster than the average for all
                  other occupations in the country.
                </p>
              </div>
            </li>

            <li>
              How Long Does it Take to Complete HHA Training
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  This Program is  76 hours of training. This is more flexible
                  program because we are offering online self spaced 60 hours of
                  training therefore you have to come to the school for 16 hours
                  of clinical. The length of the program is 3 weeks only.
                </p>
              </div>
            </li>

            <li>
              How to Select the Right Home Health Aide Training Program school.
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  The pram institute of education is the right training  school
                   where you can get immediate  opportunity to work with our own
                  homecare agency as Param Healthcare & IT Services.
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Faqs;
