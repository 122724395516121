import React, { useEffect } from "react";
import thankYouIcon from "../../assets/images/thankyouIcon.svg";
import { useNavigate, useLocation } from "react-router-dom";

const ThankyouPage = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const message = state?.message;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="py-20 text-center text-[#5C616E] md:max-w-[820px] m-4 md:m-auto">
      <p className="font-[600] text-[64px] md:text-[70px] text-[#003C9D]">
        Thank You !
      </p>
      <p className="text-[24px] leading-[45px] mt-10">{message}</p>
      <p className="text-[20px] leading-[45px] mt-6">
        <span className="text-[#FF0000]">* Note: </span>If the email is not
        received in the inbox, please check the spam/junk folder and mark the email
        as not spam/junk.
      </p>
      <div className="mt-6 flex flex-col justify-center">
        <img src={thankYouIcon} className="h-[150px]" />
        <p>
          <button
            className="mt-[60px] orangeButton"
            onClick={() => navigate(-1)}
          >
            Go Back
          </button>
        </p>
      </div>
    </div>
  );
};

export default ThankyouPage;
