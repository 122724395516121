import React, { useState } from "react";
import course1 from "../../assets/images/course1.svg";
import course2 from "../../assets/images/course2.svg";
import course3 from "../../assets/images/course3.svg";
import course4 from "../../assets/images/course4.svg";
import course5 from "../../assets/images/course5.svg";
import course6 from "../../assets/images/course6.svg";
import course7 from "../../assets/images/course7.svg";
import course8 from "../../assets/images/course8.svg";
import course9 from "../../assets/images/course9.svg";
import course10 from "../../assets/images/course10.svg";
import course11 from "../../assets/images/course11.svg";
import course12 from "../../assets/images/course12.svg";
import LPN from "../../assets/images/LPN-course.svg";

import { useNavigate } from "react-router-dom";

function WellnessPrograms() {
  const navigation = useNavigate();
  const data = [
    {
      img: course12,
      title: "Massage Therapy",
      link: "../massageTherapy",
      content:
        "The Massage Therapy Program caters to students aspiring to enter the world of massage therapy. Participants receive comprehensive education on their responsibilities and the role of a massage therapist.",
    },
    {
      img: course8,
      title: "Ayurvedic Wellness Counselor Program (Hybrid)",
      link: "../AWC",
      content:
        " These tools encompass an integrated approach, including comprehensive nutritional knowledge (Ahara), daily habits and routines to optimize well-being (Vihar).",
    },
    {
      img: course7,
      title: "Ayurvedic Wellness Practitioner Program (Hybrid)",
      link: "../AWP",
      content:
        "The Ayurvedic Wellness Practitioner (AWP) Program seems like a comprehensive course focused on wellness management based on the principles of Ayurveda.",
    },
    {
      img: course11,
      title: "200 Hours Yoga Teacher Training Certificate (Hybrid) Program",
      link: "../yoga200",
      content:
        "The program comprises three segments: Seg A: Deepening Your Asana, Seg B: Sacred Life, and Seg C: Practical Applications. It is designed to harness students' natural strengths and desires.",
    },

    {
      img: course11,
      title: "300 Hours Yoga Teacher Training Certificate (Hybrid) Program",
      link: "../yoga300",
      content:
        "The 300-Hour Advanced Teacher Certification program is tailored for yoga instructors seeking to elevate their professional journey. Designed for those already certified at the 200-hour level.",
    },
  ];
  return (
    <div className=" w-full flex flex-col items-center font-poppins mb-28">
      <div className="bg-wellness-bg bg-cover w-full h-[400px] md:h-[600px] justify-center items-center flex flex-col gap-8 md:gap-12 ">
        <p className="font-[800] text-[32px] md:text-[50px] xl:text-[60px]  w-[calc(100%_-_32px)] md:w-[calc(100%_-_64px)] text-white text-center">
          Wellness Programs
        </p>
        <div className=" flex justify-center gap-4 md:gap-8 flex-col md:flex-row">
          <button
            className="orangeButton"
            onClick={() => navigation("../scheduleAppointment")}
          >
            Schedule a Visit
          </button>
          <button
            className="orangeButton"
            onClick={() => navigation("../requestInfo")}
          >
            Inquire Now
          </button>
          <button
            className="orangeButton"
            onClick={() => navigation("../enrollment")}
          >
            Enroll Now
          </button>
        </div>
      </div>
      <div className="w-[calc(100%_-_32px)] md:w-[calc(100%_-_64px)] xl:w-[90%] flex gap-10 flex-col mt-[60px]">
        <div className=" gap-5 grid grid-cols-1 576:grid-cols-2 991:grid-cols-3 2xl:grid-cols-4">
          {data.map((data) => (
            <div id="Card" className=" bg-white shadow-lg flex flex-col">
              <div className="max-h-[250px] overflow-hidden">
                <img src={data.img} className="w-full object-cover bg-center" />
              </div>

              <div
                className="flex flex-col w-[98%] items-center mt-3 group "
                onClick={() => navigation(data.link)}
              >
                <p className="font-[600] text-[18px] text-primary group-hover:text-[#F37021] text-center cursor-pointer">
                  {data.title}
                </p>
                <p className="font-[400] text-[14px] text-[#5C616E] text-center mt-4 w-[95%] group-hover:text-[#F37021] cursor-pointer px-3 pb-4">
                  {data.content}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
export default WellnessPrograms;
