import React from "react";
import coursePlayIcon from "../../../../assets/images/coursePlayIcon.svg";
import CHHACourse from "../../../../assets/images/CHHACourse.svg";

const Overview = ({ setTab }) => {
  return (
    <>
      <div className="font-poppins text-[18px] md:text-[20px] leading-[45px] text-[#5C616E]">
        <div className="text-content md:ml-[20px] md:mb-[20px] xl:ml-[99px] xl:mb-[40px]  w-1/2 float-right hidden md:flex justify-center items-center relative">
          <img
            src={CHHACourse}
            alt="Certified Home-Maker Home health Aide Program "
          />
          <img
            className="absolute"
            src={coursePlayIcon}
            alt="course play icon"
          />
        </div>
        <p className="font-[500] text-[20px] md:text-[24px] xl:text-[32px] leading-[36px] xl:leading-[48px] text-[#003C9D]">
          Home-Maker Home Health Aide Program  (HHA-Hybrid)
        </p>
        <div className="flex md:hidden justify-center items-center mt-10">
          <img
            src={CHHACourse}
            alt="Certified Home-Maker Home health Aide Program "
          />
          <img
            className="absolute"
            src={coursePlayIcon}
            alt="course play icon"
          />
        </div>
        <p className="text-[20px] leading-[45px] text-[#5C616E] mt-10">
          The population of the elderly in our country is steadily growing and
          many people now prefer to receive health care at home in a familiar
          setting for convenience and to reduce cost. This trend has increased
          the need for qualified and diverse HHAs. The demand for HHAs is
          expected to grow much more in the coming decade and this position is
          listed among the top ten positions which have the potential to grow
          the fastest. To become an HHA one has to undergo training.
        </p>
        <p className="text-[20px] leading-[45px] text-[#5C616E]">
          The{" "}
          <a
            className="underline text-[#0755b9]"
            href="#"
            onClick={(event) => {
              event.preventDefault();
              setTab("faq");
            }}
          >
            hybrid
          </a>{" "}
          76 hours of blended training which includes approximately 60 hours of
          self-paced training and 16 hours of Clinical skills sessions. 
        </p>
        <p className="text-[20px] leading-[45px] text-[#5C616E]">
          Upon completion of this program, students will be trained for entry
          level position in Healthcare field. The position of Home Health Aid
          and Home maker are in very high demand and offers flexible work hours.
          The Home Maker and Home Health Aide program qualifies candidates for
          these positions.
        </p>
      </div>
      <div className="mt-[60px]">
        <p className="font-[500] text-[20px] md:text-[24px] xl:text-[32px] leading-[36px] xl:leading-[48px] text-[#003C9D]">
          The primary subjects covered in any HHA training program include
        </p>
        <div className="flex flex-col gap-4 text-[18px] md:text-[20px] leading-[45px] mt-5 md:mt-10 text-[#5C616E]">
          <ul className="list-disc ml-6 flex flex-col gap-4">
            <li>First aid and CPR.</li>
            <li>Life Support.</li>
            <li>Basic body movements and positions.</li>
            <li>Common diseases.</li>
            <li>Basic concepts of healthcare.</li>
            <li>Personal care.</li>
            <li>Common terminology in the medical field.</li>
            <li>Nutrition.</li>
            <li>Communication and working with people.</li>
            <li>Cleaning and care of homes.</li>
          </ul>
        </div>
      </div>
      <div className="mt-10 md:mt-[60px]">
        <div className="p-6 border-[1px] rounded-[5px] border-[rgb(217,217,217)] shadow-lg">
          <ul className="list-disc  text-[18px] md:text-[20px] font-[500] text-[#003C9D] leading-[35px] flex flex-col gap-[10px] ml-6">
            <li>
              Prerequisite:{" "}
              <span className="text-[#1B2336]">
                At least seventeen (17) years of age and no academic credential
                required.
              </span>
            </li>
            <li>
              Program Length: <span className="text-[#1B2336]">3 weeks</span>
            </li>
            <li>
              Academic Credential Awarded:{" "}
              <span className=" text-[#1B2336]">Certificate and License</span>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Overview;
