import React from "react";
import tick from "../../../assets/images/tick.svg";

function CourseOutcomes() {
  const listData = [
    {
      l1: "Demonstrate clinical competence in the role of practical nurse in accordance with ethical, legal, and professional standards.",
    },
    {
      l1: "Apply the nursing process to provide client centered care under the supervision of RN, APN or a Provider.",
    },
    {
      l1: "Provide safe and effective nursing care integrating current evidenced-based practice.",
    },
    {
      l1: "Collaborate with the inter professional team utilizing appropriate technology and informatics, maintaining integrity of information.",
    },
    {
      l1: "Upon Satisfactory completion of the program, the students are eligible to apply to the state board of nursing and Licensure. Students will place emphasis on the principal of nursing care for patient and holistic care.",
    },
    {
      l1: "Leadership skills such as delegation to the health care team members, effective communication with health care professional and time management will all be explored; assisting the practical nursing student to prepare for employment and transition from student to practitioner.",
    },
  ];
  const data = [
    {
      title: "Patient-centered Care",
      l1: "The student will determine the practical nursing care associated with the holistic well-being of the patient based on respect for the patient’s preferences, values and needs.",
    },
    {
      title: "Teamwork and Collaboration",
      l1: "The student will practice as a collaborative member of the healthcare team through coordination of care.",
    },
    {
      title: "Evidence-based Practice",
      l1: "The student will associate best current evidence and clinical expertise for delivery of optimal healthcare.",
    },
    {
      title: "Quality Improvement",
      l1: "The student will examine data to continuously improve the quality and safety of practical nursing care.",
    },
    {
      title: "Safety",
      l1: "The student will demonstrate the delivery of safe and effective patient care.",
    },
    {
      title: "Informatics",
      l1: "The student will appropriately utilize technology and informatics relative to patient-centered care.",
    },
    {
      title: "Code of Ethics",
      l1: "The student will engage in ethical framework when making professional judgements and practice decisions by demonstrating honesty, integration and trustworthiness in all interactions.",
    },
  ];
  const jobLookOut = [
    {
      l1: "Employment of licensed practical and licensed vocational nurses is projected to grow 5 percent from 2022 to 2032, faster than the average for all occupations.",
    },
    {
      l1: "About 54,400 openings for licensed practical and licensed vocational nurses are projected each year, on average, over the decade. Many of those openings are expected to result from the need to replace workers who transfer to different occupations or exit the labor force, such as to retire.",
    },
    {
      l1: "As the baby-boom population ages, the overall need for healthcare services is expected to increase. LPNs and LVNs will be needed in residential care facilities and in home health environments to care for older patients.",
    },
    {
      l1: "A number of chronic conditions, such as diabetes and obesity, have become more prevalent in recent years. LPNs and LVNs will be needed to assist and care for patients with these and other conditions. In addition, many procedures that once could be done only in hospitals are now being done outside of hospitals, creating demand in other settings, such as outpatient care centers.",
    },
  ];
  return (
    <div className="font-poppins flex flex-col gap-5 md:gap-10">
      <p className="font-[500] text-[20px] md:text-[24px] xl:text-[32px] leading-[36px] xl:leading-[48px] text-[#003C9D]">
        Licensed Practical Nursing Program Outcome
      </p>
      <p className="font-[400] text-[18px] md:text-[20px] text-[#5C616E]  font-inter">
        Upon the completion of this LPN Program the student will be able to:
      </p>
      <p className="font-[400] text-[18px] md:text-[20px] text-[#5C616E]  font-inter">
        Based On Knowledge Skill And Value After the completion of the Practical
        Nurse Program the graduate will be able to:
      </p>
      <div className="flex flex-col gap-6">
        {listData.map((data) => (
          <div className="flex flex-col gap-4">
            <ul className=" list-disc font-[400] text-[18px] md:text-[20px] text-[#5C616E]  font-inter flex flex-col gap-4 ml-5">
              <li>{data.l1}</li>
            </ul>
          </div>
        ))}
        {data.map((data) => (
          <div className="flex flex-col gap-4">
            <div className="flex gap-6 items-center">
              <img
                src={tick}
                alt=""
                className="h-[22px] w-[22px] md:h-[30px] md:w-[30px]"
              />
              <p className="font-[500] text-[20px] md:text-[24px]  text-[#F37021] font-inter">
                {data.title}
              </p>
            </div>
            <ul className=" list-disc font-[400] text-[18px] md:text-[20px] text-[#5C616E]  font-inter flex flex-col gap-4 ml-12 md:ml-14">
              {data.l1}
            </ul>
          </div>
        ))}
      </div>

      <div className="w-full flex gap-10 flex-col">
        <div className="w-full mt-[20px]">
          <p className="font-[500] text-[20px] md:text-[24px] xl:text-[32px] text-[#003C9D] pb-[20px] md:pb-[40px]">
            Job Outlook for LPN
          </p>
          <div className="flex gap-8 flex-col xl:flex-row">
            <ul className="w-full list-disc font-[400] text-[18px] md:text-[20px] text-[#5C616E] flex  list-outside gap-4 ml-5 flex-col">
              <li>
                Employment of licensed practical and licensed vocational nurses
                is projected to grow 5 percent from 2022 to 2032, faster than
                the average for all occupations.
              </li>
              <li>
                About 54,400 openings for licensed practical and licensed
                vocational nurses are projected each year, on average, over the
                decade. Many of those openings are expected to result from the
                need to replace workers who transfer to different occupations or
                exit the labor force, such as to retire.
              </li>
              <li>
                As the baby-boom population ages, the overall need for
                healthcare services is expected to increase. LPNs and LVNs will
                be needed in residential care facilities and in home health
                environments to care for older patients.
              </li>
              <li>
                A number of chronic conditions, such as diabetes and obesity,
                have become more prevalent in recent years. LPNs and LVNs will
                be needed to assist and care for patients with these and other
                conditions. In addition, many procedures that once could be done
                only in hospitals are now being done outside of hospitals,
                creating demand in other settings, such as outpatient care
                centers.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CourseOutcomes;
