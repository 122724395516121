import React from "react";
import iosDeveloperCourse from "../../../../assets/images/iosDeveloperCourse.svg";
import coursePlayIcon from "../../../../assets/images/coursePlayIcon.svg";

const Overview = () => {
  return (
    <>
      <div className="text-[18px] md:text-[20px] leading-[45px] text-[#5C616E]">
        <div className="text-content md:ml-[20px] md:mb-[20px] xl:ml-[99px] xl:mb-[40px] xl:mt-[85px]  w-1/2 float-right hidden md:flex justify-center items-center relative">
          <img src={iosDeveloperCourse} alt="iOS Development Course" />
          <img
            className="absolute"
            src={coursePlayIcon}
            alt="course play icon"
          />
        </div>
        <p className="font-[500] text-[20px] md:text-[24px] xl:text-[32px] leading-[36px] xl:leading-[48px] text-[#003C9D]">
          iOS Developer Program
        </p>
        <div className="flex md:hidden justify-center items-center mt-10">
          <img src={iosDeveloperCourse} alt="iOS Development Course" />
          <img
            className="absolute"
            src={coursePlayIcon}
            alt="course play icon"
          />
        </div>
        <p className="mt-10">
          iOS (previously iPhone OS) is a mobile operating system developed and
          distributed by Apple Inc. Originally released in 2007 for the iPhone
          and iPod Touch platforms, it has been extended to support other Apple
          devices such as the iPAD and Apple TV. Unlike Microsoft’s Windows
          Phone and Google’s Android, Apple does not license iOS for
          installation on non-Apple hardware.
        </p>
        <p className="mt-4">
          This course prepares individual to be capable of architecting,
          designing, developing and testing complex applications for iPhone and
          iPad devices. A program that focuses on the development, use, critical
          evaluation, and managers of digital communications media. Includes
          instruction in computer and telecommunications technologies and
          processes; design and development of digital communications; marketing
          and distribution; digital communications regulation, law and policy;
          the study of human interaction with, and use of, digital media; and
          emerging trends and issues.
        </p>
      </div>
      <div className="mt-10 md:mt-[60px]">
        <p className="font-[500] text-[20px] md:text-[24px] xl:text-[32px] leading-[36px] xl:leading-[48px] text-[#003C9D]">
          Program Outline
        </p>
        <div className="p-6 border-[1px] rounded-[5px] border-[#D9D9D9] mt-5 md:mt-10 shadow-lg">
          <ul className="list-disc font-[400] text-[18px] md:text-[20px] text-[#1B2336] leading-[35px] flex flex-col gap-[10px] ml-6">
            <li>
              Prerequisite:{" "}
              <span className="font-[500] text-[#003C9D]">
                Bachelors in Computer Science
              </span>
            </li>
            <li>
              Program Length:{" "}
              <span className="font-[500] text-[#003C9D]">4 Weeks</span>
            </li>
            <li>
              Academic Credential Awarded:{" "}
              <span className="font-[500] text-[#003C9D]">Certificate</span>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Overview;
