import React from "react";

const Faqs = () => {
  return (
    <>
      <div>
        <p className="font-[500] text-[20px] md:text-[24px] xl:text-[32px] leading-[36px] xl:leading-[48px] text-[#003C9D]">
          Ayurveda Wellness Practitioner (AWP) Program FAQ:
        </p>
        <div className="mt-5 md:mt-10 leading-[30px] text-[18px] md:text-[20px]">
          <ul className="list-disc flex flex-col gap-6 ml-5 font-[500]">
            <li>
              What is Hybrid Programs?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  All hybrid programs will allow you to choose in-person and/or
                   online instruction. Lectures may be offered at the  School
                  location or remotely via a Web Conferencing Platform.
                  Laboratory/ clinical classes and practical training will be
                  held on at School site or at a clinical site. Synchronous
                  (Online synchronous classes) instruction is real-time
                  instruction that requires students to attend classes at
                  scheduled times.
                </p>
              </div>
            </li>
            <li className="">
              What does an Ayurveda Wellness Practitioner (AWP) do?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  An Ayurveda Wellness Practitioner (AWP) plays a multifaceted
                  role in promoting holistic health and well-being using the
                  principles of Ayurveda. Here are some key tasks and
                  responsibilities:
                </p>

                <ul className="list-disc marker:text-[#003C9D]  leading-[35px] ml-8 flex flex-col gap-[10px]">
                  <li>
                    <span className="text-[#003C9D]">Health Assessment: </span>{" "}
                    Ayurveda Wellness Practitioner (AWP) assess individuals'
                    health conditions using Ayurvedic diagnostic methods,
                    including pulse diagnosis (Nadi Pariksha), tongue
                    examination (Jivha Pariksha), and observation of physical
                    characteristics (Darshanam). They identify imbalances in the
                    body's doshas (bioenergies) and determine the individual's
                    constitution (Prakriti) and current state (Vikriti).
                  </li>
                  <li>
                    <span className="text-[#003C9D]">
                      Personalized Wellness Plans:{" "}
                    </span>{" "}
                    Based on their assessments Ayurveda Wellness Practitioner
                    (AWP)s create personalized wellness plans tailored to
                    everyone’s unique constitution and imbalances. These plans
                    may include dietary recommendations, lifestyle
                    modifications, herbal remedies, therapeutic treatments, and
                    mind-body practices to restore balance and promote health.
                  </li>

                  <li>
                    <span className="text-[#003C9D]">Herbal Medicine: </span>{" "}
                    Ayurveda Wellness Practitioner (AWP) prescribe or recommend
                    Ayurvedic herbs and formulations to address specific health
                    concerns or imbalances. They have knowledge of the
                    properties, actions, and therapeutic uses of various herbs
                    and herbal preparations to support the body's natural
                    healing processes.
                  </li>

                  <li>
                    <span className="text-[#003C9D]">
                      Therapeutic Treatments:{" "}
                    </span>{" "}
                    Ayurveda Wellness Practitioner (AWP) administers Ayurvedic
                    therapies and treatments to promote detoxification,
                    rejuvenation, and healing. These may include Panchakarma
                    (cleansing therapies), Abhyanga (oil massage), Shirodhara
                    (oil pouring therapy), Swedana (herbal steam therapy), and
                    other specialized treatments.
                  </li>

                  <li>
                    <span className="text-[#003C9D]">
                      Lifestyle Counseling:{" "}
                    </span>{" "}
                    Ayurveda Wellness Practitioner (AWP) offers guidance on
                    lifestyle factors such as diet, exercise, sleep, stress
                    management, and daily routines to support overall health and
                    prevent disease. They educate individuals on Ayurvedic
                    principles of healthy living and empower them to make
                    sustainable lifestyle choices.
                  </li>

                  <li>
                    <span className="text-[#003C9D]">
                      Mind-Body Integration:
                    </span>{" "}
                    Ayurveda Wellness Practitioner (AWP) facilitates the
                    integration of mind and body through practices such as
                    meditation, yoga, pranayama (breathing exercises), and
                    mindfulness techniques. They recognize the
                    interconnectedness of mental, emotional, and physical health
                    and provide tools for holistic well-being.
                  </li>

                  <li>
                    <span className="text-[#003C9D]">Health Education:</span>{" "}
                    Ayurveda Wellness Practitioner (AWP) educates individuals
                    and communities about Ayurvedic principles, preventive
                    healthcare measures, and self-care practices to empower them
                    in managing their health. They conduct workshops, classes,
                    and seminars to raise awareness and promote Ayurvedic
                    wisdom.
                  </li>
                </ul>
              </div>
            </li>

            <li>
              What is an Ayurveda Wellness Practitioner (AWP)?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  An Ayurveda Wellness Practitioner is a trained professional
                  who applies the principles of Ayurveda to assess individuals'
                  health imbalances and provide personalized wellness plans to
                  promote holistic health and well-being.
                </p>
              </div>
            </li>

            <li>
              What does the Ayurveda Wellness Practitioner (AWP) program entail?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  The AWP program typically includes comprehensive coursework
                  covering Ayurvedic principles, diagnostic techniques, herbal
                  medicine, therapeutic treatments, lifestyle recommendations,
                  and clinical internship experience to provide hands-on
                  learning.
                </p>
              </div>
            </li>

            <li>
              Who can enroll in the Ayurveda Wellness Practitioner (AWP)
              program?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  The AWP program is open to individuals interested in holistic
                  health and wellness, including healthcare professionals,
                  alternative medicine practitioners, yoga instructors,
                  nutritionists, and anyone enthusiastic about Ayurveda.
                </p>
              </div>
            </li>

            <li>
              What are the career opportunities for Ayurveda Wellness
              Practitioner (AWP) graduates?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  AWP graduates can pursue various career paths, including
                  working in wellness centers, spas, hospitals, clinics,
                  Ayurvedic resorts, teaching, consulting, research, and
                  establishing their own private practice.
                </p>

                <ul className="list-disc marker:text-[#003C9D]  leading-[35px] ml-8 flex flex-col gap-[10px]">
                  <li>
                    <span className="text-[#003C9D]">
                      Collaboration with Healthcare Professionals:{" "}
                    </span>
                    Ayurveda Wellness Practitioner (AWP) may collaborate with
                    other healthcare providers, including allopathic doctors,
                    naturopaths, chiropractors, and holistic practitioners, to
                    offer integrated and comprehensive care to patients. They
                    communicate effectively and respectably with other
                    professionals to ensure the best outcomes for patients.
                  </li>
                  <li>
                    <span className="text-[#003C9D]">Ethical Practice: </span>{" "}
                    Ayurveda Wellness Practitioner (AWP) adhere to ethical
                    standards and professionalism in their practice, maintaining
                    patient confidentiality, respecting cultural beliefs, and
                    upholding integrity in all interactions. They practice
                    within legal and regulatory frameworks and prioritize the
                    well-being and safety of their clients.
                  </li>
                </ul>
              </div>
            </li>

            <li>
              What are the prerequisites for enrolling in the Ayurveda Wellness
              Practitioner (AWP) program?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  Typically, applicants are required to have a high school
                  diploma or equivalent and a foundation understanding of
                  anatomy and physiology.
                </p>
              </div>
            </li>

            <li>
              What topics are covered in the Ayurveda Wellness Practitioner
              (AWP) curriculum?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  The curriculum typically covers a wide range of topics,
                  including, Advanced Anatomy, Physiology & Basic Principle,
                  Pathophysiology &Ayurvedic Diagnostic Methods, Advanced
                  Herbology & Ayurvedic Pharmaceutics, Advanced Bodywork,
                  Detoxification & Rasayana, Management of Digestive system &
                  Metabolic Disorders, Management of Nervous system &
                  Musculoskeletal Disorders, Management of Respiratory system &
                  Cardiovascular system disorders, Management of skin, special
                  senses, & Mental Disorders, Management of Reproductive &
                  Excretory system disorders, Disease Management (Kaya
                  Chikitsa).
                </p>
              </div>
            </li>

            <li>
              What learning formats are available for the Ayurveda Wellness
              Practitioner (AWP) program?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  Ayurveda Wellness Practitioner (AWP) programs may be offered
                  in various formats, including in-person classes, hybrid
                  formats combining both online and in-person learning, and
                  self-paced study options.
                </p>
              </div>
            </li>

            <li>
              Is certification available upon completion of the Ayurveda
              Wellness Practitioner (AWP) program?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  Yes, Ayurveda Wellness Practitioner (AWP) programs offer
                  certification from the Param Institute of Education upon
                  successful completion of the coursework and any required
                  assessments. Certification demonstrates proficiency in
                  Ayurvedic principles and may enhance career opportunities.
                </p>
              </div>
            </li>

            <li>
              How long does it take to complete the Ayurveda Wellness
              Practitioner (AWP) program?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  The duration of the program may be completed in 52 weeks- 1000
                  Hrs.
                </p>
              </div>
            </li>

            <li>
              Can I continue my education after completing the Ayurveda Wellness
              Practitioner (AWP) program?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  Yes, graduates of the Ayurveda Wellness Practitioner (AWP)
                  program may choose to further their education by pursuing
                  advanced studies in Ayurveda, such as becoming an Ayurvedic
                  Practitioner.
                </p>
              </div>
            </li>

            <li>
              When can I start the course?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  This course is open enrollment, so you can register and start
                  the course as soon as you are ready.
                </p>
              </div>
            </li>

            <li>
              What kind of support will I receive?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  During the course, you can reach out to your instructor via
                  email for any questions or feedback on your performance.
                  Occasionally, industry experts may also contribute to your
                  learning experience. You will have the support of a student
                  advising team available to assist you throughout your journey.
                </p>
              </div>
            </li>

            <li>
              What if I do not have enough time to complete my course within the
              time provided?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  The duration for completing the course has been determined
                  according to the total number of course hours. If you find
                  yourself unable to finish within the allotted time, you can
                  reach out to the student advising team to explore potential
                  options for extending your completion date. Please be aware
                  that there may be an extension fee associated with this.
                </p>
              </div>
            </li>

            <li>
              Can I be assured of employment after completing the program?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  This course equips you with the necessary skills for securing
                  a job position in many cases. We offer support with resume
                  preparation and job search strategies to aid you in finding
                  employment. Additionally, prospective students are advised to
                  research the job market in their area before enrolling.
                </p>
              </div>
            </li>

            <li>
              Can I get financial assistance?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  This course is non-credit, so it does not qualify for federal
                  aid, FAFSA and/or Pell Grant.
                </p>
              </div>
            </li>

            <li>
              How can I get more information about this course?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  If you have questions that are not answered on our website,
                  representatives are available via EMAIL (info@paramedu.com).
                  You can also call us at +1 848 256 2737 during regular
                  business hours to have your questions promptly answered. If
                  you are visiting us during non-business hours, please send us
                  a question using the "Contact Us" form.
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Faqs;
