import React from "react";
import missionPic from "../../assets/images/missionPic.svg";
import missionLogo from "../../assets/images/missionLogo.svg";
import visionLogo from "../../assets/images/visionLogo.svg";
import { useNavigate } from "react-router-dom";

function MissionVision() {
  const navigation = useNavigate();
  return (
    <div className=" w-full flex flex-col items-center font-poppins mb-28">
      <div className="bg-mission-bg bg-cover w-full h-[400px] md:h-[600px] justify-center items-center flex flex-col gap-8 md:gap-12 ">
        <p className="font-[800] text-[32px] md:text-[50px] 2xl:text-[60px] w-[calc(100%_-_32px)] md:w-[calc(100%_-_64px)] text-center text-white">
          Mission & Vision
        </p>
        <div className=" flex justify-center gap-4 md:gap-8 flex-col md:flex-row">
          <button
            className="orangeButton"
            onClick={() => navigation("../scheduleAppointment")}
          >
            Schedule a Visit
          </button>
          <button
            className="orangeButton"
            onClick={() => navigation("../requestInfo")}
          >
            Inquire Now
          </button>
          <button
            className="orangeButton"
            onClick={() => navigation("../enrollment")}
          >
            Enroll Now
          </button>
        </div>
      </div>
      <div className="w-[calc(100%_-_32px)] md:w-[calc(100%_-_64px)] xl:w-[90%] mt-16">
        <div className="font-[400] text-[20px] text-[#5C616E]  leading-[45px] gap-10 2xl:gap-20  flex flex-col 2xl:flex-row">
          <div className="w-full 2xl:w-[55%] relative pb-[74%] md:pb-[55%] 2xl:pb-[40%] top-0 left-0">
            <div className="absolute top-0 left-0 w-full h-full">
              <img
                src={missionPic}
                alt="missionPic"
                className="w-full h-full object-cover"
              />
            </div>
          </div>

          <div className="w-full 2xl:w-[45%] flex flex-col items-center gap-8 md:gap-10">
            <div className="w-[97%] 2xl:w-[90%] gap-4 md:gap-6 flex flex-col">
              <div className="flex gap-6 items-center">
                <img
                  src={missionLogo}
                  className="h-[24px] w-[24px] md:h-[32px] md:w-[32px]"
                />
                <p className="font-[600] text-[24px] md:text-[32px] text-[#003C9D]">
                  Our Mission
                </p>
              </div>
              <p className="font-[400] text-[18px] md:text-[20px] text-[#5C616E] text-justify whitespace-[-2px]">
                Our School Mission is to provide high quality vocational
                education to students with significant contributions to the
                regional economies and committed to the principle of equal
                opportunity in education and employment. We aspire to create
                learning environments which will transform the lives of the
                people we serve, enabling them to have a greater positive impact
                in their communities and in the field of allied healthcare.
              </p>
            </div>
            <div className="w-[97%] 2xl:w-[90%] gap-4 md:gap-6 flex flex-col">
              <div className="flex gap-6 items-center">
                <img
                  src={visionLogo}
                  className="h-[24px] w-[24px] md:h-[32px] md:w-[32px]"
                />
                <p className="font-[600] text-[24px] md:text-[32px] text-[#003C9D]">
                  Our Vision
                </p>
              </div>
              <p className="font-[400] text-[18px] md:text-[20px] text-[#5C616E] text-justify whitespace-[-2px]">
                Param Institute of Education aims to become premier provider of
                world class education, skills training, and lifelong learning
                for success in a global economy with our guiding principles of
                quality, integrity and sustainability and partnerships within
                the Educational Community providing a seamless transition to
                educational and career goals.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MissionVision;
