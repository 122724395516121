import React, { useEffect } from "react";
import headerLogo from "../assets/images/headerLogo.svg";
import footerLogo from "../assets/images/footerLogo.png";
import facebook from "../assets/images/facebook.svg";
import insta from "../assets/images/insta.svg";
import twitter from "../assets/images/twitter.svg";
import youtube from "../assets/images/youtube.svg";
import linkdin from "../assets/images/linkdin.svg";
import { useNavigate } from "react-router-dom";
import useScreenSize from "../hooks/useScreenSize";
function Footer() {
  const setYear = new Date().getFullYear();
  const navigation = useNavigate();
  const { width, height } = useScreenSize();

  return (
    <>
      <div className="w-full  bg-[#003C9D1A] mt-10 flex items-center font-poppins flex-col gap-9 md:gap-16 pt-12 pb-10 991:pb-24">
        <div className="w-[calc(100%_-_32px)] md:w-[calc(100%_-_64px)] 2xl:w-[75%] bg-[#003C9D] text-white flex justify-center ">
          <div className="px-3 py-4 md:px-12 md:py-12 flex items-center gap-4 md:gap-10 flex-col 2xl:flex-row">
            <div className="w-full 2xl:w-[80%] flex gap-3 md:gap-8 flex-col">
              <p className="font-[500] text-[24px] md:text-[36px] ">
                Request More Info to Get Started
              </p>
              <p className="font-[300] text-[14px] md:text-[16px]">
                Our school is one of the  leading Allied healthcare Program 
                training school in State of New Jersey.  Don't miss out on this
                valuable opportunity. For Request information about the program
                please click on Inquire Now !
              </p>
            </div>
            <div className="w-full 2xl:w-[20%] text-center">
              <button
                className="orangeButton"
                onClick={() => {
                  navigation("requestInfo");
                }}
              >
                Inquire Now
              </button>
            </div>
          </div>
        </div>
        <div className="w-[calc(100%_-_32px)] md:w-[calc(100%_-_64px)]">
          <div className="w-full flex gap-10 2xl:gap-24 flex-col 991:flex-row">
            <div className="w-full 991:w-[25%] flex items-start 2xl:items-center justify-center">
              {" "}
              <img
                src={width < 768 ? footerLogo : headerLogo}
                alt="paramLogo"
                className="w-[221px] 991:w-full 2xl:w-[221px]"
              />
            </div>
            <div className="w-full 991:w-[25%] flex flex-col gap-4">
              <p className="font-[500] text-[20px] 991:text-[24px] text-[#000000]">
                Address
              </p>
              <p className="font-[500] text-[14px] 991:text-[16px] text-[#1B2336] w-[70%]">
                200 Middlesex Essex Turnpike, Suite #105,
                <br /> Iselin, NJ, 08830.
                <br />{" "}
                <a
                  href="tel:+1
                732-283-5656"
                >
                  +1 732-283-5656
                </a>{" "}
                <br /> <a href="mailto:info@paramedu.com">info@paramedu.com</a>
              </p>
            </div>
            <div className="w-full 991:w-[25%] flex flex-col gap-4">
              <div className="flex flex-col gap-2">
                <p className="font-[500] text-[20px] 991:text-[24px] text-[#000000]">
                  Top Categories
                </p>
                <a
                  href="../healthcarePrograms"
                  className="font-[500] text-[14px] 991:text-[16px] text-[#1B2336]"
                >
                  Healthcare Programs
                </a>
                <a
                  href="../WellnessPrograms"
                  className="font-[500] text-[14px] 991:text-[16px] text-[#1B2336]"
                >
                  Wellness Programs
                </a>
                <a
                  href="../ITPrograms"
                  className="font-[500] text-[14px] 991:text-[16px] text-[#1B2336]"
                >
                  Information Technology
                </a>
              </div>
            </div>
            <div className="w-full 991:w-[25%] flex flex-col gap-4">
              <p className="font-[500] text-[20px] 991:text-[24px] text-[#000000]">
                Follow Us
              </p>
              <div className="flex flex-col gap-2">
                {" "}
                <div className="flex gap-4 xl:gap-6 flex-wrap">
                  <a href="https://x.com/paraminc?s=11" target="_blank">
                    <img src={twitter} alt="twitter" />
                  </a>
                  <a
                    href="https://www.facebook.com/paraminstituteofeducation"
                    target="_blank"
                  >
                    <img src={facebook} alt="facebook" />
                  </a>
                  <a
                    href="https://www.instagram.com/paraminstituteofeducation?igsh=MWFzcHlteWhzODkzeA=="
                    target="_blank"
                  >
                    <img src={insta} alt="insta" />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/param-institute-of-education/"
                    target="_blank"
                  >
                    <img src={linkdin} alt="linkdin" />
                  </a>
                  <a
                    href="https://youtube.com/playlist?list=PLy1ia9XY0qTHLLlIj2KsoUVfytKs1TwgR&feature=shared"
                    target="_blank"
                  >
                    <img src={youtube} alt="youtube" />
                  </a>
                </div>
                <a
                  href="../termsAndconditions"
                  className="font-[500] text-[14px] 991:text-[16px] text-[#1B2336]"
                >
                  Terms & Conditions
                </a>
                <a
                  href="../privacyPolicy"
                  className="font-[500] text-[14px] 991:text-[16px] text-[#1B2336]"
                >
                  Privacy Policy
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full bg-[#003C9D] flex justify-center font-poppins text-white py-4">
        <div className="flex justify-between  flex-col 991:flex-row text-center gap-4 991:gap-0 w-[calc(100%_-_24px)] md:w-[calc(100%_-_64px)] xl:w-[90%]">
          <p className="font-[500] text-[12px]">
            Copyright © <span>{setYear}</span> Param Institute of Education. All
            Rights Reserved.
          </p>
          <div className="flex justify-center items-center gap-4">
            <a
              href="https://www.freepik.com"
              target="_blank"
              className="font-[400] text-[12px] underline"
            >
              Images by storyset on Freepik
            </a>
            <div className="w-[8px] h-[8px] bg-white rounded-full" />
            <a
              href="https://unsplash.com/"
              target="_blank"
              className="font-[400] text-[12px] underline"
            >
              Images by unsplash
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
