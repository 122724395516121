import {
  ADD_ENROLLMENT_VERIFICATION,
  ADD_ENROLLMENT_VERIFICATION_SUCCESS,
  ADD_ENROLLMENT_VERIFICATION_FAILURE,
  ADD_LEAVE_ABSENCE,
  ADD_LEAVE_ABSENCE_SUCCESS,
  ADD_LEAVE_ABSENCE_FAILURE,
  GET_WITHDRAWAL_REASONS,
  GET_WITHDRAWAL_REASONS_SUCCESS,
  GET_WITHDRAWAL_REASONS_FAILURE,
  ADD_ADMISSION_WITHDRAWAL,
  ADD_ADMISSION_WITHDRAWAL_FAILURE,
  ADD_ADMISSION_WITHDRAWAL_SUCCESS,
  ADD_TRANSCRIPT,
  ADD_TRANSCRIPT_SUCCESS,
  ADD_TRANSCRIPT_FAILURE,
  ADD_CERTIFICATE,
  ADD_CERTIFICATE_SUCCESS,
  ADD_CERTIFICATE_FAILURE,
  ADD_ENROLLMENT,
  ADD_APPOINTMENT_SUCCESS,
  ADD_ENROLLMENT_FAILURE,
  ADD_ENROLLMENT_SUCCESS,
  GET_HOLIDAYS,
  GET_HOLIDAYS_SUCCESS,
  GET_HOLIDAYS_FAILURE,
  ADD_GRIEVANCE,
  ADD_GRIEVANCE_SUCCESS,
  ADD_GRIEVANCE_FAILURE,
} from "../ActionTypes";

export const addEnrollmentVerification = (payload) => {
  return {
    type: ADD_ENROLLMENT_VERIFICATION,
    payload: payload,
  };
};
export const addEnrollmentVerificationSuccess = (payload) => {
  return {
    type: ADD_ENROLLMENT_VERIFICATION_SUCCESS,
    payload: payload,
  };
};
export const addEnrollmentVerificationFailure = (payload) => {
  return {
    type: ADD_ENROLLMENT_VERIFICATION_FAILURE,
    payload: payload,
  };
};

export const addLeaveAbsence = (payload) => {
  return {
    type: ADD_LEAVE_ABSENCE,
    payload: payload,
  };
};
export const addLeaveAbsenceSuccess = (payload) => {
  return {
    type: ADD_LEAVE_ABSENCE_SUCCESS,
    payload: payload,
  };
};
export const addLeaveAbsenceFailure = (payload) => {
  return {
    type: ADD_LEAVE_ABSENCE_FAILURE,
    payload: payload,
  };
};

export const getWithdrawalReasons = (payload) => {
  return {
    type: GET_WITHDRAWAL_REASONS,
    payload: payload,
  };
};
export const getWithdrawalReasonsSuccess = (payload) => {
  return {
    type: GET_WITHDRAWAL_REASONS_SUCCESS,
    payload: payload,
  };
};
export const getWithdrawalReasonsFailure = (payload) => {
  return {
    type: GET_WITHDRAWAL_REASONS_FAILURE,
    payload: payload,
  };
};

export const addAdmissionWithdrawal = (payload) => {
  return {
    type: ADD_ADMISSION_WITHDRAWAL,
    payload: payload,
  };
};
export const addAdmissionWithdrawalSuccess = (payload) => {
  return {
    type: ADD_ADMISSION_WITHDRAWAL_SUCCESS,
    payload: payload,
  };
};
export const addAdmissionWithdrawalFailure = (payload) => {
  return {
    type: ADD_ADMISSION_WITHDRAWAL_FAILURE,
    payload: payload,
  };
};

export const addTranscript = (payload) => {
  return {
    type: ADD_TRANSCRIPT,
    payload: payload,
  };
};
export const addTranscriptSuccess = (payload) => {
  return {
    type: ADD_TRANSCRIPT_SUCCESS,
    payload: payload,
  };
};
export const addTranscriptFailure = (payload) => {
  return {
    type: ADD_TRANSCRIPT_FAILURE,
    payload: payload,
  };
};

export const addCertificate = (payload) => {
  return {
    type: ADD_CERTIFICATE,
    payload: payload,
  };
};
export const addCertificateSuccess = (payload) => {
  return {
    type: ADD_CERTIFICATE_SUCCESS,
    payload: payload,
  };
};
export const addCertificateFailure = (payload) => {
  return {
    type: ADD_CERTIFICATE_FAILURE,
    payload: payload,
  };
};

export const addEnrollment = (payload) => {
  return {
    type: ADD_ENROLLMENT,
    payload: payload,
  };
};

export const addEnrollmentSuccess = (payload) => {
  return {
    type: ADD_ENROLLMENT_SUCCESS,
    payload: payload,
  };
};

export const addEnrollmentFailure = (payload) => {
  return {
    type: ADD_ENROLLMENT_FAILURE,
    payload: payload,
  };
};

export const getHolidays = (payload) => {
  return {
    type: GET_HOLIDAYS,
    payload: payload,
  };
};

export const getHolidaysSuccess = (payload) => {
  return {
    type: GET_HOLIDAYS_SUCCESS,
    payload: payload,
  };
};

export const getHolidaysFailure = (payload) => {
  return {
    type: GET_HOLIDAYS_FAILURE,
    payload: payload,
  };
};

export const addGrievance = (payload) => {
  return {
    type: ADD_GRIEVANCE,
    payload: payload,
  };
};
export const addGrievanceSuccess = (payload) => {
  return {
    type: ADD_GRIEVANCE_SUCCESS,
    payload: payload,
  };
};
export const addGrievanceFailure = (payload) => {
  return {
    type: ADD_GRIEVANCE_FAILURE,
    payload: payload,
  };
};
