import { call, put, takeLatest } from "redux-saga/effects";
import {
  ADD_CONTACTUS,
  ADD_REQUESTINFO,
  GET_COURSES,
  GET_COMBO_COURSES,
} from "../ActionTypes";
import {
  addContactusAPI,
  addRequestInfoAPI,
  getCoursesAPI,
  getComboCoursesAPI,
} from "../Api";
import {
  addContactusFailure,
  addContactusSuccess,
  addReuestInfoFailure,
  addReuestInfoSuccess,
  getCoursesFailure,
  getCoursesSuccess,
  getComboCoursesSuccess,
  getComboCoursesFailure,
} from "../actions/common.action";

function* contactusSaga(action) {
  try {
    const response = yield call(addContactusAPI, action.payload);
    if (response) {
      console.log("response", response);
      yield put(addContactusSuccess(response)); // Pass response.data instead of the whole response object
    }
  } catch (error) {
    yield put(addContactusFailure(error));
    console.log("error", error);
  }
}
function* coursesSaga(action) {
  try {
    const response = yield call(getCoursesAPI, action.payload);
    if (response) {
      yield put(getCoursesSuccess(response.data));
    }
  } catch (error) {
    yield put(getCoursesFailure(error));
    console.log("error", error);
  }
}

function* requestInfoSaga(action) {
  try {
    const response = yield call(addRequestInfoAPI, action.payload);
    if (response) {
      yield put(addReuestInfoSuccess(response)); // Pass response.data instead of the whole response object
    }
  } catch (error) {
    yield put(addReuestInfoFailure(error));
    console.log("error", error);
  }
}

function* comboCoursesSaga(action) {
  try {
    const response = yield call(getComboCoursesAPI, action.payload);
    if (response) {
      yield put(getComboCoursesSuccess(response.data));
    }
  } catch (error) {
    yield put(getComboCoursesFailure(error));
    console.log("error", error);
  }
}

export function* commonSaga() {
  yield takeLatest(ADD_CONTACTUS, contactusSaga);
  yield takeLatest(GET_COURSES, coursesSaga);
  yield takeLatest(ADD_REQUESTINFO, requestInfoSaga);
  yield takeLatest(GET_COMBO_COURSES, comboCoursesSaga);
}
