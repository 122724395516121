//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//
import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
// import { useDispatch, useSelector } from "react-redux";
import facebook from "../assets/images/facebook.svg";
import insta from "../assets/images/insta.svg";
import twitter from "../assets/images/twitter.svg";
import youtube from "../assets/images/youtube.svg";
import linkdin from "../assets/images/linkdin.svg";
import mobileHeaderLogo from "../assets/images/pie_logo.gif";
import headerLogo from "../assets/images/headerLogo.svg";
import subMenuArrow from "../assets/images/subMenuArrow.svg";
import dowmArrow from "../assets/images/dowmArrow.svg";
import HamburgerMenu from "./HamburgerMenu";
import useScreenSize from "../hooks/useScreenSize";

//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//

function Header({ route }) {
  //--------------------------------------------y------------------------All Variables---------------------------------------------------------------------------//
  const navigation = useNavigate();
  const location = useLocation();
  let currentRoute = location.pathname;
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const { width, height } = useScreenSize();

  // let current_route = useSelector((state) => state.allData.current_route);
  // const dispatch = useDispatch();
  let parentfree = ["/"];
  let showHeader = !parentfree.includes(route);
  const setYear = new Date().getFullYear();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [AHPsubmenuOpen, setAHPsubmenuOpen] = useState(false);
  const [ATPsubmenuOpen, setATPsubmenuOpen] = useState(false);
  const [ITsubmenuOpen, setITsubmenuOpen] = useState(false);
  const [SCsubmenuOpen, setSCsubmenuOpen] = useState(false);

  //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//

  // useEffect(() => {
  //     dispatch({ type: "CURRENT_ROUTE", payload: currentRoute });
  //   }, [currentRoute]);
  const handleMouseEnter = () => {
    setDropdownVisible(!isDropdownVisible);
  };

  const handleMouseLeave = () => {
    setDropdownVisible(false);
  };
  const [showSubMenu, setShowSubMenu] = useState(false);

  const toggleSubMenu = () => {
    setShowSubMenu(!showSubMenu);
  };
  return (
    <div className="w-full items-center flex py-3 flex-col font-poppins gap-3 lg:gap-6">
      <div className="flex justify-between w-[calc(100%_-_32px)] md:w-[calc(100%_-_64px)] xl:w-[90%] items-center">
        <div className="block 1200:hidden mr-[20px] 991:mr-[82px] ">
          <HamburgerMenu />
        </div>

        <div className="flex justify-center w-full md:w-auto mr-[52px] md:mr-0">
          <img
            src={width < 768 ? mobileHeaderLogo : headerLogo}
            alt="paramLogo"
            onClick={() => {
              navigation("");
            }}
            className="block 1800:hidden h-auto w-[109px] md:w-[173px] 1400:h-auto 1400:w-full cursor-pointer "
          />
        </div>
        <div className="justify-end hidden md:flex items-end gap-5 w-full">
          <p className="font-[400] text-[16px] text-primary  ">
            +1 732-283-5656
          </p>
          <div className="border-x border border-primary  h-[21px] w-[2px]" />
          <div className="flex gap-10">
            <a href="https://x.com/paraminc?s=11" target="_blank">
              <img src={twitter} alt="twitter" />
            </a>
            <a
              href="https://www.facebook.com/paraminstituteofeducation"
              target="_blank"
            >
              <img src={facebook} alt="facebook" />
            </a>
            <a
              href="https://www.instagram.com/paraminstituteofeducation?igsh=MWFzcHlteWhzODkzeA=="
              target="_blank"
            >
              <img src={insta} alt="insta" />
            </a>
            <a
              href="https://www.linkedin.com/company/param-institute-of-education/"
              target="_blank"
            >
              <img src={linkdin} alt="linkdin" />
            </a>
            <a
              href="https://youtube.com/playlist?list=PLy1ia9XY0qTHLLlIj2KsoUVfytKs1TwgR&feature=shared"
              target="_blank"
            >
              <img src={youtube} alt="youtube" />
            </a>
          </div>
        </div>
      </div>

      <div className="w-[calc(100%_-_64px)] xl:w-[90%] hidden 1200:flex items-center gap-5 justify-between ">
        <img
          src={headerLogo}
          alt="paramLogo"
          onClick={() => {
            navigation("");
          }}
          className="hidden 1800:block cursor-pointer"
        />
        <ul className="flex gap-5 1400:gap-10 text-[20px] font-[500] ">
          <li id="aboutUs">
            <div
              className="items-center justify-between hidden w-full md:flex md:w-auto"
              id="navbar-sticky"
            >
              <div className="group inline-block">
                <Link to="./">
                  <button className=" md:p-0 md:hover:text-[#003C9D]  text-[20px] font-[500] flex items-center justify-start">
                    <span>Home</span>
                  </button>
                </Link>
              </div>
            </div>
          </li>
          <li id="aboutUs">
            <div
              className="items-center justify-between hidden w-full md:flex md:w-auto"
              id="navbar-sticky"
            >
              <div className="group inline-block">
                <button className=" md:p-0 md:hover:text-[#003C9D]  text-[20px] font-[500] flex items-center justify-start">
                  <span>About Us</span>
                  <span>
                    <svg
                      className="fill-current h-6 w-6 transform group-hover:-rotate-180 transition duration-150 ease-in-out"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </span>
                </button>
                <ul className="bg-white border-b-[5px] pt-4 border-[#003C9D]  flex flex-col gap-5 shadow-md transform scale-0 group-hover:scale-100 hover:scale-100 absolute z-10 py-2 px-5 transition duration-150 ease-in-out origin-top max-auto">
                  <Link to={"historyPIE"}>
                    <li className="flex px-3  text-[18px] font-[400] text-[#262626] font-inter  ">
                      Param Institute of Education History
                    </li>
                  </Link>
                  <Link to={"accreditation"}>
                    <li className="flex px-3  text-[18px] font-[400] text-[#262626] font-inter  ">
                      Approval & Accreditation
                    </li>
                  </Link>
                  <Link to={"mission&vision"}>
                    <li className="flex px-3  text-[18px] font-[400] text-[#262626] font-inter  ">
                      Mission and Vision
                    </li>
                  </Link>
                  <Link to={"schoolobjectives"}>
                    <li className="flex px-3  text-[18px] font-[400] text-[#262626] font-inter ">
                      School Objectives & Core values
                    </li>
                  </Link>
                  <Link to={"schoolpresident"}>
                    <li className="flex px-3  text-[18px] font-[400] text-[#262626] font-inter ">
                      School President & Director Message
                    </li>
                  </Link>
                  <Link to={"facultyStaff"}>
                    <li className="flex px-3  text-[18px] font-[400] text-[#262626] font-inter ">
                      Faculty & Staffs
                    </li>
                  </Link>
                  <Link to={"whyParam"}>
                    <li className="flex px-3  text-[18px] font-[400] text-[#262626] font-inter ">
                      Why Param Institute of Education?
                    </li>
                  </Link>
                  <Link to={"hiring"}>
                    <li className="flex px-3  text-[18px] font-[400] text-[#262626] font-inter ">
                      Who Is Hiring Our Graduates?
                    </li>
                  </Link>
                </ul>
              </div>
            </div>
          </li>
          {/* <li id="courses" className="relative">
                        <button onClick={() => setDropdownOpen(!dropdownOpen)}
                            className=" hover:text-[#003C9D]  text-[20px] font-[500] flex items-center justify-start">
                            <span >Courses</span>
                            <span>
                                <svg
                                    className="fill-current h-6 w-6 transform group-hover:-rotate-180 transition duration-150 ease-in-out"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20">
                                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                </svg>
                            </span>
                        </button>

                        {dropdownOpen && (
                            <ul className="w-[395px] absolute  mt-4 space-y-2 rounded shadow-lg bg-white border-b-[5px]  border-[#003C9D]  ">
                                <li className="relative">
                                    <button
                                        onClick={() => {
                                            setAHPsubmenuOpen(!AHPsubmenuOpen);
                                            setATPsubmenuOpen(false);
                                            setITsubmenuOpen(false);
                                        }}
                                        className="w-full text-left block px-4 py-2 ">
                                        <span className=' w-full flex justify-between font-inter text-[18px] font-[400]'>
                                            Allied Healthcare Programs
                                            <img src={subMenuArrow} alt="downarrow" />
                                        </span>
                                    </button>
                                    {AHPsubmenuOpen && (
                                        <ul className="w-[395px] absolute left-full top-0 mt-2 space-y-2  shadow-lg  bg-white border-b-[5px]  border-[#003C9D] font-inter text-[18px] font-[400] flex flex-col gap-2  p-2">
                                            <li>
                                                <a href="#" className="block">Medical Billing and Coding Specialist</a>
                                            </li>
                                            <li>
                                                <a href="#" className="block">Allied Healthcare Programs sub menu</a>
                                            </li>
                                            <li>
                                                <a href="#" className="block">Certified Electrocardiograph Technician</a>
                                            </li>
                                            <li>
                                                <a href="#" className="block">Certified Patient Care Technician</a>
                                            </li>
                                            <li>
                                                <a href="#" className="block">Certified Clinical Medical Assistant (CCMA)</a>
                                            </li>
                                            <li>
                                                <a href="#" className="block">Certified Nurse Aide (CNA) to Homemaker Home Health aide (CHHA) conversion program</a>
                                            </li>
                                            <li>
                                                <a href="#" className="block">Certified Nurse Assistant (CNA)</a>
                                            </li>
                                            <li>
                                                <a href="#" className="block">Home-Maker Home Health Aide (CHHA)</a>
                                            </li>
                                            <li>
                                                <a href="#" className="block">Pharmacy Technician (In-Person Program)</a>
                                            </li>
                                        </ul>
                                    )}
                                </li>

                                <li className="relative">
                                    <button
                                        onClick={() => {
                                            setATPsubmenuOpen(!ATPsubmenuOpen);
                                            setAHPsubmenuOpen(false)
                                            setITsubmenuOpen(false)
                                        }}
                                        className="w-full text-left block px-4 py-2 ">
                                        <span className=' w-full flex justify-between font-inter text-[18px] font-[400]'>
                                            Alternate Therapy / Wellness Programs
                                            <img src={subMenuArrow} alt="downarrow" />
                                        </span>
                                    </button>
                                    {ATPsubmenuOpen && (
                                        <ul className="w-[395px] absolute left-full top-0 mt-2 space-y-2  shadow-lg  bg-white border-b-[5px]  border-[#003C9D] font-inter text-[18px] font-[400] flex flex-col gap-2  p-2">
                                            <li>
                                                <a href="#" className="block">Ayurvedic Wellness Counselor Program (AWC)</a>
                                            </li>
                                            <li>
                                                <a href="#" className="block">Ayurvedic Wellness Practitioner Program (AWP)</a>
                                            </li>
                                            <li>
                                                <a href="#" className="block">Yoga 200-Hrs</a>
                                            </li>
                                            <li>
                                                <a href="#" className="block">Yoga 300-Hrs</a>
                                            </li>
                                            <li>
                                                <a href="#" className="block">Massage Therapy</a>
                                            </li>
                                        </ul>
                                    )}
                                </li>
                                <li className="relative">
                                    <button
                                        onClick={() => {
                                            setITsubmenuOpen(!ITsubmenuOpen);
                                            setAHPsubmenuOpen(false);
                                            setATPsubmenuOpen(false)
                                        }}
                                        className="w-full text-left block px-4 py-2 ">
                                        <span className=' w-full flex justify-between font-inter text-[18px] font-[400]'>
                                            IT Training Program
                                            <img src={subMenuArrow} alt="downarrow" />
                                        </span>
                                    </button>
                                    {ITsubmenuOpen && (
                                        <ul className="w-[395px] absolute left-full top-0 mt-2 space-y-2  shadow-lg  bg-white border-b-[5px]  border-[#003C9D] font-inter text-[18px] font-[400] flex flex-col gap-2  p-2" >
                                            <li>
                                                <a href="./projectManagement" className="block">Project Management Professional Program</a>
                                            </li>
                                            <li>
                                                <a href="./iOSDevelopment" className="block">iOS Developer Program</a>
                                            </li>
                                            <li>
                                                <a href="./agileScrumMaster" className="block">Agile/ Scrum Master Program</a>
                                            </li>
                                            <li>
                                                <a href="./coreJavaDeveloperProgram" className="block">Core Java Developer Program</a>
                                            </li>
                                            <li>
                                                <a href="#" className="block">Advance Java Developer Program</a>
                                            </li>
                                        </ul>
                                    )}
                                </li>

                            </ul>
                        )}
                    </li> */}

          <li id="courses">
            <div
              className="items-center justify-between hidden w-full md:flex md:w-auto"
              id="navbar-sticky"
            >
              <div className="group inline-block">
                <button className=" hover:text-[#003C9D]  text-[20px] font-[500] flex items-center justify-start">
                  <span>Courses</span>
                  <span>
                    <svg
                      className="fill-current h-6 w-6 transform group-hover:-rotate-180 transition duration-150 ease-in-out"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </span>
                </button>
                <ul className=" w-[30%] xl:w-[22%] bg-white border-b-[5px] pt-4 border-[#003C9D] justify-between  flex flex-col gap-5 shadow-md transform scale-0 group-hover:scale-100 absolute z-10 py-2 transition duration-150 ease-in-out origin-top max-auto">
                  <li className="relative">
                    <button
                      onClick={() => {
                        setATPsubmenuOpen(false);
                        setAHPsubmenuOpen(false);
                        setITsubmenuOpen(false);
                      }}
                      className="w-full text-left block px-4 py-2 "
                    >
                      <a href="./LPN">
                        <span className=" w-full flex justify-between font-inter text-[18px] font-[400]">
                          Licensed Practical Nursing Program
                        </span>
                      </a>
                    </button>
                  </li>

                  <li className="relative">
                    <button
                      onClick={() => {
                        setAHPsubmenuOpen(!AHPsubmenuOpen);
                        setATPsubmenuOpen(false);
                        setITsubmenuOpen(false);
                      }}
                      className="w-full text-left block px-4 py-2 "
                    >
                      <span className=" w-full flex justify-between font-inter text-[18px] font-[400]">
                        Allied Healthcare Programs
                        <img src={subMenuArrow} alt="downarrow" />
                      </span>
                    </button>
                    {AHPsubmenuOpen && (
                      <ul className="w-[395px] absolute left-full top-0 mt-2 space-y-2  shadow-lg  bg-white border-b-[5px]  border-[#003C9D] font-inter text-[18px] font-[400] flex flex-col gap-2  p-4">
                        <li>
                          <a href="./mbcs" className="block">
                            Medical Billing and Coding Specialist (Hybrid)
                          </a>
                        </li>
                        <li>
                          <a href="./phlebotomy" className="block">
                            Certified Phlebotomy Technician Program (Hybrid)
                          </a>
                        </li>
                        <li>
                          <a href="./ecg" className="block">
                            Certified Electrocardiograph Technician (Hybrid)
                          </a>
                        </li>
                        <li>
                          <a href="./patientCare" className="block">
                            Certified Patient Care Technician (Hybrid)
                          </a>
                        </li>
                        <li>
                          <a href="./CCMA" className="block">
                            Certified Clinical Medical Assistant (CCMA) (Hybrid)
                          </a>
                        </li>
                        <li>
                          <a href="./CNAToCHHA" className="block">
                            Certified Nurse Aide (CNA) to Homemaker Home Health
                            aide (CHHA) conversion program (Hybrid)
                          </a>
                        </li>
                        <li>
                          <a href="./CNA" className="block">
                            Certified Nurse Assistant (CNA)
                          </a>
                        </li>
                        <li>
                          <a href="./CHHA" className="block">
                            Home-Maker Home Health Aide (CHHA) (Hybrid)
                          </a>
                        </li>
                        <li>
                          <a href="./PTP" className="block">
                            Pharmacy Technician (In-Person Program)
                          </a>
                        </li>
                      </ul>
                    )}
                  </li>

                  <li className="relative">
                    <button
                      onClick={() => {
                        setATPsubmenuOpen(!ATPsubmenuOpen);
                        setAHPsubmenuOpen(false);
                        setITsubmenuOpen(false);
                      }}
                      className="w-full text-left block px-4 py-2 "
                    >
                      <span className=" w-full flex justify-between font-inter text-[18px] font-[400]">
                        Alternate Therapy / Wellness Programs
                        <img src={subMenuArrow} alt="downarrow" />
                      </span>
                    </button>
                    {ATPsubmenuOpen && (
                      <ul className="w-[395px] absolute left-full top-0 mt-2 space-y-2  shadow-lg  bg-white border-b-[5px]  border-[#003C9D] font-inter text-[18px] font-[400] flex flex-col gap-2  p-4">
                        <li>
                          <a href="./AWC" className="block">
                            Ayurvedic Wellness Counselor Program (AWC) (Hybrid)
                          </a>
                        </li>
                        <li>
                          <a href="./AWP" className="block">
                            Ayurvedic Wellness Practitioner Program (AWP)
                            (Hybrid)
                          </a>
                        </li>
                        <li>
                          <a href="./yoga200" className="block">
                            200 Hours Yoga Teacher Training Certificate (Hybrid)
                            Program
                          </a>
                        </li>
                        <li>
                          <a href="./yoga300" className="block">
                            300 Hours Yoga Teacher Training Certificate (Hybrid)
                            Program
                          </a>
                        </li>
                        <li>
                          <a href="./massageTherapy" className="block">
                            Massage Therapy
                          </a>
                        </li>
                      </ul>
                    )}
                  </li>
                  <li className="relative">
                    <button
                      onClick={() => {
                        setITsubmenuOpen(!ITsubmenuOpen);
                        setAHPsubmenuOpen(false);
                        setATPsubmenuOpen(false);
                      }}
                      className="w-full text-left block px-4 py-2 "
                    >
                      <span className=" w-full flex justify-between font-inter text-[18px] font-[400]">
                        IT Training Program
                        <img src={subMenuArrow} alt="downarrow" />
                      </span>
                    </button>
                    {ITsubmenuOpen && (
                      <ul className="w-[395px] absolute left-full top-0 mt-2 space-y-2  shadow-lg  bg-white border-b-[5px]  border-[#003C9D] font-inter text-[18px] font-[400] flex flex-col gap-2  p-4">
                        <li>
                          <a href="./projectManagement" className="block">
                            Project Management Professional Program
                          </a>
                        </li>
                        <li>
                          <a href="./iOSDevelopment" className="block">
                            iOS Developer Program
                          </a>
                        </li>
                        <li>
                          <a href="./agileScrumMaster" className="block">
                            Agile/ Scrum Master Program
                          </a>
                        </li>
                        <li>
                          <a
                            href="./coreJavaDeveloperProgram"
                            className="block"
                          >
                            Core Java Developer Program
                          </a>
                        </li>
                        <li>
                          <a href="./advJavaDeveloperProgram" className="block">
                            Advance Java Developer Program
                          </a>
                        </li>
                      </ul>
                    )}
                  </li>
                </ul>
              </div>
            </div>
          </li>
          <li id="admission">
            <div
              className="items-center justify-between hidden w-full md:flex md:w-auto"
              id="navbar-sticky"
            >
              <div className="group inline-block">
                <button className=" md:p-0 md:hover:text-[#003C9D]  text-[20px] font-[500] flex items-center justify-start">
                  <span>Admissions</span>
                  <span>
                    <svg
                      className="fill-current h-6 w-6 transform group-hover:-rotate-180 transition duration-150 ease-in-out"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </span>
                </button>
                <ul className="bg-white border-b-[5px] pt-4 border-[#003C9D]  flex flex-col gap-5 shadow-md transform scale-0 group-hover:scale-100 absolute z-10 py-2 px-5 transition duration-150 ease-in-out origin-top max-auto">
                  <Link to={"./welcomePIE"}>
                    <li className="flex px-3  text-[18px] font-[400] text-[#262626] font-inter  ">
                      Welcome to PIE A Healthcare Education School
                    </li>
                  </Link>
                  <Link to={"./requirementAndEligibility"}>
                    <li className="flex px-3  text-[18px] font-[400] text-[#262626] font-inter  ">
                      Admission Requirements/ Eligibility
                    </li>
                  </Link>
                  <Link to={"./admissionProcess"}>
                    <li className="flex px-3  text-[18px] font-[400] text-[#262626] font-inter  ">
                      Admissions Process
                    </li>
                  </Link>
                  <Link to={"./admissionForm"}>
                    <li className="flex px-3  text-[18px] font-[400] text-[#262626] font-inter ">
                      Admissions Forms
                    </li>
                  </Link>
                  <Link to={"./requestInfo"}>
                    <li className="flex px-3  text-[18px] font-[400] text-[#262626] font-inter ">
                      Request Information
                    </li>
                  </Link>
                </ul>
              </div>
            </div>
          </li>
          <li id="studentResources">
            <div
              className="items-center justify-between hidden w-full md:flex md:w-auto"
              id="navbar-sticky"
            >
              <div className="group inline-block">
                <button className=" md:p-0 md:hover:text-[#003C9D]  text-[20px] font-[500] flex items-center justify-start">
                  <span>Student Resources </span>
                  <span>
                    <svg
                      className="fill-current h-6 w-6 transform group-hover:-rotate-180 transition duration-150 ease-in-out"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </span>
                </button>
                <ul className="w-[395px] bg-white border-b-[5px] pt-4 border-[#003C9D]  flex flex-col gap-5 shadow-md transform scale-0 group-hover:scale-100 absolute z-10 py-2 px-5 transition duration-150 ease-in-out origin-top max-auto text-[18px] font-[400] text-black font-inter">
                  <Link to={"/academicCalendar"}>
                    <li>Academic Calendar</li>
                  </Link>
                  <Link to={"/schoolCatalog"}>
                    <li>School Catalog</li>
                  </Link>
                  <li className="relative">
                    <button
                      onClick={() => {
                        setSCsubmenuOpen(!SCsubmenuOpen);
                      }}
                      className="w-full text-left block"
                    >
                      <span className=" w-full flex justify-between font-inter text-[18px] font-[400]">
                        Students Services
                        <img src={subMenuArrow} alt="downarrow" />
                      </span>
                    </button>
                    {SCsubmenuOpen && (
                      <ul className="w-[395px] absolute right-full -top-5 space-y-2  shadow-lg  bg-white border-b-[5px]  border-[#003C9D] font-inter text-[18px] font-[400] flex flex-col gap-2  p-3">
                        <Link to={"/EnrollmentVerificationLetter"}>
                          <li>Enrollment Verification Letter</li>
                        </Link>
                        <Link to={"/LeaveOfAbsence"}>
                          <li>Leave of Absence</li>
                        </Link>
                        <Link to={"/WithdrawalApplication"}>
                          <li>Withdrawal</li>
                        </Link>
                        <Link to={"/TranscriptRequest"}>
                          <li>Transcript</li>
                        </Link>
                        <Link to={"/CertificateRequest"}>
                          <li>Certificate</li>
                        </Link>
                        <Link to={"/GrievanceForm"}>
                          <li>Grievance Form</li>
                        </Link>
                      </ul>
                    )}
                  </li>
                </ul>
              </div>
            </div>
          </li>
          <Link to={"blog"}>
            {" "}
            <li className="hover:text-[#003C9D]">Blog</li>
          </Link>
          <Link to={"contactUs"}>
            {" "}
            <li className="hover:text-[#003C9D]">Contact Us</li>
          </Link>
          <Link to={"https://parameducational.com/account/login"}>
            {" "}
            <li className="hover:text-[#003C9D]">Student Login</li>
          </Link>
        </ul>
      </div>
    </div>
  );
}

export default Header;
