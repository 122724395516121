import React from "react";
import vipulAmin from "../../assets/images/VipulAmin.svg";
import { useNavigate } from "react-router-dom";

function SchoolPresident() {
  const navigation = useNavigate();
  return (
    <div className=" w-full flex flex-col items-center font-poppins mb-28">
      <div className="bg-schoolpresident-bg w-full h-[400px] md:h-[600px] justify-center items-center flex flex-col gap-8 md:gap-12 ">
        <p className="font-[800] text-[32px] md:text-[50px] xl:text-[60px] text-white w-[calc(100%_-_32px)] md:w-[70%] 2xl-[50%] text-center">
          School President’s & Director’s Message
        </p>
        <div className=" flex justify-center gap-4 md:gap-8 flex-col md:flex-row">
          <button
            className="orangeButton"
            onClick={() => navigation("../scheduleAppointment")}
          >
            Schedule a Visit
          </button>
          <button
            className="orangeButton"
            onClick={() => navigation("../requestInfo")}
          >
            Inquire Now
          </button>
          <button
            className="orangeButton"
            onClick={() => navigation("../enrollment")}
          >
            Enroll Now
          </button>
        </div>
      </div>
      <div className="w-[calc(100%_-_32px)] md:w-[calc(100%_-_64px)] xl:w-[90%] md:gap-8 mt-12 md:mt-[105px]  ">
        <div className="text-content  md:mr-5 xl:mb-8 xl:mr-8 md:w-1/2 float-left flex justify-center items-center relative mb-10 md:mb-5">
          <img src={vipulAmin} />
        </div>
        <p className="font-[600] text-[24px] md:text-[26px] xl:text-[32px] text-primary">
          A Message from the Param Institute Education President{" "}
          <span className="text-[#F37021]">Mr. Vipul Amin</span>
        </p>
        <p className="font-[400] text-[18px] md:text-[20px] text-[#5C616E] leading-[40px] mt-4">
          Dear Student,
        </p>
        <p className="font-[400] text-[18px] md:text-[20px] text-[#5C616E] leading-[40px] mt-4">
          Welcome to{" "}
          <span className="text-[#003C9D] font-[600]">
            PARAM INSTITUTE OF EDUCATION!{" "}
          </span>
          I am excited and pleased that you have decided to pursue your
          education with us. You are about to become part of a 14-year legacy in
          vocational education and training Institutes of the New Jersey. I am
          confident you will find your educational experience here to be
          challenging and rewarding. I have high expectations of you, and all
          our students, because I am confident Param Institute of Education
          (PIE) provides learning opportunities that lead to great career
          success.
        </p>
        <p className="font-[400] text-[18px] md:text-[20px] text-[#5C616E] leading-[40px] mt-4">
          I encourage you to explore the specifics of the Hybrid and In-person
          programs featured at PIE for Healthcare, Wellness, massage therapy and
          technology. PIE’s Highly qualified faculty/Instructor members lead
          these programs. Many of them are working professionals in the career
          field you are pursuing, so I encourage you to get to know them well as
          you progress through your rigorous certification of study. I am proud
          of the faculty and staff members here at our PIE and their commitment
          to student achievement. They will gladly assist you throughout your
          Certification program of study. Additionally, you are provided
          services by all the PIE’s other departments such as administration,
          Placement Assistance etc. I encourage you to connect with them and
          become acquainted with all they have to offer.
        </p>
        <div className=" flex flex-col gap-4"></div>
      </div>
      <p className="font-[400] text-[20px] text-[#5C616E] leading-[40px] w-[calc(100%_-_32px)] md:w-[calc(100%_-_64px)] xl:w-[90%] mt-4">
        I look forward to having you as part of Param Institute of Education’s
        diverse, creative, and talented family, and congratulate you for
        choosing your higher education institution wisely! Please do not
        hesitate to contact me with questions or concerns at any time. In the
        meantime, please go through our school catalog, which is true and
        correct to the best of my knowledge, will serve as a great resource for
        you, helping you design your map to an exciting and rewarding future.
        Thanks for considering Param Institute of Education for enlightening
        your path of success and converting your hopes & dreams into reality.
      </p>
      <div className="w-[calc(100%_-_32px)] md:w-[calc(100%_-_64px)] xl:w-[90%] flex gap-8">
        <div className="w-full mt-10 flex flex-col gap-4">
          <p className="font-[600] text-[24px] md:text-[26px] xl:text-[32px] text-primary">
            A Message from the School Director{" "}
            <span className="text-[#F37021]">Dr. Riteshkumar Patel </span>
          </p>
          <p className="font-[400] text-[18px] md:text-[20px] text-[#5C616E] leading-[40px]">
            Dear Students, Faculty, and Staff,
          </p>
          <p className="font-[400] text-[18px] md:text-[20px] text-[#5C616E] leading-[40px]">
            I am thrilled to address you as the School Director of our esteemed{" "}
            <span className="text-[#003C9D] font-[600]">
              Private Vocational Allied Health Care School.{" "}
            </span>{" "}
            Since our inception, we've been on a journey of growth, excellence,
            and commitment to providing top-notch education in healthcare. Our
            vision has always been to cultivate a community of skilled
            professionals dedicated to making a positive impact on the health
            and wellness of individuals.
          </p>
          <p className="font-[400] text-[18px] md:text-[20px] text-[#5C616E] leading-[40px]">
            As we move forward, our focus remains on delivering cutting-edge
            healthcare programs that align with industry needs. We take pride in
            our faculty's dedication to fostering a dynamic learning environment
            and empowering our students with the knowledge and skills needed to
            excel in their chosen healthcare fields.
          </p>
          <p className="font-[400] text-[18px] md:text-[20px] text-[#5C616E] leading-[40px]">
            The current global challenges underscore the importance of
            healthcare professionals, and I commend each one of you for your
            resilience and dedication. Our commitment to your success remains
            unwavering, and we will continue to adapt and innovate to meet the
            evolving demands of the healthcare landscape. Thank you for being a
            part of our vibrant and committed Educational community. Together,
            let's continue to inspire excellence in healthcare education and
            make a lasting impact on the well-being of our communities.
          </p>
        </div>
      </div>
    </div>
  );
}

export default SchoolPresident;
