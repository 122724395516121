import React from "react";
import coursePlayIcon from "../../../../assets/images/coursePlayIcon.svg";
import ccmaCourse from "../../../../assets/images/ccma-course.svg";

const Overview = ({ setTab }) => {
  return (
    <>
      <div className="font-poppins text-[18px] md:text-[24px]text-[20px] leading-[45px] text-[#5C616E]">
        <div className="text-content md:ml-[20px] md:mb-[20px] xl:ml-[99px] xl:mb-[40px] xl:mt-[85px]  w-1/2 float-right hidden md:flex justify-center items-center relative">
          <img
            src={ccmaCourse}
            alt="Certified Clinical Medical Assistant Program"
          />
          <img
            className="absolute"
            src={coursePlayIcon}
            alt="course play icon"
          />
        </div>
        <p className="font-[500] text-[20px] md:text-[24px] xl:text-[32px] leading-[36px] xl:leading-[48px] text-[#003C9D]">
          Certified Clinical Medical Assistant  (CCMA-Hybrid)
        </p>
        <div className="flex md:hidden justify-center items-center mt-10">
          <img
            src={ccmaCourse}
            alt="Certified Clinical Medical Assistant Program"
          />
          <img
            className="absolute"
            src={coursePlayIcon}
            alt="course play icon"
          />
        </div>
        <p className="text-[18px] md:text-[24px]text-[20px] leading-[45px] text-[#5C616E] mt-10">
          Certified Clinical Medical Assistant (CCMA) Program trains students to
          assist physicians by performing functions related to the clinical
          responsibilities of a medical office. Instruction includes preparing
          patients for examination and treatment, routine laboratory procedures,
          diagnostic testing, technical aspects of phlebotomy, and the EKG and
          the cardiac life cycle. Students review important topics including
          phlebotomy, pharmacology, the proper use and administration of
          medications, taking and documenting vital signs, cardiology including
          proper EKG lead placements, a professional workplace behavior, ethics
          and the legal aspects of healthcare. Program includes classroom
          lecture and hands on labs and a clinical externship opportunity at a
          local healthcare provider. Students who complete this course sit or
          eligible  for the{" "}
          <a
            className="underline text-[#0755b9]"
            href="https://www.nhanow.com/"
          >
            National Health Career Association (NHA)
          </a>{" "}
          and{" "}
          <a
            className="underline text-[#0755b9]"
            href="https://www.amcaexams.com/amca-certification-board/"
          >
            AMCA.
          </a>{" "}
          Certified Clinical Medical Assistant (CCMA) exam
        </p>
        <p className="text-[18px] md:text-[24px]text-[20px] leading-[45px] text-[#5C616E] mt-4">
          The Certified  Clinical Medical Assistant   Program at Param Institute
          of Education is designed for{" "}
          <a
            className="underline text-[#0755b9]"
            href="#"
            onClick={(event) => {
              event.preventDefault();
              setTab("faq");
            }}
          >
            Hybrid
          </a>{" "}
          (where you can choose either an Online or In-person theory class) 500
          hours of Comprehensive training including classroom instructions and
          clinical externship training to prepare the student to assist medical
          providers by performing administrative duties ,basic clinical duties
          in an office and/or medical center and appear in National
          certification exams such as NHA and AMCA.
        </p>
        <p className="text-[18px] md:text-[24px]text-[20px] leading-[45px] text-[#5C616E] mt-4">
          This health care professional performs administrative and clinical
          duties and may manage emergency situations, facilities, and/or
          personnel.  Medical assistants work in physicians’ offices, clinics,
          hospitals and other medical facilities under the supervision of a
          physician.
        </p>
      </div>
      <div className="mt-[40px]">
        <p className="font-[500] text-[20px] md:text-[24px]text-[24px] xl:text-[32px] leading-[36px] xl:leading-[48px] text-[#003C9D]">
          Why Choose Certified Clinical Medical Assistant?
        </p>
        <div className="flex flex-col gap-6 text-[18px] md:text-[24px]text-[20px] leading-[40px] mt-5 md:mt-10 text-[#5C616E]">
          <p>
            A certified clinical medical assistant (CCMA) is a medical assistant
            who has gone further in their career by qualifying for
            certification. Medical assistants fulfill both clinical and
            administrative tasks at hospitals, physicians' offices, and other
            medical settings. In comparison, CCMA responsibilities focus more on
            the clinical side of these tasks. CCMA includes key skills
            Analytical, interpersonal and technical skill.
          </p>

          <p>
            No Additional training needed (postemployment) to attain competency
            in the skills needed in this occupation.
          </p>
          <p>
            Majority Jobs are NOT require Work experience that is commonly
            considered necessary by employers, or is a commonly accepted
            substitute for more formal types of training or education.
          </p>
          <p>
            The projected percent change in employment from 2021 to 2031. The
            average growth rate for all occupations is 16 percent much faster
            than average.
          </p>
          <p>
            The projected numeric change in employment from 2021 to 2031 is
            117,800.
          </p>
        </div>
      </div>
      <div className="flex flex-col gap-10 md:gap-10 mt-10 md:mt-[60px]">
        <p className="font-[500] text-[20px] md:text-[24px] xl:text-[32px] leading-[36px] xl:leading-[48px] text-[#003C9D]">
          Job Outlook for Certified Clinical Medical Assistant
        </p>
        <ul className=" list-disc font-[400] text-[18px] md:text-[20px] text-[#5C616E] leading-[45px] ml-8 flex flex-col gap-4">
          <li>
            Employment of medical assistants is projected to grow 18 percent
            from 2021 to 2031, much faster than the average for all occupations.
          </li>
          <li>
            About 123,000 openings for medical assistants are projected each
            year, on average, over the decade. Many of those openings are
            expected to result from the need to replace workers who transfer to
            different occupations or exit the labor force, such as to retire.
          </li>
        </ul>
      </div>
      <div className="mt-10 md:mt-[60px]">
        <p className="font-[500] text-[20px] md:text-[24px]  xl:text-[32px] leading-[36px] xl:leading-[48px] text-[#003C9D]">
          CCMA Program Outline
        </p>
        <div className="p-6 border-[1px] rounded-[5px] border-[#D9D9D9] mt-5 md:mt-10 shadow-lg">
          <ul className="list-disc font-[400] text-[18px] md:text-[20px] text-[#1B2336] leading-[35px] flex flex-col gap-[10px] ml-6">
            <li>
              Total Hours:{" "}
              <span className="font-[500] text-[#003C9D]">500</span>
            </li>
            <li>
              Hybrid Theory Study:{" "}
              <span className="font-[500] text-[#003C9D]">270 hours</span>
            </li>
            <li>
              Practical/Clinical Study at School:{" "}
              <span className="font-[500] text-[#003C9D]">80 Hours</span>
            </li>
            <li>
              Clinical Externship at Healthcare Site:{" "}
              <span className="font-[500] text-[#003C9D]">150 Hours</span>
            </li>
          </ul>
        </div>
        <div className="p-6 border-[1px] rounded-[5px] border-[#D9D9D9] mt-6 shadow-lg">
          <ul className="font-[400] text-[18px] md:text-[20px] text-[#1B2336] leading-[30px] flex flex-col gap-[10px]">
            <li>Module 1: MEDICAL TERMINOLOGY (ANATOMY AND PHISIOLOGY) </li>
            <li>
              Module 2: FUNDAMENTALS OF MEDICAL ASSISTING PATIENT CARE SKILLS
            </li>
            <li>Module 3: CLINICAL AND SURGICAL SKILLS</li>
            <li>Module 4: PHARMACOLOGY</li>
            <li>Module 5: MEDICAL BILLING AND CLAIM</li>
            <li>Module 6: MEDICAL OFFICE AND ADMINISTRATIVE SKILLS</li>
            <li>Module 7: PHLEBOTOMY</li>
            <li>Module 8: ELECTROCARDIOGRAPHY</li>
            <li>Module 9: CLINICAL EXTERNSHIP</li>
          </ul>
        </div>

        <div className="w-full flex gap-10 flex-col">
          <div className="w-full  mt-[20px]">
            <p className="font-[500] text-[20px] md:text-[24px] xl:text-[32px] text-[#003C9D] pb-[40px] pt-10">
              What you get from us?
            </p>
            <div className="flex gap-8 xl:flex-row flex-col">
              <ul className=" w-full list-disc font-[400] text-[18px] md:text-[20px] text-[#5C616E] flex  list-inside gap-4 flex-col">
                <li>Online lectures and material access</li>
                <li>Live instructor for your queries</li>
                <li>Administrative support</li>
                <li>Onsite clinical training</li>
                <li>Field/Internship/Externship training</li>
                <li>CCMA certificate from school</li>

                <li>
                  NHA or AMCA Certified Phlebotomy Technician (CCMA) exam
                  preparation material
                </li>
                <li>
                  NHA or AMCA certification once you appear and pass the NHA or
                  AMCA exams
                </li>

                <li>Placement Assistant</li>
                <li>Job opportunities</li>
                <li>Career pathways opportunities and guidelines</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Overview;
