import React from "react";
import coursePlayIcon from "../../../../assets/images/coursePlayIcon.svg";
import awpCourse from "../../../../assets/images/awpCourse.svg";

const Overview = () => {
  return (
    <>
      <div className="inline-block">
        <div className="text-content md:ml-[20px] md:mb-[20px] xl:ml-[99px] xl:mb-[40px] 2xl:mb-0 xl:mt-[85px]  w-1/2 float-right hidden md:flex justify-center items-center relative">
          <img src={awpCourse} alt="Ayurvedic Wellness Practitioner Program" />
          <img
            className="absolute"
            src={coursePlayIcon}
            alt="course play icon"
          />
        </div>
        <p className="font-[500] text-[20px] md:text-[24px] xl:text-[32px] leading-[36px] xl:leading-[48px] text-[#003C9D]">
          Ayurvedic Wellness Practitioner Program (Hybrid)
        </p>
        <div className="flex md:hidden justify-center items-center mt-5 md:mt-10">
          <img src={awpCourse} alt="Ayurvedic Wellness Practitioner Program" />
          <img
            className="absolute"
            src={coursePlayIcon}
            alt="course play icon"
          />
        </div>
        <p className="text-[18px] md:text-[20px] leading-[45px] text-[#5C616E] mt-10">
          The Ayurvedic Wellness Practitioner (AWP) Program seems like a
          comprehensive course focused on wellness management based on the
          principles of Ayurveda. It is structured to provide students with a
          deep understanding of illness processes and techniques for managing
          them at various stages. The inclusion of a 200-hour clinical
          internship ensures practical exposure and hands-on experience, which
          is invaluable in fields like Ayurveda where experiential learning
          plays a crucial role. Upon completion, receiving the Ayurvedic
          Wellness Practitioner Certificate acknowledges the mastery of
          Ayurvedic science and techniques, marking a significant milestone in
          one's journey toward becoming a proficient practitioner.
        </p>
        <p className="text-[18px] md:text-[20px] leading-[45px] text-[#5C616E] mt-4">
          The   Ayurvedic Wellness Practitioner Program at Param Institute of
          Education is designed for{" "}
          <a className="underline text-[#0755b9]" href="#">
            Hybrid
          </a>{" "}
          (where you can choose either an Online or In-person theory class) 1000
          hours of Comprehensive training including classroom instructions and
          skill training to prepare the student for assess health
          conditions, create personalized wellness plan and ancient healing
          tradition of Ayurveda.
        </p>
      </div>
      <div className="mt-10 md:mt-[60px]">
        <p className="font-[500] text-[20px] md:text-[24px] xl:text-[32px] leading-[36px] xl:leading-[48px] text-[#003C9D]">
        What are the Key Reasons for Pursuing an Ayurvedic Wellness Practitioner Program?
        </p>
        <p className="text-[18px] md:text-[20px] leading-[40px] mt-5 md:mt-10 text-[#5C616E]">
          Pursuing an Ayurvedic Wellness Practitioner program offers several key
          benefits and reasons:
        </p>
        <ul className="mt-6 text-[#F37021] font-[500] text-[20px] md:text-[24px] leading-[30px] flex flex-col gap-6">
          <li className="flex flex-col relative before:content-[''] before:absolute before:left-0 before:w-[30px] pl-[54px] before:h-[30px] before:bg-checkmark-bg before:bg-no-repeat">
            Holistic Approach to Health
            <span className="mt-4 text-[#5C616E] text-[18px] md:text-[20px] leading-10 font-[400]">
              Ayurveda emphasizes a holistic approach to health, focusing on the
              balance of mind, body, and spirit. By studying Ayurveda,
              individuals gain a comprehensive understanding of health and
              wellness, addressing both the root cause of illness and promoting
              overall well-being.
            </span>
          </li>
          <li className="flex flex-col relative before:content-[''] before:absolute before:left-0 before:w-[30px] pl-[54px] before:h-[30px] before:bg-checkmark-bg before:bg-no-repeat">
            Personalized Healthcare
            <span className="mt-4 text-[#5C616E] text-[18px] md:text-[20px] leading-10 font-[400]">
              Ayurveda recognizes that everyone is unique, with their own
              constitution and health needs. Practitioners learn how to tailor
              treatments and recommendations according to the specific needs of
              each person, offering personalized healthcare solutions.
            </span>
          </li>
          <li className="flex flex-col relative before:content-[''] before:absolute before:left-0 before:w-[30px] pl-[54px] before:h-[30px] before:bg-checkmark-bg before:bg-no-repeat">
            Natural and Sustainable Practices
            <span className="mt-4 text-[#5C616E] text-[18px] md:text-[20px] leading-10 font-[400]">
              Ayurveda utilizes natural remedies, including herbs, diet,
              lifestyle modifications, and therapies, to promote health and help
              to address several conditions. This approach often aligns with
              principles of sustainability and environmental consciousness.
            </span>
          </li>
          <li className="flex flex-col relative before:content-[''] before:absolute before:left-0 before:w-[30px] pl-[54px] before:h-[30px] before:bg-checkmark-bg before:bg-no-repeat">
            Complementary Medicine
            <span className="mt-4 text-[#5C616E] text-[18px] md:text-[20px] leading-10 font-[400]">
              Ayurveda can complement modern medical practices, offering
              alternative or adjunctive treatments for various health
              conditions. Integrating Ayurvedic principles with conventional
              medicine can provide a more comprehensive approach to patient
              care.
            </span>
          </li>
          <li className="flex flex-col relative before:content-[''] before:absolute before:left-0 before:w-[30px] pl-[54px] before:h-[30px] before:bg-checkmark-bg before:bg-no-repeat">
            Career Opportunities
            <span className="mt-4 text-[#5C616E] text-[18px] md:text-[20px] leading-10 font-[400]">
              Completing an Ayurvedic Wellness Practitioner program can open
              diverse career opportunities. Graduates may work in wellness
              centers, spas, hospitals, clinics, or establish their own private
              practice. Additionally, there may be opportunities for research,
              teaching, or consulting roles.
            </span>
          </li>

          <li className="flex flex-col relative before:content-[''] before:absolute before:left-0 before:w-[30px] pl-[54px] before:h-[30px] before:bg-checkmark-bg before:bg-no-repeat">
            Cultural and Philosophical Enrichment
            <span className="mt-4 text-[#5C616E] text-[18px] md:text-[20px] leading-10 font-[400]">
              Studying Ayurveda provides insight into ancient Indian culture,
              philosophy, and spirituality. It offers a deeper understanding of
              the interconnectedness between humans, nature, and the universe,
              enriching both personal and professional perspectives.
            </span>
          </li>
          <li className="flex flex-col relative before:content-[''] before:absolute before:left-0 before:w-[30px] pl-[54px] before:h-[30px] before:bg-checkmark-bg before:bg-no-repeat">
            Personal Growth
            <span className="mt-4 text-[#5C616E] text-[18px] md:text-[20px] leading-10 font-[400] flex flex-col gap-[10px]">
              <p>
                Engaging with Ayurvedic teachings can facilitate personal growth
                and self-awareness. Practitioners often experience
                transformational changes in their own health and well-being,
                leading to a more balanced and fulfilling life.
              </p>
              <p>
                Overall, pursuing an Ayurvedic Wellness Practitioner program can
                be a rewarding journey for those interested in natural health,
                holistic medicine, and the ancient wisdom of Ayurveda.
              </p>
            </span>
          </li>
        </ul>
      </div>
      <div className="mt-10 md:mt-[60px]">
        <p className="font-[500] text-[20px] md:text-[24px] xl:text-[32px] leading-[36px] xl:leading-[48px] text-[#003C9D]">
          Ayurveda Wellness Counselor (AWP) Program Outline
        </p>
        <div className="p-6 border-[1px] rounded-[5px] border-[#D9D9D9] mt-5 md:mt-10 shadow-lg">
          <ul className="list-disc font-[400] text-[18px] md:text-[20px] text-[#1B2336] leading-[35px] flex flex-col gap-[10px] ml-6">
            <li>
              Program Length:{" "}
              <span className="font-[500] text-[#003C9D]">52 Weeks</span>
            </li>
            <li>
              Total Hours of Program:{" "}
              <span className="font-[500] text-[#003C9D]">1000 hours</span>
            </li>
            <li>
              Prerequisite:{" "}
              <span className="font-[500] text-[#003C9D]">
              The minimum criteria for admission to the AWP program include possessing a high school diploma and a foundational understanding of Basic
              Anatomy and Physiology.
              </span>
            </li>
            <li>
              Academy Credential Award:{" "}
              <span className="font-[500] text-[#003C9D]">Certificate</span>
            </li>
          </ul>
        </div>
        <div className="p-6 border-[1px] rounded-[5px] border-[#D9D9D9] mt-6 shadow-lg">
          <ul className="font-[400] text-[18px] md:text-[20px] text-[#1B2336] leading-[30px] flex flex-col gap-[10px]">
            <li>Module 1: Advanced Anatomy, Physiology & Basic Principle</li>
            <li>Module 2: Pathophysiology &Ayurvedic Diagnostic Methods</li>
            <li>Module 3: Advanced Herbology & Ayurvedic Pharmaceutics</li>
            <li>Module 4: Advanced Bodywork, Detoxification & Rasayana</li>
            <li>
            Module 5: Kayachikitsa 1: Management of Digestive System & Metabolic Disorders
            </li>
            <li>
            Module 6: Kayachikitsa 2: Management of Nervous System & Musculoskeletal Disorders
            </li>
            <li>
            Module 7: Kayachikitsa 3: Management of Respiratory System & Cardiovascular System Disorders
            </li>
            <li>
            Module 8: Kayachikitsa 4: Management of Skin, Special Senses, & Mental Disorders
            </li>
            <li>
            Module 9: Kayachikitsa 5: Management of Reproductive & Excretory System Disorders
            </li>
            <li>Module 10: Disease Management (Kaya Chikitsa)</li>
            <li>Clinical in-class Internship</li>
            <li>Weekly Virtual Classroom Session</li>
            <li>E-learning & Video Instruction</li>
            <li>Special Project</li>
            <li>Home Study</li>
            <li>Clinical Internship</li>
          </ul>
        </div>
        <div className="mt-10 md:mt-[60px]">
          <div className="">
            <p className="font-[500] text-[20px] md:text-[32px] leading-[36px] text-[#003C9D]">
            What you get from us?
            </p>
            <ul className="list-disc font-[400] text-[18px] md:text-[20px] text-[#5C616E] leading-[30px] mt-10 flex flex-col gap-4 ml-6">
              <li>Lectures and material access</li>
              <li>Live instructor for your queries</li>
              <li>Administrative support</li>
              <li>Onsite clinical training</li>
              <li>Career pathways opportunities and guidelines</li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Overview;
