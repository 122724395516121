import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../assets/styles/Slider.css";
import course1 from "../../assets/images/course1.svg";
import course2 from "../../assets/images/course2.svg";
import course3 from "../../assets/images/course3.svg";
import course4 from "../../assets/images/course4.svg";
import course5 from "../../assets/images/course5.svg";
import course6 from "../../assets/images/course6.svg";
import course7 from "../../assets/images/course7.svg";
import course8 from "../../assets/images/course8.svg";
import course9 from "../../assets/images/course9.svg";
import course10 from "../../assets/images/course10.svg";
import course11 from "../../assets/images/course11.svg";
import course12 from "../../assets/images/course12.svg";
import LPN from "../../assets/images/LPN-course.svg";
import { useNavigate } from "react-router-dom";

function Section2() {
  const navigate = useNavigate();
  const data = [
    {
      img: LPN,
      title: "Licensed Practical Nursing Program",
      link: "../LPN",
      content:
        "PARAM INSTITUTE OF EDUCATION offers a Licensed Practical Nursing (LPN) program in the state of New Jersey. The program consisting of 1,554 hours of theory and clinical practicum.",
    },
    {
      img: course9,
      title: "Certified Clinical Medical Assistant (Hybrid)",
      link: "../CCMA",
      content:
        "Certified Clinical Medical Assistant (CCMA) Program trains students to assist physicians by performing functions related to the clinical responsibilities of a medical office.",
    },
    {
      img: course10,
      title: "Medical Billing And Coding Specialist (Hybrid)",
      link: "../mbcs",
      content:
        "Medical billing and coding is a rising star in the healthcare field today. This course will prepare you to start a career in this high-demand occupation and earn the professional certification.",
    },
    {
      img: course5,
      title: "Home-Maker Home Health Aide (CHHA) (Hybrid)",
      link: "../CHHA",
      content:
        "The population of the elderly in our country is steadily growing and many people now prefer to receive health care at home in a familiar setting for convenience and to reduce cost.",
    },
    {
      img: course6,
      title: "Certified Phlebotomy Technician Program (Hybrid)",
      link: "../phlebotomy",
      content:
        "Phlebotomists are health care professionals who work in hospitals, blood banks and other diagnostic health care facilities, where they draw blood from patients.",
    },
    {
      img: course4,
      title: "Certified  Patient Care Technician (Hybrid)",
      link: "../patientCare",
      content:
        "The Patient Care Technician Program at Param Institute of Education (PIE) is 300 hours (20 Weeks) comprehensive program includes classroom instructions and clinical externship training.",
    },
    {
      img: course12,
      title: "Massage Therapy",
      link: "../massageTherapy",
      content:
        "The Massage Therapy Program caters to students aspiring to enter the world of massage therapy. Participants receive comprehensive education on their responsibilities and the role of a massage therapist.",
    },
    {
      img: course2,
      title: "Certified Electrocardiogram Technician (Hybrid)",
      link: "../ecg",
      content:
        "This technique will help them to diagnose primary heart conditions. The students will be able to learn basic anatomy and physiology of the heart and common pathology of heart conditions.",
    },
    {
      img: course5,
      title: "CNA to Certified Home-Maker Home Health Aide Program (Hybrid)",
      link: "../CNAToCHHA",
      content:
        "This program is for Certified Nurse Assistants who are planning to do Home Care patients along with Nursing home patients. They will get education regarding their duties and role in transition from facilities to homes. ",
    },
    {
      img: course3,
      title: "Certified Nurse Assistant",
      link: "../CNA",
      content:
        "This program teaches skills such as lab training and practical Nursing homes instruction as well as classroom teaching. Most skills will be taught through lectures, role playing, training videos",
    },
    {
      img: course1,
      title: "Pharmacy Technician Program",
      link: "../PTP",
      content:
        "The curriculum is designed in accordance with Pharmacy Technician Certification Board (PTCB) guidelines and prepares individual under the supervision of pharmacist",
    },
    {
      img: course8,
      title: "Ayurvedic Wellness Counselor Program (Hybrid)",
      link: "../AWC",
      content:
        " These tools encompass an integrated approach, including comprehensive nutritional knowledge (Ahara), daily habits and routines to optimize well-being (Vihar).",
    },
    {
      img: course7,
      title: "Ayurvedic Wellness Practitioner Program (Hybrid)",
      link: "../AWP",
      content:
        "The Ayurvedic Wellness Practitioner (AWP) Program seems like a comprehensive course focused on wellness management based on the principles of Ayurveda.",
    },
    {
      img: course11,
      title: "200 Hours Yoga Teacher Training Certificate (Hybrid) Program",
      link: "../yoga200",
      content:
        "The program comprises three segments: Seg A: Deepening Your Asana, Seg B: Sacred Life, and Seg C: Practical Applications. It is designed to harness students' natural strengths and desires.",
    },
    {
      img: course11,
      title: "300 Hours Yoga Teacher Training Certificate (Hybrid) Program",
      link: "../yoga300",
      content:
        "The 300-Hour Advanced Teacher Certification program is tailored for yoga instructors seeking to elevate their professional journey. Designed for those already certified at the 200-hour level.",
    },
  ];

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    speed: 10,
    cssEase: "ease-in",
    appendDots: (dots) => <ul>{dots}</ul>,
    customPaging: (i) => (
      <div className="ft-slick__dots--custom">
        <div className="loading" />
      </div>
    ),
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 2.1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="w-full font-poppins flex items-center  pb-10 xl:pb-16 flex-col gap-10">
      <p className="font-[600] text-[24px] md:text-[35px] xl:text-[48px] text-primary">
        Browse Our Top Courses
      </p>
      <div className="w-[calc(100%_-_32px)] md:w-[calc(100%_-_64px)] xl:w-[90%] flex gap-5">
        <Slider {...settings} className="w-full shadow-xl md:shadow-none">
          {data.map((data) => (
            <div
              id="Card"
              className="w-[415px] h-[419px] bg-white shadow-lg flex flex-col "
            >
              <div className="max-h-[250px] overflow-hidden">
                <img src={data.img} className="w-full object-cover bg-center" />
              </div>

              <div
                className="flex flex-col w-[98%] items-center mt-3 group cursor-pointer"
                onClick={() => navigate(data.link)}
              >
                <p className="font-[600] text-[18px] text-primary group-hover:text-[#F37021] text-center">
                  {data.title}
                </p>
                <p className="font-[400] text-[14px] text-[#5C616E] text-center mt-4 w-[95%] group-hover:text-[#F37021]">
                  {data.content}
                </p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}

export default Section2;
