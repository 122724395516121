//--------------------------------------------------------- All Imports Starts -------------------------------------------------------------------------------//
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useState, useEffect, useRef } from "react";
import "react-calendar/dist/Calendar.css";
import "react-date-picker/dist/DatePicker.css";
import * as Yup from "yup";
import calendarIcon from "../../assets/images/calendarIcon.svg";
import globeIcon from "../../assets/images/globeIcon.svg";
import headerLogo from "../../assets/images/headerLogo.svg";
import locationIcon from "../../assets/images/locationIcon.svg";
import timeIcon from "../../assets/images/timeIcon.svg";
import TagsInput from "react-tagsinput";
import moment from "moment/moment";
import "react-tagsinput/react-tagsinput.css";
import {
  addAppointment,
  addAppointmentSuccess,
} from "../../redux/actions/appointmentBooking.action";
import { getEducationLevel } from "../../redux/actions/educationLevel.action";
import { useDispatch, useSelector } from "react-redux";
import { getCourses } from "../../redux/actions/common.action";
import { imageGenerator } from "../../utils/imageGenerator";
import { useNavigate } from "react-router-dom";
import useLoader from "../../hooks/useLoader";
//--------------------------------------------------------- All Imports Ends -------------------------------------------------------------------------------//

const AppointmentForm = ({ setFormStep }) => {
  //------------------------------------------------ States, Variables Initialiation Starts ----------------------------------------------------------------//
  const [showGuestEmail, setShowGuestEmail] = useState(false);
  const [guestEmailArray, setGuestEmailArray] = useState([]);
  const formRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let date = useSelector((state) => state.appointmentReducer.selected_date);
  let educationLevelData = useSelector(
    (state) => state.educationLevelReducer.educationLevelData
  );
  let coursesData = useSelector((state) => state.CommonReducer.getcourses_data);
  let timeFormat = useSelector(
    (state) => state.appointmentReducer.selected_format
  );
  let selectedTime = useSelector(
    (state) => state.appointmentReducer.selected_time
  );
  let selectedSlotId = useSelector(
    (state) => state.appointmentReducer.selected_time_id
  );
  let appointmentSuccessData = useSelector(
    (state) => state.appointmentReducer.appointmentData
  );
  const { isLoading, setLoader, Loader } = useLoader();

  useEffect(() => {
  
    if (appointmentSuccessData && appointmentSuccessData.status == 201) {
      setLoader(false);
      const message = appointmentSuccessData.data.message;
      dispatch(addAppointmentSuccess(null));
      navigate("../thankYou", {
        state: { message: message },
      });
    }
  }, [appointmentSuccessData]);
  //------------------------------------------------ States, Variables Initialiation Ends ----------------------------------------------------------------//

  //-------------------------------------------- Form Validation Schema Initialization Starts ---------------------------------------------------------------//
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    phoneNumber: Yup.string().required("Phone Number is required"),
    education: Yup.string().required("Please select one option"),
    programOfInterest: Yup.array().required("Please select one option"),
    levelOfEducation: Yup.string().optional(),
  });
  //-------------------------------------------- Form Validation Schema Initialization Ends ---------------------------------------------------------------//

  //-------------------------------------------------------------- Functions to Get Formated Time Starts --------------------------------------------------//
  const formattedDateWithYear = moment(date, "YYYY-MM-DD").format(
    "dddd, MMMM D, YYYY"
  );
  //-------------------------------------------------------------- Functions to Get Formated Time Ends --------------------------------------------------//

  //------------------------------------------------------- Function to Handle Form Submission Starts ---------------------------------------------------//
  const handleSubmit = async (values, { resetForm }) => {
    const pdf = await imageGenerator(formRef.current);
    const payloadData = {
      firstname: values.firstName,
      lastname: values.lastName,
      email: values.email,
      phonenumber: values.phoneNumber,
      education_level_id: values.education,
      appointment_date: moment(date).format("YYYY-MM-DD"),
      course_id: values.programOfInterest.toString(),
      textMessageNumber: values.message,
      guestEmails: guestEmailArray.toString(),
      slot_id: selectedSlotId,
      pdfFile: pdf,
      otherEducation: values.levelOfEducationOther
        ? values.levelOfEducationOther
        : null,
    };
    dispatch(addAppointment(payloadData));
    setLoader(true);
  };
  //------------------------------------------------------- Function to Handle Form Submission Ends ---------------------------------------------------//

  // ---------------------------------------- useEffect to fetch education level and courses data starts -------------------------------------------//
  useEffect(() => {
    dispatch(getEducationLevel());
    dispatch(getCourses());
  }, []);
  // ---------------------------------------- useEffect to fetch education level and courses data ends -------------------------------------------//

  return (
    <div
      className="flex items-center justify-center flex-col 991:flex-col 1400:flex-row w-full"
      ref={formRef}
    >
      {isLoading ? (
        <Loader type={"component"} />
      ) : (
        <>
          <div className="shadow-xl rounded-[10px] border border-[#D9D9D9] md:w-[calc(100%_-_64px)] xl:w-[89%] flex">
            <div className="w-full 1400:w-[32%] flex flex-col gap-12 1400:border-r border-[#D9D9D9] py-10 px-8 pr-[64px]">
              <div className="">
                <img src={headerLogo} />
              </div>
              <div className="text-[18px] leading-[27px]">
                <p className="text-[24px] font-[600]">
                  Schedule An Appointment
                </p>
                <div className="mt-4 flex flex-col gap-8 text-[#989898]">
                  <span className="flex items-center gap-4 leading-[27px]">
                    <img src={timeIcon} />
                    <p>15 min</p>
                  </span>

                  <span className="flex items-center gap-4 leading-[27px]">
                    <img src={locationIcon} />
                    <p>
                      200 Middlesex Essex Turnpike, Suite #105, Iselin, NJ,
                      08830.
                    </p>
                  </span>

                  <span className="flex items-center gap-4 leading-[27px]">
                    <img src={calendarIcon} />
                    <p>{`${
                      timeFormat == "24hrs"
                        ? `${moment(selectedTime, "hh:mma").format(
                            "HH:mm"
                          )} to ${moment(selectedTime, "hh:mma")
                            .add(15, "minutes")
                            .format("HH:mm")}`
                        : `${moment(selectedTime, "hh:mma").format(
                            "hh:mma"
                          )} to ${moment(selectedTime, "HH:mma")
                            .add(15, "minutes")
                            .format("hh:mma")}`
                    }, ${formattedDateWithYear}`}</p>
                  </span>

                  <span className="flex items-center gap-4 leading-[27px]">
                    <img src={globeIcon} />
                    <p>Eastern Time - US & Canada</p>
                  </span>
                </div>
              </div>
            </div>

            <div className="hidden flex-col w-[68%] pt-10 pb-[54px] px-[64px] 1400:flex">
              <div className="flex gap-10 w-full">
                <div className="flex flex-col gap-10 w-full">
                  <p className="text-[20px] leading-[30px] font-[500]">
                    Enter Details
                  </p>
                  <div>
                    <Formik
                      initialValues={{
                        firstName: "",
                        lastName: "",
                        email: "",
                        phoneNumber: "",
                        education: "",
                        programOfInterest: "",
                        message: "",
                        levelOfEducationOther: "",
                      }}
                      validationSchema={validationSchema}
                      onSubmit={handleSubmit}
                    >
                      {({ values, errors, touched }) => (
                        <Form className="w-full gap-6 flex flex-col">
                          <div className="grid grid-cols-2 gap-6">
                            <div className="w-full flex flex-col gap-2">
                              <label className="font-[400] text-[16px] md:text-[18px] text-primary">
                                First Name{" "}
                                <span className="text-[#BB2727]">*</span>
                              </label>
                              <Field
                                type="text"
                                name="firstName"
                                className={`h-[50px] border border-[#989898] rounded-[5px] p-3 ${
                                  errors.firstName && touched.firstName
                                    ? "border-red-500"
                                    : ""
                                }`}
                              />
                              <ErrorMessage
                                name="firstName"
                                component="div"
                                className="text-red-500"
                              />
                            </div>
                            <div className="w-full flex flex-col gap-2">
                              <label className="font-[400] text-[16px] md:text-[18px] text-[#5D6169]">
                                Last Name{" "}
                                <span className="text-[#BB2727]">*</span>
                              </label>
                              <Field
                                type="text"
                                name="lastName"
                                className={`h-[50px] border border-[#989898] rounded-[5px] p-3 ${
                                  errors.lastName && touched.lastName
                                    ? "border-red-500"
                                    : ""
                                }`}
                              />
                              <ErrorMessage
                                name="lastName"
                                component="div"
                                className="text-red-500"
                              />
                            </div>
                            <div className="w-full flex flex-col gap-2">
                              <label className="font-[400] text-[16px] md:text-[18px] text-[#5D6169]">
                                Email <span className="text-[#BB2727]">*</span>
                              </label>
                              <Field
                                type="text"
                                name="email"
                                className={`h-[50px] border border-[#989898] rounded-[5px] p-3 ${
                                  errors.email && touched.email
                                    ? "border-red-500"
                                    : ""
                                }`}
                              />
                              <ErrorMessage
                                name="email"
                                component="div"
                                className="text-red-500"
                              />
                            </div>
                            <div className="w-full flex flex-col gap-2">
                              <label className="font-[400] text-[16px] md:text-[18px] text-[#5D6169">
                                Phone Number{" "}
                                <span className="text-[#BB2727]">*</span>
                              </label>
                              <Field
                                type="text"
                                name="phoneNumber"
                                className={`h-[50px] border border-[#989898] rounded-[5px] p-3 ${
                                  errors.phoneNumber && touched.phoneNumber
                                    ? "border-red-500"
                                    : ""
                                }`}
                              />
                              <ErrorMessage
                                name="phoneNumber"
                                component="div"
                                className="text-red-500"
                              />
                            </div>
                          </div>
                          {!showGuestEmail && (
                            <div className="flex gap-3 w-[90%]">
                              <button
                                type="button"
                                className="orangeOutlineButton"
                                onClick={() => setShowGuestEmail(true)}
                              >
                                Add Guests Email
                              </button>
                            </div>
                          )}
                          {showGuestEmail && (
                            <div className="w-full flex flex-col gap-2">
                              <label className="font-[400] text-[16px] md:text-[18px] text-[#5D6169">
                                Guest Email(s)
                              </label>
                              {/* <Field
                      as="textarea"
                      name="guestEmail"
                      rows="5"
                      className={`border border-[#989898] rounded-[5px] p-3 resize-none`}
                    /> */}
                              <Field
                                name="guestEmail"
                                className="border border-[#989898] rounded-[5px] p-3 resize-none"
                                render={({ field }) => (
                                  <TagsInput
                                    {...field}
                                    value={guestEmailArray}
                                    onChange={(tags) =>
                                      setGuestEmailArray(tags)
                                    }
                                    inputProps={{ placeholder: "" }}
                                  />
                                )}
                              />
                            </div>
                          )}

                          <div className="flex flex-col gap-4">
                            <label className="font-[400] text-[16px] md:text-[18px] text-[#5D6169]">
                              Your Education{" "}
                              <span className="text-[#BB2727]">*</span>
                            </label>
                            <div
                              role="group"
                              aria-labelledby="my-radio-group"
                              className="flex gap-[25px] xl:gap-8 text-[16px] text-[#1B2336] flex-wrap"
                            >
                              {educationLevelData?.data?.map((item) => (
                                <>
                                  <label
                                    className={`flex gap-2 items-center custom-radio-container ${
                                      item.id == "3" &&
                                      values.education.includes("3") &&
                                      "hidden"
                                    }`}
                                  >
                                    <Field
                                      type="radio"
                                      name="education"
                                      value={`${item.id}`}
                                      className="min-h-6 min-w-6"
                                    />
                                    <span class="checkmark"></span>
                                    <span className="label">
                                      {item.educationName || item.educationname}
                                    </span>
                                  </label>
                                  <Field
                                    type="text"
                                    name="levelOfEducationOther"
                                    placeholder="Education"
                                    className={`h-[50px] border border-[#989898] rounded-[5px] p-3 ${
                                      item.id == "3" &&
                                      values.education.includes("3")
                                        ? "block"
                                        : "hidden"
                                    } ${
                                      errors.education && touched.education
                                        ? "border-red-500"
                                        : ""
                                    }`}
                                  />
                                </>
                              ))}
                            </div>
                            <ErrorMessage
                              name="education"
                              component="div"
                              className="text-red-500"
                            />
                          </div>

                          <div className="flex flex-col gap-4 custom-checkbox-container">
                            <label className="font-[400] text-[16px] md:text-[18px] text-[#5D6169]">
                              Program of Interest{" "}
                              <span className="text-[#BB2727]">*</span>
                            </label>
                            <div
                              role="group"
                              aria-labelledby="checkbox-group"
                              className="grid grid-cols-2 text-[16px] text-[#1B2336] gap-6"
                            >
                              {coursesData?.data?.map((item) => (
                                <label className="flex items-center gap-2">
                                  <Field
                                    type="checkbox"
                                    name="programOfInterest"
                                    className="min-h-6 min-w-6"
                                    value={`${item.id}`}
                                  />
                                  {item.coursename}
                                </label>
                              ))}
                            </div>
                            <ErrorMessage
                              name="programOfInterest"
                              component="div"
                              className="text-red-500"
                            />
                          </div>

                          <div className="w-full flex flex-col gap-2">
                            <label className="font-[400] text-[16px] md:text-[18px] text-[#5D6169]">
                              Send text messages to{" "}
                            </label>
                            <Field
                              type="text"
                              name="message"
                              className={`h-[50px] border border-[#989898] rounded-[5px] p-3 ${
                                errors.message && touched.message
                                  ? "border-red-500"
                                  : ""
                              }`}
                            />
                            <ErrorMessage
                              name="message"
                              component="div"
                              className="text-red-500"
                            />
                          </div>
                          <div className="flex justify-end gap-5 mt-4">
                            <button className="orangeButton" type="submit">
                              Schedule Event
                            </button>
                            <button
                              type="button"
                              className="orangeOutlineButton"
                              onClick={() => setFormStep(1)}
                            >
                              Cancel
                            </button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col pt-14 pb-[54px] 1400:hidden w-[calc(100%_-_32px)] md:w-[calc(100%_-_64px)] xl:w-[89%]">
            <div className="flex gap-10 w-full">
              <div className="flex flex-col gap-10 w-full">
                <p className="text-[20px] leading-[30px] font-[500]">
                  Enter Details
                </p>
                <div>
                  <Formik
                    initialValues={{
                      firstName: "",
                      lastName: "",
                      email: "",
                      phoneNumber: "",
                      education: "",
                      programOfInterest: "",
                      message: "",
                      levelOfEducation: "",
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                  >
                    {({ values, errors, touched }) => (
                      <Form className="w-full gap-6 flex flex-col">
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                          <div className="w-full flex flex-col gap-2">
                            <label className="font-[400] text-[16px] md:text-[18px] text-primary">
                              First Name{" "}
                              <span className="text-[#BB2727]">*</span>
                            </label>
                            <Field
                              type="text"
                              name="firstName"
                              className={`h-[50px] border border-[#989898] rounded-[5px] p-3 ${
                                errors.firstName && touched.firstName
                                  ? "border-red-500"
                                  : ""
                              }`}
                            />
                            <ErrorMessage
                              name="firstName"
                              component="div"
                              className="text-red-500"
                            />
                          </div>
                          <div className="w-full flex flex-col gap-2">
                            <label className="font-[400] text-[16px] md:text-[18px] text-[#5D6169]">
                              Last Name{" "}
                              <span className="text-[#BB2727]">*</span>
                            </label>
                            <Field
                              type="text"
                              name="lastName"
                              className={`h-[50px] border border-[#989898] rounded-[5px] p-3 ${
                                errors.lastName && touched.lastName
                                  ? "border-red-500"
                                  : ""
                              }`}
                            />
                            <ErrorMessage
                              name="lastName"
                              component="div"
                              className="text-red-500"
                            />
                          </div>
                          <div className="w-full flex flex-col gap-2">
                            <label className="font-[400] text-[16px] md:text-[18px] text-[#5D6169]">
                              Email <span className="text-[#BB2727]">*</span>
                            </label>
                            <Field
                              type="text"
                              name="email"
                              className={`h-[50px] border border-[#989898] rounded-[5px] p-3 ${
                                errors.email && touched.email
                                  ? "border-red-500"
                                  : ""
                              }`}
                            />
                            <ErrorMessage
                              name="email"
                              component="div"
                              className="text-red-500"
                            />
                          </div>
                          <div className="w-full flex flex-col gap-2">
                            <label className="font-[400] text-[16px] md:text-[18px] text-[#5D6169">
                              Phone Number{" "}
                              <span className="text-[#BB2727]">*</span>
                            </label>
                            <Field
                              type="text"
                              name="phoneNumber"
                              className={`h-[50px] border border-[#989898] rounded-[5px] p-3 ${
                                errors.phoneNumber && touched.phoneNumber
                                  ? "border-red-500"
                                  : ""
                              }`}
                            />
                            <ErrorMessage
                              name="phoneNumber"
                              component="div"
                              className="text-red-500"
                            />
                          </div>
                        </div>
                        {!showGuestEmail && (
                          <div className="flex gap-3 w-[90%]">
                            <button
                              type="button"
                              className="orangeOutlineButton"
                              onClick={() => setShowGuestEmail(true)}
                            >
                              Add Guests Email
                            </button>
                          </div>
                        )}
                        {showGuestEmail && (
                          <div className="w-full flex flex-col gap-2">
                            <label className="font-[400] text-[16px] md:text-[18px] text-[#5D6169">
                              Guest Email(s)
                            </label>
                            {/* <Field
                        as="textarea"
                        name="guestEmail"
                        rows="5"
                        className={`border border-[#989898] rounded-[5px] p-3 resize-none`}
                      /> */}
                            <Field
                              name="guestEmail"
                              className="border border-[#989898] rounded-[5px] p-3 resize-none"
                              render={({ field }) => (
                                <TagsInput
                                  {...field}
                                  value={guestEmailArray}
                                  onChange={(tags) => setGuestEmailArray(tags)}
                                  inputProps={{ placeholder: "" }}
                                />
                              )}
                            />
                          </div>
                        )}

                        <div className="flex flex-col gap-4">
                          <label className="font-[400] text-[16px] md:text-[18px] text-[#5D6169]">
                            Education <span className="text-[#BB2727]">*</span>
                          </label>
                          <div
                            role="group"
                            aria-labelledby="my-radio-group"
                            className="flex gap-[25px] xl:gap-8 text-[16px] text-[#1B2336] flex-wrap"
                          >
                            {educationLevelData?.data?.map((item) => (
                              <>
                                <label
                                  className={`flex gap-2 items-center custom-radio-container ${
                                    item.id == "3" &&
                                    values.education.includes("3") &&
                                    "hidden"
                                  }`}
                                >
                                  <Field
                                    type="radio"
                                    name="education"
                                    value={`${item.id}`}
                                    className="min-h-6 min-w-6"
                                  />
                                  <span class="checkmark"></span>
                                  <span className="label">
                                    {item.educationName || item.educationname}
                                  </span>
                                </label>
                                <Field
                                  type="text"
                                  name="levelOfEducationOther"
                                  placeholder="Education"
                                  className={`h-[50px] border border-[#989898] rounded-[5px] p-3 ${
                                    item.id == "3" &&
                                    values.education.includes("3")
                                      ? "block"
                                      : "hidden"
                                  } ${
                                    errors.education && touched.education
                                      ? "border-red-500"
                                      : ""
                                  }`}
                                />
                              </>
                            ))}
                          </div>
                          <ErrorMessage
                            name="education"
                            component="div"
                            className="text-red-500"
                          />
                        </div>

                        <div className="flex flex-col gap-4 custom-checkbox-container">
                          <label className="font-[400] text-[16px] md:text-[18px] text-[#5D6169]">
                            Program of Interest{" "}
                            <span className="text-[#BB2727]">*</span>
                          </label>
                          <div
                            role="group"
                            aria-labelledby="checkbox-group"
                            className="grid grid-cols-2 text-[16px] text-[#1B2336] gap-6"
                          >
                            {coursesData?.data?.map((item) => (
                              <label className="flex items-center gap-2">
                                <Field
                                  type="checkbox"
                                  name="programOfInterest"
                                  className="min-h-6 min-w-6"
                                  value={`${item.id}`}
                                />
                                {item.coursename}
                              </label>
                            ))}
                          </div>
                          <ErrorMessage
                            name="programOfInterest"
                            component="div"
                            className="text-red-500"
                          />
                        </div>

                        <div className="w-full flex flex-col gap-2">
                          <label className="font-[400] text-[16px] md:text-[18px] text-[#5D6169]">
                            Send text messages to{" "}
                          </label>
                          <Field
                            type="text"
                            name="message"
                            className={`h-[50px] border border-[#989898] rounded-[5px] p-3 ${
                              errors.message && touched.message
                                ? "border-red-500"
                                : ""
                            }`}
                          />
                          <ErrorMessage
                            name="message"
                            component="div"
                            className="text-red-500"
                          />
                        </div>
                        <div className="flex justify-end gap-5 mt-4">
                          <button className="orangeButton" type="submit">
                            Schedule Event
                          </button>
                          <button
                            type="button"
                            className="orangeOutlineButton"
                            onClick={() => setFormStep(1)}
                          >
                            Cancel
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AppointmentForm;
