import React from "react";

const CourseOutcomes = () => {
  return (
    <>
      <div className="leading-[35px]">
        <p className="font-[500] text-[20px] md:text-[24px] xl:text-[32px] leading-[36px] xl:leading-[48px] text-[#003C9D]">
          Pharmacy Technician Program Outcome
        </p>

        <div className="text-content text-[18px] md:text-[20px] flex flex-col gap-10 mt-10 text-[#5C616E]">
          <p className="">
            Upon the completion of this course the student will be able to:
          </p>
          <p>
            Program Learning Outcomes detail the knowledge, skills and abilities
            that you as a student will demonstrate upon successful completion of
            your program.
          </p>

          <ul className="list-disc ml-6 flex flex-col gap-4 leading-[45px]">
            <li>
              Demonstrate the ability to accurately interpret the information on
              a new prescription, request any missing information, and enter it.
            </li>
            <li>
              Demonstrate familiarity with brand and generic drug names,
              appearance, manufacturer, dosage forms(s), and route of
              administration for at least the top 100 drugs.
            </li>
            <li>
              Demonstrate the ability to process a medication order completely,
              accurately, and efficiently (interpretation, drug product
              selection, computer warnings, packaging, and labeling, filling a
              prescription in an outpatient setting, and preparing IV medication
              using aseptic technique).
            </li>
            <li>
              Demonstrate accuracy and timeliness in mathematical computation of
              ingredient amounts, doses, infusion rates, or any relevant
              calculation encountered in an Outpatient and Inpatient setting.
            </li>
            <li>
              Demonstrate familiarity with third party insurance and perform
              necessary functions for reimbursement.
            </li>
            <li>
              Demonstrate knowledge in durable medical equipment to adequately
              communicate with patients about them.
            </li>
            <li>
              Demonstrate knowledge of pharmacy law and apply to the
              prescription filling process.
            </li>
            <li>
              Display acceptable communication skills during interactions with
              patients, coworkers and other health care professionals.
            </li>
            <li>
              Display a professional attitude about the practice of pharmacy and
              the ability to problem-solve.
            </li>
            <li>
              Demonstrate fundamental knowledge of medical terminology and basic
              rules of vocabulary building.
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default CourseOutcomes;
