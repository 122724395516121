import {
  ADD_CONTACTUS,
  ADD_CONTACTUS_FAILURE,
  ADD_CONTACTUS_SUCCESS,
  ADD_REQUESTINFO,
  ADD_REQUESTINFO_FAILURE,
  ADD_REQUESTINFO_SUCCESS,
  GET_COURSES,
  GET_COURSES_FAILURE,
  GET_COURSES_SUCCESS,
  GET_COMBO_COURSES,
  GET_COMBO_COURSES_SUCCESS,
  GET_BLOG_BY_ID_FAILURE,
  GET_COMBO_COURSES_FAILURE,
} from "../ActionTypes";

export const addContactus = (payload) => {
  return {
    type: ADD_CONTACTUS,
    payload: payload,
  };
};
export const addContactusSuccess = (payload) => {
  return {
    type: ADD_CONTACTUS_SUCCESS,
    payload: payload,
  };
};
export const addContactusFailure = (payload) => {
  return {
    type: ADD_CONTACTUS_FAILURE,
    payload: payload,
  };
};
export const getCourses = (payload) => {
  return {
    type: GET_COURSES,
    payload: payload,
  };
};
export const getCoursesSuccess = (payload) => {
  return {
    type: GET_COURSES_SUCCESS,
    payload: payload,
  };
};
export const getCoursesFailure = (payload) => {
  return {
    type: GET_COURSES_FAILURE,
    payload: payload,
  };
};
export const addReuestInfo = (payload) => {
  return {
    type: ADD_REQUESTINFO,
    payload: payload,
  };
};
export const addReuestInfoSuccess = (payload) => {
  return {
    type: ADD_REQUESTINFO_SUCCESS,
    payload: payload,
  };
};
export const addReuestInfoFailure = (payload) => {
  return {
    type: ADD_REQUESTINFO_FAILURE,
    payload: payload,
  };
};
export const getComboCourses = (payload) => {
  return {
    type: GET_COMBO_COURSES,
    payload: payload,
  };
};
export const getComboCoursesSuccess = (payload) => {
  return {
    type: GET_COMBO_COURSES_SUCCESS,
    payload: payload,
  };
};
export const getComboCoursesFailure = (payload) => {
  return {
    type: GET_COMBO_COURSES_FAILURE,
    payload: payload,
  };
};
