export const ADD_CONTACTUS = "ADD_CONTACTUS";
export const ADD_CONTACTUS_SUCCESS = "ADD_CONTACTUS_SUCCESS";
export const ADD_CONTACTUS_FAILURE = "ADD_CONTACTUS_FAILURE";

export const GET_COURSES = "GET_COURSES";
export const GET_COURSES_SUCCESS = "GET_COURSES_SUCCESS";
export const GET_COURSES_FAILURE = "GET_COURSES_FAILURE";

export const ADD_REQUESTINFO = "ADD_REQUESTINFO";
export const ADD_REQUESTINFO_SUCCESS = "ADD_REQUESTINFO_SUCCESS";
export const ADD_REQUESTINFO_FAILURE = "ADD_REQUESTINFO_FAILURE";

export const GET_BLOG = "GET_BLOG";
export const GET_BLOG_SUCCESS = "GET_BLOG_SUCCESS";
export const GET_BLOG_FAILURE = "GET_BLOG_FAILURE";

export const GET_BLOG_BY_ID = "GET_BLOG";
export const GET_BLOG_BY_ID_SUCCESS = "GET_BLOG_BY_ID_SUCCESS";
export const GET_BLOG_BY_ID_FAILURE = "GET_BLOG_BY_ID_FAILURE";

export const GET_RECENT_BLOGS = "GET_RECENT_BLOGS";
export const GET_RECENT_BLOGS_SUCCESS = "GET_RECENT_BLOGS_SUCCESS";
export const GET_RECENT_BLOGS_FAILURE = "GET_RECENT_BLOGS_FAILURE";

export const SELECTED_DATE = "SELECTED_DATE";
export const SELECTED_TIME = "SELECTED_TIME";
export const SELECTED_TIME_ID = "SELECTED_TIME_ID";
export const SELECTED_FORMAT = "SELECTED_FORMAT";

export const GET_BOOKING_SLOTS = "GET_BOOKING_SLOTS";
export const GET_BOOKING_SLOTS_SUCCESS = "GET_BOOKING_SLOTS_SUCCESS";
export const GET_BOOKING_SLOTS_FAILURE = "GET_BOOKING_SLOTS_FAILURE";

export const ADD_APPOINTMENT = "ADD_APPOINTMENT";
export const ADD_APPOINTMENT_SUCCESS = "ADD_APPOINTMENT_SUCCESS";
export const ADD_APPOINTMENT_FAILURE = "ADD_APPOINTMENT_FAILURE";

export const GET_EDUCATION_LEVEL = "GET_EDUCATION_LEVEL";
export const GET_EDUCATION_LEVEL_SUCCESS = "GET_EDUCATION_LEVEL_SUCCESS";
export const GET_EDUCATION_LEVEL_FAILURE = "GET_EDUCATION_LEVEL_FAILURE";

export const ADD_ENROLLMENT_VERIFICATION = "ADD_ENROLLMENT_VERIFICATION";
export const ADD_ENROLLMENT_VERIFICATION_SUCCESS =
  "ADD_ENROLLMENT_VERIFICATION_SUCCESS";
export const ADD_ENROLLMENT_VERIFICATION_FAILURE =
  "ADD_ENROLLMENT_VERIFICATION_FAILURE";

export const ADD_LEAVE_ABSENCE = "ADD_LEAVE_ABSENCE";
export const ADD_LEAVE_ABSENCE_SUCCESS = "ADD_LEAVE_ABSENCE_SUCCESS";
export const ADD_LEAVE_ABSENCE_FAILURE = "ADD_LEAVE_ABSENCE_FAILURE";

export const GET_WITHDRAWAL_REASONS = "GET_WITHDRAWAL_REASONS";
export const GET_WITHDRAWAL_REASONS_SUCCESS = "GET_WITHDRAWAL_REASONS_SUCCESS";
export const GET_WITHDRAWAL_REASONS_FAILURE = "GET_WITHDRAWAL_REASONS_FAILURE";

export const ADD_ADMISSION_WITHDRAWAL = "ADD_ADMISSION_WITHDRAWAL";
export const ADD_ADMISSION_WITHDRAWAL_SUCCESS =
  "ADD_ADMISSION_WITHDRAWAL_SUCCESS";
export const ADD_ADMISSION_WITHDRAWAL_FAILURE =
  "ADD_ADMISSION_WITHDRAWAL_FAILURE";

export const ADD_TRANSCRIPT = "ADD_TRANSCRIPT";
export const ADD_TRANSCRIPT_SUCCESS = "ADD_TRANSCRIPT_SUCCESS";
export const ADD_TRANSCRIPT_FAILURE = "ADD_TRANSCRIPT_FAILURE";

export const ADD_CERTIFICATE = "ADD_CERTIFICATE";
export const ADD_CERTIFICATE_SUCCESS = "ADD_CERTIFICATE_SUCCESS";
export const ADD_CERTIFICATE_FAILURE = "ADD_CERTIFICATE_FAILURE";

export const ADD_ENROLLMENT = "ADD_ENROLLMENT";
export const ADD_ENROLLMENT_SUCCESS = "ADD_ENROLLMENT_SUCCESS";
export const ADD_ENROLLMENT_FAILURE = "ADD_ENROLLMENT_FAILURE";

export const ADD_BLOG_COMMENT = "ADD_BLOG_COMMENT";
export const ADD_BLOG_COMMENT_SUCCESS = "ADD_BLOG_COMMENT_SUCCESS";
export const ADD_BLOG_COMMENT_FAILURE = "ADD_BLOG_COMMENT_FAILURE";

export const GET_HOLIDAYS = "GET_HOLIDAYS";
export const GET_HOLIDAYS_SUCCESS = "GET_HOLIDAYS_SUCCESS";
export const GET_HOLIDAYS_FAILURE = "GET_HOLIDAYS_FAILURE";

export const GET_COMBO_COURSES = "GET_COMBO_COURSES";
export const GET_COMBO_COURSES_SUCCESS = "GET_COMBO_COURSES_SUCCESS";
export const GET_COMBO_COURSES_FAILURE = "GET_COMBO_COURSES_FAILURE";

export const ADD_GRIEVANCE = "ADD_GRIEVANCE";
export const ADD_GRIEVANCE_SUCCESS = "ADD_GRIEVANCE_SUCCESS";
export const ADD_GRIEVANCE_FAILURE = "ADD_GRIEVANCE_FAILURE";