//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//
import React, { useEffect, useState } from "react";
import Section1 from "./HomePage/Section1";
import Section2 from "./HomePage/Section2";
import Section4 from "./HomePage/Section4";
import Section5 from "./HomePage/Section5";
import Section3 from "./HomePage/Section3";
//--------------------------------------------------------------------All Imports---------------------------------------------------------------------------//

function HomePage() {
  //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//

  const [projectList, setProjectList] = useState([]);
  //--------------------------------------------------------------------All Variables---------------------------------------------------------------------------//


  return (
    <div className="flex flex-col gap-12">
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
    
    </div>
  );
}

export default HomePage;
