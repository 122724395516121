import React from "react";
import patientCareOverview from "../../../../assets/images/patientCareOverview.svg";
import coursePlayIcon from "../../../../assets/images/coursePlayIcon.svg";
import tick from "../../../../assets/images/tick.svg";
import QuickFactsTable from "../../../../assets/images/QuickFacts.svg";

const Overview = ({setTab}) => {
  return (
    <>
      <div className="font-poppins text-[18px] md:text-[20px] leading-[45px] text-[#5C616E]">
        <div className="text-content md:ml-[20px] md:mb-[20px] xl:ml-[99px] xl:mb-[40px] xl:mt-[85px]  w-1/2 float-right hidden md:flex justify-center items-center relative">
          <img
            src={patientCareOverview}
            alt="Certified Patient Care Technician Program Overview"
          />
          <img
            className="absolute"
            src={coursePlayIcon}
            alt="course play icon"
          />
        </div>
        <p className="font-[500] text-[20px] md:text-[24px] xl:text-[32px] leading-[36px] xl:leading-[48px] text-[#003C9D]">
          Certified Patient Care Technician (Hybrid) Overview
        </p>
        <div className="flex md:hidden justify-center items-center mt-10">
          <img
            src={patientCareOverview}
            alt="Certified Patient Care Technician Program Overview"
          />
          <img
            className="absolute"
            src={coursePlayIcon}
            alt="course play icon"
          />
        </div>
        <p className="mt-10">
          The Certified  Patient Care Technician Program at Param Institute of
          Education is designed for{" "}
          <a className="underline text-[#0755b9]" href="#" onClick={(event) => {
                  event.preventDefault();
                  setTab("faq");
                }}>
            (Hybrid)
          </a>{" "}
          (where you can choose either an Online or In-person theory class) 300
          hours of comprehensive training including classroom instructions and
          clinical externship training.  The program is designed to advance your
          career in an already growing Healthcare industry. The PIE will prepare
          you to work in countless settings, including hospitals, Emergency
          Department, Ambulatory care centers, Urgent care centers and medical
          offices, etc. Patient Care Technicians work with doctors, nurses, and
          other healthcare professionals to supervise and monitor patients. As
          vital members of the healthcare team, they provide direct patient care
          and involve to measure vital signs, handle lab specimens, practice
          Infection control techniques, assist during Physical Examination,
          conduct catheterizations, saline locks and wound care procedures,
          administer first aid. They are also cross trained in venipuncture,
          electrocardiography, and general patient care and enjoy much broader
          responsibilities and
        </p>
        <p className="mt-4">
          advancement opportunities. Once students complete the requirements of
          the patient care technician program, they're entitled to take the
          Patient Care Technician (PCT) national certification exam offered by
          <a
            className="underline text-[#0755b9]"
            href="https://www.nhanow.com/"
          >
            National Health Career Association (NHA)
          </a>{" "}
          and{" "}
          <a
            className="underline text-[#0755b9]"
            href="https://www.amcaexams.com/amca-certification-board/"
          >
            American Medical Certification Association (AMCA).
          </a>{" "}
          They can appear in the exam while completing their clinical education.
        </p>

        <div className="flex flex-col gap-5 md:gap-10">
          <p className="font-[500] text-[20px] md:text-[24px] xl:text-[32px] leading-[36px] xl:leading-[48px] text-[#003C9D] mt-14">
            Why Choose Patient Care Technician?
          </p>
          <p className="text-content w-full  text-[18px] md:text-[20px] leading-[45px] flex flex-col gap-4 text-[#5C616E]">
            Many people don’t realize that the patient care technician career is
            a great way to get your feet wet in the medical field. It’s very
            rewarding and has a variety of job opportunities, which includes
            dialysis technician, EKG technician, hospital caregiver, emergency
            department technician and so much more. It allows you to find out if
            you want to continue your education onto other pathways or stick to
            your specialty as a Patient Care Technician (PCT). This type of job
            has many important reasons why you should consider the career.
          </p>
          <div className=" flex flex-col gap-6 leading-[35px]">
            <div className="flex flex-col gap-4">
              <div className="flex gap-6 items-center">
                <img
                  src={tick}
                  alt=""
                  className="h-[22px] w-[22px] md:h-[30px] md:w-[30px]"
                />
                <p className="font-[500] text-[20px] md:text-[24px]  text-[#F37021] font-inter">
                  It’s rewarding
                </p>
              </div>
              <p className="font-[400] text-[18px] md:text-[20px] text-[#5C616E]  font-inter leading-[40px] ml-12">
                A PCT is essential to the healthcare industry and makes a
                positive impact daily to those in need. This career allows you
                to work with and build relationships with your healthcare team
                along with the patients you care for.
              </p>
            </div>
            <div className="flex flex-col gap-4">
              <div className="flex gap-6 items-center">
                <img
                  src={tick}
                  alt=""
                  className="h-[22px] w-[22px] md:h-[30px] md:w-[30px]"
                />
                <p className="font-[500] text-[20px] md:text-[24px]  text-[#F37021] font-inter">
                  Opens a door to higher education
                </p>
              </div>
              <p className="font-[400] text-[18px] md:text-[20px] text-[#5C616E]  font-inter leading-[40px] ml-12">
                If you find yourself wanting more once you graduate and gain
                experience in the field, you can grow your education into
                the nursing field, healthcare administration and more. Pima
                Medical Institute offers many pathways you can take with
                our associate, bachelor’s and master’s degree programs.
              </p>
            </div>
            <div className="flex flex-col gap-4">
              <div className="flex gap-6 items-center">
                <img
                  src={tick}
                  alt=""
                  className="h-[22px] w-[22px] md:h-[30px] md:w-[30px]"
                />
                <p className="font-[500] text-[20px] md:text-[24px]  text-[#F37021] font-inter">
                  You can choose where you work
                </p>
              </div>
              <p className="font-[400] text-[18px] md:text-[20px] text-[#5C616E]  font-inter leading-[40px] ml-12">
                Because you learn many different skills in the patient care
                technician program, you can choose where you want to work, such
                as emergency rooms, pediatric hospitals, specialty clinics, home
                healthcare facilities and more. You can even be a traveling
                dialysis technician.
              </p>
            </div>
            <div className="flex flex-col gap-4">
              <div className="flex gap-6 items-center">
                <img
                  src={tick}
                  alt=""
                  className="h-[22px] w-[22px] md:h-[30px] md:w-[30px]"
                />
                <p className="font-[500] text-[20px] md:text-[24px]  text-[#F37021] font-inter">
                  Enter the medical field quickly
                </p>
              </div>
              <p className="font-[400] text-[18px] md:text-[20px] text-[#5C616E]  font-inter leading-[40px] ml-12">
                At Pima Medical, once you complete your nursing assistant
                certification, you can move into the PCT program, which is only
                eight months long. This allows you to begin working in the
                medical field in just a short period of time.
              </p>
            </div>
            <div className="flex flex-col gap-4">
              <div className="flex gap-6 items-center">
                <img
                  src={tick}
                  alt=""
                  className="h-[22px] w-[22px] md:h-[30px] md:w-[30px]"
                />
                <p className="font-[500] text-[20px] md:text-[24px]  text-[#F37021] font-inter">
                  No day is the same
                </p>
              </div>
              <p className="font-[400] text-[18px] md:text-[20px] text-[#5C616E]  font-inter leading-[40px] ml-12">
                You will work with different patients each day, caring for them
                in various ways. Some duties may include wound care, recording
                patient behaviors, dialysis treatments and bedside care. You may
                also work with different staff, like doctors, surgeons, RNs or
                physical therapists.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-10 md:mt-[60px]">
        <p className="font-[500] text-[20px] md:text-[24px] xl:text-[32px] leading-[36px] xl:leading-[48px] text-[#003C9D]">
          Program Outline
        </p>
        <div className="p-6 border-[1px] rounded-[5px] border-[#D9D9D9] mt-5 md:mt-10 shadow-lg">
          <ul className="list-disc font-[400] text-[18px] md:text-[20px] text-[#1B2336] leading-[35px] flex flex-col gap-[10px] ml-6">
            <li>
              Total Hours:{" "}
              <span className="font-[500] text-[#003C9D]">400</span>
            </li>
            <li>
              Hybrid / In Person Study:{" "}
              <span className="font-[500] text-[#003C9D]">200 Hours </span>
            </li>
            <li>
              Practical/Clinical Study at School:{" "}
              <span className="font-[500] text-[#003C9D]">25 Hours </span>
            </li>
            <li>
              Clinical Externship at Healthcare Site:{" "}
              <span className="font-[500] text-[#003C9D]">175 Hours</span>
            </li>
          </ul>
        </div>

        <div className="p-6 border-[1px] rounded-[5px] border-[#D9D9D9] mt-6 shadow-lg">
          <ul className="font-[400] text-[18px] md:text-[20px] text-[#1B2336] leading-[35px] flex flex-col gap-[10px] ml-6">
            <li>Module 1: GENERAL PATIENT CARE</li>
            <li>Module 2: PHLEBOTOMY</li>
            <li>Module 3: ELECTROCARDIOGRAPHY</li>
            <li>Module 4: PATIENTCARE TECHNICIAN</li>
            <li>Module 5: CLINICAL EXTERNSHIP</li>
          </ul>
        </div>

        <div className="w-full flex gap-10 flex-col">
          <div className="w-full  mt-[20px]">
            <p className="font-[500] text-[20px] md:text-[24px] xl:text-[32px] text-[#003C9D] pb-[40px] pt-10">
              What you get from us?
            </p>
            <div className="flex gap-8 xl:flex-row flex-col">
              <ul className=" w-full list-disc font-[400] text-[18px] md:text-[20px] text-[#5C616E] flex  list-inside gap-4 flex-col">
                <li>Online lectures and material access</li>
                <li>Live instructor for your queries</li>
                <li>Administrative support</li>
                <li>Onsite clinical training</li>
                <li>Field/Internship/Externship training</li>
                <li>PCT Certificate from school</li>

                <li>
                  NHA or AMCA Certified Phlebotomy Technician (PCT) exam
                  preparation material
                </li>
                <li>
                  NHA or AMCA certification once you appear and pass the NHA or
                  AMCA exams
                </li>

                <li>Placement assistant</li>
                <li>Job opportunities</li>
                <li>Career pathways opportunities and guidelines</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Overview;
