import NetworkClient from "./NetworkClient";

export const addContactusAPI = (payload) => {
  return NetworkClient.post("contactus/addcontactus", payload);
};

export const getCoursesAPI = () => {
  return NetworkClient.get("courses/getcourses");
};

export const addRequestInfoAPI = (payload) => {
  return NetworkClient.post("requestinfo/addrequestinfo", payload);
};

export const getBlogAPI = ({ pageSize, currentPage }) => {
  return NetworkClient.get(
    `blog/getblog?page_size=${pageSize}&current_page=${currentPage}`
  );
};

export const getBlogByIdAPI = (id) => {
  return NetworkClient.get(`blog/getblog/${id}`);
};

export const getRecentBlogsAPI = (limit) => {
  return NetworkClient.get(`blog/recentBlogs?limit=${limit}`);
};

export const getBookingSlotsAPI = (date) => {
  return NetworkClient.get(`bookingSlots/getBookingSlots?date=${date}`);
};

export const addAppointmentAPI = (payload) => {
  return NetworkClient.post(`appointments/addappointments`, payload);
};

export const getEducationLevelAPI = () => {
  return NetworkClient.get(`educationLevel/getEducationLevel`);
};

export const addEnrollmentVerificationAPI = (payload) => {
  return NetworkClient.post(
    `enrollmentVerification/addEnrollmentVerifications`,
    payload
  );
};

export const addLeaveAbsenceAPI = (payload) => {
  return NetworkClient.post(`leaveAbsence/addLeaveAbsence`, payload);
};

export const getWithdrawalReasonsAPI = (payload) => {
  return NetworkClient.get(`withdrawalReasons/getWithdrawlReasons`, payload);
};

export const addAdmissionWithdrawalAPI = (payload) => {
  return NetworkClient.post(
    `admissionWithdrawal/addAdmissionWithdrawal`,
    payload
  );
};

export const addTranscriptAPI = (payload) => {
  return NetworkClient.post(`transcript/addTranscripts`, payload);
};

export const addCertificateAPI = (payload) => {
  return NetworkClient.post(`certificate/addCertificates`, payload);
};

export const addEnrollmentAPI = (payload) => {
  return NetworkClient.post(`enrollment/addEnrollments`, payload);
};

export const addBlogCommentAPI = (payload) => {
  return NetworkClient.post(`blogComments/addblogComments`, payload);
};

export const getHolidaysAPI = (payload) => {
  return NetworkClient.get(`holidays/getHolidays`);
};

export const getComboCoursesAPI = (payload) => {
  return NetworkClient.get(`comboCourses/getComboCourses`);
};

export const addGrievanceAPI = (payload) => {
  return NetworkClient.post(`grievance/addGrievance`, payload);
};