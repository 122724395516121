import React from "react";
import coursePlayIcon from "../../../../assets/images/coursePlayIcon.svg";
import yoga200Course from "../../../../assets/images/yoga200Course.svg";

const Overview = () => {
  return (
    <>
      <div>
        <div className="text-content md:ml-[20px] md:mb-[20px] xl:ml-[99px] xl:mb-[40px] xl:mt-[85px]  w-1/2 float-right hidden md:flex justify-center items-center relative">
          <img
            src={yoga200Course}
            alt="Yoga Teacher Training Certificate Program (200-Hours)"
          />
          <img
            className="absolute"
            src={coursePlayIcon}
            alt="course play icon"
          />
        </div>
        <p className="font-[500] text-[20px] md:text-[24px] xl:text-[32px] leading-[36px] xl:leading-[48px] text-[#003C9D]">
          200 Hours Yoga Teacher Training Certificate (Hybrid) Program
        </p>
        <div className="flex md:hidden justify-center items-center mt-10">
          <img
            src={yoga200Course}
            alt="Yoga Teacher Training Certificate Program (200-Hours)"
          />
          <img
            className="absolute"
            src={coursePlayIcon}
            alt="course play icon"
          />
        </div>
        <p className="text-[18px] md:text-[20px] leading-[45px] text-[#5C616E] mt-10">
          The program comprises three segments: Seg A: Deepening Your Asana, Seg
          B: Sacred Life, and Seg C: Practical Applications. It is designed to
          harness students' natural strengths and desires to cultivate their
          unique teaching styles. Students will acquire a versatile skill set
          enabling them to teach in a variety of approaches.
        </p>
        <p className="text-[18px] md:text-[20px] leading-[45px] text-[#5C616E] mt-4">
          In the Practical Applications course, Param Institute of Education
          will equip students with essential teaching skills, including
          sequencing and formatting platforms. The course offers two distinct
          platforms: Yoga FORM and FLOW. In Yoga FORM, students focus on form,
          alignment, and biomechanics in asanas, ensuring a solid foundation in
          the physical practice of yoga. Meanwhile, in FLOW, students learn to
          synchronize movement with breath, crafting vinyasa-based lesson plans
          that promote fluidity and rhythm in yoga sequences.
        </p>
        <p className="text-[18px] md:text-[20px] leading-[45px] text-[#5C616E] mt-4">
          The 200 hours Yoga Teacher Training Certificate Program at Param
          Institute of Education is designed for{" "}
          <a className="underline text-[#0755b9]" href="#">
            Hybrid
          </a>{" "}
          (where you can choose either an Online or In-person theory class) 200
          hours of Comprehensive training including classroom instructions and
          skill training to prepare the student for the ability to teach yoga
          Philosophy. 
        </p>
      </div>
      <div className="mt-10 md:mt-[60px]">
        <p className="font-[500] text-[20px] md:text-[24px] xl:text-[32px] leading-[36px] xl:leading-[48px] text-[#003C9D]">
          What are the Key Reasons for Pursuing a Yoga Teacher Training Certificate Program?
        </p>
        <p className="text-[18px] md:text-[20px] leading-[40px] mt-5 md:mt-10 text-[#5C616E]">
          Yoga Teacher Training Program (YTT) – typically spanning 200 hours –
          offers numerous compelling reasons for those passionate about yoga and
          holistic wellness:
        </p>
        <ul className="mt-6 text-[#F37021] font-[500] text-[20px] md:text-[24px] leading-[30px] flex flex-col gap-6">
          <li className="flex flex-col relative before:content-[''] before:absolute before:left-0 before:w-[30px] pl-[54px] before:h-[30px] before:bg-checkmark-bg before:bg-no-repeat">
            Deepen Personal Practice
            <span className="mt-4 text-[#5C616E] text-[18px] md:text-[20px] leading-10 font-[400]">
              Yoga Teacher Training Program (YTT) provides an immersive
              environment to deepen your own yoga practice, exploring asanas
              (postures), pranayama (breath control), meditation, and other
              yogic techniques under the guidance of experienced instructors.
            </span>
          </li>
          <li className="flex flex-col relative before:content-[''] before:absolute before:left-0 before:w-[30px] pl-[54px] before:h-[30px] before:bg-checkmark-bg before:bg-no-repeat">
            Gain Comprehensive Knowledge
            <span className="mt-4 text-[#5C616E] text-[18px] md:text-[20px] leading-10 font-[400]">
              Yoga Teacher Training Program (YTT)covers a wide range of topics
              including yoga philosophy, anatomy and physiology, teaching
              method, and the subtle energetics of yoga. This comprehensive
              understanding enriches your practice and teaching abilities.
            </span>
          </li>
          <li className="flex flex-col relative before:content-[''] before:absolute before:left-0 before:w-[30px] pl-[54px] before:h-[30px] before:bg-checkmark-bg before:bg-no-repeat">
            Cultivate Teaching Skills
            <span className="mt-4 text-[#5C616E] text-[18px] md:text-[20px] leading-10 font-[400]">
              Yoga Teacher Training Program (YTT) equips you with essential
              teaching skills, such as class sequencing, cueing, demonstration,
              and hands-on adjustments. Through practice teaching sessions, you
              develop confidence and ability in leading yoga classes
              effectively.
            </span>
          </li>
          <li className="flex flex-col relative before:content-[''] before:absolute before:left-0 before:w-[30px] pl-[54px] before:h-[30px] before:bg-checkmark-bg before:bg-no-repeat">
            Explore Yogic Philosophy
            <span className="mt-4 text-[#5C616E] text-[18px] md:text-[20px] leading-10 font-[400]">
              Yoga Teacher Training Program (YTT) delves into the philosophical
              underpinnings of yoga, including ancient texts like the Yoga
              Sutras and Bhagavad Gita. This exploration offers profound
              insights into the principles of yoga and how they can be applied
              to modern life.
            </span>
          </li>
          <li className="flex flex-col relative before:content-[''] before:absolute before:left-0 before:w-[30px] pl-[54px] before:h-[30px] before:bg-checkmark-bg before:bg-no-repeat">
            Enhance Mind-Body Awareness
            <span className="mt-4 text-[#5C616E] text-[18px] md:text-[20px] leading-10 font-[400]">
              Through regular practice and study, Yoga Teacher Training Program
              (YTT) cultivates greater awareness of the mind-body connection.
              You learn to listen to your body, recognize physical and emotional
              sensations, and cultivate mindfulness both on and off the mat.
            </span>
          </li>

          <li className="flex flex-col relative before:content-[''] before:absolute before:left-0 before:w-[30px] pl-[54px] before:h-[30px] before:bg-checkmark-bg before:bg-no-repeat">
            Connect with a Community
            <span className="mt-4 text-[#5C616E] text-[18px] md:text-[20px] leading-10 font-[400]">
              Yoga Teacher Training Program (YTT) offers an opportunity to
              connect with like-minded individuals who share your passion for
              yoga and holistic living. The supportive community fosters growth,
              inspiration, and lasting friendships.
            </span>
          </li>
          <li className="flex flex-col relative before:content-[''] before:absolute before:left-0 before:w-[30px] pl-[54px] before:h-[30px] before:bg-checkmark-bg before:bg-no-repeat">
            Pursue a Career in Yoga
            <span className="mt-4 text-[#5C616E] text-[18px] md:text-[20px] leading-10 font-[400]">
              <p>
                Upon completion of Yoga Teacher Training Program (YTT), you are
                eligible to become a Registered Yoga Teacher (RYT) with Yoga
                Alliance, a globally recognized credential. This opens doors to
                teaching opportunities in studios, gyms, retreat centers,
                corporate settings, and beyond.
              </p>
            </span>
          </li>
          <li className="flex flex-col relative before:content-[''] before:absolute before:left-0 before:w-[30px] pl-[54px] before:h-[30px] before:bg-checkmark-bg before:bg-no-repeat">
            Share the Gift of Yoga
            <span className="mt-4 text-[#5C616E] text-[18px] md:text-[20px] leading-10 font-[400]">
              <p>
                Yoga Teacher Training Program (YTT) empowers you to share the
                transformative benefits of yoga with others, serving as a
                catalyst for positive change in your own life and the lives of
                your students.
              </p>
            </span>
          </li>
        </ul>
      </div>
      <div className="mt-10 md:mt-[60px]">
        <p className="font-[500] text-[20px] md:text-[24px] xl:text-[32px] leading-[36px] xl:leading-[48px] text-[#003C9D]">
          Yoga Teacher Training Certificate Program Outline
        </p>
        <div className="p-6 border-[1px] rounded-[5px] border-[#D9D9D9] mt-5 md:mt-10 shadow-lg">
          <ul className="list-disc font-[400] text-[18px] md:text-[20px] text-[#1B2336] leading-[35px] flex flex-col gap-[10px] ml-6">
            <li>
              Program Length:{" "}
              <span className="font-[500] text-[#003C9D]">10 Weeks</span>
            </li>
            <li>
              Total Hours of Program:{" "}
              <span className="font-[500] text-[#003C9D]">200 hours</span>
            </li>

            <li>
              Academy Credential Award:{" "}
              <span className="font-[500] text-[#003C9D]">Certificate</span>
            </li>
          </ul>
        </div>
        <div className="p-6 border-[1px] rounded-[5px] border-[#D9D9D9] mt-6 shadow-lg">
          <ul className="font-[400] text-[18px] md:text-[20px] text-[#1B2336] leading-[30px] flex flex-col gap-[10px]">
            <li className="flex flex-col gap-6">
              <div>
                Module 1: <span className="font-[500]">Segment A</span>
                <p>
                  Topics will include seven characteristics of a beginner body
                  method, anatomical landmarks in asana, introduction to
                  therapeutic application of asana, 36 foundation asana, special
                  restorative yoga postures.
                </p>
              </div>
              <div>
                Module 1: <span className="font-[500]">Segment B</span>
                <p>
                  This segment may have live streaming, topics will include
                  experience the inner practice of yoga, develop a meditation
                  home practice, understand your ayurvedic dosha, teaching
                  covered in this course exceed all philosophy, lifestyle and
                  ethics requirements set forth by the National Yoga Alliance.
                </p>
              </div>
              <div>
                Module 1: <span className="font-[500]">Segment C</span>
                <p>
                  Topics will include focus on the art of teaching, learning
                  principles of correction, instruction, voice & language, and
                  demonstration while gaining the practical experience of
                  teaching in small groups, learn the art of sequencing and
                  formatting group yoga classes in the styles of FORM, FLOW &
                  POWER.
                </p>
              </div>
            </li>
            <li>Open Electives</li>
            <li>Business skills Homework Packet</li>
            <li>Homework/ Exam</li>
            <li>Lifestyle Journals Sadhana Log</li>
          </ul>
        </div>
        <div className="mt-10 md:mt-[60px]">
          <div className="">
            <p className="font-[500] text-[20px] md:text-[32px] leading-[36px] text-[#003C9D]">
              What you get from us?
            </p>
            <ul className="list-disc font-[400] text-[18px] md:text-[20px] text-[#5C616E] leading-[30px] mt-10 flex flex-col gap-4 ml-6">
              <li>Lectures and material access</li>
              <li>Live instructor for your queries</li>
              <li>Administrative support</li>
              <li>Onsite clinical training</li>
              <li>Career pathways opportunities and guidelines</li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Overview;
