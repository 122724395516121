import React from "react";
import advJavaCourse from "../../../../assets/images/advJavaCourse.svg";
import coursePlayIcon from "../../../../assets/images/coursePlayIcon.svg";

const Overview = () => {
  return (
    <>
      <div className="inline-block">
        <div className="text-content md:ml-[20px] md:mb-[20px]  xl:ml-[99px] xl:mb-[40px] 2xl:mb-0 xl:mt-[85px]  w-1/2 float-right hidden md:flex justify-center items-center relative">
          <img src={advJavaCourse} alt="Core Java Developer Program" />
          <img
            className="absolute"
            src={coursePlayIcon}
            alt="course play icon"
          />
        </div>
        <p className="font-[500] text-[20px] md:text-[24px] xl:text-[32px] leading-[36px] xl:leading-[48px] text-[#003C9D]">
          Advanced Java Developer Program
        </p>
        <div className="flex md:hidden justify-center items-center mt-10">
          <img src={advJavaCourse} alt="Core Java Developer Program" />
          <img
            className="absolute"
            src={coursePlayIcon}
            alt="course play icon"
          />
        </div>
        <p className="mt-5 md:mt-10 text-[18px] md:text-[20px] leading-[45px]  text-[#5C616E]">
          A high-level programming language developed by Sun Microsystems. Java
          was originally called OAK, and was designed for handheld devices and
          set-top boxes. Java is an object-oriented language similar to C++, but
          simplified to eliminate language features that cause common
          programming errors. Java source code files (files with a .java
          extension) are compiled into a format called byte code (files with a
          .class extension), which can then be executed by a Java interpreter.
          Compiled Java code can run on most computers because Java interpreters
          and runtime environments, known as Java Virtual Machines (VMs), exist
          for most operating systems, including UNIX, the Macintosh OS, and
          Windows.
        </p>
      </div>
      <div className="mt-10 md:mt-[60px]">
        <p className="font-[500] text-[20px] md:text-[24px] xl:text-[32px] leading-[36px] xl:leading-[48px] text-[#003C9D]">
          Program Outline
        </p>
        <div className="p-6 border-[1px] rounded-[5px] border-[#D9D9D9] mt-5 md:mt-10 shadow-lg">
          <ul className="list-disc font-[400] text-[18px] md:text-[20px] text-[#1B2336] leading-[35px] flex flex-col gap-[10px] ml-6">
            <li>
              Prerequisite:{" "}
              <span className="font-[500] text-[#003C9D]">
                Bachelors in Computer Science
              </span>
            </li>
            <li>
              Program Length:{" "}
              <span className="font-[500] text-[#003C9D]">4 Weeks</span>
            </li>
            <li>
              Academic Credential Awarded:{" "}
              <span className="font-[500] text-[#003C9D]">Certificate</span>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Overview;
