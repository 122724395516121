import React from "react";
import tick from "../../../../assets/images/tick.svg";

function CourseOutcomes() {
  return (
    <div className="font-poppins flex flex-col gap-5 md:gap-10">
      <p className="font-[500] text-[20px] md:text-[24px] xl:text-[32px] leading-[36px] xl:leading-[48px] text-[#003C9D]">
        Certified Phlebotomy Technician Program (Hybrid) Outcome
      </p>
      <p className="font-[400] text-[18px] md:text-[20px] text-[#5C616E] leading-[35px] font-inter">
        The Phlebotomy Technician Program from Param Institute of Education
        prepares professionals to collect blood and other specimens from clients
        for the purpose of laboratory analysis. Upon the completion of this
        course the student will be able to:
      </p>
      <div className="flex flex-col gap-6">
        <ul className="list-disc ml-8 font-[400] text-[18px] md:text-[20px] text-[#5C616E] flex flex-col gap-8">
          <li>
            Process and procedures for collecting blood specimens for laboratory
            analysis.
          </li>
          <li>All aspects of blood collection and related procedures</li>
          <li>The order of draw</li>
          <li>Universal precautions</li>
          <li>Skills to perform venipunctures completely and safely</li>
          <li>Terminology and related anatomy and physiology</li>
          <li>Educational requirements</li>
          <li>
            Students should have or be pursuing a high school diploma or GED.
          </li>
          <li>
            Several state and national certification exams exist to test the
            competency of aspiring phlebotomy technicians.
          </li>
          <li>Detailed course topics covered</li>
          <li>The history of phlebotomy & the healthcare setting</li>
          <li>Quality assurance and legal issues</li>
          <li>Infection control, safety, first aid, & personal wellness</li>
          <li>
            Universal precautions including safety protocols and infection
            control
          </li>
          <li>Laboratory operations (e.g. safety, quality control)</li>
          <li>Blood banks and blood typing, lab departments and personnel</li>
          <li>Medical terminology and laboratory theory</li>
          <li>Human anatomy & physiology of the circulatory system</li>
          <li>Specimen collection, processing, and handling</li>
          <li>Blood collection equipment, additives, & order of draw</li>
          <li>Venipuncture specimen collection procedures</li>
          <li>Capillary puncture equipment and procedures</li>
          <li>Special collections and point-of-care testing</li>
          <li>Arterial puncture procedures</li>
          <li>Non-blood specimens and tests</li>
          <li>Pediatric and geriatric blood collection</li>
          <li>
            Blood and blood composition, blood tubes, coagulation, venipuncture
            protocols, etc.
          </li>
          <li>Heel puncture, protocol, practice, and syringe draws</li>
          <li>Respiratory, pneumonia and TB isolation protocol</li>
          <li>Laboratory skills and simulated/animated labs</li>
        </ul>
      </div>
     
    </div>
  );
}

export default CourseOutcomes;
