import React, { useState } from "react";
import review from "../../assets/images/homeReview.svg";
import pieReviewLogo from "../../assets/images/pieReviewLogo.jpg";
import { useNavigate } from "react-router-dom";
import ReactStars from "react-rating-stars-component";
import googleLogo from "../../assets/images/logos_google.svg";

function Section1() {
  const navigation = useNavigate();
  return (
    <div className="w-full bg-home-bg bg-cover font-poppins bg-no-repeat flex flex-col pt-[70px] md:pt-48  items-center  text-white ">
      <div className="flex flex-col items-center 2xl:px-[60px] text-center mb-[60px] 991:mb-[120px] 2xl:mb-0 ">
        <p className="text-[32px] md:text-[55px] 2xl:text-[80px]  font-[600]">
          PARAM <span className="text-[#F37021] font-[800] ">INSTITUTE</span> OF
          EDUCATION
        </p>
        <div className=" flex justify-center gap-6 md:gap-8 mt-[33px] md:mt-[23px] 2xl:mt-[80px] flex-col md:flex-row">
          <button
            className="orangeButton"
            onClick={() => navigation("./scheduleAppointment")}
          >
            Schedule a Visit
          </button>
          <button
            className="orangeButton"
            onClick={() => navigation("./requestInfo")}
          >
            Inquire Now
          </button>
          <button
            className="orangeButton"
            onClick={() => navigation("./enrollment")}
          >
            Enroll Now
          </button>
          {/* <button className='whiteButton' onClick={() => { navigation("requestInfo"); }}>Request Info</button> */}
        </div>
        <p className="w-[75%] text-[14px] md:text-[16px]  xl:text-[18px]  font-[500] text-center mt-[34px] md:mt-[40px] 2xl:mt-[56px]">
          We offer high quality advanced training and unswerving to providing
          the best training facilities to our students. Our highly qualified
          instructors are professionals in their field of proficiency.
        </p>
        <div className=" w-[90%] flex justify-center mt-[26px] md:mt-[72px] gap-[10px]">
          <div className="w-[30%] flex flex-col gap-2 2xl:gap-10  items-center ">
            <p className="text-[24px] md:text-[40px] 2xl:text-[56px] font-[600]">
              15+
            </p>
            <p className="text-[14px] md:text-[20px] 2xl:text-[24px] font-[500]">
              CERTIFIED COURSES
            </p>
          </div>
          <div className="w-[30%] flex flex-col gap-2 2xl:gap-10  items-center ">
            <p className="text-[24px] md:text-[40px] 2xl:text-[56px] font-[600]">
              20+
            </p>
            <p className="text-[14px] md:text-[20px] 2xl:text-[24px] font-[500]">
              YEARS OF EXPERIENCE
            </p>
          </div>
          <div className="w-[40%] flex flex-col gap-2 2xl:gap-10  items-center ">
            <p className="text-[24px] md:text-[40px] 2xl:text-[56px] font-[600]">
              35+
            </p>
            <p className="text-[14px] md:text-[20px] 2xl:text-[24px] font-[500]">
              HEALTHCARE COLLABORATION
            </p>
          </div>
        </div>
      </div>
      <div className="2xl:mt-10 flex justify-start w-full border-none">
        <div className="rounded-t-[5px] overflow-hidden border-none">
          <div className="w-full h-[5px] bg-[#FF8310]"></div>
          <div className="flex gap-4 bg-white max-w-fit p-[14px] justify-start border border-[#FFFFFF]">
            <div>
              <img
                src={pieReviewLogo}
                className="rounded-[50%] h-[44px] w-[44px]"
              />
            </div>
            <div className=" text-[#000000]">
              <div>
                <div>
                  <a href="https://www.google.com/search?sca_esv=5a58adcc29de473b&rlz=1C1CHBD_enIN1041IN1041&sxsrf=ADLYWIIxZQzFOCi3gegSundaEtlLdSsirw:1718362114585&q=Param+Institute+of+Education&ludocid=8609223918093127830&lsig=AB86z5WL4KDnTTIOQUKtya-3ClPw&kgs=a1f0b6feb002c578&shndl=-1&shem=lrnolc,lsde,lsp,ssic&source=sh/x/loc/act/m1/1" className="underline text-[#000000]">
                    Param Institute of Education
                  </a>
                </div>
                <div className="flex items-center gap-[10px]">
                  <span className="text-[#FF8310] text-[17px] font-600">
                    4.9
                  </span>

                  <ReactStars
                    value={4.9}
                    isHalf={true}
                    size={24}
                    activeColor="#FF8310"
                    color="#FFFFFF"
                    edit={false}
                  />
                </div>
                <div>
                  <span>Based on 199 reviews</span>
                </div>
                <span className="flex gap-2">
                  powered by <img src={googleLogo} />
                </span>
              </div>
            </div>
          </div>
          {/* <img src={review} /> */}
        </div>
      </div>
    </div>
  );
}

export default Section1;
