import React from "react";

const Faqs = () => {
  return (
    <>
      <div>
        <p className="font-[500] text-[20px] md:text-[24px] xl:text-[32px] leading-[36px] xl:leading-[48px] text-[#003C9D]">
          Massage Therapy Program FAQ
        </p>
        <div className="mt-5 md:mt-10 leading-[30px] text-[18px] md:text-[20px]">
          <ul className="list-disc flex flex-col gap-6 ml-5 font-[500]">
            <li className="">
              What does a Massage Therapy do?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  A Massage Therapist is a healthcare professional who
                  specializes in using touch and manipulation techniques to
                  promote physical and psychological well-being. Here's a
                  detailed overview of what a Massage Therapist typically does:
                </p>

                <ul className="list-disc marker:text-[#003C9D]  leading-[35px] ml-8 flex flex-col gap-[10px]">
                  <li>
                    <span className="text-[#003C9D]">Client Assessment: </span>{" "}
                    Before beginning a massage session, a Massage Therapist
                    conducts a thorough assessment of the client's needs and
                    preferences. This may involve asking questions about the
                    client's medical history, current health conditions, areas
                    of discomfort or tension, and desired outcomes from the
                    message.
                  </li>
                  <li>
                    <span className="text-[#003C9D]">
                      Customized Treatment Planning:{" "}
                    </span>{" "}
                    Based on the client assessment, the Massage Therapist
                    develops a customized treatment plan tailored to address the
                    client's specific concerns and goals. This plan may include
                    selecting appropriate massage techniques, adjusting pressure
                    and intensity levels, and determining the duration of the
                    session.
                  </li>

                  <li>
                    <span className="text-[#003C9D]">
                      Hands-On Massage Therapy:{" "}
                    </span>{" "}
                    During the massage session, the Massage Therapist applies
                    various massage techniques to manipulate the soft tissues of
                    the body, including muscles, tendons, ligaments, and fascia.
                    Common massage techniques include Swedish massage, deep
                    tissue massage, sports massage, trigger point therapy, and
                    among others. The therapist uses their hands, fingers,
                    elbows, forearms, or specialized tools to apply pressure,
                    knead, stretch, and mobilize tissues to alleviate tension,
                    reduce pain, improve circulation, and promote relaxation.
                  </li>

                  <li>
                    <span className="text-[#003C9D]">
                      Client Education and Self-Care Recommendations:{" "}
                    </span>{" "}
                    Throughout the massage session, the Massage Therapist may
                    provide guidance and education to the client on proper
                    posture, ergonomics, stretching exercises, and self-care
                    techniques to help manage their condition and prevent future
                    issues. They may also recommend lifestyle modifications or
                    additional therapies to support the client's overall
                    well-being.
                  </li>

                  <li>
                    <span className="text-[#003C9D]">
                      Maintaining Professional Boundaries:{" "}
                    </span>{" "}
                    Massage Therapists adhere to strict ethical and professional
                    standards, including maintaining client confidentiality,
                    respecting boundaries, and ensuring a safe and comfortable
                    environment for the client. They communicate openly and
                    transparently with clients, addressing any concerns or
                    questions they may have during the session.
                  </li>

                  <li>
                    <span className="text-[#003C9D]">
                      Documentation and Record-Keeping:{" "}
                    </span>{" "}
                    Massage Therapists maintain accurate records of client
                    assessments, treatment plans, session notes, and progress
                    reports. This documentation helps track the client's
                    progress over time, identify trends or patterns in their
                    condition, and ensure continuity of care.
                  </li>

                  <li>
                    <span className="text-[#003C9D]">
                      Continued Professional Development:{" "}
                    </span>{" "}
                    To stay current with the latest developments in the field,
                    Massage Therapists engage in ongoing education and training.
                    They may attend workshops, seminars, conferences, and pursue
                    advanced certifications in specialized areas of massage
                    therapy to enhance their skills and knowledge. Overall,
                    Massage Therapists play a vital role in promoting health,
                    wellness, and relaxation for their clients through skilled
                    touch and compassionate care. Their holistic approach
                    addresses the interconnectedness of the body, mind, and
                    spirit, fostering healing and balance for those they serve.
                  </li>
                </ul>
              </div>
            </li>

            <li>
              What is the Massage Therapy Program?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  The Massage Therapy Program is an educational program designed
                  to train individuals to become competent and skilled massage
                  therapists. It typically includes a combination of classroom
                  instruction, hands-on practical training, and clinical
                  experience to provide students with the knowledge, skills, and
                  experience needed to succeed in the field of massage therapy.
                </p>
                <p>Key components of a Massage Therapy Program may include:</p>
                <ul className="list-disc marker:text-[#003C9D]  leading-[35px] ml-8 flex flex-col gap-[10px]">
                  <li>
                    <span className="text-[#003C9D]">
                      Anatomy and Physiology:{" "}
                    </span>{" "}
                    Students learn about the structure and function of the human
                    body, including muscles, bones, organs, and systems such as
                    the nervous, circulatory, and musculoskeletal systems.
                    Understanding anatomy and physiology is essential for
                    effective massage therapy practice.
                  </li>
                  <li>
                    <span className="text-[#003C9D]">Massage Techniques: </span>{" "}
                    Students are trained in various massage techniques, such as
                    Swedish massage, deep tissue massage, sports massage,
                    myofascial release, trigger point therapy, and others. They
                    learn how to implement these techniques safely and
                    effectively to address different client needs and
                    conditions.
                  </li>
                  <li>
                    <span className="text-[#003C9D]">Clinical Practice: </span>{" "}
                    Hands-on clinical experience is an essential part of a
                    Massage Therapy Program. Students can practice their skills
                    under the supervision of experienced instructors in a
                    real-world setting. This may involve working with clients in
                    a school clinic, community health center, or other
                    healthcare facility.
                  </li>
                  <li>
                    <span className="text-[#003C9D]">
                      Business and Marketing:{" "}
                    </span>{" "}
                    Some massage therapy programs include coursework on business
                    management, marketing strategies, and entrepreneurship.
                    Students learn how to start and run a successful massage
                    therapy practice, including managing finances, attracting
                    clients, and building a professional reputation.
                  </li>
                  <li>
                    <span className="text-[#003C9D]">
                      Continuing Education:{" "}
                    </span>{" "}
                    Massage therapy is a dynamic field with new techniques,
                    research, and trends emerging regularly. Many massage
                    therapy programs emphasize the importance of continuing
                    education and lifelong learning. Graduates are encouraged to
                    pursue additional training, certifications, and professional
                    development opportunities to stay current and enhance their
                    skills.
                    <p>
                      Overall, the Massage Therapy Program provides a
                      comprehensive education and training experience for
                      individuals seeking to become licensed massage therapists.
                      Graduates are prepared to pursue employment in a variety
                      of settings, including spas, wellness centers,
                      chiropractic offices, hospitals, sports teams, and private
                      practice. They are equipped with the knowledge, skills,
                      and professionalism needed to provide safe, effective, and
                      compassionate massage therapy services to their clients.
                    </p>
                  </li>
                </ul>
              </div>
            </li>

            <li>
              Whom is the Massage Therapy program suitable for?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  The Massage Therapy program is suitable for a wide range of
                  individuals who are passionate about health, wellness, and
                  helping others. Here are some specific groups for whom the
                  program may be particularly well-suited for career changers:
                  Individuals who are seeking a new career path or are
                  interested in transitioning into a field focused on holistic
                  health and wellness may find the Massage Therapy program
                  appealing, healthcare professionals, fitness and wellness
                  enthusiasts, holistic health practitioners, recent high school
                  graduates: Young adults who are interested in pursuing a
                  career in healthcare or wellness but prefer a hands-on,
                  practical approach to learning may consider enrolling in a
                  Massage Therapy program, individuals Seeking work hours
                  flexibility.
                </p>
                <p>
                  The Massage Therapy program is suitable for anyone with a
                  genuine interest in holistic health, a desire to help others,
                  and a willingness to learn and grow in a dynamic and rewarding
                  field. It welcomes individuals from diverse backgrounds and
                  experiences who share a common passion for promoting wellness
                  and improving quality of life through the power of touch.
                </p>
              </div>
            </li>

            <li>
              What are the prerequisites for enrolling in the Massage Therapy
              program?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  Applicants typically need to demonstrate proficiency in
                  reading, writing, and comprehending English.
                </p>
              </div>
            </li>

            <li>
              What topics are covered in the Massage Therapy curriculum?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  The curriculum typically covers a wide range of topics,
                  including Introduction to Massage Therapy, Medical
                  Terminology, Anatomy & Physiology, Musculoskeletal Anatomy &
                  Kinesiology, Pathology, Massage Technique, Therapeutic
                  Application I (Effect and Contraindication), Oriental Medicine
                  Theory, Shiatsu Massage / 16 movements, Swedish Massage,
                  Business and Professional Standard, Ethics and Legal Practice.
                </p>
              </div>
            </li>

            <li>
              What learning formats are available for the Massage Therapy
              program?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  The Massage Therapy program may be offered in various formats,
                  including in-person classes, hybrid formats combining both
                  online and in-person learning, and self-paced study options.
                </p>
              </div>
            </li>

            <li>
              What are the career opportunities for graduates of the Massage
              Therapy program?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  Graduates of the Massage Therapy program have a variety of
                  career opportunities available to them in both traditional
                  healthcare settings and alternative wellness environments.
                  Here's a detailed look at some of the career paths they may
                  pursue:
                </p>

                <ul className="list-disc marker:text-[#003C9D]  leading-[35px] ml-8 flex flex-col gap-[10px]">
                  <li>
                    <span className="text-[#003C9D]">
                      Spas and Wellness Centers:{" "}
                    </span>
                    Many massage therapists find employment in spas, resorts,
                    and wellness centers, where they provide relaxation and
                    therapeutic massages to clients seeking stress relief, pain
                    management, and overall well-being.
                  </li>
                  <li>
                    <span className="text-[#003C9D]">
                      Chiropractic and Physical Therapy Offices:{" "}
                    </span>{" "}
                    Massage therapists may work alongside chiropractors,
                    physical therapists, and other healthcare professionals in
                    clinical settings to complement their treatments. They
                    assist in reducing muscle tension, improving circulation,
                    and enhancing the effectiveness of other therapeutic
                    interventions.
                  </li>
                  <li>
                    <span className="text-[#003C9D]">
                      Sports and Fitness Facilities:{" "}
                    </span>{" "}
                    Massage therapists play a vital role in sports medicine and
                    fitness by working with athletes to prevent injuries,
                    promote recovery, and enhance performance. They may be
                    employed by gyms, sports teams, athletic clubs, and fitness
                    centers to provide pre-event, post-event, and maintenance
                    massages.
                  </li>
                  <li>
                    <span className="text-[#003C9D]">
                      Hospitals and Rehabilitation Centers:{" "}
                    </span>{" "}
                    Some massage therapists work in hospitals, rehabilitation
                    centers, and nursing homes to provide comfort and pain
                    relief to patients recovering from surgeries, injuries, or
                    chronic illnesses. They collaborate with healthcare teams to
                    support patients' physical and emotional well-being during
                    their recovery journey.
                  </li>
                  <li>
                    <span className="text-[#003C9D]">
                      Corporate Wellness Programs:{" "}
                    </span>{" "}
                    Many companies offer massage therapy as part of their
                    employee wellness programs to reduce stress, boost morale,
                    and improve productivity. Massage therapists may be employed
                    by corporations to provide on-site chair massages or
                    wellness workshops for employees.
                  </li>
                  <li>
                    <span className="text-[#003C9D]">Private Practice: </span>{" "}
                    Some massage therapists choose to establish their own
                    private practice, either as sole proprietors or in
                    partnership with other healthcare professionals. They have
                    the flexibility to set their own schedules, choose their
                    clientele, and create a personalized treatment environment
                    that aligns with their values and goals.
                  </li>
                  <li>
                    <span className="text-[#003C9D]">
                      Educators and Trainers:{" "}
                    </span>{" "}
                    Experienced massage therapists may pursue opportunities to
                    teach and mentor aspiring massage therapists by working as
                    educators in massage therapy schools, colleges, or
                    vocational training programs. They share their knowledge,
                    skills, and passion for the profession with the next
                    generation of practitioners.
                  </li>
                  <li>
                    <span className="text-[#003C9D]">Entrepreneurship: </span>{" "}
                    Entrepreneurial-minded massage therapists may explore
                    various business opportunities, such as mobile massage
                    services, online coaching, retreats, workshops, and product
                    sales (e.g., massage oils, self-care products). They have
                    the freedom to innovate and create unique offerings tailored
                    to their niche market.
                    <p>
                      Graduates of the Massage Therapy program can pursue
                      diverse and rewarding career paths that allow them to make
                      a positive impact on the health and well-being of others
                      while enjoying flexibility, autonomy, and personal
                      fulfillment in their work.
                    </p>
                  </li>
                </ul>
              </div>
            </li>

            <li>
              Is certification available upon completion of the Massage Therapy
              program?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  Yes, Massage Therapy program offers certification from the
                  Param Institute of Education upon successful completion of the
                  coursework and any required assessments. Certification
                  demonstrates proficiency in Massage Therapy Practice and may
                  enhance career opportunities.
                </p>
              </div>
            </li>

            <li>
              How long does it take to complete the Massage Therapy program?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>The duration of the program may be completed in 36 weeks.</p>
              </div>
            </li>

            <li>
              When can I start the course?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  This course is open enrollment, so you can register and start
                  the course as soon as you are ready.
                </p>
              </div>
            </li>

            <li>
              What kind of support will I receive?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  During the course, you can reach out to your instructor via
                  email for any questions or feedback on your performance.
                  Occasionally, industry experts may also contribute to your
                  learning experience. You will have the support of a student
                  advising team available to assist you throughout your journey.
                </p>
              </div>
            </li>

            <li>
              What if I don't have enough time to complete my course within the
              time provided?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  The duration for completing the course has been determined
                  according to the total number of course hours. If you find
                  yourself unable to finish within the allotted time, you can
                  reach out to the student advising team to explore potential
                  options for extending your completion date. Please be aware
                  that there may be an extension fee associated with this.
                </p>
              </div>
            </li>

            <li>
              Can I be assured of employment after completing the program?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  This course equips you with the necessary skills for securing
                  a job position in many cases. We offer support with resume
                  preparation and job search strategies to aid you in finding
                  employment. Additionally, prospective students are advised to
                  research the job market in their area before enrolling.
                </p>
              </div>
            </li>

            <li>
              Can I get financial assistance?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  This course is non-credit, so it does not qualify for federal
                  aid, FAFSA and/or Pell Grant.
                </p>
              </div>
            </li>

            <li>
              How can I get more information about this course?
              <div className="flex flex-col gap-[10px] mt-[10px] font-[400] text-[#5C616E]">
                <p>
                  If you have questions that are not answered on our website,
                  representatives are available via EMAIL (info@paramedu.com).
                  You can also call us at +1 848 256 2737 during regular
                  business hours to have your questions promptly answered. If
                  you are visiting us during non-business hours, please send us
                  a question using the "Contact Us" form.
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Faqs;
