import React from "react";
import { useNavigate } from "react-router-dom";

function WhoHire() {
  const navigation = useNavigate();
  return (
    <div className=" w-full flex flex-col items-center font-poppins mb-28 gap-12 md:gap-20">
      <div className="bg-whoHireBg-bg bg-center bg-cover w-full h-[400px] md:h-[600px] justify-center items-center flex flex-col gap-8 md:gap-12 ">
        <p className="font-[800] text-[32px] md:text-[50px] xl:text-[60px] w-[calc(100%_-_32px)] md:w-[calc(100%_-_64px)] text-white text-center">
          Who Hires Our Graduates?
        </p>
        <div className=" flex justify-center gap-4 md:gap-8 flex-col md:flex-row">
          <button
            className="orangeButton"
            onClick={() => navigation("../scheduleAppointment")}
          >
            Schedule a Visit
          </button>
          <button
            className="orangeButton"
            onClick={() => navigation("../requestInfo")}
          >
            Inquire Now
          </button>
          <button
            className="orangeButton"
            onClick={() => navigation("../enrollment")}
          >
            Enroll Now
          </button>
        </div>
      </div>
      <div className="w-[calc(100%_-_32px)] md:w-[calc(100%_-_64px)] xl:w-[90%] flex gap-10 flex-col">
        <div className="w-full shadow-lg px-[16px] py-[20px] pt-0 md:px-[40px]">
          <p className="font-[500] text-[20px] md:text-[24px] text-[#003C9D] pb-[30px]">
            Our Graduates are Being Hired
          </p>
          <div className=" gap-10 md:gap-10 grid grid-cols-1 991:grid-cols-2 2xl:grid-cols-3 ">
            <ul className=" list-disc font-[400] text-[18px] md:text-[20px] text-[#5C616E] flex  list-outside 991:list-outside gap-10 ml-7 md:ml-5 flex-col">
              <li>Changebridge Medical Center</li>
              <li>Regional Cancer Center</li>
              <li>Teij Medical Inc</li>
              <li>Harvard Cardiovascular Inc.</li>
              <li>Ocen Heart Group</li>
              <li>Healing Joints Physical Therapy</li>
              <li>Sahni Heart Center</li>
              <li>University Urgi Care</li>
              <li>Lab Corp</li>
              <li>Param Healthcare & IT Services</li>
              <li>Unity Home Healthcare</li>
            </ul>
            <ul className=" list-disc font-[400] text-[18px] md:text-[20px] text-[#5C616E] flex list-outside 991:list-outside gap-10 ml-7 md:ml-5 flex-col">
              <li>AristaCare</li>
              <li>Home Care Agencies</li>
              <li>Adult Day Cares</li>
              <li>CareOne (Long-Term Care Facility)</li>
              <li>Accurate Lab</li>
              <li>Avanti Medical Lab</li>
              <li>Various Pharmacies</li>
              <li>Wellness Centers & Medical Offices</li>
              <li>Jewish Renaissance</li>
              <li>Almeda Nursing Home</li>
              <li>Quest Diagnostics</li>
            </ul>
            <ul className=" list-disc font-[400] text-[18px] md:text-[20px] text-[#5C616E] flex list-outside 991:list-outside gap-10 ml-7 md:ml-5 flex-col">
              <li>Robert Wood Johnson University Hospital</li>
              <li>HMH JFK University Medical Center</li>
              <li>Senior Care Centers</li>
              <li>Long-Term Care Facility</li>
              <li>Rehab Centers </li>
              <li>Saint Peter's University Hospital</li>
              <li>Medical Offices (Separate from the wellness centers)</li>
              <li>New Jersey Imaging Network</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WhoHire;
