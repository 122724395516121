import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const AdmissionProcess = () => {
  const navigation = useNavigate();
  return (
    <div className=" w-full flex flex-col items-center font-poppins mb-28">
      <div className="bg-admissionProcess-bg bg-cover bg-center w-full h-[400px] md:h-[600px] justify-center items-center flex flex-col gap-8 md:gap-12 font-poppins">
        <p className="font-[800] text-[32px] md:text-[50px] xl:text-[60px]  w-[calc(100%_-_32px)] md:w-[calc(100%_-_64px)] text-white text-center">
          Admissions Process
        </p>
        <div className=" flex justify-center gap-4 md:gap-8 flex-col md:flex-row">
          <button
            className="orangeButton"
            onClick={() => navigation("../scheduleAppointment")}
          >
            Schedule a Visit
          </button>
          <button
            className="orangeButton"
            onClick={() => navigation("../requestInfo")}
          >
            Inquire Now
          </button>
          <button
            className="orangeButton"
            onClick={() => navigation("../enrollment")}
          >
            Enroll Now
          </button>
        </div>
      </div>

      <div className="w-[calc(100%_-_32px)] md:w-[calc(100%_-_64px)] xl:w-[90%] mt-[50px] md:mt-20">
        <div className="text-[18px] md:text-[20px] leading-[45px] text-[#5C616E]">
          <p className="font-[500] text-[24px] xl:text-[32px] leading-[36px] xl:leading-[48px] text-[#003C9D]">
            Admission Procedure at Param Institute of Education
          </p>
          <p className="mt-10">
            To start your journey toward a fulfilling and successful career in
            healthcare, You must begin with the admission process. The following
            procedure is the general practice for the admission:
          </p>
          <ul className="list-disc list-outside ml-6 md:ml-8 mt-4 md:mt-11 xl:mt-4 flex flex-col gap-6">
            <li className="text-[20px] md:text-[24px] font-[500] text-[#F37021]">
              Get Connected- Our Admission Team
              <p className="text-[20px] font-[400] text-[#5C616E] mt-4">
                Take the first step. Connect to our expert team for more details
                about the admission process. Call or schedule an appointment
                online to learn more about our institute, your program of
                interest, and the admission requirements. Our counselors are
                here to help you decide and choose the best program and career
                based on your background and experience. 
              </p>
            </li>

            <li className="text-[20px] md:text-[24px] font-[500] text-[#F37021]">
              School Tour
              <p className="text-[20px] font-[400] text-[#5C616E] mt-4">
                Once a student connects to our admission team and confirms to
                join our institute, they will be given a tour of the facilities.
                This will give you an opportunity to see our classrooms and
                medical labs, and also meet the instructional team. You may also
                be able to experience and see our live class.
              </p>
            </li>

            <li className="text-[20px] md:text-[24px] font-[500] text-[#F37021]">
              Financial Assistance
              <p className="text-[20px] font-[400] text-[#5C616E] mt-4">
                We have an advisor available virtually or at the institute to
                assist with any financial questions. The school advisor can
                discuss your financial needs, help you with sponsorship if
                eligible, and also assist with payment plans if needed.
                Furthermore, PIE has collaborated and developed relations with
                different county offices in NJ, hence we can also get you
                connected to a One-Stop Career Center in your county for grants.
                Eligibility criteria are screened by county officials. that can
                be received if qualified.
              </p>
            </li>

            <li className="text-[20px] md:text-[24px] font-[500] text-[#F37021]">
              Entrance Test/ Exams
              <p className="text-[20px] font-[400] text-[#5C616E] mt-4">
                <p>
                  Once you have decided on the program, you must provide the
                  following items and schedule for the Admissions/ Entrance 
                  Exam.
                </p>
                <p>
                  You may take Online entrance exams ( Wonderlic SLE-Q) on or
                  before the enrollment of any certification program at PIE
                  except LPN program.  The exams login credentials will be
                  provided by admission personnel.
                </p>
                <p>
                  For LPN Program{" "}
                  <span className="font-[600]">
                    if you choose to complete the entrance exam (Wonderlic)
                    offered by our school, please contact school personnel for
                    more information.
                  </span>
                </p>
              </p>
            </li>
          </ul>
        </div>
      </div>

      <div className="w-[calc(100%_-_32px)] md:w-[calc(100%_-_64px)] xl:w-[90%] mt-[50px] md:mt-[60px]">
        <div className="text-[18px] md:text-[20px] leading-[45px] text-[#5C616E]">
          <p className="font-[500] text-[24px] xl:text-[32px] leading-[36px] xl:leading-[48px] text-[#003C9D]">
            Steps for Application and Enrollment
          </p>
          <p className="mt-6 md:mt-10">
            After gathering all the necessary information and finalizing the
            program to be enrolled, the next step is to submit your
            application. 
          </p>
          <ul className="list-disc list-outside ml-6 md:ml-8 mt-6 flex flex-col gap-6">
            <li className="text-[20px] md:text-[24px] font-[500] text-[#F37021]">
              <a className="underline" href="../enrollment">
                Step 1: Complete Enrollment Application
              </a>
              <p className="text-[18px] md:text-[20px] font-[400] text-[#5C616E] mt-4">
                Complete the application form- you can
                <p>1. Submit online application (Provide link)</p>
                <p className="ml-36">OR</p>
                <p>
                  2. Visit the school to enroll and complete On- Site enrollment
                </p>
              </p>
            </li>

            <li className="text-[20px] md:text-[24px] font-[500] text-[#F37021]">
              <span className="underline">
                Step 2: Submission of Pre- Enrollment Document
              </span>
              <p className="text-[18px] md:text-[20px] font-[400] text-[#5C616E] mt-4 flex flex-col gap-[10px]">
                <p>
                  Submit in-person and/or upload online necessary documentations
                  such as ID proof, U.S. Residential Proof, Enrollment
                  Agreement, Enrollment Application Form, Entrance Test Results,
                  Background check and other{" "}
                  <a
                    className="underline text-[#003C9D] font-[500]"
                    href="./requirementAndEligibility"
                  >
                    Admission Requirement/ Eligibility
                  </a>{" "}
                  must be completed during the enrollment process.
                </p>
                <p className="font-[500] italic">
                  <span className="text-[#FF302F] font-[600] text-[22px] not-italic">
                    Note:{" "}
                  </span>
                  All documents must be submitted on or before the day of the
                  enrollment appointment.
                </p>
              </p>
            </li>
            <li className="text-[20px] md:text-[24px] font-[500] text-[#F37021]">
              <span className="underline">
                Step 3: Complete Additional Enrollment Requirements
              </span>
              <p className="text-[18px] md:text-[20px] font-[400] text-[#5C616E] mt-4 flex flex-col gap-[10px]">
                <p>
                  Students will complete an interview for applicable programs,
                  pay an application fee, complete all required enrollment
                  documents, recommendation letter if applicable and sign the
                  enrollment agreement.
                </p>
                <p>
                  Also, submit all pending documents, including the health
                  immunization and physical records and background check / drug
                  screening (if applicable), should be submitted by student as
                  per the program requirement.
                </p>
              </p>
            </li>
            <li className="text-[20px] md:text-[24px] font-[500] text-[#F37021]">
              <span className="underline">Step 4: Application Decision</span>
              <p className="text-[18px] md:text-[20px] font-[400] text-[#5C616E] mt-4 flex flex-col gap-[10px]">
                <p>
                  Once a student has completed all application steps and submit
                  required recommendation documents, their application packet
                  will be review by designated personnel and after reviewing
                  final decision will be taken for enrollment.
                </p>
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AdmissionProcess;
