import React, { useState } from 'react';
import Pagination from '../Pagination';
import defaultImage from "../../assets/images/defaultImage.svg";

function TestimonialReview() {
    const pageSize = 10;
    const current_page = 1;
    const [currentPage, setCurrentPage] = useState(current_page);
    const testimonial = [
        {
          img: defaultImage,
          name: "Sucheta Sardesai",
          title: "Medical Billing and Coding Program (MBCS)",
          content:
            "I have completed Medical Billing and Coding Program (MBCS) from Param Institute of Education, I found the  services offered at PIE to be excellent and helpful, the staff is very supportive and polite. They also provide excellent services with externship and placement in the medical field. I highly recommend this Institute for great education and learning in healthcare industry",
        },
        {
          img: defaultImage,
          name: "Kezia Samuel",
          title: "Certified Home Health Aid (CHHA)",
          content:
            "I completed my CHHA (Certified Home Health Aid) program from Param Institute of Education. The program was easy to complete. The faculty was very nice, friendly, and helpful. I absolutely loved the process and experience with them! Would highly recommend anyone that is interested to give them a try.",
        },
        {
          img: defaultImage,
          name: "Ally Licks",
          title: "Certified Phlebotomy Technician (CPT)",
          content:
            "My name is Ally Licks, and I just completed my Certified Phlebotomy Technician (CPT) class exam, the admissions process was very easily navigated. The class was great! Small class sizes, and great individualized instruction. I would highly recommend this program, and cannot thank the instructors and staff enough!",
        },
        {
            img: defaultImage,
            name: "Jignasa Patel",
            title: "Certified Clinical Medical Assistant (CCMA)",
            content: "My name is Jignasa Patel, and I've recently completed my studies in the Certified Clinical Medical Assistant (CCMA) program at PARAM Institute of Education. I must say, my experience at PARAM has been absolutely remarkable. The faculty and instructors provided unwavering support, ensuring a seamless and enriching journey every step of the way. Throughout my externship at a local physician's office, I gained invaluable hands-on experience, seamlessly connecting theory with practice. The program's comprehensive curriculum left no stone unturned, equipping me with the knowledge and skills necessary to excel in patient care and ace the NHA exam. I wholeheartedly recommend PARAM Institute of Education to anyone considering a career in healthcare. With its outstanding CCMA program, dedicated staff, and emphasis on practical learning, PARAM truly stands out as the ideal choice."
        },
        {
            img: defaultImage,
            name: "Shefali Makhesana",
            title: "Massage therapy",
            content: "Completing the Massage therapy course at Param Institute of Education has been an enriching journey. The comprehensive curriculum, expert instructors, and hands on training have equipped me with a deep understanding of massage techniques and holistic wellness practices. The institute's dedication to excellence in education truly shines through, and I highly recommend it to anyone considering a career in Massage Therapy Program. Thank you Param Institute of Education, for helping me achieve my goals in this field."
        },
      ]
  return (
    <div className='flex flex-col items-center w-full gap-16 justify-center'>
        {testimonial.map((option) => (         
            <div className='w-full shadow-lg rounded-lg  md:h-fit h-fit p-6 flex gap-11 flex-col md:flex-row  md:justify-center '>
                <div className='flex flex-col gap-6 md:w-[18%] w-full  items-center justify-center h-auto'>
                    <div className=' '>
                        <img className='w-[192px] h-[166px]' src={option.img}></img>
                    </div>
                    <div className='flex flex-col gap-2 items-center'>
                        <label className='font-[500] text-[18px] md:text-[20px] xl:text-[24px] text-[#5C616E] leading-9'>{option.name}</label>
                        <label className='font-[400] text-[14px] md:text-[16px] xl:text-[16px] text-[#5C616E] leading-6 text-center'>{option.title}</label>
                    </div>
                </div>
                <div className='border border-[#D9D9D9]'></div>
                <div className='flex flex-col md:w-[78%] w-full justify-center gap-1'>
                    <label className='font-[400] text-[14px] md:text-[16px] xl:text-[16px] text-[#5C616E] leading-6 text-center md:text-left'>{option.content}</label>
                    {/* <label className='font-[400] text-[14px] md:text-[16px] xl:text-[16px] text-[#5C616E] leading-6 text-center md:text-left'>Lorem ipsum dolor sit amet consectetur. Purus orci enim nisl suspendisse sollicitudin metus. Viverra nulla volutpat pulvinar duis porttitor vel massa et mattis. Dictumst nunc ipsum duis consectetur id nulla diam sed non. Habitant purus orci morbi dignissim sed. Quam at sed malesuada velit. Praesent ultrices sapien quis nec suspendisse. Fusce sed nullam sed bibendum nulla integer molestie diam.
                    Bibendum magna sed dapibus quam interdum. Senectus potenti faucibus ornare varius orci dictum montes. Sit bibendum morbi ac morbi senectus vestibulum. Dui amet magnis scelerisque egestas id nullam. Venenatis pretium faucibus elementum in sit arcu. Vel sem feugiat nulla elementum lacus malesuada commodo. Posuere leo enim sed interdum in. Lectus arcu aliquam nulla lacus. Aliquet amet molestie erat eget pretium. Aliquam eget et convallis massa amet vulputate. In vel egestas id leo nibh consectetur pharetra quis leo. Risus suscipit vitae morbi sem sapien iaculis.</label> */}
                </div>
            </div>
        ))}
        {/* <div className="w-full md:w-[90%] flex justify-center 991:justify-center">
            <div>
                <Pagination totalPages={10} currentPage={currentPage} setCurrentPage={setCurrentPage}/>
            </div>
        </div> */}
  </div>
  )
}

export default TestimonialReview