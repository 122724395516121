import React from "react";

const TermsAndCondition = () => {
  return (
    <div className="bg-[#F5F5F5] font-poppins px-4 md:px-8 xl:px-20 py-12 flex flex-col items-center gap-12 -mb-10">
      <p className="font-[600] text-[32px] md:text-[40px] xl:text-[42px] text-[#F37021] underline">
        Legal Terms and Conditions
      </p>
      <div className="text-[20px] md:text-[24px] text-[#5C616E] flex flex-col gap-8">
        <div>
          <p className="text-[#003C9D] font-[500] leading-[35px]">
            Param Institute of Education (PIE):
          </p>
          <p className="text-[18px] md:text-[20px] leading-[45px] mt-4">
            Welcome to the website of Param Institute of Education (PIE). By
            accessing or using this Site, you agree to comply with and be bound
            by the following terms and conditions ("Terms"). If you do not agree
            to these Terms, you must not use this Site. Please read these Terms
            carefully.
          </p>
        </div>
        <div>
          <p className="text-[#003C9D] font-[500] leading-[35px]">
            Terms and Conditions:
          </p>
          <p className="text-[18px] md:text-[20px] leading-[45px] mt-4">
            The Param Institute of Education website is offered to you on the
            condition that you accept, without modification, our terms,
            conditions, and notices contained herein (the “Terms”). Your use of
            this Site constitutes your complete agreement to all such Terms. PIE
            makes no warranty or guarantee concerning the accuracy or
            reliability of the content at this Site or other sites to which we
            link. If you do not agree to these Terms, you are not permitted to
            use this Site.
          </p>
        </div>
        <div>
          <p className="text-[#003C9D] font-[500] leading-[35px]">
            I. Privacy Statement:
          </p>
          <p className="text-[18px] md:text-[20px] leading-[45px] mt-4">
            Your use of this Site is subject to the PIE Privacy Policy. Please
            review our Privacy Policy, which also governs the Site and informs
            users of our data collection practices.
          </p>
          <p className="text-[18px] md:text-[20px] leading-[45px] mt-4">
            When requesting information or scheduling an appointment, please
            provide accurate and complete information (including but not limited
            to your name, email address, phone number, etc.). Children 13 years
            old and younger may visit this Site without disclosing any
            personally identifiable information.
          </p>
        </div>
        <div>
          <p className="text-[#003C9D] font-[500] leading-[35px]">
            II. Electronic Communication:
          </p>
          <p className="text-[18px] md:text-[20px] leading-[45px] mt-4">
            By visiting the Site or sending emails to PIE, you are communicating
            with us electronically. You consent to receive communications from
            us electronically. We will communicate with you via email or by
            posting notices on this Site. You agree that all agreements,
            notices, disclosures, and other communications that we provide to
            you electronically satisfy any legal requirement that such
            communications be in writing.
          </p>
        </div>
        <div>
          <p className="text-[#003C9D] font-[500] leading-[35px]">
            III. Links to Third-Party Sites:
          </p>
          <p className="text-[18px] md:text-[20px] leading-[45px] mt-4">
            Our website may contain links to third-party websites (“Linked
            Sites”). The Linked Sites are not under the control of Param
            Institute of Education and we are not responsible for the contents
            of any Linked Site, including without limitation any link contained
            in a Linked Site, or any changes or updates to a Linked Site. Param
            Institute of Education provides these links to you only as a
            convenience, and the inclusion of any link does not imply
            endorsement by Param Institute of Education or any association with
            its operators.
          </p>
        </div>
        <div>
          <p className="text-[#003C9D] font-[500] leading-[35px]">
            IV. No Unlawful or Prohibited Use:
          </p>
          <p className="text-[18px] md:text-[20px] leading-[45px] mt-4">
            By providing consent and agreeing with all terms and conditions of
            PIE you may be granted a nonexclusive, nontransferable, revocable
            license to access and use Param Institute of Education strictly
            following these Terms. As a condition of your use of the Site, you
            warrant to Param Institute of Education that you will not use the
            Site for any purpose that is unlawful or prohibited by these Terms.
            You may not use the Site in any manner that could damage, disable,
            overburden, or impair the Site or interfere with any other party’s
            use and enjoyment of the Site. You may not obtain or attempt to
            obtain any materials or information through any means not
            intentionally made available or provided for through the Site.
          </p>
          <p className="text-[18px] md:text-[20px] leading-[45px] mt-4">
            All content included as part of the Site, such as text, graphics,
            logos, images, as well as the compilation thereof, and any software
            used on the Site, is the property of Param Institute of Education or
            its suppliers and protected by copyright and other laws that protect
            intellectual property and proprietary rights. You agree to observe
            and abide by all copyright and other proprietary notices, legends,
            or other restrictions contained in any such content and will not
            make any changes thereto.
          </p>
        </div>
        <div>
          <p className="text-[#003C9D] font-[500] leading-[35px]">
            I. Use of Communication Services:
          </p>
          <p className="text-[18px] md:text-[20px] leading-[45px] mt-4">
            The Site may contain bulletin board services, chat areas, news
            groups, forums, communities, personal web pages, calendars, and/or
            other message or communication facilities designed to enable you to
            communicate with the public at large or with a group (collectively,
            "Communication Services"). You agree to use the Communication
            Services only to post, send, and receive messages and material that
            are proper and related to the particular Communication Service. By
            way of example, and not as a limitation, you agree that when using a
            Communication Service, you will not:
          </p>
          <ol className="text-[18px] md:text-[20px] leading-[45px] mt-4 list-alpha ml-7">
            <li>
              Defame, abuse, harass, stalk, threaten, or otherwise violate the
              legal rights (such as rights of privacy and publicity) of others.
            </li>
            <li>
              Publish, post, upload, distribute, or disseminate any
              inappropriate, profane, defamatory, infringing, obscene, indecent,
              or unlawful topic, name, material, or information.
            </li>
            <li>
              Upload files that contain software or other material protected by
              intellectual property laws (or by rights of privacy of publicity)
              unless you own or control the rights thereto or have received all
              necessary consents.
            </li>
            <li>
              Upload files that contain viruses, corrupted files, or any other
              similar software or programs that may damage the operation of
              another computer.
            </li>
            <li>
              Advertise or offer to sell or buy any goods or services for any
              business purpose, unless such Communication Service specifically
              allows such messages.
            </li>
            <li>
              Conduct or forward surveys, contests, pyramid schemes, or chain
              letters.
            </li>
            <li>
              Download any file posted by another user of a Communication
              Service that you know, or reasonably should know, cannot be
              legally distributed in such a manner.
            </li>
            <li>
              Falsify or delete any author attributions, legal or other proper
              notices or proprietary designations, or labels of the origin or
              source of software or other material contained in a file that is
              uploaded.
            </li>
            <li>
              Restrict or inhibit any other user from using and enjoying the
              Communication Services.
            </li>
            <li>
              Violate any code of conduct or other guidelines that may apply to
              any particular Communication Service.
            </li>
            <li>
              Harvest or otherwise collect information about others, including
              email addresses, without their consent.
            </li>
            <li>Violate any applicable laws or regulations.</li>
          </ol>
          <p className="text-[18px] md:text-[20px] leading-[45px] mt-2">
            PIE has no obligation to monitor the Communication Services.
            However, PIE reserves the right to review materials posted to a
            Communication Service and to remove any materials at its sole
            discretion. PIE reserves the right to terminate your access to any
            or all of the Communication Services at any time without notice for
            any reason whatsoever.
          </p>
        </div>
        <div>
          <p className="text-[#003C9D] font-[500] leading-[35px]">
            VI. International Users:
          </p>
          <p className="text-[18px] md:text-[20px] leading-[45px] mt-4">
            This Site is controlled, operated, and administered by PIE from our
            office within the USA. If you access the Site from a location
            outside the USA, you are responsible for compliance with all local
            laws. You agree that you will not use the PIE content accessed
            through this Site in any country or any manner prohibited by any
            applicable laws, restrictions, or regulations.
          </p>
        </div>
        <div>
          <p className="text-[#003C9D] font-[500] leading-[35px]">
            VII. Indemnification:
          </p>
          <p className="text-[18px] md:text-[20px] leading-[45px] mt-4">
            You agree to indemnify, defend, and hold harmless PIE, its officers,
            directors, employees, agents, and third parties, for any losses,
            costs, liabilities, and expenses (including attorney’s fees)
            relating to or arising out of your use of or inability to use the
            Site or services, any user postings made by you, your violation of
            any terms of this Agreement or your violation of any rights of a
            third party, or your violation of any applicable laws, rules, or
            regulations. PIE reserves the right, at its own cost, to assume the
            exclusive defense and control of any matter otherwise subject to
            indemnification by you, in which event you will fully cooperate with
            PIE in asserting any available defenses.
          </p>
        </div>
        <div>
          <p className="text-[#003C9D] font-[500] leading-[35px]">
            VIII. Liability Disclaimer:
          </p>
          <p className="text-[18px] md:text-[20px] leading-[45px] mt-4">
            The information, software, and services included in or available
            through the Site may include inaccuracies or typographical errors.
            Changes are periodically added to the information herein. PIE and/or
            its suppliers may make improvements and/or changes to the Site at
            any time.
          </p>
          <p className="text-[18px] md:text-[20px] leading-[45px] mt-4">
            PIE and/or its suppliers make no representations about the
            suitability, reliability, availability, timeliness, and accuracy of
            the information, software, services, and related graphics contained
            on the Site for any purpose. To the maximum extent permitted by
            applicable law, all such information, software, services, and
            related graphics are provided “as is” without warranty or condition
            of any kind. PIE and/or its suppliers hereby disclaim all warranties
            and conditions concerning this information, software, services, and
            related graphics, including all implied warranties or conditions of
            merchantability, fitness for a particular purpose, title, and
            non-infringement.
          </p>
        </div>

        <div>
          <p className="text-[#003C9D] font-[500] leading-[35px]">
            IX. Termination/Access Restriction:
          </p>
          <p className="text-[18px] md:text-[20px] leading-[45px] mt-4">
            PIE reserves the right to update or change this privacy policy at
            any time. Any changes will be posted on this page with an updated
            effective date. You are encouraged to review this privacy policy
            periodically to stay informed about how we are protecting the
            personal information we collect.
          </p>
          <p className="text-[18px] md:text-[20px] leading-[45px] mt-4">
            You agree that no joint venture, partnership, employment, or agency
            relationship exists between you and PIE as a result of this
            agreement or use of the Site. PIE’s performance of this agreement is
            subject to existing laws and legal processes, and nothing contained
            in this agreement derogates PIE’s right to comply with governmental,
            court, and law enforcement requests or requirements relating to your
            use of the Site or information provided to or gathered by PIE
            concerning such use.
          </p>
          <p className="text-[18px] md:text-[20px] leading-[45px] mt-4">
            If any part of this agreement is determined to be invalid or
            unenforceable according to applicable law, including but not limited
            to the warranty disclaimers and liability limitations set forth
            above, the invalid or unenforceable provision will be deemed
            superseded by a valid, enforceable provision that most closely
            matches the intent of the original provision. The remainder of the
            agreement shall continue in effect.
          </p>
          <p className="text-[18px] md:text-[20px] leading-[45px] mt-4">
            Unless otherwise specified herein, this agreement constitutes the
            entire agreement between the user and PIE concerning the Site,
            superseding all prior or contemporaneous communications and
            proposals, whether electronic, oral, or written, between the user
            and PIE concerning the Site. Superseding all prior or
            contemporaneous communications and proposals, whether electronic,
            oral, or written, between the user and PIE concerning the Site. A
            printed version of this agreement and any notice given in electronic
            form shall be admissible in judicial or administrative proceedings
            based upon or relating to this agreement to the same extent and
            subject to the same conditions as other business documents and
            records originally generated and maintained in printed form. It is
            the express wish of the parties that this agreement and all related
            documents be written in English.
          </p>
        </div>
        <div>
          <p className="text-[#003C9D] font-[500] leading-[35px]">
            X. Changes to Terms:
          </p>
          <p className="text-[18px] md:text-[20px] leading-[45px] mt-4">
            These Legal Terms and Conditions are effective from July 2024.
            However, PIE reserves the right, at its sole discretion, to change
            the Terms under which the Site is offered. The most current version
            of the Terms will supersede all previous versions. PIE encourages
            you to periodically review the Terms to stay informed of our
            updates.
          </p>
          <p className="text-[18px] md:text-[20px] leading-[45px] mt-4">
            If you have any questions regarding these Terms, please contact us
            at:
          </p>
          <p className="text-[18px] md:text-[20px] leading-[45px] mt-4">
            <div className="font-[500] ">
              <p>Param Institute of Education</p>
              <p>200 Middlesex Essex Tpke, Suite 105</p>
              <p>Iselin, NJ 08830</p>
            </div>
          </p>
        </div>
      </div>
    </div>
  );
};

export default TermsAndCondition;
